/**
 * PS_DAF_1.0
 * import the neccassary files, packages
 * PS_DAF_1.2
 */
import moment from 'moment';
import CONSTANTS from '../constants/constant';
import { MenuConfig } from '../interface/paymentInfoInterface';

/**
 * PS_DAF_1.3
 * @param schedule
 * @returns schedule
 * THis function returns the schedule
 * PS_DAF_1.12
 */
export function schedule(schedule: string) {
  const semi = schedule?.includes(CONSTANTS.SEMI) ? CONSTANTS.SEMI : schedule;
  switch (semi) {
    case CONSTANTS.WK:
      return CONSTANTS.WEEKLY;
    case CONSTANTS.BIWK:
      return CONSTANTS.BI_WEEKLY;
    case CONSTANTS.MON:
      return CONSTANTS.MONTHLY;
    case CONSTANTS.SEMI:
      return CONSTANTS.SEMI_MONTHLY;
    default:
      return CONSTANTS.EMPTY_STRING;
  }
}

/**
 * PS_DAF_1.13
 * @param date
 * @param format
 * This function formats the date
 * @returns date
 * PS_DAF_1.16
 */
//Need to add format type in all places this fuction invokes
export function dateFormat(date: string | Date | undefined, format: string) {
  if (date == CONSTANTS.HYPHEN || date == CONSTANTS.EMPTY_STRING) return date;
  else return moment(date).format(format);
}

/**
 * PS_DAF_1.17
 * @param date
 * @returns date
 * This function formats the date without the day
 * PS_DAF_1.20
 */
export function dateFormatWithoutDay(date: string) {
  return date ? moment(date).format(CONSTANTS.MM_DD_YYYY) : null;
}

/**
 * PS_DAF_1.21
 * @param desc
 * @param size
 * @returns ellipsis
 * PS_DAF_1.24
 */
export function ellipsisFormat(desc: string, size: number) {
  return desc?.length > size
    ? desc?.substring(0, size) + CONSTANTS.ELLIPSIS
    : desc;
}

/**
 * PS_DAF_1.25
 * @param bestTime
 * @returns Best Time
 * PS_DAF_1.30
 */
export function bestTimeFormat(bestTime: string) {
  //Need to check with the return words (MORN, AFTR, EVE)
  switch (bestTime) {
    case CONSTANTS.CALL_IN_MORNING:
      return CONSTANTS.MORN;
    case CONSTANTS.CALL_IN_EVENING:
      return CONSTANTS.EVE;
    case CONSTANTS.CALL_IN_AFTERNOON:
      return CONSTANTS.AFTN;
  }
}

/**
 * PS_DAF_1.31
 * @param multipleStoreNumber
 * @returns formatted store number
 * PS_DAF_1.35
 */
export function storeNumberFormat(multipleStoreNumber: string[]) {
  const formattedStoreNumber = multipleStoreNumber.join(',');
  return formattedStoreNumber.length > 15
    ? formattedStoreNumber.substring(0, 15) + CONSTANTS.ELLIPSIS
    : formattedStoreNumber;
}

/**
 * PS_DAF_1.36
 * @param amount
 * @param tax
 * @returns
 * PS_DAF_1.38
 */
export function totalDueAmount(amount: string, tax: string) {
  const totalTax = parseFloat(tax.replace(/,/g, ''));
  const totalAmount = parseFloat(amount.replace(/,/g, ''));
  return Number(totalAmount) + Number(totalTax);
}

/**
 * PS_DAF_1.39
 * @param menuConfig
 * @returns cash payment
 * PS_DAF_1.42
 */
export const validateCashPayment = (menuConfig: MenuConfig[]) => {
  return !menuConfig.some(
    (item: MenuConfig) =>
      item.appComponentFunctionName == CONSTANTS.CASH_PAYMENT && item.edit == 1
  );
};

/**
 * PS_DAF_1.43
 * @param splitData
 * @param splitKey
 * @returns splited key
 * PS_DAF_1.44
 */
export const splitMethod = (splitData: string, splitKey: string) => {
  return splitData.split(splitKey);
};

/**
 * PS_DAF_1.45
 * @param dueDate
 * @returns duedate
 * PS_DAF_1.55
 */
export const dueDateFormat = (dueDate: string) => {
  switch (dueDate) {
    case CONSTANTS.EPO:
    case CONSTANTS.INEPO:
      return CONSTANTS.EPO;

    case CONSTANTS.PIF:
    case CONSTANTS.INPIF:
      return CONSTANTS.PIF;

    case CONSTANTS.SAC:
    case CONSTANTS.INSAC:
      return CONSTANTS.SAC;

    default:
      return dueDate;
  }
};

export const primaryPhoneFormat = (input: any) => {
  const cleaned = ('' + input).replace(/\D/g, '');
  const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  return cleaned.replace(PhoneRegex, '($1) $2-$3');
};

export const reduceMethod = (
  array,
  condition1: string,
  condition2: string,
  key: string
) => {
  return array?.reduce(
    (acc: number, item) =>
      acc +
      Number(
        item[condition1] == condition2 ? item[key] : CONSTANTS.ZERO_NUMBER
      ),
    CONSTANTS.ZERO_NUMBER
  );
};
