/* eslint-disable react-hooks/exhaustive-deps */
/**
 * PS_TC_1
 * import necessary components
 */
import React, { useEffect, useState, useContext } from 'react';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { Grid, makeStyles, RACTextbox, RACButton } from '@rentacenter/racstrap';
import {
  calculateRemainingAmountDue,
  resetTenderTypes,
  tenderDetails,
} from '../utils/amountCalculations';
import { agreementContext } from '../context/PaymentInformationContext';
import CONSTANTS from '../constants/constant';
interface propsInterface {
  setRerenderReaminingAmount: (flag: boolean) => void;
  rerenderReaminingAmount: boolean;
}
export default function TenderCash(props: propsInterface) {
  /**
   * declare the styles
   */
  const useClasses = makeStyles(() => ({
    navLink: {
      transition:
        'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out',
      position: 'relative',
      fontFamily: 'OpenSans-semibold',
      color: '#000',
    },
    navLinkactive: {
      color: '#2179FE',
      borderBottom: '5px solid #2468FF !important',
      fontFamily: 'OpenSans-semibold',
      '&:before': {
        content: "''",
        position: 'absolute',
        height: '5px',
        width: '10px',
        background: '#2468ff',
        left: '-5px',
        bottom: '-5px',
        borderTopLeftRadius: '5px',
      },
      '&:after': {
        content: "''",
        position: 'absolute',
        height: '5px',
        width: '10px',
        background: '#2468ff',
        right: '-5px',
        bottom: '-5px',
        borderTopRightRadius: '5px',
      },
    },
    dropdowntoggle: {
      display: 'inline-block',
      marginLeft: '0.255em',
      verticalAlign: '0.255em',
      content: '',
      borderTop: '0.3em solid',
      borderRight: '0.3em solid transparent',
      borderBottom: '0',
      borderLeft: '0.3em solid transparent',
    },
    dropdownitemcustom: {
      color: '#2468FF',
      fontFamily: 'OpenSans-bold',
      backgroundColor: 'white',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    popUp: {
      position: 'absolute',
      bottom: '100%',
      backgroundColor: 'white',
      width: '200px',
      padding: '15px',
      '& li': {
        listStyle: 'none',
      },
    },
    popUpHide: {
      display: 'none',
    },
    customMenuContainer: {
      border: '1px solid #2468FF',
    },
    paymentBtnHover: {
      '&:hover': {
        color: 'white',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
      },
    },
  }));
  const classes = takePaymentStyles();
  const navlinkClass = useClasses();

  /**
   * PS_TC_3
   * Create an variable to hold the context
   */
  const agreementInfo = useContext(agreementContext);

  /**
   * PS_TC_4
   * State variabel declaration
   * PS_TC_5
   */
  const [cashAmount, setCashAmount] = useState<string>(
    CONSTANTS.ZREO_TWO_DECIMAL_STRING
  );
  const [cashAmountChange, setCashAmountChange] = useState<string>(
    CONSTANTS.EMPTY_STRING
  );
  const [restTender, setResetTender] = useState<boolean>(false);

  /**
   * PS_TC_6
   * this useEffect update the cash amount to zero if set to default is clicked
   */
  useEffect(() => {
    setCashAmount(CONSTANTS.ZREO_TWO_DECIMAL_STRING);
  }, [agreementInfo.isSetToDefault]);

  useEffect(() => {
    setResetTender(true);
    if (resetTenderTypes(agreementInfo) && restTender) {
      agreementInfo.customerInfo.amountUsed.tenderDetails.cash.amount =
        CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      agreementInfo.customerInfo.amountUsed.tenderDetails.card = [];
      agreementInfo.customerInfo.amountUsed.tenderDetails.checkDetails = [];
      agreementInfo.customerInfo.amountUsed.tenderDetails.moneyOrderDetails =
        [];
      setCashAmount(CONSTANTS.ZREO_TWO_DECIMAL_STRING);
    }
  }, [agreementInfo.agreementDetails]);

  /**
   * PS_TC_7
   * this useEffect update the cash amount based on the context
   * PS_TC_8
   */
  useEffect(() => {
    setCashAmount(
      agreementInfo.customerInfo.amountUsed.tenderDetails.cash.amount
    );
  }, []);

  /**
   * PS_TC_9
   * this useEffect update the cashAmount based on the condition
   */
  useEffect(() => {
    if (
      cashAmount == CONSTANTS.EMPTY_STRING ||
      cashAmount == CONSTANTS.ZREO_TWO_DECIMAL_STRING
    ) {
      setCashAmount(CONSTANTS.ZREO_TWO_DECIMAL_STRING);
    }
  }, []);

  /**
   * PS_TC_10
   * this useEffect is used to calculte based on the cash amount entered
   * PS_TC_16
   */
  useEffect(() => {
    const remainingAmountDue = parseFloat(
      agreementInfo.customerInfo.amountUsed.remainingAmountDue
    );
    const cashAmountChangeValue =
      cashAmountChange == CONSTANTS.EMPTY_STRING
        ? CONSTANTS.ZREO_TWO_DECIMAL_STRING
        : cashAmountChange;
    let newRemainingAmountDue: number | string = (
      remainingAmountDue - parseFloat(cashAmountChangeValue)
    ).toFixed(2);

    setCashAmountChange(CONSTANTS.ZREO_TWO_DECIMAL_STRING);

    const agreementInfoClone = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );
    const remainAmount = calculateRemainingAmountDue(agreementInfo);
    const remainingAmountDueCalci = remainAmount.remainingAmount;

    if (cashAmount === CONSTANTS.ZERO_STRING) {
      newRemainingAmountDue = remainingAmountDueCalci;
    }

    agreementInfoClone.amountUsed.remainingAmountDue = newRemainingAmountDue;

    agreementInfo.setCustomerInfo(agreementInfoClone);
  }, [cashAmountChange]);

  /**
   * PS_TC_18
   *this function handles the tile click amount change
   * @param event
   * PS_TC_24
   */
  function onAmountChange(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    const currentButtonId = event.currentTarget.id;
    let amountToAdd = 0;
    switch (currentButtonId) {
      case CONSTANTS.ONE_LETTER_STRING:
        amountToAdd = CONSTANTS.ONE_NUMBER;
        break;
      case CONSTANTS.FIVE_LETTER_STRING:
        amountToAdd = CONSTANTS.FIVE_NUMBER;
        break;
      case CONSTANTS.TEN_LETTER_STRING:
        amountToAdd = CONSTANTS.TEN_NUMBER;
        break;
      case CONSTANTS.TWENTY_LETTER_STRING:
        amountToAdd = CONSTANTS.TWENTY_NUMBER;
        break;
      case CONSTANTS.FIFTY_LETTER_STRING:
        amountToAdd = CONSTANTS.FIFTY_NUMBER;
        break;
      case CONSTANTS.HUNDRED_LETTER_STRING:
        amountToAdd = CONSTANTS.HUNDRED_NUMBER;
        break;
    }

    function amountChange(amount: string) {
      const parsedPrevAmount = parseFloat(
        amount.replace(/,/g, CONSTANTS.EMPTY_STRING)
      );
      return (parsedPrevAmount + amountToAdd).toFixed(2);
    }

    setCashAmount((prevAmount) => {
      return amountChange(prevAmount);
    });

    setCashAmountChange((prevAmount) => {
      return amountChange(prevAmount);
    });

    const agreementInfoCloneForTender = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );
    if (
      agreementInfoCloneForTender.amountUsed.tenderDetails.cash.orderId ==
      CONSTANTS.ZERO_NUMBER
    ) {
      agreementInfoCloneForTender.amountUsed.tenderDetails.orderId =
        agreementInfoCloneForTender.amountUsed.tenderDetails.orderId +
        CONSTANTS.ONE_NUMBER;
    }
    agreementInfoCloneForTender.amountUsed.tenderDetails.cash.amount = (
      parseFloat(
        agreementInfoCloneForTender.amountUsed.tenderDetails.cash.amount
      ) + amountToAdd
    ).toFixed(2);
    if (
      agreementInfoCloneForTender.amountUsed.tenderDetails.cash.orderId ==
      CONSTANTS.ZERO_NUMBER
    ) {
      agreementInfoCloneForTender.amountUsed.tenderDetails.cash.orderId =
        agreementInfoCloneForTender.amountUsed.tenderDetails.orderId;
    }

    agreementInfo.setCustomerInfo(agreementInfoCloneForTender);

    props.setRerenderReaminingAmount(!props.rerenderReaminingAmount);
  }

  /**
   * PS_TC_26
   * this function handles amount change on the text box and update the variable
   * @param event
   * PS_TC_28
   */
  function handleAmountChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newAmount = event.target.value;
    setCashAmount(newAmount);
  }

  /**
   * PS_TC_29
   * this function handles amount change on the text box and updates the context
   * @param event
   */
  function handleAmountOnBlur(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value == '' ? '0' : e.target.value;
    const agreementInfoCloneForTender = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );
    const totalAmount = tenderDetails(agreementInfo).reduce(
      (acc: number, tender) => {
        return acc + parseFloat(tender.amount);
      },
      CONSTANTS.ZERO_NUMBER
    );
    const parsedValue = parseFloat(value.replace(/,/g, CONSTANTS.EMPTY_STRING));
    const newRemainingAmountDueWithTenderTypes = (
      parsedValue - totalAmount
    ).toFixed(2);

    setCashAmountChange(newRemainingAmountDueWithTenderTypes);
    setCashAmount(parsedValue.toFixed(2));
    if (
      agreementInfoCloneForTender.amountUsed.tenderDetails.cash.orderId ==
      CONSTANTS.ZERO_NUMBER
    ) {
      agreementInfoCloneForTender.amountUsed.tenderDetails.orderId =
        agreementInfoCloneForTender.amountUsed.tenderDetails.orderId +
        CONSTANTS.ONE_NUMBER;
    }
    agreementInfoCloneForTender.amountUsed.tenderDetails.cash.amount =
      parsedValue.toFixed(2);
    if (
      agreementInfoCloneForTender.amountUsed.tenderDetails.cash.orderId ==
      CONSTANTS.ZERO_NUMBER
    ) {
      agreementInfoCloneForTender.amountUsed.tenderDetails.cash.orderId =
        agreementInfoCloneForTender.amountUsed.tenderDetails.orderId;
    }

    agreementInfo.setCustomerInfo(agreementInfoCloneForTender);
    props.setRerenderReaminingAmount(!props.rerenderReaminingAmount);
  }

  /**
   * this returns the html for tender cash
   */
  return (
    <>
      <Grid
        item
        id="Cash"
        role="tabpanel"
        data-testid="cashtabtestid"
        aria-labelledby="Cash-tab"
      >
        <Grid
          item
          className={`${classes.w100} ${classes.floatLeft} ${classes.my3} ${classes.mt4}`}
        >
          <Grid
            item
            className={`${classes.w25} ${classes.mt2} ${classes.floatLeft}`}
          >
            <label
              htmlFor="a11y_takehome"
              className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
            >
              Amount
            </label>
            <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
              <RACTextbox
                isCurrency={true}
                className={`${classes.custDigit}`}
                dollarTextClassName={classes.currencyDollarField}
                value={cashAmount}
                data-testid="amounttextbox"
                type={'number'}
                digitFormat={'currency'}
                maxlength={9}
                OnChange={(e) => {
                  handleAmountChange(e);
                }}
                Onblur={(e) => {
                  handleAmountOnBlur(e);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            className={`${classes.floatLeft} ${classes.w75} ${classes.ps4} ${classes.mt4} `}
          >
            <RACButton
              className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold}${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
              data-testid="amountval1"
              variant="outlined"
              id={'one'}
              color="primary"
              onClick={(e) => {
                onAmountChange(e);
              }}
            >
              {' '}
              $ 1
            </RACButton>
            <RACButton
              className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold}  ${classes.ms2} ${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
              data-testid="amountval5"
              variant="outlined"
              id={'five'}
              color="primary"
              onClick={(e) => {
                onAmountChange(e);
              }}
            >
              {' '}
              $ 5
            </RACButton>
            <RACButton
              className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold} ${classes.ms2} ${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
              data-testid="amountval10"
              variant="outlined"
              id={'ten'}
              color="primary"
              onClick={(e) => {
                onAmountChange(e);
              }}
            >
              {' '}
              $ 10
            </RACButton>
            <RACButton
              className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold} ${classes.ms2} ${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
              data-testid="amountval20"
              variant="outlined"
              id={'twenty'}
              color="primary"
              onClick={(e) => {
                onAmountChange(e);
              }}
            >
              {' '}
              $ 20
            </RACButton>
            <RACButton
              className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold} ${classes.ms2} ${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
              data-testid="amountval50"
              variant="outlined"
              id={'fifty'}
              color="primary"
              onClick={(e) => {
                onAmountChange(e);
              }}
            >
              {' '}
              $ 50
            </RACButton>
            <RACButton
              className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold} ${classes.ms2} ${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
              data-testid="amountval100"
              variant="outlined"
              id={'hundred'}
              color="primary"
              onClick={(e) => {
                onAmountChange(e);
              }}
            >
              {' '}
              $ 100
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
