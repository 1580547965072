/*eslint-disable */
/**PS_CO_RSD_NO_17
 * Required hooks are imported and the racstrap component is imported.
 */
import { Grid, RACButton, RACModalCard, makeStyles, RACSelect, RACTextbox, TextareaAutosize, Typography } from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../../stylesJS/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import { agreementContext } from '../../context/PaymentInformationContext';
import CONSTANTS from '../../constants/constant';
import { DropDownArray } from '../../interface/commonInterface'
import { RefundSecurityDepositeProps, rsdObj } from '../../interface/refundSecurityDepositeInterface';
import { refundSecurityDepositeStyles } from '../../../stylesJS/refundSecurityDepositeStyle';

/**PS_CO_RSD_NO_18
 * The parent component RefundSecurityDeposite() is declared which helps to render the Refund security deposite popup.
 * And the required state variables are declared.
 */
export function RefundSecurityDeposite(props: RefundSecurityDepositeProps) {

  const classes = takePaymentStyles();
  const classes1 = refundSecurityDepositeStyles();
  const {
    customerInfo, setCustomerInfo, setRenderContext, renderContext
  } = useContext(agreementContext);
  const [proceedBtnDisable, setProceedBtnDisable] = useState(false);
  const dropDownArray: DropDownArray[] = [{ label: CONSTANTS.SELECT, value: CONSTANTS.SELECT }];
  const [rsdDropDownArray, setrsdDropDownArray] = useState<DropDownArray[]>([{ label: CONSTANTS.SELECT, value: CONSTANTS.SELECT }]);
  const [rsdData, setRsdData] = useState<rsdObj>({
    agreementId: CONSTANTS.SELECT,
    agreementNo: CONSTANTS.EMPTY_STRING,
    depositAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    amountToCollect: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    totalRefund: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    description: CONSTANTS.EMPTY_STRING
  });

  /**PS_CO_RSD_NO_19
   * This useEffect is used to form the Drop-Down to render in the refund security deposite popup.
   */
  useEffect(() => {
    if (props.rsdAgreementInfo) {
      props.rsdAgreementInfo.map((el) => {
        const obj: DropDownArray = {
          label: CONSTANTS.Agr + el.agreementNo,
          value: el.agreementId ?? CONSTANTS.EMPTY_STRING
        }
        dropDownArray.push(obj);
      });
      setrsdDropDownArray(dropDownArray);
    }
  }, []);

  /**PS_CO_RSD_NO_26
   * This useEffect is used to enable and disable the proceed button.
   */
  useEffect(() => {
    if (rsdData.agreementId != CONSTANTS.SELECT) {
      setProceedBtnDisable(false);
    }
    else {
      setProceedBtnDisable(true);
    }
  }, [rsdData])

  /**PS_CO_RSD_NO_22 - PS_CO_RSD_NO_25
   * The handleOnchange() method is invoked when the co-worker selects the refund security deposite drop-down
   */
  const handleOnchange = (el: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>, name: string) => {
    let selectedAgr: rsdObj[];
    let value: string;
    let totalRefund: string;
    switch (name) {
      case CONSTANTS.SECURITY_DEPOSITE:
        selectedAgr = props.rsdAgreementInfo.filter(
          (x) => x.agreementId == el.target.value
        );
        setRsdData(selectedAgr[0]);
        break;
      case CONSTANTS.AMOUNT_TO_COLLECT:
        value = el.target.value.replace(CONSTANTS.REPLACE_COMMA, CONSTANTS.EMPTY_STRING);
        totalRefund = Number(Number(rsdData.depositAmount) - Number(value)).toFixed(2) < CONSTANTS.ZREO_TWO_DECIMAL_STRING ? CONSTANTS.ZREO_TWO_DECIMAL_STRING : Number(Number(rsdData.depositAmount) - Number(value)).toFixed(2)
        setRsdData({ ...rsdData, totalRefund: totalRefund, amountToCollect: value })
        break;
      case CONSTANTS.REASON_LOWER_CASE:
        setRsdData({ ...rsdData, description: el.target.value })
    }

  }
  
  const handleOnblur = (val: React.ChangeEvent<HTMLInputElement>)=>{
    const value = String(val.target.value).replace(
      CONSTANTS.REPLACE_COMMA,
      CONSTANTS.EMPTY_STRING
    );
    setRsdData({ ...rsdData, amountToCollect:  value == CONSTANTS.EMPTY_STRING ||
      parseFloat(value) == CONSTANTS.ZERO_NUMBER
        ? CONSTANTS.ZREO_TWO_DECIMAL_STRING
        : Number(value).toFixed(2)})
  }
  /**PS_CO_RSD_NO_27
   * The proceedRefund() method is invoked when the user click the proceed button.
   */
  const proceedRefund = () => {
    const rsdAgreementDetails = customerInfo.rsdAgreementInfo ?? []
    if (rsdAgreementDetails.length > CONSTANTS.ZERO_NUMBER) {

      rsdAgreementDetails.forEach((el: rsdObj, ind: number) => {
        if (el.agreementId == rsdData.agreementId) {
          rsdAgreementDetails[ind] = rsdData;
        }
      })
      setCustomerInfo({ ...customerInfo, rsdAgreementInfo: rsdAgreementDetails })

    }
    if (rsdData?.agreementId && !rsdAgreementDetails.some((x)=> x.agreementId == rsdData?.agreementId)) {
      setCustomerInfo({ ...customerInfo, rsdAgreementInfo: [...customerInfo.rsdAgreementInfo, rsdData] })
    }
    props.onClose(false);
    setRenderContext(!renderContext);

  }

  /**PS_CO_RSD_NO_20 
   * The renderRDSModal() method contains the entire UI of the refund security deposite.
  */
  const renderRDSModal = () => {
    return (
      <>
        <Grid container spacing={2} className={classes.px3}>
          <Grid item md={6} className={classes.mb2}>
            <Typography >Security Deposit</Typography>
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACSelect
              data-testid='securityDepositSelect'
              defaultValue={rsdData.agreementId}
              name='securityDeposit'
              onChange={(el) => handleOnchange(el, CONSTANTS.SECURITY_DEPOSITE)}
              options={rsdDropDownArray}
            />
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <Typography>Deposit Amount</Typography>
          </Grid>

          <Grid item md={6} className={classes.mb2} data-testid='DepositAmount_id'>
            <RACTextbox
              // type='text'
              type='number'
              isCurrency={true}
              // name='amountToCollect'
              maxlength={10}
              className={classes1.dollartext3}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={rsdData.depositAmount}
              disabled={true}
            />
            {/* </div> */}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <Typography
              data-testid="AmountCollected"
            >
              Amount Collected <span className={classes.emphasized}>*</span>
            </Typography>
          </Grid>

          <Grid item md={6} className={classes.mb2}
            data-testid='amountToCollectTextBox'
          >
            <RACTextbox
              type='number'
              isCurrency={true}
              name='amountToCollect'
              maxlength={10}
              
              // required={true}
              className={classes1.dollartext3}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={rsdData.amountToCollect}
              OnChange={(e) => {
                handleOnchange(e, CONSTANTS.AMOUNT_TO_COLLECT)
              }}
            Onblur={(e) => {handleOnblur(e)}}
            />
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <Typography>Total to Refund</Typography>
          </Grid>

          <Grid item md={6} className={classes.mb2} data-testid='totalRefund_id'>
            <RACTextbox
              // type='text'
              type='number'
              isCurrency={true}
              // name='amountToCollect'
              maxlength={10}
              className={classes1.dollartext3}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={rsdData.totalRefund}
              disabled={true}
            />

          </Grid>
        </Grid>
        <Grid container className={classes.px3}>
          <Grid item>
            <Typography>Describe reason for Amount Collected</Typography>
          </Grid>
          <TextareaAutosize
            data-testid='textAreaAutoSize_id'
            minRows={5}
            className={classes.textArea}
            value={rsdData.description}
            name='reason'
            onChange={(e) => {
              handleOnchange(e, CONSTANTS.REASON_LOWER_CASE)
            }}

          />
        </Grid>
        <Grid
          className={`${classes.textright} ${classes.modalfooter} ${classes.floatRight} ${classes.gridwidth} ${classes.mt3}`}
        >
          <RACButton
            className={classes.ms2}
            color='primary'
            variant='outlined'
            style={{
              backgroundColor: 'white',
              color: '#2C3333',
            }}
            onClick={() => {
              props.onClose(false);
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.ms2}
            color='primary'
            variant='contained'
            disabled={proceedBtnDisable}
            onClick={() => proceedRefund()}
          >
            Proceed
          </RACButton>
        </Grid>
      </>
    );
  };

  return (
    <RACModalCard
      isOpen={props.isOpen}
      maxWidth='xs'
      closeIcon={true}
      title='Refund Security Deposit'
      onClose={() => {
        props.onClose(false);
      }}
      borderRadius={'25px !important'}
      // eslint-disable-next-line react/no-children-prop
      children={renderRDSModal()}
    />
  );
}