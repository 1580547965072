/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console*/
/**
 * PS_PSB_1
 */
import React, { useContext, useEffect, useState } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import { calculateTotalDueAmount } from '../utils/calculateTotalDueAmount';
import {
  tenderDetails,
  calculateChangeAmount,
  calculateRefundSecurityDeposit,
} from '../utils/amountCalculations';
import { ReactComponent as Infosus } from '../../assets/images/infosus.svg';
import { ReactComponent as Deleteicon } from '../../assets/images/delete.svg';
import {
  RACTextbox,
  RadioColor,
  RACToggle,
  Grid,
  Typography,
  Box,
  RACModalCard,
  RACButton,
} from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import {
  SideBarInterface,
  tenderDetailsInterface,
  storeInfo,
  rsdAgreementInfo,
} from '../interface/paymentSideBarInterface';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import CONSTANTS from '../constants/constant';
import { AgreementDetails } from '../interface/paymentInfoInterface';

/**
 * PS_PSB_2
 * @returns
 */
export function PaymentSideBar() {
  /**
   * PS_PSB_3, PS_PSB_4
   */
  const classes = takePaymentStyles();
  const storeInform: storeInfo = {
    storeNumber: CONSTANTS.EMPTY_STRING,
    stateProvinceName: CONSTANTS.EMPTY_STRING,
    city: CONSTANTS.EMPTY_STRING,
    zipCode: CONSTANTS.EMPTY_STRING,
    merchantId: CONSTANTS.EMPTY_STRING,
    transferAgreementStatus: false,
    isDonationEnabled: CONSTANTS.EMPTY_STRING,
    donationType: CONSTANTS.EMPTY_STRING,
    donationDesc: CONSTANTS.EMPTY_STRING,
  };

  const agreementInfo = useContext(agreementContext);
  console.log('agreementInfo', agreementInfo);
  const [sideBarData, setSideBarData] = useState<SideBarInterface>({
    totalDue: CONSTANTS.ZERO_DECIMAL_NUMBER,
    totalTax: CONSTANTS.ZERO_DECIMAL_NUMBER,
    totalLateFee: CONSTANTS.ZERO_DECIMAL_NUMBER,
    carryRent: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    carriedRent: CONSTANTS.ZERO_DECIMAL_NUMBER,
    carriedLateRent: CONSTANTS.ZERO_DECIMAL_NUMBER,
    coaUsed: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    webLeadUsed: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    refundSecurityDeposit: [],
    convenienceFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    agreementDetails: agreementInfo.agreementDetails,
    tenderDetails: [],
    totalTenderAmount: CONSTANTS.ZERO_NUMBER,
    balanceAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    donation: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    enableDonation: false,
    balanceReturnTypeRadio: CONSTANTS.CHANGE,
    remainingAmountDue: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
  });

  const [showSuspense, setShowSuspense] = useState<boolean>(false);
  const [storeInformation, setStoreInformation] =
    useState<storeInfo>(storeInform);
  const [donationFeatureFlag, setDonationFeatureFlag] = useState<string>();
  const [disableCredit, setDisableCredit] = useState<boolean>(false);
  const [suspenseDeleteConfirmation, setSuspenseDeleteConfirmation] =
    useState<boolean>(false);
  let cloneData: SideBarInterface = { ...sideBarData };
  let isCoPrelim = false;
  let isCoInital = false;
  agreementInfo.customerOrderPayment == CONSTANTS.IS_CO_PRELIM
    ? (isCoPrelim = true)
    : agreementInfo.customerOrderPayment == CONSTANTS.IS_CO_INITIAL
    ? (isCoInital = true)
    : null;

  /**
   * PS_PSB_5
   * invoking the calculating the totalDue, donation and variable set state.
   */
  useEffect(() => {
    const tenderTypeDetails: tenderDetailsInterface[] =
      tenderDetails(agreementInfo);
    const changeAndRemainingAmountDue = calculateChangeAmount(agreementInfo);

    cloneData = {
      ...sideBarData,
      coaUsed: agreementInfo?.customerInfo?.amountUsed?.coaUsed,
      webLeadUsed: agreementInfo?.customerInfo?.amountUsed?.webLeadUsed,
      carryRent: agreementInfo?.carryRent,
      convenienceFee:
        (agreementInfo?.customerInfo?.amountUsed?.convenienceFee).toString(),
      refundSecurityDeposit: agreementInfo?.customerInfo?.rsdAgreementInfo,
      tenderDetails: tenderTypeDetails,
      balanceAmount: agreementInfo?.customerInfo?.change?.amount,
      remainingAmountDue: changeAndRemainingAmountDue.remainingAmountDue,
    };
    setSideBarData(cloneData);

    const storeDetails: storeInfo[] =
      agreementInfo?.storeInfo?.storeDetails?.filter(
        (store) =>
          store.storeNumber == sessionStorage.getItem(CONSTANTS.STORE_NUMBER)
      ) || [];
    const donationFeature = agreementInfo?.featureFlags?.PaymentDonation;
    setStoreInformation(
      storeDetails?.length > CONSTANTS.ZERO_NUMBER
        ? storeDetails[0]
        : storeInform
    );
    setDonationFeatureFlag(donationFeature);
    calculateDonation(cloneData);
    calculateTotalDue(cloneData);
  }, [agreementInfo.agreementDetails, agreementInfo.customerInfo]);

  /**cog complex starts */
  const calculateTotalDue = (sideBarData: SideBarInterface) => {
    let totalDueAmount: number = CONSTANTS.ZERO_NUMBER;
    let totalTax: number = CONSTANTS.ZERO_NUMBER;
    let totalLateFee: number = CONSTANTS.ZERO_NUMBER;
    let carriedRentTotal: number = CONSTANTS.ZERO_NUMBER;
    let carriedLateRentTotal: number = CONSTANTS.ZERO_NUMBER;

    // Add convenience fee and donation to the total due amount
    totalDueAmount +=
      parseFloat(sideBarData.convenienceFee) +
      parseFloat(agreementInfo.customerInfo.donation);

    // Calculate totals for each selected agreement detail
    agreementInfo?.agreementDetails?.forEach((value) => {
      if (value.selected) {
        const amounts = calculateAmountsForAgreement(value);
        totalDueAmount += amounts.totalDueAmount;
        totalTax += amounts.totalTax;
        totalLateFee += amounts.totalLateFee;
        carriedRentTotal += amounts.carriedRentTotal;
        carriedLateRentTotal += amounts.carriedLateRentTotal;
      }
    });
    if (agreementInfo.customerInfo?.rsdAgreementInfo?.length > 0) {
      const { surrenderAmount, depositAmount } = calculateRefundSecurityDeposit(
        agreementInfo.customerInfo
      );
      totalDueAmount = totalDueAmount - surrenderAmount + depositAmount;
    }

    // Adjust the total due amount by subtracting change amount
    // const changeAmountResponse = calculateChangeAmount(agreementInfo);
    // const changeAmount: number = parseFloat(changeAmountResponse.change);

    // Update state with calculated values
    setSideBarData((prevState) => ({
      ...prevState,
      totalDue: totalDueAmount < 0 ? 0 : totalDueAmount,
      totalTax,
      totalLateFee,
      carriedRent: carriedRentTotal,
      carriedLateRent: carriedLateRentTotal,
    }));

    suspenseAndCreditHideAndShow(totalDueAmount);
  };

  const calculateAmountsForAgreement = (value: AgreementDetails) => {
    const amountForSpecificAgreements =
      calculateTotalDueAmount(value).toString();

    return {
      totalDueAmount: parseFloat(
        amountForSpecificAgreements.includes(CONSTANTS.OPEN_BRACKET_STRING) &&
          amountForSpecificAgreements.includes(CONSTANTS.CLOSE_BRACKET_STRING)
          ? CONSTANTS.ZREO_TWO_DECIMAL_STRING
          : amountForSpecificAgreements
      ),

      totalTax: parseFloat(
        value.totalTax ? value.totalTax : CONSTANTS.ZREO_TWO_DECIMAL_STRING
      ),

      totalLateFee: parseFloat(
        value.lateFee
          ? value.lateFee.toString()
          : CONSTANTS.ZREO_TWO_DECIMAL_STRING
      ),

      carriedRentTotal: parseFloat(
        value.carriedRent
          ? value.carriedRent
          : CONSTANTS.ZREO_TWO_DECIMAL_STRING
      ),

      carriedLateRentTotal: parseFloat(
        value.carriedLateRent
          ? value.carriedLateRent
          : CONSTANTS.ZREO_TWO_DECIMAL_STRING
      ),
    };
  };

  // const adjustTotalDueByChange = (totalDue: number, change: number) => {
  //   return Math.max(totalDue - change, CONSTANTS.ZERO_TWO_DECIMAL_NUMBER);
  // };

  /**cog complex ends */

  /**
   * Binding the suspense values into the UI
   * PS_PSB_18, PS_PSB_19, PS_PSB_20, PS_PSB_21, PS_PSB_22
   * @returns
   */
  const bindSuspense = () => {
    if (!agreementInfo?.agreementDetails?.some(({ selected }) => selected)) {
      return null;
    }

    return agreementInfo.agreementDetails.flatMap(
      (agreementValues: AgreementDetails, index: number) => {
        if (!shouldRenderAgreement(agreementValues)) {
          return null;
        }
        return Object.entries(agreementValues.suspenseDetails)
          .filter(([, value]) => Number(value) > CONSTANTS.ZERO_NUMBER)
          .map(([key, value]) =>
            renderSuspenseItem(key, String(value), agreementValues, index)
          );
      }
    );
  };

  const renderSuspenseItem = (
    key: string,
    value: string,
    agreementValues: AgreementDetails,
    index: number
  ) => {
    const isValidKey =
      ((key === CONSTANTS.SUSPENSE_ADD || key === CONSTANTS.SUSPENSE_USE) &&
        agreementValues.selected) ||
      key === CONSTANTS.TRANSFER_SUSPENSE_ADD ||
      key === CONSTANTS.TRANSFER_SUSPENSE_USE;

    if (!isValidKey) {
      return null;
    }

    return (
      <Grid
        item
        key={index}
        className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
      >
        <Typography
          variant="caption"
          className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
        >
          {agreementValues.agreementNumber} {CONSTANTS.HYPHEN}
          {CONSTANTS.STRING_WITH_SINGLE_SPACE}
          {renderSuspenseAction(key, value)}
          {renderTransferInfo(key, value)}
        </Typography>
        <Typography
          variant="caption"
          className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
        >
          <Typography className={classes.displayInline}>
            ${formatSuspenseAmount(value)}
          </Typography>
        </Typography>
      </Grid>
    );
  };

  const renderSuspenseAction = (key: string, value: string) => {
    const isAddAction =
      (key === CONSTANTS.SUSPENSE_ADD ||
        key === CONSTANTS.TRANSFER_SUSPENSE_ADD) &&
      parseFloat(value) > CONSTANTS.ZERO_NUMBER;
    return isAddAction ? CONSTANTS.ADD : CONSTANTS.USE;
  };

  const renderTransferInfo = (key: string, value: string) => {
    const shouldShowTransferInfo =
      (key === CONSTANTS.TRANSFER_SUSPENSE_USE ||
        key === CONSTANTS.TRANSFER_SUSPENSE_ADD) &&
      parseFloat(value) > CONSTANTS.ZERO_NUMBER;

    return shouldShowTransferInfo ? (
      <>
        <span> </span>
        <Infosus
          title={CONSTANTS.TRANSFER}
          onMouseOver={() => {
            CONSTANTS.TRANSFER;
          }}
        />
        <span> </span>
      </>
    ) : null;
  };

  const formatSuspenseAmount = (value: string) => {
    return value !== undefined && value !== null
      ? parseFloat(value).toFixed(2)
      : CONSTANTS.ZERO_DECIMAL_NUMBER;
  };

  const shouldRenderAgreement = (agreementValues: AgreementDetails) => {
    const { suspAdd, suspUse, transferSusAdd, transferSusUse } =
      agreementValues?.suspenseDetails || {};

    return (
      parseFloat(suspAdd) > CONSTANTS.ZERO_NUMBER ||
      parseFloat(suspUse) > CONSTANTS.ZERO_NUMBER ||
      parseFloat(transferSusAdd) > CONSTANTS.ZERO_NUMBER ||
      parseFloat(transferSusUse) > CONSTANTS.ZERO_NUMBER
    );
  };

  /**
   * PS_PSB_23, PS_PSB_24, PS_PSB_25, PS_PSB_26
   * Binding the Receipt Items to UI.
   * @returns
   */
  const bindReceiptItems = () => {
    let miscellaneousCount: number = CONSTANTS.ZERO_NUMBER;

    return agreementInfo?.agreementDetails?.map(
      (value: AgreementDetails, index: number) => {
        if (!value.selected) {
          return null;
        }

        miscellaneousCount = updateMiscellaneousCount(
          value,
          miscellaneousCount
        );
        // const agreementAmount = getAgreementAmount(value);
        const displayNumber = getDisplayNumber(value, miscellaneousCount);
        const amount = calculateAmount(value);

        return (
          <Grid
            item
            key={index}
            className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
          >
            <Typography
              variant="caption"
              className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
            >
              {displayNumber}
            </Typography>
            <Grid
              item
              className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
            >
              <Typography
                className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
              >
                ${amount}
              </Typography>
            </Grid>
          </Grid>
        );
      }
    );
  };

  const updateMiscellaneousCount = (
    value: AgreementDetails,
    count: number
  ): number => {
    return value.agreementNumber === CONSTANTS.ADJUST ? count + 1 : count;
  };

  // const getAgreementAmount = (value: AgreementDetails): string => {
  //   const amount = calculateTotalDueAmount(value, CONSTANTS.RECIEPT).toString();
  //   return amount.includes(CONSTANTS.OPEN_BRACKET_STRING) &&
  //     amount.includes(CONSTANTS.CLOSE_BRACKET_STRING)
  //     ? CONSTANTS.ZREO_TWO_DECIMAL_STRING
  //     : amount;
  // };

  const getDisplayNumber = (
    value: AgreementDetails,
    miscellaneousCount: number
  ): string => {
    if (value.agreementNumber === CONSTANTS.ADJUST_BACK_RENT) {
      return CONSTANTS.BACK_RENT;
    }
    if (value.agreementNumber === CONSTANTS.ADJUST) {
      return `${CONSTANTS.MISCELLANEOUS_ITEM} ${miscellaneousCount}`;
    }
    return value.agreementNumber;
  };

  const calculateAmount = (value: AgreementDetails): string => {
    if (value.isExactSus || value.isOverSus || value.isEPOSusAmtDue) {
      return Number(value.epoAmount).toFixed(2);
    }
    if (value.isReturnAgreement) {
      return CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    }
    return (Number(value.totalAmount) + Number(value.totalTax)).toFixed(2);
  };

  /**
   * PS_PSB_27, PS_PSB_28, PS_PSB_29, PS_PSB_30, PS_PSB_31
   * Binding the Tender types to UI.
   * @returns
   */
  const bindTenderType = () => {
    return sideBarData?.tenderDetails?.map(
      (tenderValues: tenderDetailsInterface, index: number) => {
        return (
          <Grid
            item
            key={index}
            className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
          >
            <Typography
              variant="caption"
              className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
            >
              {tenderValues.tenderType}
            </Typography>
            <Typography
              variant="caption"
              className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
            >
              <Typography className={classes.displayInline}>
                ${tenderValues.amount}
              </Typography>
            </Typography>
          </Grid>
        );
      }
    );
  };

  /**
   * PS_PSB_32, PS_PSB_33, PS_PSB_34, PS_PSB_35
   * Calculating the default donation amount.
   * @param sideBarData
   * @returns
   */
  const calculateDonation = (sideBarData: SideBarInterface) => {
    const donationAmount: number = parseFloat(
      (
        Math.ceil(parseFloat(sideBarData.remainingAmountDue)) -
        parseFloat(sideBarData.remainingAmountDue)
      ).toFixed(2)
    );

    if (
      Number(agreementInfo.customerInfo.userEnteredDonationAmount) <=
      CONSTANTS.ZERO_NUMBER
    ) {
      if (donationAmount > CONSTANTS.ZERO_NUMBER) {
        setSideBarData((prevState) => ({
          ...prevState,
          donation: donationAmount.toFixed(2),
        }));

        return donationAmount.toString();
      } else {
        setSideBarData((prevState) => ({
          ...prevState,
          donation: CONSTANTS.ONE_WITH_DECIMAL,
        }));

        return CONSTANTS.ONE_WITH_DECIMAL;
      }
    } else {
      setSideBarData((prevState) => ({
        ...prevState,
        donation: agreementInfo.customerInfo.userEnteredDonationAmount,
      }));
    }
  };

  /**
   * PS_PSB_57
   * Deleting the suspense from context.
   */
  const deleteSuspense = () => {
    const agreementDetail = JSON.parse(
      JSON.stringify(agreementInfo.agreementDetails)
    );
    agreementDetail.map((agreement: AgreementDetails) => {
      agreement.suspenseDetails = {
        suspAdd: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        suspUse: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        transferSusAdd: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        transferSusUse: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      };
    });
    const customerDetail = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );
    customerDetail.carryRentEnable = false;
    agreementInfo.setCustomerInfo(customerDetail);
    agreementInfo.setAgreementDetails(agreementDetail);
    agreementInfo.setRenderContext(!agreementInfo.renderContext);
  };

  /**
   * PS_PSB_59
   * Choosing the change option.
   * @param e
   */
  const onRadioChange = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const target = e.target as HTMLInputElement;
    setSideBarData((prevState) => ({
      ...prevState,
      balanceReturnTypeRadio: target.value,
    }));
    agreementInfo.customerInfo.change.type = target.value;
  };

  /**
   * Enabling and disabling the doantion toggle button.
   * PS_PSB_61
   */
  const onDonationToggleChange = (event) => {
    const customerInfoClone = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );
    const changeAndRemainingAmountDue = calculateChangeAmount(agreementInfo);

    if (event) {
      setSideBarData((prevState) => ({
        ...prevState,
        totalDue: prevState.totalDue + parseFloat(prevState.donation),
        enableDonation: true,
      }));

      addDonation();
    } else {
      const donationAmount = calculateDonation(sideBarData);
      setSideBarData((prevState) => ({
        ...prevState,
        totalDue: prevState.totalDue - parseFloat(prevState.donation),
        donation: donationAmount
          ? donationAmount
          : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        enableDonation: false,
      }));

      customerInfoClone.donation = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      customerInfoClone.userEnteredDonationAmount =
        CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      customerInfoClone.change.amount = changeAndRemainingAmountDue.change;
      customerInfoClone.amountUsed.remainingAmountDue = parseFloat(
        changeAndRemainingAmountDue.remainingAmountDue
      ).toFixed(2);
      customerInfoClone.amountUsed.donationremainingAmountDue =
        CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      agreementInfo.setCustomerInfo(customerInfoClone);
    }
    agreementInfo.setRenderContext(!agreementInfo.renderContext);
  };

  /**
   * While Donation toogle on and donation onBlur this function will add the
   * donation amount into the remaining amount and donation in context.
   * @param calculatedDonationAmount
   */
  const addDonation = (
    calculatedDonationAmount: string = CONSTANTS.EMPTY_STRING
  ) => {
    const customerInfoClone = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );
    let donationAmount = calculatedDonationAmount
      ? calculatedDonationAmount
      : JSON.parse(JSON.stringify(sideBarData.donation)).replace(
          /,/g,
          CONSTANTS.EMPTY_STRING
        );
    const changeAndRemainingAmountDue = calculateChangeAmount(agreementInfo);

    customerInfoClone.donation = sideBarData.donation;
    customerInfoClone.userEnteredDonationAmount = sideBarData.donation;

    if (
      parseFloat(changeAndRemainingAmountDue.change) > CONSTANTS.ZERO_NUMBER
    ) {
      if (
        parseFloat(changeAndRemainingAmountDue.change) >=
        parseFloat(donationAmount)
      ) {
        customerInfoClone.change.amount = (
          parseFloat(changeAndRemainingAmountDue.change) -
          parseFloat(donationAmount)
        ).toFixed(2);
        customerInfoClone.amountUsed.remainingAmountDue =
          CONSTANTS.ZERO_TWO_DECIMAL_NUMBER.toFixed(2);
        agreementInfo.setCustomerInfo(customerInfoClone);
      } else {
        donationAmount = (
          parseFloat(donationAmount) -
          parseFloat(changeAndRemainingAmountDue.change)
        ).toFixed(2);
        // customerInfoClone.amountUsed.remainingAmountDue = (
        //   parseFloat(changeAndRemainingAmountDue.remainingAmountDue) +
        //   parseFloat(donationAmount)
        // ).toFixed(2);
        customerInfoClone.amountUsed.donationRemainingAmountDue =
          donationAmount;
        customerInfoClone.change.amount = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
        agreementInfo.setCustomerInfo(customerInfoClone);
      }
    } else {
      // customerInfoClone.amountUsed.remainingAmountDue = (
      //   parseFloat(changeAndRemainingAmountDue.remainingAmountDue) +
      //   parseFloat(donationAmount)
      // ).toFixed(2);
      customerInfoClone.amountUsed.donationRemainingAmountDue = donationAmount;
      agreementInfo.setCustomerInfo(customerInfoClone);
    }
    agreementInfo.setRenderContext(!agreementInfo.renderContext);
  };

  /**
   * PS_PSB_63
   * updating the donation amount in state variable.
   * @param e
   */
  const onDonationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSideBarData((prevState) => ({
      ...prevState,
      donation: e.target.value.replace(/,/g, CONSTANTS.EMPTY_STRING),
    }));
  };

  /**
   * PS_PSB_65
   * Formating the donation amount and updating the context.
   */
  const onDonationBlur = () => {
    if (sideBarData.donation) {
      const formated = Number(
        JSON.parse(JSON.stringify(sideBarData.donation)).replace(
          /,/g,
          CONSTANTS.EMPTY_STRING
        )
      ).toFixed(2);

      setSideBarData((prevState) => ({
        ...prevState,
        donation: formated,
      }));

      addDonation();
      agreementInfo.setRenderContext(!agreementInfo.renderContext);
    } else {
      const donationAmount = calculateDonation(sideBarData);
      addDonation(donationAmount);
      agreementInfo.setRenderContext(!agreementInfo.renderContext);
    }
  };

  /**
   * PS_PSB_45, PS_PSB_46, PS_PSB_47
   * Binding COA, Weblead, Ipsuspense to UI.
   * @returns
   */
  // const AvailCreditsBinding = () => {
  //   return Object.keys(agreementInfo?.customerInfo?.amountUsed).map(
  //     (key: string, index: number) => {
  //       if (
  //         (key == CONSTANTS.COA_USED &&
  //           parseFloat(agreementInfo?.customerInfo?.amountUsed?.coaUsed) >
  //             CONSTANTS.ZERO_NUMBER) ||
  //         (key == CONSTANTS.WEB_LEAD_USED &&
  //           parseFloat(agreementInfo?.customerInfo?.amountUsed?.webLeadUsed) >
  //             CONSTANTS.ZERO_NUMBER) ||
  //         (key == CONSTANTS.IP_SUSPENSE_USED &&
  //           parseFloat(
  //             agreementInfo?.customerInfo?.amountUsed?.ipSuspenseUsed
  //           ) > CONSTANTS.ZERO_NUMBER)
  //       ) {
  //         return (
  //           <Grid
  //             item
  //             className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
  //           >
  //             <Grid
  //               item
  //               key={index}
  //               className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
  //             >
  //               <Typography
  //                 variant='caption'
  //                 className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
  //               >
  //                 {key == CONSTANTS.COA_USED
  //                   ? CONSTANTS.COA
  //                   : key == CONSTANTS.WEB_LEAD_USED
  //                   ? CONSTANTS.WEB_LEAD_DEPOSIT
  //                   : key == CONSTANTS.IP_SUSPENSE_USED
  //                   ? CONSTANTS.IP_SUSPENSE
  //                   : CONSTANTS.EMPTY_STRING}
  //               </Typography>
  //               <Typography
  //                 variant='caption'
  //                 className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
  //               >
  //                 ($<span>{agreementInfo?.customerInfo?.amountUsed[key]})</span>
  //               </Typography>
  //             </Grid>
  //           </Grid>
  //         );
  //       }
  //     }
  //   );
  // };

  const AvailCreditsBinding = () => {
    return Object.keys(agreementInfo?.customerInfo?.amountUsed).map(
      (key: string, index: number) => {
        if (shouldRenderCredit(key)) {
          return renderCreditItem(key, index);
        } else {
          return null;
        }
      }
    );
  };

  const shouldRenderCredit = (key: string) => {
    const amountUsed = agreementInfo?.customerInfo?.amountUsed;

    switch (key) {
      case CONSTANTS.COA_USED:
        return parseFloat(amountUsed?.coaUsed) > CONSTANTS.ZERO_NUMBER;
      case CONSTANTS.WEB_LEAD_USED:
        return parseFloat(amountUsed?.webLeadUsed) > CONSTANTS.ZERO_NUMBER;
      case CONSTANTS.IP_SUSPENSE_USED:
        return parseFloat(amountUsed?.ipSuspenseUsed) > CONSTANTS.ZERO_NUMBER;
      default:
        return false;
    }
  };

  const renderCreditItem = (key: string, index: number) => (
    <Grid
      item
      className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
    >
      <Grid
        item
        key={index}
        className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
      >
        <Typography
          variant="caption"
          className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
        >
          {getLabelForKey(key)}
        </Typography>
        <Typography
          variant="caption"
          className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
        >
          ($
          <span>
            {Number(agreementInfo?.customerInfo?.amountUsed[key]).toFixed(2)}
          </span>
          )
        </Typography>
      </Grid>
    </Grid>
  );

  const getLabelForKey = (key: string) => {
    switch (key) {
      case CONSTANTS.COA_USED:
        return CONSTANTS.CAPITAL_COA;
      case CONSTANTS.WEB_LEAD_USED:
        return CONSTANTS.WEB_LEAD_DEPOSIT;
      case CONSTANTS.IP_SUSPENSE_USED:
        return CONSTANTS.IP_SUSPENSE;
      default:
        return CONSTANTS.EMPTY_STRING;
    }
  };

  /**
   * PS_PSB_50
   * Binding the security deposit into UI.
   * @returns
   */
  const renderRSDList = () => {
    return sideBarData.refundSecurityDeposit.map(
      (value: rsdAgreementInfo, index: number) => {
        return (
          <>
            {Number(value.amountToCollect) >= CONSTANTS.ZERO_NUMBER ? (
              <Grid
                item
                key={index}
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  variant="caption"
                  className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
                >
                  {/* {value.RSDLabel} */}
                  {`${value.agreementNo} ${CONSTANTS.HYPHEN} ${CONSTANTS.REFUND}`}
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
                >
                  <Typography className={classes.displayInline}>
                    {`$(${Number(value.depositAmount).toFixed(2)})`}
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              <></>
            )}

            {Number(value.amountToCollect) >= CONSTANTS.ZERO_NUMBER ? (
              <Grid
                item
                key={index}
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  variant="caption"
                  className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
                >
                  {/* {value.RSDLabel} */}
                  {`${value.agreementNo} ${CONSTANTS.HYPHEN} ${CONSTANTS.COLLECT}`}
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
                >
                  <Typography className={classes.displayInline}>
                    {`$ ${Number(value.amountToCollect).toFixed(2)}`}
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
          </>
        );
      }
    );
  };

  /**
   * PS_PSB_49
   * Hide and show the suspense delete button.
   * @returns
   */
  const showSuspenseDelete = () => {
    return agreementInfo?.agreementDetails?.some(
      (el: AgreementDetails) =>
        el.selected &&
        (parseFloat(el.suspenseDetails?.suspAdd) > CONSTANTS.ZERO_NUMBER ||
          parseFloat(el.suspenseDetails?.suspUse) > CONSTANTS.ZERO_NUMBER ||
          parseFloat(el.suspenseDetails?.transferSusAdd) >
            CONSTANTS.ZERO_NUMBER ||
          parseFloat(el.suspenseDetails?.transferSusUse) >
            CONSTANTS.ZERO_NUMBER) &&
        !(el?.isExactSus || el?.isOverSus || el?.isEPOSusAmtDue) &&
        !CONSTANTS.PIF_SAC_EPO_RENT_ARRAY.includes(el.agreementType)
    );
  };

  /**
   *PS_PSB_17.1
   * Hide and show the suspense and credit and disable the credit.
   * @param totalDueAmount
   */
  const suspenseAndCreditHideAndShow = (totalDueAmount: number) => {
    let totalAmount =
      totalDueAmount -
      parseFloat(sideBarData.convenienceFee) +
      parseFloat(agreementInfo.customerInfo.donation);
    totalAmount = totalAmount * 2;
    const clubAgreement = agreementInfo?.agreementDetails.filter(
      (agrmeentData: AgreementDetails) =>
        agrmeentData.agreementType == CONSTANTS.CLUB
    );
    const tenderSumAmount = sideBarData?.tenderDetails?.reduce(
      (accumulator: number, currentValue: tenderDetailsInterface) =>
        accumulator + parseFloat(currentValue.amount),
      CONSTANTS.ZERO_NUMBER
    );
    const NonFinalAgreements = agreementInfo?.agreementDetails.filter(
      (agreementData: AgreementDetails) =>
        agreementData.otherIncomeType == CONSTANTS.EMPTY_STRING &&
        ![CONSTANTS.CLUB, CONSTANTS.NOTE_PAYMENT].includes(
          agreementData.agreementType
        ) &&
        !CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(agreementData.nextDueDate)
    );
    const FinalAgreements = agreementInfo?.agreementDetails.filter(
      (agreementData: AgreementDetails) =>
        agreementData.otherIncomeType == CONSTANTS.EMPTY_STRING &&
        ![CONSTANTS.CLUB, CONSTANTS.NOTE_PAYMENT].includes(
          agreementData.agreementType
        ) &&
        CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(agreementData.nextDueDate)
    );
    const NonClubAgreements = agreementInfo?.agreementDetails.filter(
      (agreementData: AgreementDetails) =>
        agreementData.agreementType != CONSTANTS.CLUB
    );
    const nonInventorySaleAgreement = agreementInfo?.agreementDetails.filter(
      (agreementData: AgreementDetails) =>
        agreementData.agreementNumber == CONSTANTS.ADJUST
    );
    const selectedAgreements = agreementInfo?.agreementDetails.filter(
      (agreementData: AgreementDetails) => agreementData.selected
    );

    if (NonFinalAgreements.length > CONSTANTS.ZERO_NUMBER) {
      if (
        clubAgreement.length > CONSTANTS.ZERO_NUMBER &&
        !clubAgreement[CONSTANTS.ZERO_NUMBER]?.selected
      ) {
        const clubAmount: string | number = calculateTotalDueAmount(
          clubAgreement[CONSTANTS.ZERO_NUMBER]
        );
        totalAmount = totalAmount - parseFloat(clubAmount.toString());
      }
      if (
        tenderSumAmount > totalAmount &&
        tenderSumAmount != CONSTANTS.ZERO_NUMBER
        // totalAmount < tenderSumAmount
      ) {
        setShowSuspense(true);
      } else {
        setShowSuspense(false);
      }
    } else if (
      (NonClubAgreements.length == CONSTANTS.ONE_NUMBER &&
        CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(
          NonClubAgreements[CONSTANTS.ZERO_NUMBER].nextDueDate
        )) ||
      selectedAgreements.length == nonInventorySaleAgreement.length ||
      selectedAgreements.length ==
        nonInventorySaleAgreement.length + FinalAgreements.length
    ) {
      setDisableCredit(true);
    } else {
      setDisableCredit(false);
    }
  };

  const suspenseDeleteConfirmationPopUp = () => {
    return (
      <>
        <Grid
          item
          id="suspenseDeleteConfirmationPopUp"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="suspenseDeleteConfirmationPopUp"
          aria-hidden="true"
        >
          <Grid item md={12} className={classes.textCenter}>
            <AlertIcon className={classes.racErrorIcon}></AlertIcon>
            <Typography variant={'body2'} className={classes.racErrorFunction}>
              {CONSTANTS.DELETE_ALL_SUSPENSE}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            data-testid="yesbutton"
            variant="contained"
            color="primary"
            className={classes.me2}
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => {
              deleteSuspense();
              setSuspenseDeleteConfirmation(false);
            }}
          >
            Yes
          </RACButton>

          <RACButton
            autoFocus
            data-testid="nobutton"
            variant="contained"
            color="primary"
            className={classes.me2}
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => {
              setSuspenseDeleteConfirmation(false);
            }}
          >
            No
          </RACButton>
        </Grid>
      </>
    );
  };

  /**
   * PS_PSB_36 - PS_PSB_65
   * binding RacStrap elements with sidebar values.
   */
  return (
    <>
      {/*Total due starts here*/}
      <Grid
        item
        className={`${classes.raccollg3} ${classes.floatLeft} ${classes.px1} ${classes.mb3}`}
      >
        <Grid
          item
          className={`${classes.RACpaymenttotalcontainer} ${classes.p3}`}
        >
          <Grid
            item
            className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
          >
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.totallabelstyle} ${classes.bold} ${classes.mt2}`}
              >
                TOTAL DUE
              </Typography>
              <Grid item className={classes.floatRight}>
                <Typography
                  className={`${classes.floatLeft} ${classes.totalvaluestyle} ${classes.bold} ${classes.totallabelstyle}`}
                >
                  $
                </Typography>
                <Typography
                  className={`${classes.floatLeft} ${classes.totalvaluestyle} ${classes.bold} ${classes.totallabelstyle}`}
                >
                  {isNaN(Number(sideBarData?.totalDue?.toFixed(2)))
                    ? CONSTANTS.ZREO_TWO_DECIMAL_STRING
                    : sideBarData?.totalDue?.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
              >
                Total Tax
              </Typography>
              <Grid
                item
                className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
              >
                <Typography
                  className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                >
                  $
                </Typography>
                <Typography
                  className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                >
                  {sideBarData?.totalTax?.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            {agreementInfo?.customerInfo?.carryRentToggleEnabled &&
            !isCoPrelim ? (
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
                >
                  Carry Rent
                </Typography>

                <Grid
                  item
                  className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
                >
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    ($
                  </Typography>
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    {Number(agreementInfo.carryRent)?.toFixed(2)} )
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            {sideBarData?.carriedRent > CONSTANTS.ZERO_NUMBER && !isCoPrelim ? (
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
                >
                  Carried Rent
                </Typography>

                <Grid
                  item
                  className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
                >
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    $
                  </Typography>
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    {sideBarData?.carriedRent?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            {sideBarData?.carriedLateRent > CONSTANTS.ZERO_NUMBER &&
            !isCoPrelim ? (
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
                >
                  Carried Late Rent
                </Typography>

                <Grid
                  item
                  className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
                >
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    $
                  </Typography>
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    {sideBarData?.carriedLateRent?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            {!isCoPrelim && (
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
                >
                  Total Late Fee
                </Typography>
                <Grid
                  item
                  className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
                >
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    $
                  </Typography>
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    {sideBarData?.totalLateFee?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>

          {!isCoPrelim && AvailCreditsBinding()}

          {Number(agreementInfo?.customerInfo?.amountUsed?.convenienceFee) >
            CONSTANTS.ZERO_NUMBER &&
            !isCoPrelim && (
              <>
                <Grid
                  item
                  className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
                >
                  <Grid item>
                    <Typography
                      className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
                    >
                      Convenience Fee
                    </Typography>
                    <Typography
                      className={` ${classes.floatRight} ${classes.totallabelstyle} ${classes.totalvaluestyle}`}
                    >
                      <Typography>
                        $
                        {Number(
                          agreementInfo?.customerInfo?.amountUsed
                            ?.convenienceFee
                        ).toFixed(2)}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}

          {isCoInital && !isCoPrelim && (
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
            >
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
                >
                  Payment at Order
                </Typography>
                <Grid item className={classes.floatRight}>
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    ($
                    {Number(
                      agreementInfo.agreementDetails[0]?.agreementRateDetails
                        ?.coPaymentAmount
                    ).toFixed(2)}
                    )
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}

          {!isCoPrelim && (
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
              key={Math.random()}
            >
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite} ${classes.mt2}`}
                >
                  Suspense
                  {showSuspenseDelete() ? (
                    <Deleteicon
                      role="suspDeleteButton"
                      onClick={() => setSuspenseDeleteConfirmation(true)}
                    ></Deleteicon>
                  ) : (
                    <></>
                  )}
                </Typography>
                {bindSuspense()}
              </Grid>
            </Grid>
          )}

          <RACModalCard
            isOpen={suspenseDeleteConfirmation}
            maxWidth="sm"
            className={`${classes.customModal4}`}
          >
            {suspenseDeleteConfirmationPopUp()}
          </RACModalCard>

          {!isCoPrelim && (
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
            >
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite} ${classes.mt2} `}
                  style={{ marginBottom: '20px' }}
                >
                  Receipt Items
                </Typography>
                {bindReceiptItems()}
              </Grid>
            </Grid>
          )}
          {sideBarData.refundSecurityDeposit?.length > CONSTANTS.ZERO_NUMBER &&
          !isCoPrelim ? (
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite} ${classes.mt2}`}
                style={{ marginBottom: '20px' }}
              >
                Security Deposit
              </Typography>
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                {renderRSDList()}
              </Grid>
            </Grid>
          ) : null}
          <Grid
            item
            className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
          >
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite} ${classes.mt2}`}
              >
                Tender Type
              </Typography>
            </Grid>
            {bindTenderType()}
          </Grid>
          <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
            <Grid
              item
              className={`${classes.formcheckinline} ${classes.floatLeft}`}
            >
              <Grid
                item
                className={`${classes.formcheck} ${classes.ps0} ${classes.floatLeft} ${classes.me2}`}
              >
                <RadioColor
                  className={classes.floatLeft}
                  name={CONSTANTS.CHANGE}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    onRadioChange(e);
                  }}
                  disabled={agreementInfo.multiStoreSelected}
                  checked={
                    sideBarData.balanceReturnTypeRadio == CONSTANTS.CHANGE &&
                    !agreementInfo.multiStoreSelected
                  }
                  value={CONSTANTS.CHANGE}
                  IscolorVariant={true}
                  variantColor={'#2FB452'}
                  backgroundColor={'white'}
                />
                <Box
                  component="span"
                  className={`${classes.changecredit} ${classes.ps0}`}
                >
                  Change
                </Box>
              </Grid>
              {!isCoPrelim && (
                <Grid
                  item
                  className={`${classes.formcheck} ${classes.ps0} ${classes.floatLeft}`}
                >
                  <RadioColor
                    className={classes.floatLeft}
                    name="suspenseorcredit"
                    onClick={(e) => onRadioChange(e)}
                    checked={
                      agreementInfo.multiStoreSelected ||
                      sideBarData.balanceReturnTypeRadio ==
                        CONSTANTS.CAPITAL_SUSPENSE ||
                      sideBarData.balanceReturnTypeRadio ==
                        CONSTANTS.CAPITAL_CREDIT
                        ? true
                        : false
                    }
                    disabled={disableCredit || agreementInfo.multiStoreSelected}
                    value={
                      showSuspense
                        ? CONSTANTS.CAPITAL_SUSPENSE
                        : CONSTANTS.CAPITAL_CREDIT
                    }
                    IscolorVariant={true}
                    variantColor={'#2FB452'}
                    backgroundColor={'white'}
                  />
                  {showSuspense ? (
                    <Box
                      component="span"
                      className={`${classes.changecredit} ${classes.ps0}`}
                    >
                      Suspense
                    </Box>
                  ) : (
                    <Box
                      component="span"
                      className={`${classes.changecredit} ${classes.ps0}`}
                    >
                      Credit
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
            <Box
              component="span"
              className={`${classes.changecredit} ${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.mtcustom}`}
            >
              $
              <Box component="span" className={classes.changecredit}>
                {sideBarData.balanceAmount}
              </Box>
            </Box>
          </Grid>
          {donationFeatureFlag == CONSTANTS.ONE_STRING &&
          !isCoPrelim &&
          !isCoInital ? (
            <>
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
              >
                <Grid
                  item
                  className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
                >
                  <Grid>
                    <Typography
                      className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite} ${classes.mt2}`}
                    >
                      Donation
                    </Typography>
                  </Grid>
                  {storeInformation?.donationDesc?.length > 30 && (
                    <Grid>
                      <Typography
                        style={{ marginTop: '10%' }}
                        className={` ${classes.changecredit} ${classes.ps0}`}
                      >
                        <span className={classes.bold}>
                          {storeInformation.donationType ||
                            CONSTANTS.EMPTY_STRING}
                        </span>{' '}
                        {storeInformation.donationDesc}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
              >
                <Grid
                  container
                  className={`${classes.formcheckinline} ${classes.floatLeft}`}
                >
                  <Grid
                    item
                    md={8}
                    className={`${classes.formcheck} ${classes.ps0} ${classes.floatLeft} ${classes.me2}`}
                  >
                    <RACToggle
                      GridClassName={`${classes.gridClassToggleReverse} ${classes.carryRentBottom}`}
                      labelGridClassName={classes.gridClassLabel}
                      checked={sideBarData.enableDonation}
                      data-testid="donationToggle"
                      id="donationToggle"
                      toggleColor="#71CA2F"
                      leftText={true}
                      disabled={
                        agreementInfo.agreementDetails.some(
                          (agr: AgreementDetails) => agr.selected === true
                        ) &&
                        storeInformation.isDonationEnabled ==
                          CONSTANTS.STATUS_YES
                          ? false
                          : true
                      }
                      OnClick={(e) => onDonationToggleChange(e.target.checked)}
                    />
                    {storeInformation?.donationDesc?.length < 30 ? (
                      <Box
                        title={storeInformation.donationDesc}
                        component="span"
                        className={`${classes.changecredit} ${classes.ps0}`}
                      >
                        {storeInformation.donationDesc}
                      </Box>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    md={4}
                    className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} `}
                    style={{ marginRight: '-6%' }}
                  >
                    <RACTextbox
                      className={classes.custDigitN1}
                      dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                      type="number"
                      OnChange={(e) => {
                        onDonationChange(e);
                      }}
                      Onblur={() => {
                        onDonationBlur();
                      }}
                      value={sideBarData.donation}
                      isCurrency={true}
                      maxlength={10}
                      required={false}
                      disabled={!sideBarData.enableDonation}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>
      {/*Total due ends here*/}
    </>
  );
}
