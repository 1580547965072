/* eslint-disable */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable prefer-const */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-redundant-boolean */
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import CONSTANTS from "../constants/constant";
import { AgreementAmountDue, CalculatePaymentRequest, ClubAmountDue, EpoDiscountInput, FreeTimeInput, otherIncomeRequest } from "../interface/calculatePaymentRequest";

interface clubAdjustmentsPayload {
    clubAmountDue:ClubAmountDue[];

}

export default async function calculationModulePayloadFormationUpdate(props) {
    
    console.log(props,'props in accept epo')

    let agreementPayloadFormation:AgreementAmountDue[] =[];
    let clubAdjustmentsPayload:clubAdjustmentsPayload[]  =[];
    let otherIncomePayment:otherIncomeRequest[] =[];
 for(let i=0;i<props.checkedAgr?.length;i++){
     let paygridInfo=props.checkedAgr[i]
     let adjustedDueDate = false;
     let adjustedDueDateValue:string | undefined;
     let editSchedule=false;
     let editScheduleValue:string | undefined;
     let acceptEpo= false;
     let acceptEpoValue:string | undefined;
     let acceptEpoValueDiscount: EpoDiscountInput = {} as EpoDiscountInput ;
     let freeTime = false;
     let freeTimeValue:FreeTimeInput | undefined;
     let carryLatefee = false;
     let carryLatefeeValue:string | undefined;
     let waivelatefee = false;
     let waivelatefeeValue:string | undefined;

    //EditSchedule
    if(paygridInfo?.editSchedule!=CONSTANTS.EMPTY_STRING||paygridInfo?.isEditScheduleValue){
        editSchedule=true;
        if(paygridInfo?.editSchedule!=CONSTANTS.EMPTY_STRING){
           editScheduleValue=paygridInfo?.editSchedule
        }else if(paygridInfo?.isEditScheduleValue){
           editScheduleValue=paygridInfo?.isEditScheduleValue
        }
    }
    //AdjustDueDate
    if(paygridInfo?.dueDateModified||paygridInfo.isAdjustDueDateValue){
        console.log("Inside Adjust Due Date",paygridInfo)
        if((paygridInfo?.nextDueDate==CONSTANTS.SAC||paygridInfo?.nextDueDate==CONSTANTS.EPO)&&paygridInfo?.dueDateModified){
            acceptEpo=true
            acceptEpoValue=CONSTANTS.STATUS_YES
        }else{
            adjustedDueDate=true
            if(paygridInfo?.isAdjustDueDateValue==CONSTANTS.SAC||paygridInfo?.isAdjustDueDateValue==CONSTANTS.EPO){
                acceptEpo=true
                adjustedDueDate=false
                acceptEpoValue=CONSTANTS.STATUS_YES
            }else if(paygridInfo?.dueDateModified){
                adjustedDueDateValue =paygridInfo.nextDueDate
            }else if (paygridInfo.isAdjustDueDateValue){
                adjustedDueDateValue =paygridInfo.isAdjustDueDateValue
            }
        }
    }
    //Accept EPO
    if(paygridInfo?.acceptEpoFlag== CONSTANTS.ONE_NUMBER||paygridInfo?.isAcceptEPOValue){
        acceptEpo=true
        acceptEpoValue=CONSTANTS.STATUS_YES
        if(paygridInfo?.isAcceptEPOValue||paygridInfo?.isAcceptEPODiscounts){
            acceptEpoValueDiscount=paygridInfo?.isAcceptEPODiscounts
        }
    }
    //Free time
    console.log(paygridInfo?.freeTime, 'paygridInfo?.freeTime', paygridInfo.isFreeTimeValue)
    if(paygridInfo?.freeTime ||paygridInfo.isFreeTimeValue){
        freeTime=true
        if(paygridInfo?.freeTime != null){
            freeTimeValue ={
                type:paygridInfo?.freeTime.freeTimeType,
                freeDays:paygridInfo?.freeTime.freeTimeDays,
                waiveOptionalService:paygridInfo?.freeTime.isOptServiceWaived
                ? CONSTANTS.STATUS_YES
                : CONSTANTS.STATUS_NO,

            }
        }else if (paygridInfo.isFreeTimeValue){
            freeTimeValue ={
                type:paygridInfo?.isFreeTimeValue.freeTimeType,
                freeDays:paygridInfo?.isFreeTimeValue.freeTimeDays,
                waiveOptionalService:paygridInfo?.isFreeTimeValue.isOptServiceWaived
                ? CONSTANTS.STATUS_YES
                : CONSTANTS.STATUS_NO,

            }
        }
    }
    //Carry Late Fee
    if(Number(paygridInfo?.carriedLateFee)>CONSTANTS.ZERO_NUMBER||Number(paygridInfo?.iscarriedLateFeeValue)>CONSTANTS.ZERO_NUMBER){
        carryLatefee=true
        if(Number(paygridInfo?.carriedLateFee)>CONSTANTS.ZERO_NUMBER){
            carryLatefeeValue=Number(paygridInfo.carriedLateFee) > CONSTANTS.ZERO_NUMBER
            ? Number(paygridInfo.carriedLateFee).toFixed(2)
            : undefined
        }else if(Number(paygridInfo?.iscarriedLateFeeValue)>CONSTANTS.ZERO_NUMBER){
            carryLatefeeValue=Number(paygridInfo.iscarriedLateFeeValue) > CONSTANTS.ZERO_NUMBER
            ? Number(paygridInfo.iscarriedLateFeeValue).toFixed(2)
            : undefined
        }
    }
    //Waive Late Fee
    if(Number(paygridInfo?.waivedLateFee)>CONSTANTS.ZERO_NUMBER||paygridInfo?.waivedLateFeeValue){
        waivelatefee=true
        if(Number(paygridInfo?.waivedLateFee)>CONSTANTS.ZERO_NUMBER){
            waivelatefeeValue = Number(paygridInfo?.waivedLateFee) > CONSTANTS.ZERO_NUMBER
            ? Number(paygridInfo?.waivedLateFee).toFixed(2)
            : undefined
        }else if(paygridInfo?.waivedLateFeeValue){
            waivelatefeeValue=paygridInfo?.waivedLateFeeValue
        }
    }
    //toggle LDW


    //toggle Policy

    if(adjustedDueDate||editSchedule||acceptEpo||freeTime||carryLatefee||waivelatefee||(paygridInfo?.toggleOptionalService!=CONSTANTS.EMPTY_STRING&&paygridInfo?.toggleOptionalService!=undefined)||paygridInfo?.isCoverageActionPerformed!=CONSTANTS.EMPTY_STRING){
        if(paygridInfo?.agreementType!=CONSTANTS.CLUB&&paygridInfo?.otherIncomeType==CONSTANTS.EMPTY_STRING&&
        paygridInfo?.type!=CONSTANTS.RESTITUTION&& paygridInfo?.type!=CONSTANTS.BACK_RENT &&paygridInfo?.type!=CONSTANTS.NSF_CHECK&& paygridInfo?.type==undefined ){
            agreementPayloadFormation.push({
                agreementId:paygridInfo?.agreementId,
                adjustments:{
                    editedSchedule:editScheduleValue,
                    adjustedDueDate:adjustedDueDate?moment(adjustedDueDateValue).format('YYYY-MM-DD'):undefined,
                    freeTime:freeTimeValue,
                    carryLateFeeAmount:carryLatefeeValue,
                    waiveLateFeeAmount:waivelatefeeValue,
                    epoPayment:acceptEpoValue,
                    epoDiscount:acceptEpoValue==CONSTANTS.STATUS_YES?acceptEpoValueDiscount:undefined,
                    toggleOptionalService:paygridInfo?.toggleOptionalService!=CONSTANTS.EMPTY_STRING&&paygridInfo?.toggleOptionalService!=undefined?paygridInfo?.toggleOptionalService:paygridInfo?.isCoverageActionPerformed!=CONSTANTS.EMPTY_STRING?paygridInfo?.isCoverageActionPerformed:undefined
                }
            })
        }
        else if(paygridInfo?.type==CONSTANTS.RESTITUTION|| paygridInfo?.type==CONSTANTS.BACK_RENT||paygridInfo?.type==CONSTANTS.NSF_CHECK)
        {
            otherIncomePayment.push(
                {
                    agreementId: paygridInfo?.agreementId == undefined ? undefined : String(paygridInfo?.agreementId),
                    amount: paygridInfo?.amountWithoutTax,
                    type: paygridInfo?.type == CONSTANTS.RESTITUTION ? CONSTANTS.CO : paygridInfo?.type == CONSTANTS.BACK_RENT ? CONSTANTS.BR : CONSTANTS.NSF,
                    receiptTransactionId: paygridInfo?.receiptTransactionId == undefined ? undefined : paygridInfo?.receiptTransactionId,
                    customerAccountId: paygridInfo?.customerAccountID == undefined ? undefined : paygridInfo?.customerAccountID,
                }
            )
        }else if(paygridInfo?.Agreementtype==CONSTANTS.CLUB){
                 clubAdjustmentsPayload =[{
                    clubAmountDue:[
                        {
                            customerClubId:String(paygridInfo?.customerClubId),
                            adjustments:{
                                editedSchedule:editScheduleValue,
                                adjustedDueDate:adjustedDueDate?moment(adjustedDueDateValue).format('YYYY-MM-DD'):undefined,
                            }
                        }
                    ]
                }]


        }
    }


 }

 let commonModulePayload:CalculatePaymentRequest ={
    customerId: props.customerId,
    paymentOrigin:CONSTANTS.ORIGIN_STORE,
    agreementAmountDue:agreementPayloadFormation?.length>CONSTANTS.ZERO_NUMBER?agreementPayloadFormation:undefined,
    clubAmountDue:clubAdjustmentsPayload?.length>CONSTANTS.ZERO_NUMBER?clubAdjustmentsPayload[0]?.clubAmountDue:undefined,
    otherIncomePayment:otherIncomePayment?.length>CONSTANTS.ZERO_NUMBER?otherIncomePayment:undefined
 }
 return commonModulePayload

}



// export default FreeTime;
