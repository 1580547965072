/*eslint-disable react-hooks/exhaustive-deps*/
/**
 * PS_AD_02
 * Import the required packgaes, interface and context
 */
import React, { useContext, useEffect, useState } from 'react';
import {
  RACDatePicker,
  RACTextbox,
  RACButton,
  RACModalCard,
  Grid,
  Typography,
} from '@rentacenter/racstrap';
import { agreementContext } from '../context/PaymentInformationContext';
import moment from 'moment';
import {
  adjustDueDateCalciPayloadFormation,
  commonModulePayloadFormation,
} from '../utils/payloadFormations';
import AdjustDueDatecalci from './existingFunctions/AdjustDueDateCalci';
import { useParams } from 'react-router-dom';
import calculationModulePayloadFormation from './existingFunctions/commonModulePayloadFormation';
import { calculatePaymentModule } from '../../api/user';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import CONSTANTS from '../constants/constant';
import { ParamType } from '../interface/commonInterface';
import { DueDateData } from '../interface/adjustDueDateInterface';
import { AgreementDetails } from '../interface/paymentInfoInterface';
import {
  dateFormat,
  dueDateFormat,
  totalDueAmount,
} from '../utils/scheduleAndDateFormat';
import { AgreementContextValue } from '../interface/contextInterface';
import {
  AgreementPaymentDetails,
  ClubPaymentDetails,
} from '../interface/calculatePaymentResponse';
import { CommonModuleProps } from '../interface/commonModulePayloadFormationInterface';
import {
  AdjustDueDateReq,
  AdjustDueResponse,
  AgreementRates,
} from '../interface/adjustDueDateCalciInterface';

/**
 * PS_AD_03 - PS_AD_72
 * Invoke a functional based component HandleAdjustDueDate()
 * @returns
 */
export default function HandleAdjustDueDate() {
  /**
   * PS_AD_04 - PS_AD_08
   * Declare variables and destruct from the context
   */
  const { customerId } = useParams<ParamType>();
  const storeNumber = sessionStorage.getItem(CONSTANTS.STORE_NUMBER);
  const classes = takePaymentStyles();

  const {
    agreementDetails,
    setAgreementDetails,
    renderContext,
    setRenderContext,
    modalCardEnable,
    setModalCardEnable,
  } = useContext<AgreementContextValue>(agreementContext);

  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [adjustDueDateData, setAdjustDueDateData] = useState<DueDateData>({
    nextDueDate: CONSTANTS.EMPTY_STRING,
    amount: CONSTANTS.EMPTY_STRING,
    dateError: CONSTANTS.EMPTY_STRING,
    amountError: CONSTANTS.EMPTY_STRING,
    validationMessage: [],
  });

  const selectedAgreement = agreementDetails.filter(
    (el: AgreementDetails) => el.selected
  );
  const [adjustDueDateResArray, setAdjustDueDateResArray] =
    useState<AgreementDetails[]>(selectedAgreement);
  let minimumAmount: number = CONSTANTS.ZERO_NUMBER;
  /**
   * PS_AD_09 - PS_AD_13
   * Get the total amount of all selected agreements
   * set the total amount and next due date to the inout fields
   */
  useEffect(() => {
    const nextDueDate = dateFormat(
      selectedAgreement[0].nextDueDate,
      CONSTANTS.YYYY_MM_DD
    );
    AdjustmentCalculation(nextDueDate, CONSTANTS.DATE);
  }, []);
  /**
   * PS_AD_14 - PS_AD_20
   * This function triggers in onChange of input field
   * It retrives the per day value of all selected agreements
   * And checks the minimum amount to display validation if not amount less than minimum amount
   * @param e
   */
  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = e.target.value.replace(/[^\d.]/g, CONSTANTS.EMPTY_STRING);

    selectedAgreement.forEach((eachAgreement: AgreementDetails) => {
      let total = CONSTANTS.ZERO_NUMBER;
      if (eachAgreement.agreementType == CONSTANTS.CLUB) {
        total =
          Number(eachAgreement?.agreementRateDetails?.perDayClub) +
          Number(eachAgreement?.agreementRateDetails?.perDayClubTax);
      } else {
        total =
          Number(eachAgreement?.agreementRateDetails?.perDayRent) +
          Number(eachAgreement?.agreementRateDetails?.perDayRentTax) +
          Number(
            eachAgreement?.agreementRateDetails?.perDayLdw ??
              CONSTANTS.ZERO_NUMBER
          ) +
          Number(
            eachAgreement?.agreementRateDetails?.perDayLdwTax ??
              CONSTANTS.ZERO_NUMBER
          ) +
          Number(
            eachAgreement?.agreementRateDetails?.perDayPolicy ??
              CONSTANTS.ZERO_NUMBER
          ) +
          Number(
            eachAgreement?.agreementRateDetails?.perDayPolicyTax ??
              CONSTANTS.ZERO_NUMBER
          ) +
          Number(eachAgreement?.lateFee) +
          Number(eachAgreement?.lateFeetax) +
          Number(eachAgreement?.carryRentAmount) +
          Number(eachAgreement?.carryRentTax);
      }
      minimumAmount = Number(minimumAmount) + Number(total);
    });
    if (Number(amount) < minimumAmount)
      setAdjustDueDateData({
        ...adjustDueDateData,
        amount: amount,
        amountError: `Amount Should not be less than ${Number(
          minimumAmount
        ).toFixed(2)}`,
        validationMessage: [],
      });
    else
      setAdjustDueDateData({
        ...adjustDueDateData,
        amount: amount,
        amountError: CONSTANTS.EMPTY_STRING,
      });
  };
  /**
   * PS_AD_21 - PS_AD_25
   * This function triggers in the date change
   * It checks for the minimum date and set the data or validation message
   * @param e
   */
  const onDateChange = (e: string) => {
    const inputDate = dateFormat(e, CONSTANTS.YYYY_MM_DD);
    const minDate = moment(selectedAgreement[0].currentDueDate)
      .add(1, 'd')
      .format(CONSTANTS.YYYY_MM_DD);
    if (inputDate < minDate)
      setAdjustDueDateData({
        ...adjustDueDateData,
        nextDueDate: inputDate,
        dateError: CONSTANTS.PLEASE_CHOOSE_A_VALID_DUE_DATE,
        validationMessage: [],
      });
    else {
      setAdjustDueDateData({
        ...adjustDueDateData,
        nextDueDate: inputDate,
        dateError: CONSTANTS.EMPTY_STRING,
        validationMessage: [],
      });
      AdjustmentCalculation(inputDate, CONSTANTS.DATE);
    }
  };
  /**
   * PS_AD_26 - PS_AD_39
   * This function forms the payload for adjustDueDateCalci
   * @param e
   * @param ref
   */
  const AdjustmentCalculation = async (e: string, ref: string) => {
    const AdjustDueReq: AgreementRates[] =
      await adjustDueDateCalciPayloadFormation(selectedAgreement);
    let dueDateCalciPayload: AdjustDueDateReq = {} as AdjustDueDateReq;
    if (ref == CONSTANTS.DATE)
      dueDateCalciPayload = {
        rateResponse: AdjustDueReq,
        dueDate: dateFormat(e, CONSTANTS.MM_DD_YYYY),
      };
    else {
      const amount = Number(e);
      if (
        AdjustDueReq.length == CONSTANTS.ONE_NUMBER &&
        AdjustDueReq[0].agreementStatus === CONSTANTS.PRELIMINV &&
        amount <= minimumAmount
      ) {
        const dueDate = moment(selectedAgreement[0].currentDueDate)
          .add(1, 'd')
          .format(CONSTANTS.YYYY_MM_DD);

        dueDateCalciPayload = {
          rateResponse: AdjustDueReq,
          dueDate: moment(dueDate).format(CONSTANTS.MM_DD_YYYY),
        };
      } else {
        dueDateCalciPayload = {
          rateResponse: AdjustDueReq,
          amountAdjusted: Number(amount).toFixed(2),
        };
      }
    }
    dueDateCalci(dueDateCalciPayload, AdjustDueReq);
  };
  /**
   * PS_AD_40 - PS_AD_49
   * This function makes adjustDuedate api call and sets the data
   * @param payload
   * @param AdjustDueReq
   */
  const dueDateCalci = async (
    payload: AdjustDueDateReq,
    AdjustDueReq?: AgreementRates[]
  ) => {
    const adjustDueDatecalciRes = await AdjustDueDatecalci(payload);
    let totalAmount = CONSTANTS.ZERO_NUMBER,
      totalAmountTax = CONSTANTS.ZERO_NUMBER,
      otherFeeWithTax = CONSTANTS.ZERO_NUMBER,
      adjustedNextDueDate = CONSTANTS.EMPTY_STRING;
    const validationArray: string[] = [];
    if (
      AdjustDueReq?.length == CONSTANTS.ONE_NUMBER &&
      AdjustDueReq[0].agreementStatus === CONSTANTS.PRELIMINV
    ) {
      otherFeeWithTax =
        otherFeeWithTax +
        (selectedAgreement[0]?.deliveryFee ?? 0) +
        (selectedAgreement[0]?.deliveryFeeTax ?? 0) +
        (selectedAgreement[0].mattressFee ?? 0) +
        (selectedAgreement[0].mattressFeeTax ?? 0) +
        (selectedAgreement[0].processingFee ?? 0) +
        (selectedAgreement[0].processingFeeTax ?? 0);
    }
    adjustDueDatecalciRes.forEach((eachAgreement: AdjustDueResponse) => {
      totalAmount = Number(totalAmount) + Number(eachAgreement.totalAmount);
      totalAmountTax = Number(totalAmountTax) + Number(eachAgreement.totalTax);
      if (
        CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(eachAgreement.nextDueDate)
      )
        validationArray.push(
          `Adjusted AgreementNumber# ${eachAgreement.agreementNumber} - will be ${eachAgreement.nextDueDate}\n`
        );
      if (
        !CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(eachAgreement.nextDueDate)
      )
        adjustedNextDueDate = eachAgreement.nextDueDate;
    });
    const adjustedResponse: AgreementDetails[] = [];
    selectedAgreement.forEach((el: AgreementDetails) => {
      const currentAgreement = adjustDueDatecalciRes.find(
        (each: AdjustDueResponse) => el.agreementNumber == each.agreementNumber
      );
      if (currentAgreement) {
        adjustedResponse.push({
          ...el,
          nextDueDate: currentAgreement.nextDueDate,
          agreementType: currentAgreement.agreementType,
        });
      }
    });
    setAdjustDueDateResArray(adjustedResponse);

    updateAdjustedDueDateDate(
      validationArray,
      totalAmount,
      totalAmountTax,
      otherFeeWithTax,
      adjustDueDatecalciRes,
      adjustedNextDueDate
    );
  };

  const updateAdjustedDueDateDate = (
    validationArray: string[],
    totalAmount: number,
    totalAmountTax: number,
    otherFeeWithTax: number,
    adjustDueDatecalciRes: AdjustDueResponse[],
    adjustedNextDueDate: string
  ) => {
    if (validationArray.length > CONSTANTS.ZERO_NUMBER)
      setAdjustDueDateData((prev) => ({
        ...prev,
        amount: String(
          Number(totalAmount + totalAmountTax + otherFeeWithTax).toFixed(2)
        ),
        dateError: CONSTANTS.EMPTY_STRING,
        amountError: CONSTANTS.EMPTY_STRING,
        validationMessage: validationArray,
        nextDueDate: dateFormat(adjustedNextDueDate, CONSTANTS.YYYY_MM_DD),
      }));
    else {
      setAdjustDueDateData({
        ...adjustDueDateData,
        amount: String(
          Number(totalAmount + totalAmountTax + otherFeeWithTax).toFixed(2)
        ),
        nextDueDate: dateFormat(
          adjustDueDatecalciRes[0]?.nextDueDate,
          CONSTANTS.YYYY_MM_DD
        ),
        dateError: CONSTANTS.EMPTY_STRING,
        amountError: CONSTANTS.EMPTY_STRING,
        validationMessage: [],
      });
    }
  };
  /**
   * PS_AD_50 - PS_AD_65
   * This function triggers in the save click
   * It sets the data to the context
   */
  const onSave = async () => {
    setSaveLoader(true);
    const commonModuleResponse = await commonModuleCallFunction();
    const finalResponse: AgreementDetails[] = [];
    agreementDetails.forEach((el: AgreementDetails) => {
      if (el.selected) {
        let commonModuleAgreement: (AgreementPaymentDetails &
          ClubPaymentDetails)[] = [];
        if (el.agreementType != CONSTANTS.CLUB) {
          commonModuleAgreement =
            commonModuleResponse?.agreementPaymentDetails?.filter(
              (item: AgreementPaymentDetails) =>
                item.agreementId == el.agreementId
            );
        } else {
          commonModuleAgreement =
            commonModuleResponse?.clubPaymentDetails?.filter(
              (item: ClubPaymentDetails) =>
                item.clubMembershipNumber == el.agreementNumber
            );
        }
        updateSelectedAgreements(finalResponse, el, commonModuleAgreement);
      } else finalResponse.push({ ...el });
    });
    setAgreementDetails(finalResponse);
    setModalCardEnable({
      ...modalCardEnable,
      adjustDueDatePopup: false,
      onLoadDueDateAdjustment: false,
    });
    setRenderContext(!renderContext);
    setSaveLoader(false);
  };

  const commonModuleCallFunction = async () => {
    const payload = await commonModulePayloadFormation(
      adjustDueDateResArray,
      adjustDueDateData,
      [CONSTANTS.DDA]
    );
    const commonPayload: CommonModuleProps = {
      customerId: customerId,
      storeNumber: storeNumber,
      checkedAgr: payload,
    };
    const commonModulePayload = await calculationModulePayloadFormation(
      commonPayload
    );
    const commonModuleServiceRes = await calculatePaymentModule(
      commonModulePayload
    );
    return commonModuleServiceRes?.data?.paymentDetails[0];
  };

  const updateSelectedAgreements = (
    finalResponse: AgreementDetails[],
    el: AgreementDetails,
    commonModuleAgreement: (AgreementPaymentDetails & ClubPaymentDetails)[]
  ) => {
    finalResponse.push({
      ...el,
      agreementType: commonModuleAgreement[0].agreementType ?? el.agreementType,
      agreementSchedule: commonModuleAgreement[0].schedule,
      nextDueDate: commonModuleAgreement[0].nextDueDate
        ? dueDateFormat(commonModuleAgreement[0].nextDueDate)
        : commonModuleAgreement[0].isFinalPayment
        ? dueDateFormat(commonModuleAgreement[0].isFinalPayment)
        : dueDateFormat(el.nextDueDate),
      totalAmount: commonModuleAgreement[0].totalAmount,
      totalDueAmount: totalDueAmount(
        commonModuleAgreement[0]?.totalAmount,
        commonModuleAgreement[0]?.totalTax
      ),
      agreementRateDetails:
        commonModuleAgreement[0].agreementRateDetails ||
        commonModuleAgreement[0].clubRateDetails,
      isAdjustDuedateEdit: true,
      totalTax: commonModuleAgreement[0].totalTax,
    });
  };
  /**
   * PS_AD_66 - PS_AD_71
   * This function holds the HTML for the adjust due date popup
   * @returns
   */
  const dueDatePopup = () => {
    return (
      <Grid container spacing={2} className={classes.px3}>
        <Grid item className={`${classes.w100} ${classes.mt2}`}>
          <Grid
            className={`${classes.w100} ${classes.mb3}`}
            style={{ display: 'flex' }}
          >
            <Grid className={`${classes.ms2} ${classes.w100}`}>
              <RACDatePicker
                value={selectedAgreement[0].currentDueDate}
                label="Current Due Date"
                name="Current Due Date"
                disabled
                data-testid="currentDueDate"
              />
            </Grid>
            <Grid className={`${classes.ms2} ${classes.w100}`}>
              <RACDatePicker
                value={adjustDueDateData.nextDueDate}
                label="Next Due Date"
                name="nextDueDate"
                // disabled={loadSpinner}
                onChange={(e: string) => onDateChange(e)}
                inputProps={{
                  min: moment(selectedAgreement[0].currentDueDate)
                    .add(1, 'd')
                    .format(CONSTANTS.YYYY_MM_DD),
                }}
                errorMessage={adjustDueDateData.dateError}
                data-testid="nextDueDate"
              />
            </Grid>
          </Grid>
          <Grid
            item
            className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
          >
            <label
              htmlFor="a11y_takehome"
              className={`${classes.formLabel} ${classes.ms2}  ${classes.mb1}${classes.floatLeft}`}
            >
              Total Amount
            </label>
            <Grid
              className={`${classes.w100} ${classes.inputgroup} ${classes.mb3}`}
            >
              <Grid className={`${classes.ms2} ${classes.w100}`}>
                <RACTextbox
                  isCurrency={true}
                  className={`${classes.custDigit} ${classes.cashtxtbox} ${classes.adjustTotalAmountField}`}
                  dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                  value={String(adjustDueDateData.amount)}
                  Onblur={(e) =>
                    adjustDueDateData.dateError || adjustDueDateData.amountError
                      ? null
                      : AdjustmentCalculation(e.target.value, CONSTANTS.AMOUNT)
                  }
                  OnChange={(e) => onAmountChange(e)}
                  name="adjustTotalAmount"
                  digitFormat={'currency'}
                  maxlength={10}
                  errorMessage={adjustDueDateData.amountError}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          className={`${classes.w100} ${classes.inputgroup} ${classes.mb3}`}
        >
          {adjustDueDateData.validationMessage.length >
          CONSTANTS.ZERO_NUMBER ? (
            <Typography
              component={'label'}
              id={'epoAmountLbl'}
              className={`${classes.mandatoryfield} ${classes.susErrorLbl}`}
              role="validationMessage"
            >
              {adjustDueDateData.validationMessage}
            </Typography>
          ) : null}
        </Grid>

        <Grid
          className={`${classes.textright} ${classes.modalfooter} ${classes.floatRight} ${classes.gridwidth}`}
        >
          {selectedAgreement?.length == CONSTANTS.ONE_NUMBER &&
          CONSTANTS.PRELIM_PRELIM_INV_ARRAY.includes(
            selectedAgreement[0]?.agreementStatus
          ) ? (
            <></>
          ) : (
            <RACButton
              data-testid="cancelBtn"
              className={classes.ms2}
              color="primary"
              variant="outlined"
              name="clearCancel"
              data-bs-toggle="modal"
              style={{
                backgroundColor: 'white',
                color: '#2C3333',
              }}
              onClick={() => {
                setModalCardEnable({
                  ...modalCardEnable,
                  adjustDueDatePopup: false,
                  onLoadDueDateAdjustment: false,
                });
              }}
            >
              Cancel
            </RACButton>
          )}
          <RACButton
            data-testid="saveBtn"
            className={classes.ms2}
            color="primary"
            variant="contained"
            name="De_ActivateClubbtn"
            data-bs-toggle="modal"
            onClick={() => onSave()}
            disabled={
              adjustDueDateData.amountError || adjustDueDateData.dateError
                ? true
                : false
            }
            loading={saveLoader}
          >
            Save
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  /**
   * PS_AD_72
   * Within return, it holds rac modal card that calls dueDatePopup()
   */
  return (
    <>
      <RACModalCard
        isOpen={
          modalCardEnable.adjustDueDatePopup ||
          modalCardEnable.onLoadDueDateAdjustment
        }
        maxWidth="xs"
        title="Adjust Due Date"
        borderRadius="20px"
      >
        {dueDatePopup()}
      </RACModalCard>
    </>
  );
}

/**
 * Need to remove the save disable condition in exceeded amount validation and on click save in that scenario,
 * need to update the total amount as epo amount(will be in popup and change the next due date)
 */
