/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */

/**
 * PS_CR_1
 */
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  RACToggle,
} from '@rentacenter/racstrap';
import React, { useContext, useState, useEffect } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import { calculateTotalDueAmount } from '../utils/calculateTotalDueAmount';
import CurrencyInput from 'react-currency-input-field';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import {
  CarryRentInterface,
  carryToggleButtonEnableAndDisable,
} from '../interface/carryRentInterface';
import moment from 'moment';
import { calculateRemainingAmountDue } from '../utils/amountCalculations';
import { AgreementDetails } from '../interface/paymentInfoInterface';
import CONSTANTS from '../constants/constant';
/**
 * PS_CR_2
 * @returns
 */
export function CarryRent() {
  /**
   * PS_CR_3, PS_CR_4
   */
  const classes = takePaymentStyles();
  const agreementInfo = useContext(agreementContext);
  const [enableCarryRent, setEnableCarryRent] = useState<boolean>(false);
  const [carryRentData, setCarryRentData] = useState<CarryRentInterface>({
    minPayment: CONSTANTS.ZERO_TWO_DECIMAL_NUMBER,
    maxCarryAmount: CONSTANTS.ZERO_TWO_DECIMAL_NUMBER,
  });
  const [carryAmount, setCarryAmount] = useState<string>(
    CONSTANTS.ZREO_TWO_DECIMAL_STRING
  );
  const [carryToggleEnableAndDisable, setCarryToggleEnableAndDisable] =
    useState<carryToggleButtonEnableAndDisable>({
      // isInitialAndFinalAgreement: false,
      isAgreementSelected: false,
      isPendingAgrTransfer: false,
      diffStoreAgreement: false,
      isRevenueRecogActivated: false,
    });

  /**
   * PS_CR_5, PS_CR_6, PS_CR_7
   */

  useEffect(() => {
    const conditions = {
      // isInitialAndFinalAgreement: agreementInfo?.agreementDetails?.some(
      //   (agreementValue) => {
      //     return (
      //       agreementValue.selected &&
      //       (agreementValue.numberOfPaymentsMade == CONSTANTS.ZERO_NUMBER ||
      //         CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(
      //           agreementValue.nextDueDate
      //         ) ||
      //         agreementValue.isZeroPayment)
      //     );
      //   }
      // ),
      isAgreementSelected: agreementInfo?.agreementDetails?.some(
        (agreementValue) => agreementValue.selected
      ),
      isPendingAgrTransfer: !!agreementInfo?.storeInfo?.storeDetails?.some(
        (storeValue) =>
          storeValue.storeNumber ==
            sessionStorage.getItem(CONSTANTS.STORE_NUMBER) &&
          storeValue.transferAgreementStatus
      ),
      diffStoreAgreement: agreementInfo?.agreementDetails?.some(
        (agreementValue) =>
          agreementValue.selected &&
          agreementValue.storeNumber !=
            sessionStorage.getItem(CONSTANTS.STORE_NUMBER)
      ),
      isRevenueRecogActivated:
        !!agreementInfo?.featureFlags?.RevenueRecognition,
    };
    setCarryToggleEnableAndDisable(conditions);
    if (
      !agreementInfo.customerInfo.carryRentEnable &&
      agreementInfo.customerInfo.carryRentToggleEnabled
    ) {
      handleToggle(false);
    }
  }, [agreementInfo.customerInfo, agreementInfo.agreementDetails]);

  // useEffect(() => {
  //   setRerender(true);
  //   if (rerender) {
  //     handleToggle(false);
  //   }
  // }, [
  //   agreementInfo.customerInfo.amountUsed.remainingAmountDue,
  // ]);

  /**
   * PS_CR_9 - PS_CR_16
   * This function will calculate the Mininum payment amount
   */
  const calculateMiniPayment = () => {
    let totalAmount: number = CONSTANTS.ZERO_NUMBER;
    let totalMaxCarryAmount: number = CONSTANTS.ZERO_NUMBER;

    agreementInfo.agreementDetails.map((agreementValue: AgreementDetails) => {
      const startDate = moment(agreementValue.currentDueDate);
      const endDate = moment(agreementValue.nextDueDate);
      const DaysDiffer = endDate.diff(startDate, 'days');
      if (agreementValue.selected) {
        const amountForSpecificAgreements =
        calculateTotalDueAmount(agreementValue).toString();
        console.log(parseFloat(
          amountForSpecificAgreements.includes(CONSTANTS.OPEN_BRACKET_STRING) &&
            amountForSpecificAgreements.includes(CONSTANTS.CLOSE_BRACKET_STRING)
            ? CONSTANTS.ZREO_TWO_DECIMAL_STRING
            : amountForSpecificAgreements
        ),"total amount calculation")
        totalAmount += parseFloat(
          amountForSpecificAgreements.includes(CONSTANTS.OPEN_BRACKET_STRING) &&
            amountForSpecificAgreements.includes(CONSTANTS.CLOSE_BRACKET_STRING)
            ? CONSTANTS.ZREO_TWO_DECIMAL_STRING
            : amountForSpecificAgreements
        );

        if (
          !CONSTANTS.BASIC_AGREEMENT_TYPE_ARRAY.includes(
            agreementValue.agreementType
          ) &&
          !CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(
            agreementValue.nextDueDate
          ) &&
          agreementValue.otherIncomeType == CONSTANTS.EMPTY_STRING &&
          agreementValue.numberOfPaymentsMade !== CONSTANTS.ZERO_NUMBER &&
          !agreementValue.isZeroPayment &&
          !CONSTANTS.ZERO_AND_ONE_ARRAY.includes(DaysDiffer)
        ) {
          totalMaxCarryAmount += calculatePerDayRent(
            agreementValue.agreementRateDetails
          );
        }
      }
    });

    const calculatedRespose = calculateRemainingAmountDue(agreementInfo);
    console.log(totalAmount, totalMaxCarryAmount, "amount calculation on min payment")
    setCarryRentData({
      ...carryRentData,
      minPayment: totalAmount - totalMaxCarryAmount,
      maxCarryAmount:
        calculatedRespose.remainingAmount > CONSTANTS.ZERO_NUMBER
          ? calculatedRespose.remainingAmount <= totalMaxCarryAmount
            ? calculatedRespose.remainingAmount
            : totalMaxCarryAmount
          : CONSTANTS.ZERO_NUMBER,
    });
  };

  /**
   * PS_CR_18, PS_CR_19, PS_CR_20, PS_CR_21, PS_CR_22
   * This function will calculate the per day rent for indivigual agreement.
   * @param agreementRateDetails
   * @returns
   */
  const calculatePerDayRent = (agreementRateDetails): number => {
    let calculatePerDayRent: number = CONSTANTS.ZERO_NUMBER;
    calculatePerDayRent +=
      parseFloat(
        agreementRateDetails?.perDayRent
          ? agreementRateDetails.perDayRent
          : CONSTANTS.ZERO_NUMBER
      ) +
      parseFloat(
        agreementRateDetails?.perDayRentTax
          ? agreementRateDetails.perDayRentTax
          : CONSTANTS.ZERO_NUMBER
      );

    if (
      agreementRateDetails.isLdwCoverageAllowed === CONSTANTS.STATUS_YES ||
      agreementRateDetails.isLDWActionPerformed === CONSTANTS.STATUS_YES
    ) {
      calculatePerDayRent +=
        parseFloat(
          agreementRateDetails?.perDayLdw
            ? agreementRateDetails.perDayLdw
            : CONSTANTS.ZERO_NUMBER
        ) +
        parseFloat(
          agreementRateDetails?.perDayLdwTax
            ? agreementRateDetails.perDayLdwTax
            : CONSTANTS.ZERO_NUMBER
        );
    }

    return calculatePerDayRent;
  };

  /**
   * PS_CR_36, PS_CR_37, PS_CR_38
   * this function on and off the carry rent toggle button and invoke the calculateMiniPayment function.
   * @param e
   */

  const handleToggle = (checked: boolean) => {
    const customerInfoClone = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );

    if (
      checked &&
      carryToggleEnableAndDisable.isAgreementSelected
      // !carryToggleEnableAndDisable.isInitialAndFinalAgreement
    ) {
      setEnableCarryRent(true);
      calculateMiniPayment();
      customerInfoClone.carryRentToggleEnabled = true;
      customerInfoClone.carryRentEnable = true;
    } else {
      setEnableCarryRent(false);
      setCarryRentData({
        minPayment: CONSTANTS.ZERO_TWO_DECIMAL_NUMBER,
        maxCarryAmount: CONSTANTS.ZERO_TWO_DECIMAL_NUMBER,
      });
      setCarryAmount(CONSTANTS.ZREO_TWO_DECIMAL_STRING);
      customerInfoClone.carryRentToggleEnabled = false;
      customerInfoClone.carryRentEnable = false;
      agreementInfo.setCarryRent(CONSTANTS.ZREO_TWO_DECIMAL_STRING);
      agreementInfo.setRenderContext(!agreementInfo.renderContext);
    }

    agreementInfo.setCustomerInfo(customerInfoClone);
  };

  /**
   * PS_CR_33, PS_CR_34
   * This function set state the values into carryAmonut variable
   * @param e
   */

  const onCarryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCarryAmount(e.target.value);

    if (Number(e.target.value) > carryRentData.maxCarryAmount) {
      agreementInfo.setCarryRent(CONSTANTS.ZREO_TWO_DECIMAL_STRING);
      agreementInfo.setPaymentDisable((prev) => ({
        ...prev,
        isValidCarryRent: true,
      }));
    } else {
      agreementInfo.setCarryRent(Number(e.target.value).toFixed(2));
      agreementInfo.setPaymentDisable((prev) => ({
        ...prev,
        isValidCarryRent: false,
      }));
    }
    agreementInfo.setRenderContext(!agreementInfo.renderContext);
  };

  /**
   * PS_CR_34.1
   * this function convert the carryAmount value to two decimal places.
   */

  const carryRentonBlur = () => {
    setCarryAmount(Number(carryAmount).toFixed(2));
  };

  /**
   * PS_CR_23 - PS_CR_32
   * Rac strap binding
   */
  return (
    <>
      <RACToggle
        name="Carry Rent"
        GridClassName={`${classes.gridClassToggleReverse} ${classes.carryRentBottom}`}
        labelGridClassName={classes.gridClassLabel}
        checked={
          enableCarryRent && carryToggleEnableAndDisable.isAgreementSelected
        }
        toggleColor="#71CA2F"
        leftText={true}
        disabled={
          carryToggleEnableAndDisable.isPendingAgrTransfer ||
          (carryToggleEnableAndDisable.diffStoreAgreement &&
            carryToggleEnableAndDisable.isRevenueRecogActivated)
        }
        labelClassName={classes.switchLabel}
        leftLabel="Carry Rent"
        OnClick={(e) => {
          handleToggle(e.target.checked);
        }}
      />

      {enableCarryRent ? (
        <Grid container data-testid="availcreditid">
          <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
            <Card
              className={`${classes.card} ${classes.w100} ${classes.floatLeft} ${classes.p3}`}
              style={{ marginBottom: '90px' }}
            >
              <CardContent className={classes.p0}>
                <Grid className={`${classes.w100} ${classes.floatLeft}`}>
                  <>
                    <Typography
                      component={'label'}
                      className={`${classes.formLabel}  ${classes.mt3} ${classes.floatLeft}`}
                    >
                      Minimum Payment
                    </Typography>
                    <Box
                      component="span"
                      className={`${classes.labeltxtstyle} ${classes.mt3} ${classes.floatRight} ${classes.textend} `}
                    >
                      $
                      <Box className={`${classes.displayInline}`}>
                        {carryRentData.minPayment < CONSTANTS.ZERO_NUMBER
                          ? CONSTANTS.ZREO_TWO_DECIMAL_STRING
                          : carryRentData.minPayment.toFixed(2)}
                      </Box>
                    </Box>
                  </>
                </Grid>
                <Grid className={`${classes.w100} ${classes.floatLeft}`}>
                  <>
                    <Typography
                      component={'label'}
                      className={`${classes.formLabel}  ${classes.mt3} ${classes.floatLeft}`}
                    >
                      Max. Carry Amount
                    </Typography>
                    <Box
                      component="span"
                      className={`${classes.labeltxtstyle} ${classes.mt3} ${classes.floatRight} ${classes.textend} `}
                    >
                      $
                      <Box className={`${classes.displayInline}`}>
                        {carryRentData.maxCarryAmount <= CONSTANTS.ZERO_NUMBER
                          ? CONSTANTS.ZREO_TWO_DECIMAL_STRING
                          : carryRentData.maxCarryAmount.toFixed(2)}
                      </Box>
                    </Box>
                  </>
                </Grid>
                <Grid className={`${classes.w100} ${classes.floatLeft}`}>
                  <>
                    <Typography
                      component={'label'}
                      className={`${classes.formLabel} ${classes.w35} ${classes.mt3} ${classes.floatLeft}`}
                    >
                      Carry Amount
                    </Typography>
                  </>
                  <Grid
                    item
                    className={`${classes.w50} ${classes.floatRight} ${classes.mt2}`}
                  >
                    <Grid
                      item
                      className={`${classes.inputgroup} ${classes.mb3}`}
                    >
                      <span className={classes.inputgrouptext}>$</span>
                      <CurrencyInput
                        className={`${classes.formcontrol3} ${classes.textend}`}
                        value={carryAmount}
                        type="text"
                        id="carryRent"
                        defaultValue={carryRentData.maxCarryAmount}
                        decimalsLimit={2}
                        onChange={(e) => {
                          onCarryChange(e);
                        }}
                        onBlur={() => carryRentonBlur()}
                      />
                    </Grid>
                    {Number(carryAmount) >
                    Number(carryRentData.maxCarryAmount) ? (
                      <Typography
                        component={'label'}
                        className={classes.mandatoryfield}
                      >
                        Amount should not be entered more than the
                        Max.CarryAmount
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}
