/* eslint-disable */
/**PSC_NO_01 -PSC_NO_03
 * Importing the required hooks, component and racstrap elements
 */
import { Grid, RACButton, RACModalCard, Typography, RACRadio } from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import React, { useState, useEffect, useContext } from 'react';

import { agreementContext } from '../context/PaymentInformationContext';
import { ReactComponent as Txt2payimg } from '../../../src/assets/images/Txt-Pay-Icon.svg';
import { cardTypeValdation, formatPhone } from '../utils/textToPayUtil';
import { CoworkerRequired, Sendtxt2pay } from '../../api/user';
import { ReactComponent as Visacard } from '../../../src/assets/images/Visa-Card.svg';
import { ReactComponent as Mastercard } from '../../../src/assets/images/Mastercard.svg';
import { ReactComponent as AmericanExpress } from '../../assets/images/American Express - img.svg';
import { ReactComponent as DinersClub } from '../../assets/images/Diners Club-img.svg';
import { ReactComponent as JCB } from '../../assets/images/JCB - img.svg';
import { ReactComponent as Paypal } from '../../assets/images/paypal-img.svg';
import { ReactComponent as Discover } from '../../assets/images/Discover - img.svg';
import CONSTANTS from '../constants/constant';
import { PaymentMethod } from '../interface/paymentInfoInterface';
import { useParams } from 'react-router-dom';
import { ParamType } from '../interface/commonInterface';
import { CreatePaymentInfoForPayload } from '../utils/acceptPaymentUtills';

export function TextPayPopup() {
  /**
   * PSC_NO_04
   * Declaring the required state variables and destructuring the required context state variable.
   */
  const classes = takePaymentStyles();
  const { customerId } = useParams<ParamType>();
  const { agreementDetails, customerInfo,storeInfo, paymentOrigin, coWorkerDetails, featureFlags, multiStoreSelected, paymentDisable } = useContext(agreementContext);
  const [textToPayPopupFlag, setTextToPayPopupFlag] = useState<boolean>(false);
  const [txt2PayRadioArr, setTxt2PayRadioArr] = useState<boolean[]>([]);
  const [selectedTxt2PayCard, setSelectedTxt2PayCard] = useState<PaymentMethod[]>([]);

  /**
   * PSC_NO_07
   * @param isCardOnFile
   * @returns
   * This function is used to render the UI of Text2pay and Text2PaycardOnFile based on the configFlag
   */
  const Txt2payPopup = (isCardOnFile: boolean) => {

    console.log("isCardOnFile", isCardOnFile);
    return (
      <Grid
        item
        id="ph-text-autopay"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="ph-text-autopay"
        aria-modal="true"
        data-testid="Txt2payPopup"
      >
        <Grid container className={isCardOnFile ? CONSTANTS.EMPTY_STRING : classes.textcenter}>
          {!isCardOnFile && (
            <>
              <Grid item md={12} className={classes.mb3}>
                <Txt2payimg />
              </Grid>
              <Grid item md={12} className={classes.mb4}>
                <Typography variant="h5" className={classes.customtextpay}>
                  Phone to Text for Payment
                </Typography>
              </Grid>
            </>
          )}

          {isCardOnFile && (
            <Grid item md={12} className={classes.mb4}>
              <Typography className={classes.customtextpayNew}>
                Select a saved card on file or by making no selection customer will receive a link to manually enter card information.
              </Typography>
            </Grid>
          )}

          <Grid
            md={12}
            className={`${isCardOnFile ? classes.floatLeft : classes.bgpopdelete} ${classes.floatLeft}`}
          >
            <Grid container md={12} className={classes.justifycontentcenter}>
              <Grid item md={6} className={classes.floatLeft}>
                <Typography
                  variant="caption"
                  className={`${isCardOnFile ? classes.RAClabelText2paytxtstyle : classes.RAClabeltxtstyle} ${classes.w100} ${classes.pt4} ${classes.floatLeft}`}
                >
                  {isCardOnFile ? CONSTANTS.CUSTOMER_NAME : CONSTANTS.PHONE_NUMBER}
                </Typography>
                <Typography
                  className={`${classes.w100} ${classes.pb4} ${classes.semibold}`}
                >
                  {/**PSC_NO_08
                 * The formatPhone() method used to validate handle the regex of the phone number
                 */}
                  {isCardOnFile
                    ? `${customerInfo.customerDetails[0].firstName} ${customerInfo.customerDetails[0].lastName}`
                    : formatPhone(customerInfo.customerDetails[0]?.phoneNumber ?? CONSTANTS.EMPTY_STRING)
                  }
                </Typography>
              </Grid>
              <Grid item md={6} className={classes.floatLeft}>
                <Typography
                  variant="caption"
                  className={`${isCardOnFile ? classes.RAClabelText2paytxtstyle : classes.RAClabeltxtstyle} ${classes.w100} ${classes.pt4} ${classes.floatLeft}`}
                >
                  {isCardOnFile ? CONSTANTS.PHONE_NUMBER : CONSTANTS.CUSTOMER_NAME}
                </Typography>
                <Typography
                  className={`${classes.w100} ${classes.pb4} ${classes.semibold}`}
                >
                  {isCardOnFile
                    ? formatPhone(customerInfo.customerDetails[0]?.phoneNumber ?? CONSTANTS.EMPTY_STRING)
                    : `${customerInfo.customerDetails[0].firstName} ${customerInfo.customerDetails[0].lastName}`
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {isCardOnFile && (
            <Grid className={`${classes.w100} ${classes.cardMapping}`}>
              {GetTxt2PaycardMpping()}
            </Grid>
          )}

          <Grid
            item
            md={12}
            className={`${classes.pb2} ${isCardOnFile ? classes.modalfooterNew : classes.modalfooter}`}
          >
            <RACButton
              className={classes.mx1}
              variant="outlined"
              color="primary"
              data-bs-dismiss="modal"
              onClick={() => {
                console.log("cancel");
                setTextToPayPopupFlag(false); isCardOnFile ? handleReset() : null
              }}
              data-testid="txt2payCancel"
            >
              Cancel
            </RACButton>
            {/**PSC_NO_10
           * For the cardOnfile the Reset button is rendered
           */}
            {isCardOnFile ? (
              <>
                <RACButton
                  className={classes.mx1}
                  color="primary"
                  variant="contained"
                  data-bs-dismiss="modal"
                  disabled={customerInfo.customerCardDetails?.paymentMethods?.length > 0 && selectedTxt2PayCard.length > 0 ? false : true}
                  onClick={() => handleReset()}
                  data-testid="resettxt2payId"
                >
                  Reset
                </RACButton>
                <RACButton
                  className={classes.mx1}
                  color="primary"
                  variant="contained"
                  data-bs-dismiss="modal"
                  onClick={() => yesTextToPayButton(isCardOnFile)}
                  data-testid="Yestxt2payId"
                >
                  Send
                </RACButton>
              </>
            ) : (
              <RACButton
                className={classes.mx1}
                color="primary"
                variant="contained"
                data-bs-dismiss="modal"
                onClick={() => yesTextToPayButton(isCardOnFile)}
                data-testid="Yestxt2payId"
              >
                Yes
              </RACButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (textToPayPopupFlag && featureFlags.Txt2PayCardOnFile == '1') {
      const Card_FalseArray: boolean[] = new Array(cardTypeValdation(customerInfo.customerCardDetails?.paymentMethods)?.length).fill(
        false
      );
      setTxt2PayRadioArr(Card_FalseArray);
    }
  }, [textToPayPopupFlag]);

  /**
   * PSC_NO_15
   * @param arg
   * @returns
   * The Bindcardsymbol() used to render the symbols of the saved card.
   */
  const Bindcardsymbol = (arg: string) => {
    if (arg === CONSTANTS.MASTER_CARD) {
      return <Mastercard className={classes.cardSize}></Mastercard>;
    }
    if (arg === CONSTANTS.AMERICAN_EXPRESS_CARD) {
      return <AmericanExpress className={classes.cardSize}></AmericanExpress>;
    }
    if (arg === CONSTANTS.JCB_CARD) {
      return <JCB className={classes.cardSize}></JCB>;
    }
    if (arg === CONSTANTS.DINERS_CLUB) {
      return <DinersClub className={classes.cardSize}></DinersClub>;
    }
    if (arg === CONSTANTS.PAY_PAL) {
      return <Paypal className={classes.cardSize}></Paypal>;
    }
    if (arg === CONSTANTS.DISCOVER) {
      return <Discover className={classes.cardSize}></Discover>;
    } else {
      return <Visacard className={classes.cardSize}></Visacard>;
    }
  };

  /**
   * PSC_NO_16 - PSC_NO_18
   * @param index
   * @param item
   * The handleRadioChange() method is used to select and unselect the saved card
   */
  const handleRadioChange = (index: number, item: PaymentMethod) => {
    let radioBtnArr: boolean[] = txt2PayRadioArr.map((x: boolean, indexNew: number) => {
      return index == indexNew ? !x : false
    });
    if (radioBtnArr[index]) {
      setTxt2PayRadioArr([...radioBtnArr]);
      setSelectedTxt2PayCard([item]);
      console.log("item_Txt2pay", item);
    }


  }

  /**
   * PSC_NO_19 - PSC_NO_20
   * The handleReset() method used to reset the selected card.
   */
  const handleReset = () => {
    if (customerInfo.customerCardDetails?.paymentMethods !== undefined && customerInfo.customerCardDetails?.paymentMethods?.length > 0) {
      const Card_FalseArray: boolean[] = new Array(cardTypeValdation(customerInfo.customerCardDetails?.paymentMethods).length).fill(
        false
      );
      setTxt2PayRadioArr(Card_FalseArray);
      setSelectedTxt2PayCard([]);
    }
  }

  /**PSC_NO_15
   * If the customer has save card then the function GetTxt2PaycardMpping() used to
   * render Saved  cards.
   */
  const GetTxt2PaycardMpping = () => {

    const filteredCardData: PaymentMethod[] = cardTypeValdation(customerInfo.customerCardDetails?.paymentMethods);

    return filteredCardData?.map((item: PaymentMethod, index: number) => {
      return (
        <Grid
          item
          key={index}
          className={`${classes.w100} ${classes.floatLeft} `}
        >
          <Grid
            item
            className={`  ${classes.floatLeft} ${classes.pe0} ${classes.w100}`}
          >
            <Grid
              item
              className={`${classes.formcheck} ${classes.mar6}  ${classes.ps0} ${classes.floatLeft} ${classes.alignContentCenterTxt2Pay} ${classes.w100}`}
            >
              <RACRadio
                style={{ float: "left" }}
                defaultValue=""
                id={"card-2" + index}
                value={index}
                name="Card CheckBox"
                checked={txt2PayRadioArr[index] == false ? false : true}
                onChange={() => handleRadioChange(index, item)}
                data-testid="2ndCardChkboxId"
              />
              <Typography
                className={`${classes.font12} ${classes.alignContentCenter}`}
                htmlFor={"card-2" + index}
                id={"ce" + index}
                component={"label"}
                data-testid="2ndCardLabelId"
              >
                {CONSTANTS.STRING_WITH_SINGLE_SPACE}
                {Bindcardsymbol(item.cardType)} Card ending with {item.lastFour}
              </Typography>
            </Grid>
          </Grid>

        </Grid>
      );
    });
  };

  /**
   * PSC_NO_21 - PSC_NO_60
   * @param isCardOnFile
   * The yesTextToPayButton() method is invoked when the send/yes button clicked on the txt2pay
   * popup
   */
  const yesTextToPayButton = async (isCardOnFile: boolean) => {
    debugger;
    const transactionDate = new Date();
    const postPaymentTextToPay = await CreatePaymentInfoForPayload(agreementDetails, undefined, undefined, isCardOnFile, {
      customerId,
      agreementDetails,
      customerInfo,
      multiStoreSelected,
      featureFlags,
      paymentOrigin,
      coWorkerDetails,
      storeInfo
    })

    const txt2payReqparam: any = {
      // storeMerchantId: storeMerchantId,
      optimizedPayload:
        { postPayment: [postPaymentTextToPay] },
      // miscellaneousArray: [],
      // recieptCategoryReqDetails: [],
      storeNumber: String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)),
      customerId: String(customerId),
      storeCoWorkerId: coWorkerDetails.employeeId,
      carryAmount: Number(customerInfo.amountUsed.carryRent),
      taxRate: Number(agreementDetails[0].taxRate),
      paymentOriginName: CONSTANTS.IN_STORE_VALUE,
      totalAmountCollected: CONSTANTS.ZERO_NUMBER,
      totalTaxCollected: CONSTANTS.ZERO_NUMBER,
      paymentId: CONSTANTS.EMPTY_STRING,
      cardAmount: CONSTANTS.ZERO_NUMBER,
      cashAmount: CONSTANTS.ZERO_NUMBER,
      moneyOrderAmount: CONSTANTS.ZERO_NUMBER,
      checkAmount: CONSTANTS.ZERO_NUMBER,
      phoneNumber: String(customerInfo.customerDetails[0].phoneNumber),
      transactionDate: String(transactionDate),
      customerZip:
        customerInfo.customerDetails[0].zip +
        CONSTANTS.HYPHEN +
        customerInfo.customerDetails[0].plus4,
      customerState: String(customerInfo.customerDetails[0].state),
      customerDOB: String(customerInfo.customerDetails[0].dob),
      cardDetails: [],
      documentDetails: [],
      paymentAmount: CONSTANTS.ZERO_NUMBER, //Need to check with Naveen
      cardFlag: CONSTANTS.EMPTY_STRING,
      emailAddress: String(
        customerInfo.customerDetails[0].emailAddress
      ),
      originFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      totalAmountDue: CONSTANTS.ZERO_NUMBER,  //Need to check with Naveen
      wlDeposit:
        Number(customerInfo.amountUsed.webLeadUsed) > CONSTANTS.ZERO_NUMBER
          ? Number(customerInfo.amountUsed.webLeadUsed)
          : CONSTANTS.ZERO_NUMBER,
      itSuspence:
        Number(customerInfo.amountUsed.ipSuspenseUsed) > CONSTANTS.ZERO_NUMBER ? Number(customerInfo.amountUsed.ipSuspenseUsed) : CONSTANTS.ZERO_NUMBER,
      suspense: CONSTANTS.ZERO_NUMBER,
      customerInfo: {
        FirstName: String(customerInfo.customerDetails[0].firstName),
        LastName: String(customerInfo.customerDetails[0].lastName),
      },
      creditOnAccount:
        Number(customerInfo.amountUsed.coaUsed) > CONSTANTS.ZERO_NUMBER
          ? Number(customerInfo.amountUsed.coaUsed)
          : CONSTANTS.ZERO_NUMBER,
      firstName: String(customerInfo.customerDetails[0].firstName),
      lastName: String(customerInfo.customerDetails[0].lastName),
      address1: String(customerInfo.customerDetails[0].addressLine1),
      change: CONSTANTS.ZERO_NUMBER,
      // acceptPayment: [],
      stateProvince: String(customerInfo.customerDetails[0].state),
      postalCode: String(customerInfo.customerDetails[0].zip),
      city: String(customerInfo.customerDetails[0].city),
      // acceptClubPayment: [],
    };
    if (selectedTxt2PayCard?.length > CONSTANTS.ZERO_NUMBER && Number(featureFlags?.Txt2PayCardOnFile) == CONSTANTS.ONE_NUMBER) {
      txt2payReqparam.customerPaymentInfo = {
        address2: String(customerInfo.customerDetails[0].addressLine2) ? String(customerInfo.customerDetails[0].addressLine2) : CONSTANTS.EMPTY_STRING,
        cardType: selectedTxt2PayCard[0]?.cardTypeRef,
        cardLastFour: selectedTxt2PayCard[0]?.lastFour,
        cardExpiration: selectedTxt2PayCard[0]?.tokenInfo.expirationDate,
        globalCustomerId: customerInfo.customerDetails[0].globalCustomerId ? customerInfo.customerDetails[0].globalCustomerId : undefined,
        paymentMethodId: selectedTxt2PayCard[0]?.paymentMethodId,
        storeId: Number(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER))

      }
    }
    try {
      const Txt2Payresp = await Sendtxt2pay(txt2payReqparam);
      isCardOnFile ? handleReset() : null;
      setTextToPayPopupFlag(false);
    }
    catch (e) {
      console.log("Txt2Payresp", e);
      isCardOnFile ? handleReset() : null;
      setTextToPayPopupFlag(false);
    }


  }

  const enableTxt2Pay = () => {
    return agreementDetails.some((x) => x.selected == true) && paymentDisable.alertDisable == false && customerInfo.amountUsed.tenderDetails.moneyOrderDetails.length == 0 && customerInfo.amountUsed.tenderDetails.checkDetails.length == 0 && Number(customerInfo.amountUsed.tenderDetails.cash.amount) == 0 && customerInfo.amountUsed.cardDetails.length == 0 ? false : true
  }

  return (
    <>
      {/**PSC_NO_05
     * The txt2pay button onclick
     */}
      <RACButton
        className={classes.ms2}
        style={{ height: '42.5px' }}
        color='primary'
        variant='contained'
        name='De_ActivateClubbtn'
        data-bs-toggle='modal'
        data-testid='Txt2Pay'
        disabled={enableTxt2Pay()}
        onClick={() => setTextToPayPopupFlag(true)}
      >
        Txt2Pay
      </RACButton>
      {/**PSC_NO_06
       * This modal card used to render the UI of Text2pay popup.
       */}
      <RACModalCard
        isOpen={textToPayPopupFlag}
        maxWidth='xs'
        borderRadius='4%'
        title='Send Text for Payment'
        closeIcon={true}
        TitleClassName={`${classes.text2payPopupTitle}`}
        onClose={() => { setTextToPayPopupFlag(false); handleReset() }}
      >
        {Txt2payPopup(featureFlags.Txt2PayCardOnFile == '1' ? true : false)}
      </RACModalCard>
    </>
  )
}