/*eslint-disable sonarjs/cognitive-complexity*/
import moment from 'moment';
import CONSTANTS from '../constants/constant';
import { dateFormat } from './scheduleAndDateFormat';
import {
  AdjustmentObject,
  AgreementDetails,
} from '../interface/paymentInfoInterface';
import {
  AgreementRateDetails,
  AgreementRates,
} from '../interface/adjustDueDateCalciInterface';

export async function adjustDueDateCalciPayloadFormation(
  selectedAgreements: AgreementDetails[]
) {
  const payload: AgreementRates[] = [];
  let agreementRateDetails: AgreementRateDetails = {} as AgreementRateDetails;

  selectedAgreements.forEach((eachItem: AgreementDetails) => {
    const coverageStatus =
      eachItem.isCoverageActionPerformed == CONSTANTS.STATUS_YES
        ? true
        : eachItem?.isCoverageActionPerformed == CONSTANTS.STATUS_NO
        ? false
        : eachItem.coverageStatus == CONSTANTS.STATUS_YES
        ? true
        : false;
    const coverageEndDate = coverageStatus
      ? CONSTANTS.COVERAGE_END_DATE
      : moment().format(CONSTANTS.MM_DD_YYYY);
    const policyStatus =
      coverageStatus && eachItem.isPolicy
        ? CONSTANTS.STATUS_ACTIVE
        : CONSTANTS.POLICY_INACTIVE;
    const CheckClub = eachItem.agreementType == CONSTANTS.CLUB ?? false;
    agreementRateDetails = {
      ...eachItem.agreementRateDetails,
      perDayRent:
        eachItem?.agreementRateDetails?.perDayRent ||
        eachItem.agreementRateDetails?.perDayClub ||
        CONSTANTS.ZERO_STRING,
      perDayRentTax:
        eachItem?.agreementRateDetails?.perDayRentTax ||
        eachItem.agreementRateDetails?.perDayClubTax ||
        CONSTANTS.ZERO_STRING,
      weeklyRent:
        eachItem?.agreementRateDetails?.weeklyRent ||
        eachItem.agreementRateDetails?.weeklyClub ||
        CONSTANTS.ZERO_STRING,
      weeklyRentTax:
        eachItem?.agreementRateDetails?.weeklyRentTax ||
        eachItem.agreementRateDetails?.weeklyClubTax ||
        CONSTANTS.ZERO_STRING,
      biWeeklyRent:
        eachItem?.agreementRateDetails?.biWeeklyRent ||
        eachItem.agreementRateDetails?.biWeeklyClub ||
        CONSTANTS.ZERO_STRING,
      biWeeklyRentTax:
        eachItem?.agreementRateDetails?.biWeeklyRentTax ||
        eachItem.agreementRateDetails?.biWeeklyClubTax ||
        CONSTANTS.ZERO_STRING,
      semiMonthlyRent:
        eachItem?.agreementRateDetails?.semiMonthlyRent ||
        eachItem.agreementRateDetails?.semiMonthlyClub ||
        CONSTANTS.ZERO_STRING,
      semiMonthlyRentTax:
        eachItem?.agreementRateDetails?.semiMonthlyRentTax ||
        eachItem.agreementRateDetails?.semiMonthlyClubTax ||
        CONSTANTS.ZERO_STRING,
      monthlyRent:
        eachItem?.agreementRateDetails?.monthlyRent ||
        eachItem.agreementRateDetails?.monthlyClub ||
        CONSTANTS.ZERO_STRING,
      monthlyRentTax:
        eachItem?.agreementRateDetails?.monthlyRentTax ||
        eachItem.agreementRateDetails?.monthlyClubTax ||
        CONSTANTS.ZERO_STRING,

      perDayLdw:
        eachItem?.agreementRateDetails?.perDayLdw?.toString() ||
        CONSTANTS.ZERO_STRING,
      perDayLdwTax:
        eachItem?.agreementRateDetails?.perDayLdwTax?.toString() ||
        CONSTANTS.ZERO_STRING,
      weeklyLdw:
        eachItem?.agreementRateDetails?.weeklyLdw?.toString() ||
        CONSTANTS.ZERO_STRING,
      weeklyLdwTax:
        eachItem?.agreementRateDetails?.weeklyLdwTax?.toString() ||
        CONSTANTS.ZERO_STRING,
      biWeeklyLdw:
        eachItem?.agreementRateDetails?.biWeeklyLdw?.toString() ||
        CONSTANTS.ZERO_STRING,
      biWeeklyLdwTax:
        eachItem?.agreementRateDetails?.biWeeklyLdwTax?.toString() ||
        CONSTANTS.ZERO_STRING,
      semiMonthlyLdw:
        eachItem?.agreementRateDetails?.semiMonthlyLdw?.toString() ||
        CONSTANTS.ZERO_STRING,
      semiMonthlyLdwTax:
        eachItem?.agreementRateDetails?.semiMonthlyLdwTax?.toString() ||
        CONSTANTS.ZERO_STRING,
      monthlyLdw:
        eachItem?.agreementRateDetails?.monthlyLdw?.toString() ||
        CONSTANTS.ZERO_STRING,
      monthlyLdwTax:
        eachItem.agreementRateDetails?.monthlyLdwTax?.toString() ||
        CONSTANTS.ZERO_STRING,
      semiMonthRange:
        eachItem?.agreementRateDetails?.semiMonthRange || CONSTANTS.ZERO_STRING,

      carriedLate: eachItem.carriedLateAmount,
      carriedLateTax: eachItem.carriedLateTax,
      carryRent: eachItem.carryRentAmount,
      carryRentTax: eachItem.carryRentTax,
      daysLate: eachItem.daysLate,
      isNoteAgreement: eachItem?.isNoteAgreement ?? 0,
      scheduleRefCode: eachItem?.agreementSchedule,
      numberPaymentMade: eachItem.numberOfPaymentsMade,
      rentPaid: eachItem?.rentPaid,
      cashPrice: eachItem?.cashPrice,
      totalCost: eachItem?.totalCost,
      perDayPolicy:
        eachItem?.agreementRateDetails?.perDayPolicy ??
        CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      perDayPolicyTax:
        eachItem?.agreementRateDetails?.perDayPolicyTax ??
        CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      weeklyPolicy:
        eachItem?.agreementRateDetails?.weeklyPolicy ??
        CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      weeklyPolicyTax:
        eachItem?.agreementRateDetails?.weeklyPolicyTax ??
        CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      biWeeklyPolicy:
        eachItem?.agreementRateDetails?.biWeeklyPolicy ??
        CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      biWeeklyPolicyTax:
        eachItem?.agreementRateDetails?.biWeeklyPolicyTax ??
        CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      semiMonthlyPolicy:
        eachItem?.agreementRateDetails?.semiMonthlyPolicy ??
        CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      semiMonthlyPolicyTax:
        eachItem?.agreementRateDetails?.semiMonthlyPolicyTax ??
        CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      monthlyPolicy:
        eachItem?.agreementRateDetails?.monthlyPolicy ??
        CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      monthlyPolicyTax:
        eachItem?.agreementRateDetails?.monthlyPolicyTax ??
        CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      noteAmountIncTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING, //Need to check the key
      remainingNoteCost: CONSTANTS.ZREO_TWO_DECIMAL_STRING, //Need to check the key
      coverageEndDate: CheckClub
        ? eachItem.agreementRateDetails?.coverageEndDate || coverageEndDate
        : coverageEndDate,
      remainingAgreementCost:
        eachItem?.agreementRateDetails?.remainingAgreementCost ??
        CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    };
    payload.push({
      ...eachItem,
      agreementId:
        eachItem.agreementType === CONSTANTS.CLUB
          ? String(eachItem.agreementNumber)
          : String(eachItem.agreementId),
      agreementRateDetails: agreementRateDetails ?? CONSTANTS.EMPTY_STRING,
      currentDueDate: String(
        dateFormat(eachItem.currentDueDate, CONSTANTS.MM_DD_YYYY)
      ),
      dueDate: String(dateFormat(eachItem.nextDueDate, CONSTANTS.MM_DD_YYYY)),
      coverageEndDate: coverageEndDate,
      taxRate: eachItem.taxRate ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      carriedLate:
        eachItem.carriedLateAmount ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      carriedLateTax:
        eachItem.carriedLateTax ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      lateFee:
        Number(eachItem.lateFee).toFixed(2) ??
        CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      lateFeeTax:
        Number(eachItem.lateFeetax).toFixed(2) ??
        CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      policyStatus: String(policyStatus),
      epoAmount: String(eachItem.epoAmount),
      epoTax: eachItem.epoTax ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      sacDate: String(dateFormat(eachItem.sacDate, CONSTANTS.MM_DD_YYYY)),
      isLdwAllowed: eachItem.isLDW
        ? CONSTANTS.ONE_STRING
        : CONSTANTS.ZERO_STRING,
      agreementNumber: String(eachItem.agreementNumber),
      carryRent: eachItem.carryRentAmount ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      carryRentTax: eachItem.carryRentTax ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      scheduleRefCode: eachItem.agreementSchedule,
      ldwPercentage: eachItem.ldwPercentage,
      Agreementtype: eachItem.agreementType,
      agreementStatus: eachItem.agreementStatus,
      agreementPromotionInfo: eachItem.promoInfo,
    });
  });
  return payload;
}

export async function commonModulePayloadFormation(
  selectedAgreements: AgreementDetails[],
  adjustmentData: AdjustmentObject,
  adjustmentType: string[]
) {
  const agreementArr: AgreementDetails[] = [];
  const daysDiff = moment(adjustmentData?.nextDueDate).diff(
    moment(selectedAgreements[0].currentDueDate),
    'days'
  );
  selectedAgreements.forEach((eachAgreement: AgreementDetails) => {
    agreementArr.push({
      ...eachAgreement,
      editSchedule: adjustmentType?.includes(CONSTANTS.ES)
        ? CONSTANTS.EMPTY_STRING
        : eachAgreement.agreementRateDetails?.scheduleRefCode,
      isEditScheduleValue: adjustmentType?.includes(CONSTANTS.ES)
        ? adjustmentData.schedule
        : undefined,
      dueDateModified: adjustmentType.includes(CONSTANTS.DDA) ?? false, // need to change it as true when due date edited
      isAdjustDueDateValue: adjustmentType.includes(CONSTANTS.DDA)
        ? adjustmentData.nextDueDate
        : undefined, // if duedate edited
      acceptEpoFlag: adjustmentType.includes(CONSTANTS.AEF)
        ? CONSTANTS.ONE_NUMBER
        : eachAgreement?.acceptEpoFlag,
      isAcceptEPODiscounts:
        adjustmentType.includes(CONSTANTS.AEF) &&
        adjustmentData.isAcceptEPODiscounts
          ? adjustmentData.isAcceptEPODiscounts
          : eachAgreement?.isAcceptEPODiscounts,
      freeTime: null,
      isFreeTimeValue: adjustmentType.includes(CONSTANTS.FTT)
        ? {
            freeTimeType: adjustmentData.type || CONSTANTS.EMPTY_STRING,
            freeTimeDays: String(daysDiff),
            isOptServiceWaived:
              adjustmentData.waiveLDW || adjustmentData.waivePolicy
                ? true
                : false,
          }
        : undefined,
      carriedLateFee: adjustmentType?.includes(CONSTANTS.LFC)
        ? adjustmentData.carriedLateFee
        : eachAgreement.carriedLateFee,
      iscarriedLateFeeValue: eachAgreement.carriedLateFee,
      waivedLateFee: adjustmentType?.includes(CONSTANTS.LFW)
        ? adjustmentData.waivedLateFee
        : eachAgreement.waivedLateFee,
      waivedLateFeeValue: eachAgreement.waivedLateFee,
      toggleOptionalService: adjustmentType.includes(CONSTANTS.TOS)
        ? CONSTANTS.STATUS_YES
        : eachAgreement?.isPolicyActionPerformed
        ? eachAgreement?.isPolicyActionPerformed
        : eachAgreement?.isLDWActionPerformed
        ? eachAgreement?.isLDWActionPerformed
        : CONSTANTS.EMPTY_STRING,
      Agreementtype: eachAgreement.agreementType,
      otherIncomeType: eachAgreement.otherIncomeType,
      type: CONSTANTS.BACK_RENT_NSF_CHK_RESTITUTION_ARRAY.includes(
        eachAgreement.agreementType
      )
        ? eachAgreement.agreementType
        : undefined,
      amount: CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(
        eachAgreement.nextDueDate
      )
        ? eachAgreement.epoAmount
        : CONSTANTS.EMPTY_STRING,
      receiptTransactionId:
        eachAgreement.agreementType == CONSTANTS.NSF_CHECK ||
        eachAgreement.agreementType == CONSTANTS.CREDIT_CARD_CHARGE_BACK
          ? eachAgreement.agreementId
          : CONSTANTS.EMPTY_STRING,
      customerClubId:
        eachAgreement.agreementType == CONSTANTS.CLUB
          ? eachAgreement.agreementId
          : CONSTANTS.EMPTY_STRING,
    });
  });

  return agreementArr;
}
