/* eslint-disable react-hooks/exhaustive-deps */
/**
 * PS_MOP_1
 */
import React, { useEffect, useState, useContext } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import {
  RACTextbox,
  RACButton,
  RACModalCard,
  Grid,
  Typography,
} from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import CurrencyInput from 'react-currency-input-field';
import {
  MoneyOrderDataInterface,
  MoneyOrderErrorInterface,
  PropInterFace,
  moneyOrderRegexInterface,
} from '../interface/addMoneyOrderInterface';
import CONSTANTS from '../constants/constant';
import { tenderDetails } from '../utils/amountCalculations';

/**
 * PS_MOP_2
 * @param props
 * @returns
 */
export const AddMoneyOrder = (props: PropInterFace) => {
  /**
   * PS_MOP_3
   */
  const classes = takePaymentStyles();
  const moneyOrderDetail: MoneyOrderDataInterface = {
    moneyOrderNumber: CONSTANTS.EMPTY_STRING,
    issuer: CONSTANTS.EMPTY_STRING,
    purchaser: CONSTANTS.EMPTY_STRING,
    amount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    orderId: 0,
  };

  const moneyOrderError: MoneyOrderErrorInterface = {
    moneyOrderNumber: CONSTANTS.EMPTY_STRING,
    issuer: CONSTANTS.EMPTY_STRING,
    purchaser: CONSTANTS.EMPTY_STRING,
    amount: CONSTANTS.EMPTY_STRING,
  };

  const moneyOrderRegex: moneyOrderRegexInterface = {
    moneyOrderNumber: CONSTANTS.ONLY_NUMERIC_ALLOWED,
    issuer: CONSTANTS.ONLY_CHARS_ALLOWED,
    purchaser: CONSTANTS.ONLY_CHARS_ALLOWED,
  };

  const [moneyOrderData, setMoneyOrderData] =
    useState<MoneyOrderDataInterface>(moneyOrderDetail);
  const [moError, setMoError] =
    useState<MoneyOrderErrorInterface>(moneyOrderError);
  const [moneyOrderOverPayError, setMoneyOrderOverPayError] =
    useState<boolean>(false);
  const agreementInfo = useContext(agreementContext);

  /**
   * PS_MOP_4
   */
  useEffect(() => {
    if (
      props.openMoneyOrderForm &&
      props.editMode &&
      props.id != CONSTANTS.ONE_NEGATIVE_NUMBER
    ) {
      setMoneyOrderData(
        agreementInfo.customerInfo.amountUsed.tenderDetails.moneyOrderDetails[
          props.id
        ]
      );
    }
    setMoError(moneyOrderError);
  }, [props.editMode, props.id]);

  /**
   * PS_MOP_5
   * @param name
   * @param value
   */
  const onChange = (name: string, value: string | undefined) => {
    if (value === undefined) return;
    if (name == CONSTANTS.AMOUNT) {
      (parseFloat(value) < 100000.0 || value === undefined) &&
        setMoneyOrderData((prevState) => ({ ...prevState, [name]: value }));
    } else {
      setMoneyOrderData((prevState) => ({
        ...prevState,
        [name]: value.replace(moneyOrderRegex[name], CONSTANTS.EMPTY_STRING),
      }));
    }
  };

  /**
   * PS_MOP_11
   */
  const amountOnBlur = () => {
    moneyOrderData.amount
      ? setMoneyOrderData((prevState) => ({
          ...prevState,
          amount: Number(prevState.amount).toFixed(2),
        }))
      : setMoneyOrderData((prevState) => ({
          ...prevState,
          amount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        }));
  };

  /**
   * PS_MOP_6, PS_MOP_7
   * @returns
   */
  const validate = (): boolean => {
    let isError = false;

    if (moneyOrderData.moneyOrderNumber === CONSTANTS.EMPTY_STRING) {
      setMoError((prevState) => ({
        ...prevState,
        moneyOrderNumber: CONSTANTS.PLEASE_ENTER_MONEY_ORDER_NUMBER,
      }));
      isError = true;
    } else {
      setMoError((prevState) => ({
        ...prevState,
        moneyOrderNumber: CONSTANTS.EMPTY_STRING,
      }));
    }

    if (moneyOrderData.issuer === CONSTANTS.EMPTY_STRING) {
      setMoError((prevState) => ({
        ...prevState,
        issuer: CONSTANTS.PLEASE_ENTER_MONEY_ORDER_ISSUER,
      }));
      isError = true;
    } else {
      setMoError((prevState) => ({
        ...prevState,
        issuer: CONSTANTS.EMPTY_STRING,
      }));
    }
    if (moneyOrderData.purchaser === CONSTANTS.EMPTY_STRING) {
      setMoError((prevState) => ({
        ...prevState,
        purchaser: CONSTANTS.PLEASAE_ENTER_MONEY_ORDER_PURCHASER,
      }));
      isError = true;
    } else {
      setMoError((prevState) => ({
        ...prevState,
        purchaser: CONSTANTS.EMPTY_STRING,
      }));
    }

    if (
      moneyOrderData.amount == undefined ||
      Number(moneyOrderData.amount) <= 0
    ) {
      setMoError((prevState) => ({
        ...prevState,
        amount: CONSTANTS.AMOUNT_MUST_BE_GREATER_THAN_ZERO,
      }));
      isError = true;
    } else {
      setMoError((prevState) => ({
        ...prevState,
        amount: CONSTANTS.EMPTY_STRING,
      }));
    }
    const checkMoDetails = tenderDetails(agreementInfo, CONSTANTS.CHECK);
    const totAmountforTender = checkMoDetails.filter((check) => {
      return check.orderId === moneyOrderData.orderId;
    });

    let amountEnteredBefore: number;

    if (
      totAmountforTender.length == CONSTANTS.ZERO_NUMBER ||
      Number(totAmountforTender[0].amount) <= 0
    ) {
      amountEnteredBefore = CONSTANTS.ZERO_NUMBER;
    } else {
      amountEnteredBefore = Number(totAmountforTender[0].amount);
    }

    if (
      !isError &&
      Number(moneyOrderData.amount) - amountEnteredBefore >
        Number(agreementInfo.customerInfo.amountUsed.remainingAmountDue)
    ) {
      setMoneyOrderOverPayError(true);
      isError = true;
    }

    return !isError;
  };

  /**
   * PS_MOP_7
   * @returns
   */
  const Moneyorderalertpopup = () => {
    return (
      <Grid item data-testid="travellercheck">
        <Grid
          item
          id="transaction"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="transaction"
          aria-hidden="true"
        >
          <Typography className={`${classes.textcenter} ${classes.mt3}`}>
            Money Order is not applicable for over payment
          </Typography>
          <Grid
            item
            md={12}
            className={`${classes.textcenter} ${classes.mt3} ${classes.modalfooter}`}
          >
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="contained"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-testid="okbuttontravel"
              onClick={() => {
                setMoneyOrderOverPayError(false);
              }}
              data-bs-target="#delivery-receipt"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  /**
   * PS_MOP_9
   */
  const close = () => {
    props.closeAddMoneyOrderPopup();
    setMoneyOrderData(moneyOrderDetail);
    setMoError(moneyOrderError);
  };

  /**
   * PS_MOP_9
   */
  const save = () => {
    if (validate()) {
      if (props.editMode) {
        const currentMoData = JSON.parse(
          JSON.stringify(agreementInfo.customerInfo)
        );
        currentMoData.amountUsed.tenderDetails.moneyOrderDetails[props.id] =
          moneyOrderData;

        agreementInfo.setCustomerInfo(currentMoData);
        props.render.setRerenderReaminingAmount(
          !props.render.rerenderReaminingAmount
        );
      } else {
        const currentMoData = JSON.parse(
          JSON.stringify(agreementInfo.customerInfo)
        );

        currentMoData.amountUsed.tenderDetails.orderId =
          currentMoData.amountUsed.tenderDetails.orderId + CONSTANTS.ONE_NUMBER;
        moneyOrderData.orderId = currentMoData.amountUsed.tenderDetails.orderId;
        currentMoData.amountUsed.tenderDetails.moneyOrderDetails.push(
          moneyOrderData
        );
        agreementInfo.setCustomerInfo(currentMoData);
        props.render.setRerenderReaminingAmount(
          !props.render.rerenderReaminingAmount
        );
      }

      close();
    }
  };

  /**
   * PS_MOP_10
   * @returns
   */
  const MoneyOrderPopup = () => {
    return (
      <Grid
        item
        id="MoneyOrderInformation"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="carrywaive"
        aria-hidden="true"
      >
        <Grid container spacing={2} className={classes.px3}>
          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              type="text"
              isCurrency={false}
              id="TotalFees"
              inputlabel="Money Order#"
              name="Money Order"
              required={true}
              maxlength={20}
              value={moneyOrderData?.moneyOrderNumber}
              OnChange={(e) =>
                onChange(CONSTANTS.MONEY_ORDER_NUMBER, e.target.value)
              }
              data-testid="addmoneyorderno"
            />

            {moError?.moneyOrderNumber ? (
              <label className={classes.mandatoryfield}>
                {moError?.moneyOrderNumber}
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              type="text"
              isCurrency={false}
              id="Amount-collect"
              inputlabel="Issuer"
              required={true}
              maxlength={30}
              value={moneyOrderData?.issuer}
              OnChange={(e) => onChange(CONSTANTS.ISSUER, e.target.value)}
              data-testid="issuer"
            />

            {moError.issuer ? (
              <label className={classes.mandatoryfield}>
                {moError?.issuer}
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              type="text"
              isCurrency={false}
              inputlabel="Purchaser"
              required={true}
              id="carrylate"
              value={moneyOrderData?.purchaser}
              maxlength={30}
              OnChange={(e) => onChange(CONSTANTS.PURCHASER, e.target.value)}
              data-testid="purchaser"
            />

            {moError.purchaser ? (
              <label className={classes.mandatoryfield}>
                {moError?.purchaser}
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <label className={classes.labeltxtstyle}>
              Amount<span className={classes.mandatoryfield}></span>
            </label>
            <div className={`${classes.inputgroup} ${classes.mb3}`}>
              <span className={classes.inputgrouptext}>$</span>

              <CurrencyInput
                type="text"
                name="moneyorderAmount"
                className={classes.formcontrol3}
                value={moneyOrderData?.amount}
                onValueChange={(e: string | undefined) =>
                  onChange(CONSTANTS.AMOUNT, e)
                }
                onBlur={() => {
                  amountOnBlur();
                }}
                decimalsLimit={2}
                data-testid="amount"
              />
            </div>
            {moError.amount ? (
              <label className={classes.mandatoryfield}>
                {moError?.amount}
              </label>
            ) : null}
          </Grid>
        </Grid>

        <Grid
          className={`${classes.textright} ${classes.modalfooter} ${classes.pb2}`}
        >
          <RACButton
            className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
            variant="outlined"
            color="primary"
            data-bs-dismiss="modal"
            onClick={() => close()}
            data-testid="moneyordcanceltest"
          >
            Cancel
          </RACButton>

          <RACButton
            className={`${classes.paymentSmallBtn1}`}
            color="primary"
            variant="contained"
            value="2"
            onClick={() => save()}
            data-testid="addmoneysave"
          >
            {props.editMode ? 'Update' : 'Save'}
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  /**
   * PS_MOP_10
   */
  return (
    <>
      <RACModalCard
        isOpen={props.openMoneyOrderForm}
        maxWidth="xs"
        closeIcon={true}
        title="Money Order Information"
        onClose={() => close()}
      >
        {MoneyOrderPopup()}
      </RACModalCard>

      <RACModalCard
        isOpen={moneyOrderOverPayError}
        maxWidth="xs"
        onClose={() => setMoneyOrderOverPayError(false)}
      >
        {Moneyorderalertpopup()}
      </RACModalCard>
    </>
  );
};
