/* eslint-disable  prettier/prettier */
/*eslint-disable sonarjs/cognitive-complexity */
import moment from "moment";
import React, { useContext} from "react";
import { CustomerdataContext } from "../../context/customer/customerdata";
import CONSTANTS from "../constants/constant";
import { takePaymentStyles } from "../../stylesJS/makeStyles";
import { CustomerAddressDetail, CustomerContactDetail } from "../interface/paymentInfoInterface";
import { PaymentHistoryInterface, PaymentHistoryPrintInfoProps } from "../interface/paymentHistoryInterface";


export const PaymentHistoryPrintInfo = (props: PaymentHistoryPrintInfoProps) => {
  const classes = takePaymentStyles();
  const { customerdata } = useContext(CustomerdataContext);
  let result: any = undefined;
  let customerPrimaryAddress: string = CONSTANTS.EMPTY_STRING;
  let customerSecondaryAddress: string | null = CONSTANTS.EMPTY_STRING;
  let customerCity = CONSTANTS.EMPTY_STRING;
  let customerPhoneNumber = CONSTANTS.EMPTY_STRING;
  let customerPlusFour = CONSTANTS.EMPTY_STRING;
  let customerZip = CONSTANTS.EMPTY_STRING;
  let customerState = CONSTANTS.EMPTY_STRING;
  let besttimetocall = CONSTANTS.EMPTY_STRING;
  let schedule = CONSTANTS.EMPTY_STRING;
  let datepaid = CONSTANTS.EMPTY_STRING;
  result = customerdata;

  if (result != undefined && result != CONSTANTS.EMPTY_STRING) {
    result = result.data;
    if (result?.GetCustomer?.value?.addresses != undefined) {
      let customerAdressFilterArray: CustomerAddressDetail[] =
        result?.GetCustomer?.value?.addresses?.filter(
          (x: CustomerAddressDetail) => x.addressTypeDesc == CONSTANTS.PRIMARY_ADDRESS
        );
      if (
        customerAdressFilterArray.length == 0 &&
        result?.GetCustomer?.value?.addresses.length > 0
      ) {
        customerAdressFilterArray = [result?.GetCustomer?.value?.addresses[0]];
      }
      customerPrimaryAddress =
        customerAdressFilterArray.length > 0
          ? customerAdressFilterArray[0].addressLine1
          : CONSTANTS.EMPTY_STRING;
      customerSecondaryAddress =
        customerAdressFilterArray.length > 0
          ? customerAdressFilterArray[0].addressLine2
          : CONSTANTS.EMPTY_STRING;
      customerPlusFour =
        customerAdressFilterArray.length > 0
          ? customerAdressFilterArray[0].postalCode != null
            ? customerAdressFilterArray[0].postalCode?.split(CONSTANTS.HYPHEN)[1]
            : CONSTANTS.EMPTY_STRING
          : CONSTANTS.EMPTY_STRING;
      customerZip =
        customerAdressFilterArray.length > 0
          ? customerAdressFilterArray[0].postalCode != null
            ? customerAdressFilterArray[0].postalCode?.split(CONSTANTS.HYPHEN)[0]
            : CONSTANTS.EMPTY_STRING
          : CONSTANTS.EMPTY_STRING;
      customerState =
        customerAdressFilterArray.length > 0
          ? customerAdressFilterArray[0].state
          : CONSTANTS.EMPTY_STRING;
      customerCity =
        customerAdressFilterArray.length > 0
          ? customerAdressFilterArray[0].city
          : CONSTANTS.EMPTY_STRING;
    }
    let customerPhoneDetails: CustomerContactDetail[] = result?.GetCustomer?.value?.phones?.filter(
      (x) => x.primary == CONSTANTS.STATUS_YES
    );
    if (
      customerPhoneDetails.length == 0 &&
      result?.GetCustomer?.value?.phones.length > 0
    ) {
      customerPhoneDetails = [result?.GetCustomer?.value?.phones[0]];
    }
    customerPhoneNumber =
      customerPhoneDetails.length > 0
        ? customerPhoneDetails[0]?.phoneNumber
        : CONSTANTS.EMPTY_STRING;

    const primaryphone = result?.GetCustomer?.value?.phones.filter(
      (x) => x.primary === CONSTANTS.STATUS_YES
    );

    besttimetocall = primaryphone[0]?.callTimeTypeDesc;

    schedule =
      customerdata == undefined
        ? undefined
        : customerdata.data?.GetCustomer?.value == undefined
        ? undefined
        : customerdata.data?.GetCustomer?.value?.employerReferences == undefined
        ? undefined
        : customerdata.data?.GetCustomer?.value?.employerReferences[0]
            .employerPayscheduleDesc;
    datepaid =
      customerdata == undefined
        ? undefined
        : customerdata.data?.GetCustomer?.value == undefined
        ? undefined
        : customerdata.data?.GetCustomer?.value?.employerReferences == undefined
        ? undefined
        : customerdata.data?.GetCustomer?.value?.employerReferences[0]
            .daysPaidDesc;
  }

  const BindGrid = () => {
    return props.paygridvalues.map((value: PaymentHistoryInterface, index: number) => {
      return (
        <tr key={index}>
          <td style={{ paddingTop: "15px", fontWeight: 600, fontSize: "13px" }}>
            {value.datePaid}
          </td>
          <td style={{ paddingTop: "15px", fontWeight: 600, fontSize: "13px" }}>
            {value.dayPaid}
          </td>
          <td style={{ paddingTop: "15px", fontWeight: 600, fontSize: "13px" }}>
            {value.timePaid}
          </td>
          <td style={{ paddingTop: "15px", fontWeight: 600, fontSize: "13px" }}>
            {value.receiptId}
          </td>
          <td
            style={{
              paddingTop: "15px",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "right",
              paddingRight: "20px",
            }}
          >
            {" "}
            {Number(value.isReversed) == 1 ? "(" : null}${value.totalPayment}
            {Number(value.isReversed) == 1 ? ")" : null}
          </td>
          <td style={{ paddingTop: "15px", fontWeight: 600, fontSize: "13px" }}>
            {" "}
            {value.nextDueDate}
          </td>
          <td
            style={{
              paddingTop: "15px",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "right",
              paddingRight: "20px",
            }}
          >
            {" "}
            {value.daysLate}
          </td>
          <td
            style={{
              paddingTop: "15px",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "right",
              paddingRight: "20px",
            }}
          >
            {value.daysExt}
          </td>
          <td style={{ paddingTop: "15px", fontWeight: 600, fontSize: "13px" }}>
            {" "}
            {value.store}
          </td>
          <td
            style={{
              paddingTop: "15px",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "left",
              paddingRight: "20px",
            }}
          >
            {" "}
            {value.paymentOrigin}
          </td>
          <td style={{ paddingTop: "15px", fontWeight: 600, fontSize: "13px" }}>
            {" "}
            {value.tenderType}
          </td>
          <td
            style={{
              paddingTop: "15px",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "right",
            }}
          >
            {" "}
            {Number(value.isReversed) == 1 ? "(" : null}${value.netRent}
            {Number(value.isReversed) == 1 ? ")" : null}
          </td>
          <td
            style={{
              paddingTop: "15px",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "right",
            }}
          >
            {" "}
            {Number(value.isReversed) == 1 ? "(" : null}${value.clubAmount}
            {Number(value.isReversed) == 1 ? ")" : null}
          </td>
        </tr>
      );
    });
  };

  const BindGridSummery = () => {
    return props.paygridvalues.map((value: PaymentHistoryInterface, index: number) => {
      return (
        <tr key={index}>
          <td style={{ paddingTop: "15px", fontWeight: 600, fontSize: "13px" }}>
            {value.datePaid}
          </td>
          <td style={{ paddingTop: "15px", fontWeight: 600, fontSize: "13px" }}>
            {value.dayPaid}
          </td>
          <td style={{ paddingTop: "15px", fontWeight: 600, fontSize: "13px" }}>
            {value.timePaid}
          </td>
          <td style={{ paddingTop: "15px", fontWeight: 600, fontSize: "13px" }}>
            {value.receiptId}
          </td>
          <td
            style={{
              paddingTop: "15px",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "right",
              paddingRight: "20px",
            }}
          >
            {" "}
            {Number(value.isReversed) == 1 ? "(" : null}${value.totalPayment}
            {Number(value.isReversed) == 1 ? ")" : null}
          </td>
          <td
            style={{
              paddingTop: "15px",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "right",
            }}
          >
            {" "}
            {Number(value.isReversed) == 1 ? "(" : null}${value.netRent}
            {Number(value.isReversed) == 1 ? ")" : null}
          </td>
          <td
            style={{
              paddingTop: "15px",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "right",
            }}
          >
            {" "}
            {Number(value.isReversed) == 1 ? "(" : null}${value.clubAmount}
            {Number(value.isReversed) == 1 ? ")" : null}
          </td>
          <td
            style={{
              paddingTop: "15px",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "right",
            }}
          >
            {" "}
            {Number(value.isReversed) == 1 ? "(" : null}${value.ldw}
            {Number(value.isReversed) == 1 ? ")" : null}
          </td>
          <td
            style={{
              paddingTop: "15px",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "right",
            }}
          >
            {" "}
            {Number(value.isReversed) == 1 ? "(" : null}${value.lateFee}
            {Number(value.isReversed) == 1 ? ")" : null}
          </td>
          <td
            style={{
              paddingTop: "15px",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "right",
            }}
          >
            {" "}
            {Number(value.isReversed) == 1 ? "(" : null}${value.discount}
            {Number(value.isReversed) == 1 ? ")" : null}
          </td>
          <td
            style={{
              paddingTop: "15px",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "right",
            }}
          >
            {" "}
            {Number(value.isReversed) == 1 ? "(" : null}${value.other}
            {Number(value.isReversed) == 1 ? ")" : null}
          </td>
          <td
            style={{
              paddingTop: "15px",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "right",
            }}
          >
            {" "}
            {Number(value.isReversed) == 1 ? "(" : null}${value.tax}
            {Number(value.isReversed) == 1 ? ")" : null}
          </td>
          <td
            style={{
              paddingTop: "15px",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "right",
            }}
          ></td>
        </tr>
      );
    });
  };

  return (
    <div style={{ display: "none" }}>
      <div
        style={{ width: "100%", float: "left" }}
        id={"PaymentAgreementPrintInfo"}
      >
        {/*top section starts here*/}
        <div style={{ width: "100%", float: "left" }}>
          <div style={{ width: "50%", float: "left" }}>
            <h4 style={{ fontWeight: "bold" }}>Payment History by Customer</h4>
          </div>
          <div style={{ width: "50%", float: "left", marginTop: "25px" }}>
            <span style={{ fontWeight: 600, fontSize: "13px" }}>Date :</span>
            <span style={{ fontWeight: 600, fontSize: "13px" }}>
              {moment().format("MM/DD/YYYY")}
            </span>
          </div>
        </div>
        <div style={{ width: "100%", float: "left", marginBottom: "20px" }}>
          <span style={{ fontWeight: 600, fontSize: "13px" }}>Store #:</span>
          <span style={{ fontWeight: 600, fontSize: "13px" }}>
            {String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER))}
          </span>
        </div>
        {/*top section ends here*/}
        {/*Customer information section starts here*/}
        <div style={{ width: "100%", float: "left", marginBottom: "20px" }}>
          <div style={{ width: "45%", float: "left" }}>
            <label className={ `${classes.BorderBottom1px} ${classes.Width85} ${classes.floatLeft}`}
              style={{
                paddingBottom: "8px",
                paddingRight: "20px",
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              Customer Information
            </label>
            <label
              style={{
                width: "100%",
                float: "left",
                marginTop: "7px",
                marginBottom: "10px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              {props.printInfo.commoninformation?.customerName}
            </label>
            <span
              style={{
                width: "100%",
                float: "left",
                marginBottom: "10px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              {customerPrimaryAddress == CONSTANTS.EMPTY_STRING
                ? customerSecondaryAddress
                : customerPrimaryAddress}
            </span>
            <span
              style={{
                width: "100%",
                float: "left",
                marginBottom: "10px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              {customerCity}, {customerState} {customerZip}-{customerPlusFour}
            </span>
            <div style={{ width: "50%", float: "left" }}>
              <span
                style={{
                  marginBottom: "10px",
                  fontWeight: 600,
                  fontSize: "13px",
                }}
              >
                Phone # :
              </span>
              <span
                style={{
                  marginBottom: "10px",
                  fontWeight: 600,
                  fontSize: "13px",
                }}
              >
                {customerPhoneNumber}
              </span>
            </div>
            <div style={{ width: "50%", float: "left", marginBottom: "10px" }}>
              <span style={{ fontWeight: 600, fontSize: "13px" }}>
                Best Time To Call :
              </span>
              <span style={{ fontWeight: 600, fontSize: "13px" }}>
                {besttimetocall}
              </span>
            </div>
            <span
              style={{
                width: "50%",
                float: "left",
                marginBottom: "10px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              Alt Phone # :
            </span>
            <span style={{ fontWeight: 600, fontSize: "13px" }}>{}</span>

            <span
              style={{
                width: "50%",
                float: "left",
                marginBottom: "10px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              Best Time To Call :
            </span>
            <span style={{ fontWeight: 600, fontSize: "13px" }}>{}</span>
          </div>
          <div style={{ width: "25%", float: "left" }}>
            <label className={ ` ${classes.BorderBottom1px}`}
              style={{
                width: "50%",
                float: "left",
                paddingBottom: "8px",
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              Payment Schedule
            </label>
            <label
              style={{
                width: "100%",
                float: "left",
                marginTop: "7px",
                marginBottom: "10px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              {schedule || CONSTANTS.HYPHEN}
            </label>
          </div>
          <div style={{ width: "30%", float: "left" }}>
            <label className={ ` ${classes.BorderBottom1px}`}
              style={{
                width: "70%",
                float: "left",
                paddingBottom: "8px",
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              Day Paid
            </label>
            <label
              style={{
                width: "100%",
                float: "left",
                marginTop: "7px",
                marginBottom: "10px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              {datepaid}
            </label>
          </div>
        </div>
        {/*Customer information section ends here*/}
        {/*Agreeements section starts here*/}
        <div style={{ width: "100%", float: "left", marginBottom: "20px" }}>
          <div style={{ width: "11%", float: "left", paddingRight: "10%" }}>
            <label className={ ` ${classes.BorderBottom1px}`}
              style={{
                width: "90%",
                float: "left",
                paddingBottom: "8px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              PIF Agreements
            </label>
            <label
              style={{
                width: "90%",
                float: "left",
                paddingTop: "15px",
                textAlign: "right",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              {props.printInfo.commoninformation?.pifAgreements}
            </label>
          </div>
          <div style={{ width: "11%", float: "left", paddingRight: "10%" }}>
            <label className={ ` ${classes.BorderBottom1px}`}
              style={{
                width: "95%",
                float: "left",
                paddingBottom: "8px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              EPO Agreements
            </label>
            <label
              style={{
                width: "95%",
                float: "left",
                paddingTop: "15px",
                textAlign: "right",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              {props.printInfo.commoninformation?.epoAgreements}
            </label>
          </div>
          <div style={{ width: "11%", float: "left", paddingRight: "10%" }}>
            <label className={ ` ${classes.BorderBottom1px}`}
              style={{
                width: "80%",
                float: "left",
                paddingBottom: "8px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              Total Revenue
            </label>
            <label
              style={{
                width: "80%",
                float: "left",
                paddingTop: "15px",
                textAlign: "right",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              {props.printInfo.commoninformation?.totalRentalRevenue}
            </label>
          </div>
          <div style={{ width: "11%", float: "left", paddingRight: "8%" }}>
            <label className={ ` ${classes.BorderBottom1px}`}
              style={{
                width: "72%",
                float: "left",
                paddingBottom: "8px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              NSF Checks
            </label>
            <label
              style={{
                width: "72%",
                float: "left",
                paddingTop: "15px",
                textAlign: "right",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              {props.printInfo.commoninformation?.nsfChecks}
            </label>
          </div>
          <div style={{ width: "11%", float: "left", paddingRight: "7%" }}>
            <label className={ ` ${classes.BorderBottom1px}`}
              style={{
                width: "93%",
                float: "left",
                paddingBottom: "8px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              Customer Since
            </label>
            <label
              style={{
                width: "93%",
                float: "left",
                paddingTop: "15px",
                textAlign: "right",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              {props.printInfo.commoninformation?.customerSince}
            </label>
          </div>
        </div>
        {/*Agreeements section ends here*/}
        {/*Payment History Summary section starts here*/}
        <div style={{ width: "100%", float: "left", marginBottom: "20px" }}>
          <h4 style={{ fontWeight: "bold", fontSize: "13px" }}>
            Payment History Summary
          </h4>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "left",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Date Paid
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "left",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Day
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "left",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Time
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "left",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Receipt #
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "right",
                    fontWeight: "bold",
                    fontSize: "13px",
                    paddingRight: "20px",
                  }}
                >
                  Total Payment
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "left",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Next Due Date
                </th>
                <th className={ `${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "right",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Free Days
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "right",
                    fontSize: "13px",
                    fontWeight: "bold",
                    paddingRight: "20px",
                  }}
                >
                  Days Late
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "left",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Store
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "left",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Payment Origin
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "left",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Tender Type
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "right",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Net Rent
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "right",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Club
                </th>
              </tr>
            </thead>
            <tbody>{BindGrid()}</tbody>
          </table>
        </div>
        {/*Payment History Summary section ends here*/}
        {/*Payment History Detail section starts here*/}
        <div style={{ width: "100%", float: "left", marginBottom: "20px" }}>
          <h4 style={{ fontWeight: "bold", fontSize: "13px" }}>
            Payment History Detail
          </h4>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "left",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Date Paid
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "left",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Day
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "left",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Time
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "left",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Receipt #
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "right",
                    fontWeight: "bold",
                    fontSize: "13px",
                    paddingRight: "20px",
                  }}
                >
                  Total Payment
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "right",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Net Rent
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "right",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Club
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "right",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  LDW
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "right",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Late Fee
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "right",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Discount
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "right",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Other
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "right",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Tax
                </th>
                <th className={ ` ${classes.BorderBottom1px}`}
                  style={{
                    color: "#000",
                    paddingBottom: "8px",
                    textAlign: "right",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Card Type
                </th>
              </tr>
            </thead>
            <tbody>{BindGridSummery()}</tbody>
          </table>
        </div>
        {/*Payment History Detail section ends here*/}
      </div>
    </div>
  );
};
export default PaymentHistoryPrintInfo;
