/*eslint-disable*/
/*eslint-disable react-hooks/rules-of-hooks*/
/*eslint-disable sonarjs/cognitive-complexity*/
/**
 * PS_AC_1
 * imports
 */
import { calculateTotalDueAmount } from './calculateTotalDueAmount';
import { AgreementDetails, Customer } from '../interface/paymentInfoInterface';
import {
  totalAmountInterface,
  tenderDetailsCalculation,
} from '../interface/utilsInterface/amountCaculationInterface';
import * as interfaceType from '../interface/contextInterface';
import CONSTANTS from '../constants/constant';

/**
 * PS_AC_3
 * This function handles the amount calculations
 * @param agreementInfo
 * @returns an object with coa, webLead, cacrry rent, ipsuspense, remaining amount and total amount.
 * PS_AC_43
 */
export const calculateRemainingAmountDue = (
  agreementInfo: interfaceType.AgreementContextValue
) => {
  const totalAmountOfAgreements: string[] = [];
  const onlySelectedAgreements = agreementInfo.agreementDetails.filter(
    (agreement: AgreementDetails) => agreement.selected
  );

  for (let i = 0; i <= onlySelectedAgreements.length - 1; i++) {
    const amount: string | number = calculateTotalDueAmount(
      onlySelectedAgreements[i]
    );

    totalAmountOfAgreements.push(amount.toString());
  }

  const filteredArray = totalAmountOfAgreements.filter(
    (item: string) =>
      !item.includes(CONSTANTS.OPEN_BRACKET_STRING) &&
      !item.includes(CONSTANTS.CLOSE_BRACKET_STRING)
  );
  const amountNeedtoChange = totalAmountOfAgreements.filter(
    (item: string) =>
      item.includes(CONSTANTS.OPEN_BRACKET_STRING) &&
      item.includes(CONSTANTS.CLOSE_BRACKET_STRING)
  );
  const extractedNumbers = amountNeedtoChange.map((str: string) =>
    parseFloat(str.replace(CONSTANTS.NON_NUMERIC_CHAR, CONSTANTS.EMPTY_STRING))
  );
  const totAmountForChange = extractedNumbers.reduce(
    (acc: number, amount) => acc + Number(amount),
    CONSTANTS.ZERO_NUMBER
  );
  const totAmount = filteredArray.reduce(
    (acc: number, amount: string) => acc + Number(amount),
    CONSTANTS.ZERO_NUMBER
  );

  const customerReturnAmount = totAmount - totAmountForChange;

  const totalTenderAmount = tenderDetails(agreementInfo);

  const totAmountforTender = totalTenderAmount.reduce(
    (acc: number, tenderAmount) => acc + Number(tenderAmount.amount),
    CONSTANTS.ZERO_NUMBER
  );

  const totalAmtWithTender = customerReturnAmount - totAmountforTender;

  const totalAmount = totalAmtWithTender;

  const { surrenderAmount, depositAmount } = calculateRefundSecurityDeposit(agreementInfo.customerInfo);

  const totalRsdRefundAmount = totalAmount - surrenderAmount + depositAmount;

  const amountWithDonation =
    totalRsdRefundAmount + Number(agreementInfo.customerInfo.donation);
  const usedCredits = useAvailableCredits(agreementInfo, amountWithDonation);

  const amountWithoutCoa =
    usedCredits.coa > CONSTANTS.ZERO_NUMBER
      ? totalRsdRefundAmount - usedCredits.coa
      : totalRsdRefundAmount;
  const amountWithoutDonation =
    amountWithoutCoa - Number(agreementInfo.carryRent);
  const finalTotalAmount =
    amountWithoutDonation + Number(agreementInfo.customerInfo.donation);
  usedCredits.totalAmount = Number(finalTotalAmount.toFixed(2));
  console.log('Remaining Amount calculations', usedCredits);
  return usedCredits;
};

/**
 * PS_AC_6
 * This function handles the amount calculation based on available credits
 * @param agreementInfo
 * @param totalDueAmount
 * @returns
 * PS_AC_36
 */
export const useAvailableCredits = (
  agreementInfo: interfaceType.AgreementContextValue,
  totalDueAmount: number
) => {
  let remainingAmount: number = totalDueAmount;
  let webLead: number = CONSTANTS.ZERO_NUMBER;
  let coa: number = CONSTANTS.ZERO_NUMBER;
  let ipSuspense: number = CONSTANTS.ZERO_NUMBER;
  let webLeadUsed: number = CONSTANTS.ZERO_NUMBER;
  let coaUsed: number = CONSTANTS.ZERO_NUMBER;
  const carryRent: number = CONSTANTS.ZERO_NUMBER;
  let ipSuspenseUsed: number = CONSTANTS.ZERO_NUMBER;
  const totalAmount: number = CONSTANTS.ZERO_NUMBER;

  agreementInfo?.customerInfo?.remainingCredits?.map((values) => {
    if (values.storeNumber == sessionStorage.getItem(CONSTANTS.STORE_NUMBER)) {
      if (values.bucket == CONSTANTS.WEB_LEAD_DEPOSIT) {
        webLead += values.accountBalance;
      } else if (values.bucket == CONSTANTS.CAPITAL_COA) {
        coa += values.accountBalance;
      } else if (values.bucket == CONSTANTS.IP_SUSPENSE) {
        ipSuspense += values.accountBalance;
      }
    }
  });

  if (
    remainingAmount > CONSTANTS.ZERO_NUMBER &&
    webLead > CONSTANTS.ZERO_NUMBER
  ) {
    remainingAmount = remainingAmount - webLead;
    webLeadUsed +=
      remainingAmount >= CONSTANTS.ZERO_NUMBER
        ? webLead
        : webLead + remainingAmount;
  }

  if (
    remainingAmount > CONSTANTS.ZERO_NUMBER &&
    ipSuspense > CONSTANTS.ZERO_NUMBER
  ) {
    remainingAmount = remainingAmount - ipSuspense;
    ipSuspenseUsed +=
      remainingAmount >= CONSTANTS.ZERO_NUMBER
        ? ipSuspense
        : ipSuspense + remainingAmount;
  }

  remainingAmount = remainingAmount - coa;
  if (Number(remainingAmount) >= Number(CONSTANTS.ZERO_NUMBER)) {
    coaUsed += Number(coa);
  } else {
    coaUsed += Number(coa) + Number(remainingAmount);
  }

  if (
    remainingAmount > CONSTANTS.ZERO_NUMBER &&
    parseFloat(agreementInfo.carryRent) > CONSTANTS.ZERO_NUMBER
  ) {
    remainingAmount = remainingAmount - parseFloat(agreementInfo.carryRent);
  }

  const { surrenderAmount, depositAmount } = calculateRefundSecurityDeposit(agreementInfo.customerInfo);

  const overallTotalAmount = remainingAmount - surrenderAmount + depositAmount;
  const finalRemainingAmount =
    Number(remainingAmount) +
    Number(agreementInfo.customerInfo.amountUsed.convenienceFee);

  return {
    remainingAmount:
      finalRemainingAmount > CONSTANTS.ZERO_NUMBER
        ? finalRemainingAmount
        : CONSTANTS.ZERO_NUMBER,
    webLeadUsed: webLeadUsed,
    coaUsed: Number(
      (coaUsed <= CONSTANTS.ZERO_NUMBER
        ? CONSTANTS.ZERO_TWO_DECIMAL_NUMBER
        : coaUsed
      ).toFixed(2)
    ),
    coa: coa,
    carryRent: carryRent,
    ipSuspense: ipSuspenseUsed,
    totalAmount: totalAmount,
  };
};

/**
 * PS_AC_45
 * @params agreementInfo
 * @returns an object containing change and remainingAmount.
 * PS_AC_52
 */
export const calculateChangeAmount = (
  agreementInfo: interfaceType.AgreementContextValue
) => {
  const totalAmount: totalAmountInterface =
    calculateRemainingAmountDue(agreementInfo);
  let change: number;
  if (Number(totalAmount.totalAmount) < CONSTANTS.ZERO_NUMBER) {
    change = Math.abs(totalAmount.totalAmount);
  } else {
    change = CONSTANTS.ZERO_NUMBER;
  }

  return {
    remainingAmountDue: totalAmount.remainingAmount.toFixed(2),
    change: change.toFixed(2),
  };
};

/**
 * PS_AC_53
 * This function modify the tender details array's into single array of objects
 * @param agreementInfo
 * @returns the object of all tender detials
 * PS_AC_60
 */
export const tenderDetails = (
  agreementInfo: interfaceType.AgreementContextValue,
  onlyCheckMo?: string
) => {
  const TenderDetails: tenderDetailsCalculation[] = [];
  const TenderDetailsForCheck: tenderDetailsCalculation[] = [];

  if (
    parseFloat(
      agreementInfo?.customerInfo?.amountUsed?.tenderDetails.cash.amount
    ) > CONSTANTS.ZERO_NUMBER
  ) {
    TenderDetails.push({
      tenderType: CONSTANTS.CAPITAL_CASH,
      amount:
        agreementInfo?.customerInfo?.amountUsed?.tenderDetails.cash.amount,
      orderId:
        agreementInfo?.customerInfo?.amountUsed?.tenderDetails.cash.orderId,
    });
  }

  agreementInfo?.customerInfo?.amountUsed?.tenderDetails?.card.map((val) => {
    if (
      val.type == CONSTANTS.SWIPE &&
      Number(val.amount) > CONSTANTS.ZERO_NUMBER
    ) {
      TenderDetails.push({
        tenderType: `${CONSTANTS.CARD_SWIPE_ENDING} - ${val.cardLastFour}`,
        amount: val.amount,
        orderId: val.orderId,
      });
    } else if (Number(val.amount) > CONSTANTS.ZERO_NUMBER) {
      TenderDetails.push({
        tenderType: `${CONSTANTS.CARD_ENDING} - ${val.cardLastFour}`,
        amount: val.amount,
        orderId: val.orderId,
      });
    }
  });

  agreementInfo?.customerInfo?.amountUsed?.tenderDetails?.checkDetails.map(
    (val) => {
      TenderDetails.push({
        tenderType: CONSTANTS.CHECK,
        amount: val.amount,
        orderId: val.orderId,
      });
      TenderDetailsForCheck.push({
        tenderType: CONSTANTS.CHECK,
        amount: val.amount,
        orderId: val.orderId,
      });
    }
  );

  agreementInfo?.customerInfo?.amountUsed?.tenderDetails?.moneyOrderDetails.map(
    (val) => {
      TenderDetails.push({
        tenderType: CONSTANTS.MONEY_ORDER,
        amount: val.amount,
        orderId: val.orderId,
      });
      TenderDetailsForCheck.push({
        tenderType: CONSTANTS.CHECK,
        amount: val.amount,
        orderId: val.orderId,
      });
    }
  );

  TenderDetails.sort((a, b) => a.orderId - b.orderId);
  return onlyCheckMo == CONSTANTS.CHECK ? TenderDetailsForCheck : TenderDetails;
};

export function resetTenderTypes(
  agreementInfo: interfaceType.AgreementContextValue
) {
  const selectedAgreements = agreementInfo.agreementDetails.filter(
    (agreement) => agreement.selected
  );

  const totalTenderDetails = tenderDetails(agreementInfo);

  const totalAmountOnTender = totalTenderDetails.reduce(
    (acc: number, tenderAmount) => acc + Number(tenderAmount.amount),
    CONSTANTS.ZERO_NUMBER
  );

  const remainingAmountDue =
    agreementInfo.customerInfo.amountUsed.remainingAmountDue;

  let reRender = false;

  if (
    parseFloat(remainingAmountDue) < totalAmountOnTender ||
    (selectedAgreements.length == 0 &&
      agreementInfo.carryRent !== CONSTANTS.ZREO_TWO_DECIMAL_STRING)
  ) {
    reRender = true;
  }

  return reRender;
}

export function calculateRefundSecurityDeposit(customerInfo: Customer) {
  const amountToRefundArray = customerInfo?.rsdAgreementInfo?.map(
    (item) => parseFloat(item.depositAmount).toFixed(2)
  );
  const amountToCollectArray = customerInfo.rsdAgreementInfo?.map((item) => parseFloat(item.amountToCollect).toFixed(2));

  const totalRsdAmount = amountToRefundArray.reduce(
    (acc: number, curr: string) => acc + parseFloat(curr),
    CONSTANTS.ZERO_NUMBER
  );

  const totalAmountToCollect = amountToCollectArray.reduce(
    (acc: number, curr: string) => acc + parseFloat(curr),
    CONSTANTS.ZERO_NUMBER
  );

  let surrenderAmount = CONSTANTS.ZERO_NUMBER;
  let depositAmount = CONSTANTS.ZERO_NUMBER;
  if (totalAmountToCollect <= totalRsdAmount) {
    surrenderAmount = totalRsdAmount - totalAmountToCollect;

  }
  else if (totalAmountToCollect > totalRsdAmount) {
    depositAmount = totalAmountToCollect - totalRsdAmount;
  }

  return { surrenderAmount, depositAmount }

}
