/*eslint-disable react-hooks/exhaustive-deps*/
/**
 * PS_RACEXchange_1.0 - PS_RACEXchange_1.2
 * Import required packages, interfaces and context
 */
import React, { useContext, useEffect, useState } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import {
  RACButton,
  Grid,
  Typography,
  RACModalCard,
} from '@rentacenter/racstrap';
import { ReactComponent as TickIcon } from './../../assets/images/TickIcon.svg';
import { customerStyles } from '../../stylesJS/customerStyles';
import { useHistory, useParams } from 'react-router-dom';
import { postAgreementActivity } from '../../api/user';
import { selectedRetentionOrExchangeId } from '../interface/racExchangePopupInterface';
import { ParamType } from '../interface/commonInterface';
import { AgreementDetails } from '../interface/paymentInfoInterface';
import CONSTANTS from '../constants/constant';

/**
 * PS_RACEXchange_1.3 - PS_RACEXchange_1.34
 * Declare RacExchangePopup() with props as agreementId and agreementNumber
 * @param props
 * @returns
 */
export function RacExchangePopup(props: selectedRetentionOrExchangeId) {
  /**
   * PS_RACEXchange_1.4 - PS_RACEXchange_1.9
   * Variable declaration and initialization
   * Destructing values from the context
   */
  const classes = customerStyles();
  const history = useHistory();
  const { customerId } = useParams<ParamType>();
  const agreementId = props.agreementDetails.agreementId;
  const {
    featureFlags,
    agreementDetails,
    modalCardEnable,
    setModalCardEnable,
  } = useContext(agreementContext);
  const racExchangeEnabled: string | undefined = featureFlags.RACExchange;
  const exchangeAgreement = agreementDetails.filter(
    (eachItem: AgreementDetails) => eachItem.agreementId == agreementId
  );
  const [suspPopupOpen, setSuspPopupOpen] = useState<boolean>(false);
  const [exchangePopupOpen, setExchangePopupOpen] = useState<boolean>(false);
  const [createButtonLoader, setcreateButtonLoader] = useState<boolean>(false);

  /**
   * PS_RACEXchange_1.10 - PS_RACEXchange_1.13
   * Within the useEffect, it checks if the selected agreement has suspense amount
   * and displays suspense popup else racexchange popup
   */
  useEffect(() => {
    if (Number(exchangeAgreement[0].suspenseAmount) > 0) setSuspPopupOpen(true);
    else {
      setSuspPopupOpen(false);
      setExchangePopupOpen(true);
    }
  }, []);
  /**
   * PS_RACEXchange_1.14 - PS_RACEXchange_1.16
   * Triggers when create agreement button is clicked.
   * Within the handleCreateAgreement function
   * it makes an appi call and navigates the user.
   */
  const handleCreateAgreement = () => {
    setcreateButtonLoader(true);
    postAgreementActivity({
      agreementActivities: [
        {
          agreementId: agreementId,
          activityDescription: CONSTANTS.AGREEMENT_EXCHANGE_SELECTED,
          agreementActivityType: CONSTANTS.AGMXNGS,
        },
      ],
    });
    history.push(
      `/agreement/rental/itemsearch/${customerId}?isExchange=1&agreementId=${agreementId}`
    );
    setcreateButtonLoader(false);
    setExchangePopupOpen(false);
    setModalCardEnable({ ...modalCardEnable, racExchangeComponent: false });
  };

  /**
   * PS_RACEXchange_1.17 - PS_RACEXchange_1.26
   * This function binds the HTML for exchange popup
   * @returns
   */
  const exchangePopup = () => {
    return (
      <Grid className={`${classes.textCenter}`}>
        {!racExchangeEnabled && <TickIcon />}
        {racExchangeEnabled ? (
          <>
            <Typography
              className={`${classes.spacerMT3} ${classes.customerRacpopup123}`}
            >
              Customer is eligible for $
              {exchangeAgreement[0]?.rentPaid
                ? parseFloat(exchangeAgreement[0]?.rentPaid)?.toFixed(2)
                : 0.0}{' '}
              for Exchange.
            </Typography>
            <Typography className={`${classes.customerRacpopup123}`}>
              Do you want to proceed with agreement creation?
            </Typography>
          </>
        ) : (
          <Typography
            className={`${classes.customerRacpopup123} ${classes.spacerMT3}`}
          >
            Please follow the new agreement creation workflow to complete the
            RAC Exchange Program.
          </Typography>
        )}
        <Grid className={`${classes.buttonsPadding} ${classes.wrapContentSA}`}>
          <RACButton
            data-testid="NoBtn"
            variant="outlined"
            color="primary"
            className={`${classes.customerFloatleft}`}
            onClick={() =>
              setModalCardEnable({
                ...modalCardEnable,
                racExchangeComponent: false,
              })
            }
          >
            {racExchangeEnabled ? 'No' : 'Cancel'}
          </RACButton>
          {racExchangeEnabled && (
            <RACButton
              data-testid="createAgreement"
              variant="contained"
              color="primary"
              className={`${classes.customerFloatright}`}
              onClick={() => handleCreateAgreement()}
              loading={createButtonLoader}
            >
              Create Agreement
            </RACButton>
          )}
        </Grid>
      </Grid>
    );
  };

  /**
   * PS_RACEXchange_1.27 - PS_RACEXchange_1.30
   * This function binds the HTML for suspense popup with OK button
   * @returns
   */
  const suspensePopup = () => {
    return (
      <Grid className={`${classes.textCenter}`}>
        {
          <>
            <Typography
              className={`${classes.spacerMT3} ${classes.customerRacpopup123}`}
            >
              The suspense amount of $
              {exchangeAgreement[0].suspenseAmount ?? 0.0} on Agreement #{' '}
              {exchangeAgreement[0].agreementNumber ??
                exchangeAgreement[0].agreementId}{' '}
              will be
            </Typography>
            <Typography className={`${classes.customerRacpopup123}`}>
              transferred to the Exchange agreement after making the initial
              payment.
            </Typography>
          </>
        }
        <Grid className={`${classes.buttonsPadding} ${classes.wrapContentSA}`}>
          <RACButton
            data-testid="OkBtn"
            variant="contained"
            color="primary"
            className={`${classes.customerFloatleft}`}
            onClick={() => {
              setSuspPopupOpen(false);
              setExchangePopupOpen(true);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  /**
   * PS_RACEXchange_1.31 - PS_RACEXchange_1.34
   * The retuen part hols modal card for exchange and suspense popup
   */
  return (
    <>
      <RACModalCard
        isOpen={exchangePopupOpen}
        maxWidth="sm"
        title="Customer Retention"
        borderRadius={'25px !important'}
        closeIcon={true}
        onClose={() => {
          setExchangePopupOpen(false);
          setModalCardEnable({
            ...modalCardEnable,
            racExchangeComponent: false,
          });
        }}
        className={`${classes.customModal3}`}
      >
        {exchangePopup()}
      </RACModalCard>

      <RACModalCard
        isOpen={suspPopupOpen}
        closeIcon={true}
        maxWidth={'sm'}
        borderRadius={'25px !important'}
        onClose={() => {
          setSuspPopupOpen(false);
          setModalCardEnable({
            ...modalCardEnable,
            racExchangeComponent: false,
          });
        }}
        className={`${classes.customModal5}`}
      >
        {suspensePopup()}
      </RACModalCard>
    </>
  );
}
