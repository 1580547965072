/*eslint-disable sonarjs/cognitive-complexity */
import CONSTANTS from '../../constants/constant';
import {
  AgreementPayloadFormation,
  ClubAdjustmentsPayload,
  CommonModuleProps,
  FreeTimeValue,
  OtherIncomePayment,
} from '../../interface/commonModulePayloadFormationInterface';
import { AcceptEPODiscounts } from '../../interface/paymentInfoInterface';
import { dateFormat } from '../../utils/scheduleAndDateFormat';

export default async function calculationModulePayloadFormation(
  props: CommonModuleProps
) {
  const agreementPayloadFormation: AgreementPayloadFormation[] = [];
  let clubAdjustmentsPayload: ClubAdjustmentsPayload[] = [];
  const otherIncomePayment: OtherIncomePayment[] = [];

  for (let i = CONSTANTS.ZERO_NUMBER; i < props.checkedAgr?.length; i++) {
    const paygridInfo = props.checkedAgr[i];
    let adjustedDueDate = false;
    let adjustedDueDateValue: string | undefined = undefined;
    let editSchedule = false;
    let editScheduleValue: string | undefined = undefined;
    let acceptEpo = false;
    let acceptEpoValue: string | undefined = undefined;
    let acceptEpoValueDiscount: AcceptEPODiscounts | undefined = undefined;
    let freeTime = false;
    let freeTimeValue: FreeTimeValue | undefined = undefined;
    let carryLatefee = false;
    let carryLatefeeValue: string | undefined = undefined;
    let waivelatefee = false;
    let waivelatefeeValue: string | undefined = undefined;

    //EditSchedule
    if (
      paygridInfo?.editSchedule != CONSTANTS.EMPTY_STRING ||
      paygridInfo.isEditScheduleValue
    ) {
      editSchedule = true;
      if (paygridInfo?.editSchedule != CONSTANTS.EMPTY_STRING) {
        editScheduleValue = paygridInfo?.editSchedule;
      } else if (paygridInfo.isEditScheduleValue) {
        editScheduleValue = paygridInfo.isEditScheduleValue;
      }
    }

    //AdjustDueDate
    if (paygridInfo?.dueDateModified || paygridInfo.isAdjustDueDateValue) {
      if (
        (paygridInfo?.nextDueDate == CONSTANTS.SAC ||
          paygridInfo?.nextDueDate == CONSTANTS.EPO) &&
        paygridInfo?.dueDateModified
      ) {
        acceptEpo = true;
        acceptEpoValue = CONSTANTS.STATUS_YES;
      } else {
        adjustedDueDate = true;
        if (
          paygridInfo?.isAdjustDueDateValue == CONSTANTS.SAC ||
          paygridInfo?.isAdjustDueDateValue == CONSTANTS.EPO
        ) {
          acceptEpo = true;
          adjustedDueDate = false;
          acceptEpoValue = CONSTANTS.STATUS_YES;
        } else if (paygridInfo?.dueDateModified) {
          adjustedDueDateValue = paygridInfo.nextDueDate;
        } else if (paygridInfo.isAdjustDueDateValue) {
          adjustedDueDateValue = paygridInfo.isAdjustDueDateValue;
        }
      }
    }

    //Free time
    if (paygridInfo?.freeTime || paygridInfo.isFreeTimeValue) {
      freeTime = true;
      if (paygridInfo?.freeTime != null) {
        freeTimeValue = {
          type: paygridInfo?.freeTime?.freeTimeType,
          freeDays: paygridInfo?.freeTime?.freeTimeDays,
          waiveOptionalService: paygridInfo?.freeTime?.isOptServiceWaived
            ? CONSTANTS.STATUS_YES
            : CONSTANTS.STATUS_NO,
        };
      } else if (paygridInfo.isFreeTimeValue) {
        freeTimeValue = {
          type: paygridInfo?.isFreeTimeValue.freeTimeType,
          freeDays: paygridInfo?.isFreeTimeValue.freeTimeDays,
          waiveOptionalService: paygridInfo?.isFreeTimeValue.isOptServiceWaived
            ? CONSTANTS.STATUS_YES
            : CONSTANTS.STATUS_NO,
        };
      }
    }

    //Accept EPO
    if (paygridInfo?.acceptEpoFlag == 1 || paygridInfo.isAcceptEPOValue) {
      acceptEpo = true;
      acceptEpoValue = CONSTANTS.STATUS_YES;
      if (paygridInfo.isAcceptEPOValue || paygridInfo.isAcceptEPODiscounts) {
        acceptEpoValueDiscount = paygridInfo.isAcceptEPODiscounts;
      }
    }
    //Carry Late Fee
    if (
      Number(paygridInfo?.carriedLateFee) > CONSTANTS.ZERO_NUMBER ||
      Number(paygridInfo.iscarriedLateFeeValue) > CONSTANTS.ZERO_NUMBER
    ) {
      carryLatefee = true;
      if (Number(paygridInfo?.carriedLateFee) > CONSTANTS.ZERO_NUMBER) {
        carryLatefeeValue =
          Number(paygridInfo.carriedLateFee) > CONSTANTS.ZERO_NUMBER
            ? Number(paygridInfo.carriedLateFee).toFixed(2)
            : undefined;
      } else if (
        Number(paygridInfo.iscarriedLateFeeValue) > CONSTANTS.ZERO_NUMBER
      ) {
        carryLatefeeValue =
          Number(paygridInfo.iscarriedLateFeeValue) > CONSTANTS.ZERO_NUMBER
            ? Number(paygridInfo.iscarriedLateFeeValue).toFixed(2)
            : undefined;
      }
    }
    //Waive Late Fee
    if (
      Number(paygridInfo?.waivedLateFee) > CONSTANTS.ZERO_NUMBER ||
      paygridInfo.waivedLateFeeValue
    ) {
      waivelatefee = true;
      if (Number(paygridInfo?.waivedLateFee) > CONSTANTS.ZERO_NUMBER) {
        waivelatefeeValue =
          Number(paygridInfo.waivedLateFee) > CONSTANTS.ZERO_NUMBER
            ? Number(paygridInfo.waivedLateFee).toFixed(2)
            : undefined;
      } else if (paygridInfo.waivedLateFeeValue) {
        waivelatefeeValue = paygridInfo.waivedLateFeeValue;
      }
    }
    //toggle LDW

    //toggle Policy

    if (
      adjustedDueDate ||
      editSchedule ||
      acceptEpo ||
      freeTime ||
      carryLatefee ||
      waivelatefee ||
      (paygridInfo?.toggleOptionalService != CONSTANTS.EMPTY_STRING &&
        paygridInfo?.toggleOptionalService != undefined) ||
      paygridInfo?.isCoverageActionPerformed != CONSTANTS.EMPTY_STRING
    ) {
      if (
        paygridInfo?.agreementType != CONSTANTS.CLUB &&
        paygridInfo?.otherIncomeType == CONSTANTS.EMPTY_STRING &&
        paygridInfo?.type != CONSTANTS.RESTITUTION &&
        paygridInfo?.type != CONSTANTS.BACK_RENT &&
        paygridInfo.type != CONSTANTS.NSF_CHECK &&
        paygridInfo?.type == undefined
      ) {
        agreementPayloadFormation.push({
          agreementId: paygridInfo?.agreementId,
          adjustments: {
            editedSchedule: editScheduleValue,
            adjustedDueDate: adjustedDueDate
              ? dateFormat(adjustedDueDateValue, CONSTANTS.YYYY_MM_DD)
              : undefined,
            freeTime: freeTimeValue,
            carryLateFeeAmount: carryLatefeeValue,
            waiveLateFeeAmount: waivelatefeeValue,
            epoPayment: acceptEpoValue,
            epoDiscount:
              acceptEpoValue == CONSTANTS.STATUS_YES
                ? acceptEpoValueDiscount
                : undefined,
            toggleOptionalService:
              paygridInfo?.toggleOptionalService != CONSTANTS.EMPTY_STRING &&
              paygridInfo?.toggleOptionalService != undefined
                ? paygridInfo?.toggleOptionalService
                : paygridInfo?.toggleOptionalServicePerformed !=
                  CONSTANTS.EMPTY_STRING
                ? paygridInfo?.toggleOptionalServicePerformed
                : undefined,
          },
        });
      } else if (
        paygridInfo?.type == CONSTANTS.RESTITUTION ||
        paygridInfo?.type == CONSTANTS.BACK_RENT ||
        paygridInfo.type == CONSTANTS.NSF_CHECK
      ) {
        otherIncomePayment.push({
          agreementId:
            paygridInfo?.agreementId == undefined
              ? undefined
              : String(paygridInfo?.agreementId),
          amount: paygridInfo?.amount,
          type:
            paygridInfo?.type == CONSTANTS.RESTITUTION
              ? CONSTANTS.CO
              : paygridInfo?.type == CONSTANTS.BACK_RENT
              ? CONSTANTS.BR
              : CONSTANTS.NSF,
          receiptTransactionId:
            paygridInfo?.receiptTransactionId == undefined
              ? undefined
              : paygridInfo?.receiptTransactionId,
          customerAccountId:
            paygridInfo?.customerAccountId == undefined
              ? undefined
              : paygridInfo?.customerAccountId,
        });
      } else if (paygridInfo?.Agreementtype == CONSTANTS.CLUB) {
        clubAdjustmentsPayload = [
          {
            clubAmountDue: [
              {
                customerClubId: String(paygridInfo?.agreementId),
                adjustments: {
                  editedSchedule: editScheduleValue,
                  adjustedDueDate: adjustedDueDate
                    ? dateFormat(adjustedDueDateValue, CONSTANTS.YYYY_MM_DD)
                    : undefined,
                },
              },
            ],
          },
        ];
      }
    }
  }

  function allPropertiesUndefined(obj) {
    return Object.values(obj).every((value) => value === undefined);
  }

  return {
    customerId: props.customerId,
    paymentOrigin: CONSTANTS.RETENTION_S,
    agreementAmountDue:
      agreementPayloadFormation?.length > CONSTANTS.ZERO_NUMBER &&
      !allPropertiesUndefined(agreementPayloadFormation[0].adjustments)
        ? agreementPayloadFormation
        : undefined,
    clubAmountDue:
      clubAdjustmentsPayload?.length > CONSTANTS.ZERO_NUMBER
        ? clubAdjustmentsPayload[0].clubAmountDue
        : undefined,
    otherIncomePayment:
      otherIncomePayment?.length > CONSTANTS.ZERO_NUMBER
        ? otherIncomePayment
        : undefined,
  };
}
