/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-prototype-builtins */
/**
 * PS_TCD_1
 * Import nessacary packages
 */
import React, { useEffect, useState, useContext, useRef } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import CurrencyInput from 'react-currency-input-field';
import { ReactComponent as DeleteIcon } from '../../assets/images/layer28.svg';
import { ReactComponent as Visacard } from '../../../src/assets/images/Visa-Card.svg';
import { ReactComponent as Mastercard } from '../../../src/assets/images/Mastercard.svg';
import { ReactComponent as AmericanExpress } from '../../assets/images/American Express - img.svg';
import { ReactComponent as DinersClub } from '../../assets/images/Diners Club-img.svg';
import { ReactComponent as JCB } from '../../assets/images/JCB - img.svg';
import { ReactComponent as Paypal } from '../../assets/images/paypal-img.svg';
import { ReactComponent as Discover } from '../../assets/images/Discover - img.svg';
import { ClipLoader } from 'react-spinners';
import Autopay from './HandleAutopay';
import {
  customerBillingAddressInterface,
  customerBillingAddressOnEditInterface,
  RACSelectChangeEvent,
  InputChangeEvent,
  SwipeDeviceOption,
  StoreAddressDetails,
  StateDropDownArray,
  editCardDetailsObject,
  InputValueCreditCardInfo,
  SwipeDeviceResponse,
  SaveCardRequestDTOForEdit,
} from '../interface/tenderCardInterface';
import { PaymentInfo } from '../../domain/swipecardpayload';
import {
  GetCardInfo,
  Savecustomercard,
  Deletecustomercard,
  Swipecustomercard,
} from '../../api/user';
import { ReactComponent as Info } from '../../assets/images/info.svg';
import { ReactComponent as Step1img } from '../../assets/images/step1img.svg';
import { ReactComponent as Step2img } from '../../assets/images/step2img.svg';
import SecondFactor from '../../racstrapComponents/microfrontends/TwoFactorPopup';
import { ReactComponent as SuccessIcon } from '../../../src/assets/images/success-icon.svg';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import {
  RACSelect,
  RACTextbox,
  RACButton,
  RACCheckBox,
  RACModalCard,
  Grid,
  Typography,
  Card,
  CardContent,
  makeStyles,
} from '@rentacenter/racstrap';
import { paymentMethod } from '../interface/handleAutopayInterface';
import CONSTANTS from '../constants/constant';
import * as interfaceType from '../interface/contextInterface';
import {
  AgreementDetails,
  CardDetailsInterface,
  Customer,
  TenderCardDetailsArray,
} from '../interface/paymentInfoInterface';
import { VantivCards } from './VamtivCards';
import { resetTenderTypes } from '../utils/amountCalculations';
import { StoreDetails } from '../interface/paymentGridInterface';
import { splitMethod } from '../utils/scheduleAndDateFormat';

interface propsInterface {
  setRerenderReaminingAmount: (flag: boolean) => void;
  rerenderReaminingAmount: boolean;
}

export default function TenderCard(props: propsInterface) {
  const useClasses = makeStyles(() => ({
    navLink: {
      transition:
        'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out',
      position: 'relative',
      fontFamily: 'OpenSans-semibold',
      color: '#000',
    },
    navLinkactive: {
      color: '#2179FE',
      borderBottom: '5px solid #2468FF !important',
      fontFamily: 'OpenSans-semibold',
      '&:before': {
        content: "''",
        position: 'absolute',
        height: '5px',
        width: '10px',
        background: '#2468ff',
        left: '-5px',
        bottom: '-5px',
        borderTopLeftRadius: '5px',
      },
      '&:after': {
        content: "''",
        position: 'absolute',
        height: '5px',
        width: '10px',
        background: '#2468ff',
        right: '-5px',
        bottom: '-5px',
        borderTopRightRadius: '5px',
      },
    },
    dropdowntoggle: {
      display: 'inline-block',
      marginLeft: '0.255em',
      verticalAlign: '0.255em',
      content: '',
      borderTop: '0.3em solid',
      borderRight: '0.3em solid transparent',
      borderBottom: '0',
      borderLeft: '0.3em solid transparent',
    },
    dropdownitemcustom: {
      color: '#2468FF',
      fontFamily: 'OpenSans-bold',
      backgroundColor: 'white',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    popUp: {
      position: 'absolute',
      bottom: '100%',
      backgroundColor: 'white',
      width: '200px',
      padding: '15px',
      '& li': {
        listStyle: 'none',
      },
    },
    popUpHide: {
      display: 'none',
    },
    customMenuContainer: {
      border: '1px solid #2468FF',
    },
    paymentBtnHover: {
      '&:hover': {
        color: 'white',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
      },
    },
  }));
  const classes = takePaymentStyles();
  const navlinkClass = useClasses();

  /**
   * PS_TCD_2
   * Validation objects
   * PS_TCD_4
   */
  const customerBillingAddress: customerBillingAddressInterface = {
    name: CONSTANTS.EMPTY_STRING,
    addressLine1: CONSTANTS.EMPTY_STRING,
    addressLine2: CONSTANTS.EMPTY_STRING,
    zipcode: CONSTANTS.EMPTY_STRING,
    city: CONSTANTS.EMPTY_STRING,
    state: CONSTANTS.EMPTY_STRING,
  };

  const customerBillingAddressOnEdit: customerBillingAddressOnEditInterface = {
    name: CONSTANTS.EMPTY_STRING,
    addressLine1: CONSTANTS.EMPTY_STRING,
    addressLine2: CONSTANTS.EMPTY_STRING,
    postalCode: CONSTANTS.EMPTY_STRING,
    city: CONSTANTS.EMPTY_STRING,
    state: CONSTANTS.EMPTY_STRING,
  };
  const validationObj = {
    name: false,
    addressLine1: false,
    postalCode: false,
    city: false,
    state: false,
  };

  /**
   * PS_TCD_5
   * Invoking Context
   */
  const agreementInfo =
    useContext<interfaceType.AgreementContextValue>(agreementContext);

  /**
   * PS_TCD_6
   * State Variables Creation
   * PS_TCD_32
   */
  const [checkedItems, setCheckedItems] = useState({});
  const [remainingAmount, setRemainingAmount] = useState<string>(
    CONSTANTS.ZREO_TWO_DECIMAL_STRING
  );
  const [errorMessage, setErrorMessage] = useState({
    saveCard: CONSTANTS.EMPTY_STRING,
    chargeCard: CONSTANTS.EMPTY_STRING,
    multiStoreOneCardError: CONSTANTS.EMPTY_STRING,
  });
  const [cardAmountValues, setCardAmountValues] = useState({});
  const [cvvInputValues, setCvvInputValues] =
    useState<InputValueCreditCardInfo>({});
  const [inputInitialRemainingAmounts, setInputInitialRemainingAmounts] =
    useState({});
  const [valueObj, setValueObj] = useState({});
  const [cardPopup, setCardPopup] = useState({
    editCard: false,
    swipeCard: false,
    deleteCardPopup: false,
  });
  const [resetTender, setResetTender] = useState<boolean>(false);
  const [manageCard, setManageCard] = useState<boolean>(false);
  const [, setAuthDetails] = useState<boolean>(false);
  const [swipeDevices, setSwipeDevices] = useState<SwipeDeviceOption[]>([]);
  const [states, setStates] = useState<StateDropDownArray[]>();
  const [swipeInfoPopupShow, setSwipeInfoPopupShow] = useState<boolean>(false);
  const [nextBtn, setNextBtn] = useState<boolean>();
  const [editAddressValidation, setEditAddressValidation] =
    useState(validationObj);
  const [customerAddress, setCustomerAddress] =
    useState<customerBillingAddressInterface>(customerBillingAddress);
  const [editCardBillAddress, setEditCardBillAddress] =
    useState<customerBillingAddressOnEditInterface>(
      customerBillingAddressOnEdit
    );
  const [saveCardPopupShow, setSaveCardPopupShow] = useState({
    saveCardSuccess: false,
    saveCardPopUp: false,
    saveCardFail: false,
  });
  const [failedToSaveCardError, setFailedToSaveCardError] = useState<string>(
    CONSTANTS.EMPTY_STRING
  );
  const [typeOfCard, setTypeOfCard] = useState<string>(CONSTANTS.EMPTY_STRING);

  const [editCardDetails, setEditCardDetails] = useState<editCardDetailsObject>(
    {
      customerPaymentId: CONSTANTS.EMPTY_STRING,
      cardLastFour: CONSTANTS.EMPTY_STRING,
    }
  );
  const [deleteCardPopups, setDeleteCardPopups] = useState({
    deleteCardSuccess: false,
    deleteCardFail: false,
    deleteCardSpinner: false,
  });

  const [storeIdDetails, setStoreIdDetails] = useState({
    storeZip: CONSTANTS.EMPTY_STRING,
    storeMerchantId: CONSTANTS.EMPTY_STRING,
    terminalId: CONSTANTS.EMPTY_STRING,
    registerNumber: CONSTANTS.EMPTY_STRING,
  });

  const [swipeDeviceAmount, setSwipeDeviceAmount] = useState<string>(
    CONSTANTS.ZREO_TWO_DECIMAL_STRING
  );

  const [swipeErrorMessage, setSwipeErrorMessage] = useState<boolean>(false);
  const [, setSwipeSpinload] = useState<boolean>(false);
  const [swipeDeviceResponse, setSwipeDeviceResponse] =
    useState<SwipeDeviceResponse>();

  const [editccCardDetails, setEditccCardDetails] = useState({
    cardType: CONSTANTS.EMPTY_STRING,
    cardHolderName: CONSTANTS.EMPTY_STRING,
    tokenInfo: {
      expirationDate: CONSTANTS.EMPTY_STRING,
      paymentToken: CONSTANTS.EMPTY_STRING,
    },
    lastFour: CONSTANTS.EMPTY_STRING,
  });

  /**
   * PS_TCD_33
   * Variable Creation
   * PS_TCD_35
   */
  const customerId = agreementInfo.customerInfo.customerId;
  const firstName = agreementInfo.customerInfo.customerDetails[0].firstName;
  const lastName = agreementInfo.customerInfo.customerDetails[0].lastName;

  /**
   * PS_TCD_38
   * This gets the store details from the session storage
   * PS_TCD_41
   */
  const JsonsessionStoreDetails: string | null = window.sessionStorage.getItem(
    CONSTANTS.STORE_DETAILS
  );
  let sessionStoreDetails: StoreAddressDetails | undefined;

  try {
    if (JsonsessionStoreDetails) {
      sessionStoreDetails = JSON.parse(atob(JsonsessionStoreDetails));
    }
  } catch (e) {
    console.error('Error parsing store details:', e);
  }

  /**
   * PS_TCD_42
   * This useEffect sets the address and entered card details
   * PS_TCD_68
   */
  useEffect(() => {
    if (sessionStoreDetails?.ccPaymentMerchantId) {
      setStoreIdDetails({
        ...storeIdDetails,
        storeMerchantId: sessionStoreDetails.ccPaymentMerchantId,
      });
      setStoreIdDetails({
        ...storeIdDetails,
        storeZip: sessionStoreDetails.zip,
      });
    }

    getState();
    swipeDevicesList();
    const billingAddress =
      agreementInfo.customerInfo.customerAddressDetails.find(
        (address) => address.addressType === CONSTANTS.BILLING_ADDRESS
      );

    if (billingAddress) {
      const sessionZip: string[] = splitMethod(
        billingAddress.postalCode,
        CONSTANTS.HYPHEN
      );
      customerAddress.name = `${firstName} ${lastName}`;
      customerAddress.addressLine1 = billingAddress.addressLine1;
      customerAddress.addressLine2 = billingAddress.addressLine2;
      customerAddress.zipcode = sessionZip[0];
      customerAddress.city = billingAddress.city;
      customerAddress.state = billingAddress.state;
    } else if (sessionStoreDetails) {
      const sessionZip: string[] = splitMethod(
        sessionStoreDetails.zip,
        CONSTANTS.HYPHEN
      );
      customerAddress.name = `${firstName} ${lastName}`;
      customerAddress.addressLine1 = sessionStoreDetails.addressLine1;
      customerAddress.addressLine2 = sessionStoreDetails.addressLine2;
      customerAddress.zipcode = sessionZip[0];
      customerAddress.city = sessionStoreDetails.city;
      customerAddress.state = sessionStoreDetails.state;
    }

    const cardArray = agreementInfo.customerInfo.amountUsed.tenderDetails.card;

    const cardObject: Record<string, boolean> = {};
    const cardAmount: Record<string, number> = {};

    cardArray.forEach((card: TenderCardDetailsArray) => {
      cardObject[card.cardLastFour] = true;
      cardAmount[card.cardLastFour] = Number(card.amount);
    });

    const cardDetailsArray = agreementInfo.customerInfo.amountUsed.cardDetails;

    function createExsistingCvvObject(cardDetails: CardDetailsInterface[]) {
      const result = {};

      cardDetails.forEach((card: CardDetailsInterface, index: number) => {
        const cardDetailsLastFour = card.cardLastFour;
        result[index] = {
          [`${cardDetailsLastFour}_cvv`]: card.cvv,
          [`${cardDetailsLastFour}_cvvEncrypt`]: '***',
        };
      });

      return result;
    }
    setCvvInputValues(createExsistingCvvObject(cardDetailsArray));
    setValueObj(createExsistingCvvObject(cardDetailsArray));
    setCheckedItems(cardObject);
    setCardAmountValues(cardAmount);
  }, []);

  /**
   * PS_TCD_46
   * this useEffect set the reamining amount when the customer info changes
   */
  useEffect(() => {
    setRemainingAmount(
      agreementInfo.customerInfo.amountUsed.remainingAmountDue
    );
  }, [agreementInfo.customerInfo.amountUsed.remainingAmountDue]);

  /**
   * PS_TCD_44
   * This function gets the states from the context
   * PS_TCD_48
   */
  async function getState() {
    const response = agreementInfo.stateData;
    if (response.length > 0) {
      response.unshift({
        label: CONSTANTS.SELECT,
        value: CONSTANTS.ZERO_STRING,
      });
      setStates(response);
    }
  }

  /**
   * PS_TCD_50
   * This function call the getCard
   * PS_TCD_49
   */
  async function getCardDetails() {
    const response = await GetCardInfo(customerId);
    const agreementContextClone = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );
    agreementContextClone.customerCardDetails = response;
    agreementInfo.setCustomerInfo(agreementContextClone);
  }

  /**
   * PS_TCD_50
   * This function sets the swipe device from the context
   * PS_TCD_52
   */
  const swipeDevicesList = async () => {
    const swipeDeviceResponse: SwipeDeviceResponse = agreementInfo.storeInfo
      .swipeDevices as SwipeDeviceResponse;

    const DeviceNameArrfunc = () => {
      if (
        swipeDeviceResponse !== undefined &&
        swipeDeviceResponse.status == 200 &&
        swipeDeviceResponse.data !== undefined &&
        swipeDeviceResponse.data.StorePaymentDeviceResponse !== undefined &&
        swipeDeviceResponse.data.StorePaymentDeviceResponse.devices !==
          undefined &&
        swipeDeviceResponse.data.StorePaymentDeviceResponse.devices !== null &&
        swipeDeviceResponse.data.StorePaymentDeviceResponse.devices.length > 0
      ) {
        return swipeDeviceResponse.data.StorePaymentDeviceResponse.devices.map(
          (values) => {
            const terminalId = String(values.termNumber)?.slice(-3);
            return { label: values.name, value: terminalId };
          }
        );
      } else if (
        swipeDeviceResponse !== undefined &&
        swipeDeviceResponse.status == 200 &&
        swipeDeviceResponse.data !== undefined &&
        swipeDeviceResponse.data.devices !== undefined &&
        swipeDeviceResponse.data.devices !== null &&
        swipeDeviceResponse.data.devices.length > 0
      ) {
        return swipeDeviceResponse.data.devices.map((values) => {
          const terminalId = String(values.termNumber)?.slice(-3);
          return { label: values.name, value: terminalId };
        });
      } else {
        return [];
      }
    };
    setSwipeDevices(DeviceNameArrfunc);
    setSwipeDeviceResponse(swipeDeviceResponse);
  };

  /**
   * PS_TCD_69
   * This useEffect calculates the remaining amount
   * PS_TCD_78
   */
  useEffect(() => {
    const agreementContextClone = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );
    const remainingAmountDue = parseFloat(
      agreementContextClone.amountUsed.remainingAmountDue
    );

    if (remainingAmount == CONSTANTS.ZREO_TWO_DECIMAL_STRING) {
      const newRemainingAmountDue = (
        remainingAmountDue - parseFloat(remainingAmount)
      ).toFixed(2);
      agreementContextClone.amountUsed.remainingAmountDue =
        newRemainingAmountDue;
      agreementInfo.setCustomerInfo(agreementContextClone);
    } else {
      agreementContextClone.amountUsed.remainingAmountDue =
        parseFloat(remainingAmount).toFixed(2);
      agreementInfo.setCustomerInfo(agreementContextClone);
    }
  }, [remainingAmount]);

  /**
   * PS_TCD_85
   * @param lastFour
   * @param index
   * This function handles the check box change
   * PS_TCD_93
   */
  const handleCheckboxChange = (lastFour: string, index: number) => {
    if (
      agreementInfo.multiStoreSelected &&
      Object.values(checkedItems).some((x) => x) &&
      !checkedItems[lastFour]
    ) {
      const newCheckedItems = {
        ...checkedItems,
        [lastFour]: checkedItems[lastFour],
      };
      setCheckedItems(newCheckedItems);
      setErrorMessage((prevState) => ({
        ...prevState,
        multiStoreOneCardError: CONSTANTS.YES,
      }));
      return;
    }
    setErrorMessage((prevState) => ({
      ...prevState,
      multiStoreOneCardError: CONSTANTS.EMPTY_STRING,
    }));
    const newCheckedItems = {
      ...checkedItems,
      [lastFour]: !checkedItems[lastFour],
    };
    setCheckedItems(newCheckedItems);
    const inputValue = cardAmountValues[lastFour] || CONSTANTS.ZERO_STRING;
    const intValue = parseInt(inputValue);
    if (!newCheckedItems[lastFour] && !isNaN(intValue)) {
      if (errorMessage.saveCard !== CONSTANTS.YES) {
        setRemainingAmount((prevRemainingAmount) =>
          (parseFloat(prevRemainingAmount) + intValue).toString()
        );
      }
      setErrorMessage({ ...errorMessage, saveCard: CONSTANTS.EMPTY_STRING });
      setCardAmountValues((prevValues) => {
        const newInputValues = { ...prevValues };
        delete newInputValues[lastFour];
        return newInputValues;
      });
      const agreementContextClone = JSON.parse(
        JSON.stringify(agreementInfo.customerInfo)
      );

      const filteredCard =
        agreementContextClone.amountUsed.tenderDetails.card?.filter(
          (obj: TenderCardDetailsArray) =>
            obj.cardLastFour !== lastFour && obj.type === CONSTANTS.SAVE
        );

      const cardDetails = agreementContextClone.amountUsed.cardDetails?.filter(
        (obj: CardDetailsInterface) =>
          obj.cardLastFour !== lastFour &&
          obj.cardTransactionType === CONSTANTS.PAYTOK
      );

      agreementContextClone.amountUsed.tenderDetails.cardDetails = cardDetails;
      agreementContextClone.amountUsed.tenderDetails.card = filteredCard;

      agreementInfo.setCustomerInfo(agreementContextClone);

      setValueObj((prevValueObj) => {
        const newValueObj = { ...(prevValueObj || {}) };
        newValueObj[index] = newValueObj[index] || {};
        delete newValueObj[index][`${lastFour}_cvv`];
        delete newValueObj[index][`${lastFour}_cvvEncrypt`];
        return newValueObj;
      });

      setCvvInputValues((prevInputValue) => {
        const newInputValue = { ...prevInputValue };
        delete newInputValue[`${lastFour}_cvv`];
        delete newInputValue[`${lastFour}_cvvEncrypt`];
        return newInputValue;
      });
      props.setRerenderReaminingAmount(!props.rerenderReaminingAmount);
    } else {
      setValueObj((prevValueObj) => {
        const newValueObj = { ...(prevValueObj || {}) };
        newValueObj[index] = newValueObj[index] || {};
        newValueObj[index][`${lastFour}_cvv`] = CONSTANTS.EMPTY_STRING;
        newValueObj[index][`${lastFour}_cvvEncrypt`] = CONSTANTS.EMPTY_STRING;
        return newValueObj;
      });

      setCvvInputValues((prevInputValue) => {
        const newInputValue = { ...prevInputValue };
        newInputValue[`${lastFour}_cvv`] = CONSTANTS.EMPTY_STRING;
        newInputValue[`${lastFour}_cvvEncrypt`] = CONSTANTS.EMPTY_STRING;
        return newInputValue;
      });
    }
  };

  /**
   * PS_TCD_79
   * This useffect triggered when the isSetToDefaut changes on
   * the context and call the setToDefault function which sets
   * all the state variable to initial state
   * PS_TCD_79
   */
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (agreementInfo.isSetToDefault !== CONSTANTS.ZERO_NUMBER) {
      setToDefault();
    }
  }, [agreementInfo.isSetToDefault]);

  useEffect(() => {
    setResetTender(true);
    if (resetTenderTypes(agreementInfo) && resetTender) {
      agreementInfo.customerInfo.amountUsed.tenderDetails.cash.amount =
        CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      agreementInfo.customerInfo.amountUsed.tenderDetails.card = [];
      agreementInfo.customerInfo.amountUsed.tenderDetails.checkDetails = [];
      agreementInfo.customerInfo.amountUsed.tenderDetails.moneyOrderDetails =
        [];
      setToDefault();
    }
  }, [agreementInfo.agreementDetails]);

  function setToDefault() {
    setCheckedItems({});
    // setRemainingAmount(CONSTANTS.ZREO_TWO_DECIMAL_STRING);
    setErrorMessage({
      saveCard: CONSTANTS.EMPTY_STRING,
      chargeCard: CONSTANTS.EMPTY_STRING,
      multiStoreOneCardError: CONSTANTS.EMPTY_STRING,
    });
    setCardAmountValues({});
    setCvvInputValues({});
    setInputInitialRemainingAmounts({});
    // setVantivIframe();
    setValueObj({});
    setTypeOfCard(CONSTANTS.EMPTY_STRING);
    setCardPopup({ ...cardPopup, editCard: false });
    setCardPopup({ ...cardPopup, swipeCard: false });
    setCardPopup({ ...cardPopup, deleteCardPopup: false });

    setAuthDetails(false);
    setSwipeDevices([]);
    // setStates();
    setSwipeInfoPopupShow(false);
    // setNextBtn();
    setEditAddressValidation(validationObj);
    setCustomerAddress(customerBillingAddress);
    setEditCardBillAddress(customerBillingAddressOnEdit);
    setSaveCardPopupShow({ ...saveCardPopupShow, saveCardSuccess: false });
    setSaveCardPopupShow({ ...saveCardPopupShow, saveCardPopUp: false });
    setSaveCardPopupShow({ ...saveCardPopupShow, saveCardFail: false });
    setEditCardDetails({
      customerPaymentId: '',
      cardLastFour: '',
    });
    // setCustomerCards([]);
    setDeleteCardPopups({ ...deleteCardPopups, deleteCardSuccess: false });
    setDeleteCardPopups({ ...deleteCardPopups, deleteCardFail: false });
    setDeleteCardPopups({ ...deleteCardPopups, deleteCardSpinner: false });
    // setStorezip();
    // setStoreMerchantId();
    setSwipeDeviceAmount(CONSTANTS.ZREO_TWO_DECIMAL_STRING);
    setSwipeErrorMessage(false);
    setSwipeSpinload(false);
    // setSwipeDeviceResponse();
    // setTerminalId();
    // setRegisterNumber();
    setEditccCardDetails({
      cardType: CONSTANTS.EMPTY_STRING,
      cardHolderName: CONSTANTS.EMPTY_STRING,
      tokenInfo: {
        expirationDate: CONSTANTS.EMPTY_STRING,
        paymentToken: CONSTANTS.EMPTY_STRING,
      },
      lastFour: CONSTANTS.EMPTY_STRING,
    });
  }

  /**
   * PS_TCD_96
   * @param event
   * @param lastFour
   * This function handle the amount obj when user
   * enters the amount on the speicific card
   * PS_TCD_108
   */
  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    lastFour: string
  ) => {
    const value = event.target.value;
    const parseValue = value.replace(
      CONSTANTS.REPLACE_COMMA,
      CONSTANTS.EMPTY_STRING
    );
    const initialRemainingAmount =
      inputInitialRemainingAmounts[lastFour] ||
      Number(remainingAmount).toFixed(2);
    setCardAmountValues((prevValues) => ({
      ...prevValues,
      [lastFour]: parseValue,
    }));
    setInputInitialRemainingAmounts((prevInitialAmounts) => ({
      ...prevInitialAmounts,
      [lastFour]: initialRemainingAmount,
    }));
  };

  /**
   * PS_TCD_100
   * @param lastFour
   * This funtion updates the context
   * PS_TCD_108
   */
  const calculateTotalAmountRemaining = (
    lastFour: string,
    item: paymentMethod
  ) => {
    const initialRemainingAmount =
      inputInitialRemainingAmounts[lastFour] ||
      parseFloat(remainingAmount).toFixed(2);
    const totalAmount = parseFloat(
      cardAmountValues[lastFour] || CONSTANTS.ZERO_STRING
    );

    const newRemainingAmount = (initialRemainingAmount - totalAmount).toFixed(
      2
    );
    if (parseFloat(newRemainingAmount) >= 0) {
      setRemainingAmount(newRemainingAmount);
      setErrorMessage({ ...errorMessage, saveCard: CONSTANTS.EMPTY_STRING });
      const cardAmount = cardAmountValues;
      const agreementContextClone = JSON.parse(
        JSON.stringify(agreementInfo.customerInfo)
      );
      const orderId = agreementContextClone.amountUsed.tenderDetails.orderId;
      const card: TenderCardDetailsArray[] =
        agreementContextClone.amountUsed.tenderDetails.card;

      const isCardLastFourPresent = (
        cardLastFour: string,
        card: TenderCardDetailsArray
      ) => {
        return cardLastFour === card.cardLastFour;
      };

      const indexOfMatchingCard = card.findIndex((cardObj) => {
        return (
          cardObj.type === CONSTANTS.SAVE &&
          isCardLastFourPresent(lastFour, cardObj)
        );
      });

      if (indexOfMatchingCard == -1) {
        const lastFourCardAmount = cardAmount[lastFour];
        const cardObj = {
          amount: parseFloat(lastFourCardAmount).toFixed(2),
          type: CONSTANTS.SAVE,
          cardLastFour: lastFour,
          orderId: orderId + 1,
        };
        agreementContextClone.amountUsed.tenderDetails.card.push(cardObj);
        agreementContextClone.amountUsed.tenderDetails.orderId = orderId + 1;

        agreementInfo.setCustomerInfo(agreementContextClone);

        props.setRerenderReaminingAmount(!props.rerenderReaminingAmount);
      }
      if (indexOfMatchingCard !== -1) {
        const indexOfCard =
          agreementContextClone.amountUsed.tenderDetails.card[
            indexOfMatchingCard
          ];
        const cardLastFour = indexOfCard.cardLastFour;
        const cardAmountValue = cardAmount[cardLastFour];
        agreementContextClone.amountUsed.tenderDetails.card[
          indexOfMatchingCard
        ].amount = parseFloat(cardAmountValue).toFixed(2);

        agreementInfo.setCustomerInfo(agreementContextClone);
        props.setRerenderReaminingAmount(!props.rerenderReaminingAmount);
      }
      handleOnburforCardDetails(item, '', agreementContextClone);
    } else {
      setErrorMessage({ ...errorMessage, saveCard: CONSTANTS.YES });
      const customerContextClone = JSON.parse(
        JSON.stringify(agreementInfo.customerInfo)
      );
      customerContextClone.amountUsed.cardDetails =
        customerContextClone.amountUsed.cardDetails.filter(
          (card: CardDetailsInterface) => {
            return card.cardLastFour !== lastFour;
          }
        );
      customerContextClone.amountUsed.tenderDetails.card =
        customerContextClone.amountUsed.tenderDetails.card.filter((card) => {
          return card.cardLastFour !== lastFour;
        });
      agreementInfo.setCustomerInfo(customerContextClone);
      props.setRerenderReaminingAmount(!props.rerenderReaminingAmount);
    }
  };

  /**
   * PS_TCD_110
   * @param event
   * @param item
   * @param index
   * this function handles the on change of cvv field
   * PS_TCD_122
   */
  const handleCvvOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: paymentMethod,
    index: number
  ) => {
    const value = event.target.value;
    const cvvKey = item.lastFour + '_cvv';
    const cvvEncryptKey = item.lastFour + '_cvvEncrypt';

    const newValueObj = {
      ...valueObj,
      [index]: valueObj[index] || {
        [cvvKey]: CONSTANTS.EMPTY_STRING,
        [cvvEncryptKey]: CONSTANTS.EMPTY_STRING,
      },
    };

    if (value.length < (cvvInputValues[index]?.[cvvKey]?.length || 0)) {
      if (newValueObj[index] && newValueObj[index][cvvKey]) {
        newValueObj[index][cvvKey] = newValueObj[index][cvvKey].slice(0, -1);
        newValueObj[index][cvvEncryptKey] = newValueObj[index][
          cvvEncryptKey
        ].slice(0, -1);
      }
    } else {
      const sanitizedValue = value.replace(/\*/g, CONSTANTS.EMPTY_STRING);
      newValueObj[index][cvvKey] = newValueObj[index][cvvKey] + sanitizedValue;

      const encryptedValue = newValueObj[index][cvvKey].replace(/\d/g, '*');
      newValueObj[index][cvvEncryptKey] = encryptedValue;
    }

    setValueObj(newValueObj);
    setCvvInputValues({ ...cvvInputValues, ...newValueObj });
  };

  /**
   * PS_TCD_126
   * @param event
   * This functions updates the address
   * PS_TCD_127
   */
  function editcardOnchange(event: RACSelectChangeEvent) {
    const keyNameId = event.target.id;
    const keyNameValue = event.target.value;
    if (keyNameId == undefined) {
      setEditCardBillAddress({
        ...editCardBillAddress,
        ['state']: keyNameValue,
      });
    } else {
      setEditCardBillAddress({
        ...editCardBillAddress,
        [keyNameId]: keyNameValue,
      });
    }
    if (keyNameValue !== CONSTANTS.EMPTY_STRING) {
      if (keyNameId == undefined) {
        setEditAddressValidation((prevState) => ({
          ...prevState,
          ['state']: false,
        }));
      } else {
        setEditAddressValidation((prevState) => ({
          ...prevState,
          [keyNameId]: false,
        }));
      }
    }
  }

  /**
   * PS_TCD_123
   * @returns
   * this is the popup for edit address
   * PS_TCD_128
   */
  const editCardPopup = () => {
    return (
      <Grid
        item
        id="editcard"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="carrywaive"
        aria-hidden="true"
        data-testid="EditCardPopupId"
      >
        <Grid container spacing={2}>
          <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
            <RACTextbox
              type="text"
              id="name"
              name="name"
              required={true}
              value={editCardBillAddress.name}
              isCurrency={false}
              inputlabel="Name"
              OnChange={(e: InputChangeEvent) => editcardOnchange(e)}
            />
            {editAddressValidation.name ? (
              <Typography
                className={`${classes.validationstyle} ${classes.mandatoryfield}`}
              >
                Please enter Name
              </Typography>
            ) : null}
          </Grid>

          <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
            <RACTextbox
              type="text"
              id="addressLine1"
              name="address_line_1"
              OnChange={(e) => editcardOnchange(e)}
              value={editCardBillAddress.addressLine1}
              isCurrency={false}
              required={true}
              inputlabel="Address Line 1"
            />
            {editAddressValidation.addressLine1 ? (
              <Typography
                className={`${classes.validationstyle} ${classes.mandatoryfield}`}
              >
                Please enter Address line 1
              </Typography>
            ) : null}
          </Grid>

          <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
            <RACTextbox
              type="text"
              isCurrency={false}
              OnChange={(e) => editcardOnchange(e)}
              inputlabel="Address Line 2"
              name="address_line2"
              id="addressLine2"
              value={editCardBillAddress.addressLine2}
            />
          </Grid>

          <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
            <RACTextbox
              type="text"
              pattern="\d*"
              maxlength={5}
              OnChange={(e) => editcardOnchange(e)}
              name="zip_code"
              id="postalCode"
              required={true}
              value={editCardBillAddress.postalCode}
              inputlabel="ZIP Code"
              isCurrency={false}
            />
            {editAddressValidation.postalCode ? (
              <Typography
                className={`${classes.validationstyle} ${classes.mandatoryfield}`}
              >
                Please enter ZIP code
              </Typography>
            ) : null}
          </Grid>

          <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
            <RACTextbox
              type="text"
              name="city"
              id="city"
              required={true}
              isCurrency={false}
              OnChange={(e) => editcardOnchange(e)}
              inputlabel="City"
              value={editCardBillAddress.city}
            />
            {editAddressValidation.city ? (
              <Typography
                className={`${classes.validationstyle} ${classes.mandatoryfield}`}
              >
                Please enter City
              </Typography>
            ) : null}
          </Grid>

          <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
            <RACSelect
              options={states}
              defaultValue={editCardBillAddress.state}
              inputLabel="State"
              name="State"
              required={true}
              onChange={(e) => editcardOnchange(e)}
            ></RACSelect>
            {editAddressValidation.state ? (
              <Typography
                className={`${classes.validationstyle} ${classes.mandatoryfield}`}
              >
                Please select State
              </Typography>
            ) : null}
          </Grid>
        </Grid>
        <Grid item md={12} className={`${classes.w100} ${classes.mt4}`}>
          <RACButton
            data-bs-dismiss="modal"
            onClick={() => {
              setCardPopup((prev) => ({
                ...prev,
                editCard: false,
              }));
              setCardPopup((prev) => ({
                ...prev,
                deleteCardPopup: true,
              }));
            }}
            style={{
              backgroundColor: '#FFD1D1',
              textTransform: 'none',
              fontWeight: 'bolder',
              paddingLeft: '14px',
              paddingRight: '14px',
              color: '#B50000',
            }}
            data-testid="DeleteBtnId"
          >
            Delete Card
          </RACButton>

          <Grid item className={classes.floatRight}>
            <RACButton
              style={{
                backgroundColor: '#fafcff',
                textTransform: 'none',
                fontWeight: 'bolder',
                paddingLeft: '14px',
                paddingRight: '14px',
                color: ' #5a5a5a',
              }}
              data-bs-dismiss="modal"
              onClick={() => {
                setEditAddressValidation(validationObj);
                setCardPopup({ ...cardPopup, editCard: false });
              }}
              data-testid="EditsCrdCancelbtnIn"
            >
              Cancel
            </RACButton>

            <RACButton
              style={{
                backgroundColor: '#2179fe',
                textTransform: 'none',
                fontWeight: 'bolder',
                paddingLeft: '14px',
                paddingRight: '14px',
                color: '#FFFFFF',
              }}
              onClick={() => editCardSave()}
              data-testid="EditsCrdSavebtnIn"
            >
              {' '}
              Save
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  /**
   * PS_TCD_129
   * This function calls the api on the edit address submit button
   * PS_TCD_135
   */
  const editCardSave = async () => {
    const cardDetails = editccCardDetails;

    if (!isValidBillingAddress()) {
      setInvalidAddressFields();
      return;
    }

    const NewCardReq = createSaveCardRequest(cardDetails);
    setCardPopup({ ...cardPopup, editCard: false });
    resetEditAddressValidation();
    setSaveCardPopupShow({ ...saveCardPopupShow, saveCardPopUp: true });

    try {
      const savecardres = await Savecustomercard(NewCardReq);
      handleSaveCardResponse(savecardres);
    } catch (error) {
      console.error('Error saving card:', error);
      setSaveCardPopupShow({
        ...saveCardPopupShow,
        saveCardPopUp: false,
        saveCardFail: true,
      });
    }
  };

  const isValidBillingAddress = () => {
    const { name, addressLine1, city, postalCode, state } = editCardBillAddress;
    return (
      name !== CONSTANTS.EMPTY_STRING &&
      addressLine1 !== CONSTANTS.EMPTY_STRING &&
      city !== CONSTANTS.EMPTY_STRING &&
      postalCode !== CONSTANTS.EMPTY_STRING &&
      state !== CONSTANTS.ZERO_STRING
    );
  };

  const setInvalidAddressFields = () => {
    const { name, addressLine1, city, postalCode, state } = editCardBillAddress;
    setEditAddressValidation({
      name: name === CONSTANTS.EMPTY_STRING,
      addressLine1: addressLine1 === CONSTANTS.EMPTY_STRING,
      postalCode: postalCode === CONSTANTS.EMPTY_STRING,
      city: city === CONSTANTS.EMPTY_STRING,
      state: state === CONSTANTS.ZERO_STRING,
    });
  };

  const createSaveCardRequest = (cardDetails): SaveCardRequestDTOForEdit => ({
    raftApiFlag: 1,
    storeMerchantId: storeIdDetails.storeMerchantId || null,
    paymentFlag: 0,
    clubFlag: 0,
    updateFlag: 1,
    cardType: cardDetails?.cardType,
    cardToken: String(cardDetails.tokenInfo.paymentToken),
    cardHolderName: String(editCardBillAddress.name),
    cardExpiration: String(cardDetails.tokenInfo.expirationDate),
    storeId: String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)),
    transactionAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    cardNumberLastFour: cardDetails.lastFour,
    customerId: Number(customerId),
    PaymentID: CONSTANTS.EMPTY_STRING,
    Amount: 0,
    SalesTax: 0,
    CustomerInfo: { FirstName: firstName, LastName: lastName },
    BillToAddress: {
      Address1: editCardBillAddress.addressLine1,
      Address2: editCardBillAddress.addressLine2 ?? CONSTANTS.EMPTY_STRING,
      City: editCardBillAddress.city,
      StateProvince: editCardBillAddress.state,
      zip: splitMethod(editCardBillAddress.postalCode, CONSTANTS.HYPHEN)[0],
      Country: CONSTANTS.USA,
      BillShipSameAddressFlag: CONSTANTS.ZERO_STRING,
    },
    agreementIds: [],
  });

  const resetEditAddressValidation = () => {
    setEditAddressValidation({
      name: false,
      addressLine1: false,
      postalCode: false,
      city: false,
      state: false,
    });
  };

  const handleSaveCardResponse = (savecardres: any) => {
    if (
      savecardres?.status === 200 &&
      savecardres?.data !== CONSTANTS.EMPTY_STRING &&
      savecardres?.data?.customerId != undefined
    ) {
      setSaveCardPopupShow({
        ...saveCardPopupShow,
        saveCardPopUp: false,
        saveCardSuccess: true,
      });
    } else {
      setSaveCardPopupShow({
        ...saveCardPopupShow,
        saveCardPopUp: false,
        saveCardFail: true,
      });
    }
  };

  /**
   * PS_TCD_81
   * @param arg
   * @returns
   * This function handles the logo of the card
   * PS_TCD_85
   */
  const Bindcardsymbol = (arg: string) => {
    if (arg === CONSTANTS.MASTER_CARD) {
      return <Mastercard className={classes.cardSize}></Mastercard>;
    }
    if (arg === CONSTANTS.AMERICAN_EXPRESS_CARD) {
      return <AmericanExpress className={classes.cardSize}></AmericanExpress>;
    }
    if (arg === CONSTANTS.JCB_CARD) {
      return <JCB className={classes.cardSize}></JCB>;
    }
    if (arg === CONSTANTS.DINERS_CLUB) {
      return <DinersClub className={classes.cardSize}></DinersClub>;
    }
    if (arg === CONSTANTS.PAY_PAL) {
      return <Paypal className={classes.cardSize}></Paypal>;
    }
    if (arg === CONSTANTS.DISCOVER) {
      return <Discover className={classes.cardSize}></Discover>;
    } else {
      return <Visacard className={classes.cardSize}></Visacard>;
    }
  };

  /**
   * PS_TCD_81
   * @param arg
   * @returns
   * This functio map the user cards
   * PS_TCD_85
   */
  const GetcardMapping = () => {
    const removeDuplicates = (
      arr: paymentMethod[],
      key: string
    ): paymentMethod[] => {
      return Array.from(new Map(arr.map((item) => [item[key], item])).values());
    };
    let filteredCardData: paymentMethod[] =
      agreementInfo.customerInfo.customerCardDetails.paymentMethods?.filter(
        (value) =>
          value.cardTypeRef !== CONSTANTS.CARD_REF_TYPE_PP &&
          value.cardTypeRef !== CONSTANTS.CARD_REF_TYPE_BL &&
          value.cardTypeRef !== CONSTANTS.CARD_REF_TYPE_EC &&
          value.cardTypeRef !== CONSTANTS.CARD_REF_TYPE_GC &&
          value.cardTypeRef !== CONSTANTS.CARD_REF_TYPE_VM &&
          value.cardTypeRef !== null &&
          value.cardTypeRef !== CONSTANTS.EMPTY_STRING
      );
    if (filteredCardData == undefined) {
      filteredCardData = [];
    } else {
      const uniqueArray: paymentMethod[] = removeDuplicates(
        filteredCardData,
        CONSTANTS.LASTFOUR
      );
      filteredCardData = uniqueArray;
    }
    return filteredCardData.map((item: paymentMethod, index: number) => {
      return (
        <Grid
          item
          key={index}
          className={`${classes.w100} ${classes.floatLeft} ${classes.cardSplitBorder}`}
        >
          <Grid
            item
            className={`${classes.raccollg5} ${classes.pt2} ${classes.mt3} ${classes.floatLeft} ${classes.pe0}`}
          >
            <Grid
              item
              className={`${classes.formcheck} ${classes.mar6}  ${classes.ps0} ${classes.floatLeft} ${classes.alignContentCenter}`}
            >
              <RACCheckBox
                key={Math.random()}
                style={{ float: 'left' }}
                defaultValue={CONSTANTS.EMPTY_STRING}
                id={'card-2' + index}
                data-testid={'Cards_Checkbox'}
                value={index}
                name="Card CheckBox"
                defaultChecked={checkedItems[item.lastFour] ? true : false}
                onChange={() => handleCheckboxChange(item.lastFour, index)}
              />
              <Typography
                className={`${classes.font14} ${classes.alignContentCenter}`}
                htmlFor={'card-2' + index}
                id={'ce' + index}
                data-testid={'Cards'}
                component={'label'}
              >
                {' '}
                {Bindcardsymbol(item.cardType)} Card ending with {item.lastFour}
              </Typography>
            </Grid>
          </Grid>
          {checkedItems[item.lastFour] && (
            <>
              <Grid
                item
                className={`${classes.raccollg7} ${classes.floatLeft}`}
                key={index}
                id={'div-' + index}
                data-testid="2ndChkboxDivId"
              >
                <Grid
                  item
                  className={`${classes.raccollg5} ${classes.mt3} ${classes.px2} ${classes.floatLeft}`}
                >
                  <div
                    className={`${classes.inputgroup} ${classes.m0} ${classes.mt2}`}
                  >
                    <span className={classes.inputgrouptext}>$</span>
                    <CurrencyInput
                      data-testid="2ndtextinput"
                      required={true}
                      name="chkAmt"
                      type="text"
                      decimalsLimit={2}
                      className={`${classes.formcontrol3} ${classes.textend}`}
                      id={'txtCurr' + index}
                      onChange={(e) => handleAmountChange(e, item.lastFour)}
                      onBlur={() => {
                        calculateTotalAmountRemaining(item.lastFour, item);
                      }}
                      value={
                        cardAmountValues[item.lastFour]
                          ? cardAmountValues[item.lastFour]
                          : (cardAmountValues[item.lastFour] = '0.00')
                      }
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  className={`${classes.raccollg4} ${classes.mt4} ${classes.floatLeft} ${classes.ps3}`}
                >
                  <Grid
                    item
                    className={`${classes.floatLeft} ${classes.w25} ${classes.pt2}`}
                  >
                    <Typography
                      htmlFor="exampleFormControlInput1"
                      className={classes.formLabel}
                      data-testid="CvvId"
                      component={'label'}
                    >
                      CVV
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={`${classes.w50} ${classes.floatLeft} ${classes.ps2}`}
                  >
                    <RACTextbox
                      isCurrency={false}
                      name="chkcvv"
                      id={'CVV' + index}
                      pattern="\d*"
                      maxlength={4}
                      data-testid={'CvvField'}
                      value={
                        cvvInputValues[index]?.[
                          `${item.lastFour}_cvvEncrypt`
                        ] || CONSTANTS.EMPTY_STRING
                      }
                      OnChange={(e) => handleCvvOnChange(e, item, index)}
                      Onblur={() =>
                        handleOnburforCardDetails(item, CONSTANTS.FROM_CVV)
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  className={`${classes.raccollg3} ${classes.mt3} ${classes.floatLeft} ${classes.px2}`}
                >
                  <p
                    className={`${classes.textend} ${classes.editCardbtn}`}
                    data-bs-toggle="modal"
                    data-bs-target="#editcard"
                    data-testid="EditId"
                    onClick={() => {
                      setCardPopup({ ...cardPopup, editCard: true });
                      setEditCardBillAddress({
                        ...item.billingAddress,
                        name: item.cardHolderName,
                      });
                      // setEditCardBillAddress((prev) => ({
                      //   ...prev,
                      //   name: item.cardHolderName,
                      // }));
                      setEditCardDetails({
                        ...editCardDetails,
                        customerPaymentId: item.paymentMethodId,
                        cardLastFour: item.lastFour,
                      });
                      setEditccCardDetails(item);
                    }}
                  >
                    Edit Card
                  </p>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      );
    });
  };

  const spinnerPopup = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textcenter}>
          <ClipLoader
            color="blue"
            loading={
              deleteCardPopups.deleteCardSpinner
                ? deleteCardPopups.deleteCardSpinner
                : saveCardPopupShow.saveCardPopUp
            }
            size={40}
          />
          <Typography className="row justify-content-center popup-text">
            {deleteCardPopups.deleteCardSpinner
              ? 'Deleting card'
              : 'Saving card'}
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.textcenter}>
          <Typography variant="h6" className={classes.mt3}>
            Please Wait...
          </Typography>
        </Grid>
      </Grid>
    );
  };

  /**
   * PS_TCD_96
   * @param item
   * This function updates the card array
   * on the context
   * PS_TCD_122
   */
  function handleOnburforCardDetails(
    item: paymentMethod,
    fromCvv?: string,
    fromAmountCal?: Customer
  ) {
    const initialRemainingAmount =
      inputInitialRemainingAmounts[item.lastFour] ||
      parseFloat(remainingAmount).toFixed(2);
    const totalAmount = parseFloat(
      cardAmountValues[item.lastFour] || CONSTANTS.ZERO_STRING
    );

    const newRemainingAmount = (initialRemainingAmount - totalAmount).toFixed(
      2
    );
    if (parseFloat(newRemainingAmount) >= 0) {
      Object.keys(cvvInputValues).forEach((key) => {
        if (typeof cvvInputValues[key] !== CONSTANTS.OBJECT) {
          delete cvvInputValues[key];
        }
      });
      if (item.lastFour in cardAmountValues) {
        const keyToCheck = `${item.lastFour}_cvv`;
        const correctCvv = Object.values(cvvInputValues).some((obj: string) => {
          if (obj.hasOwnProperty(keyToCheck)) {
            const value = obj[keyToCheck];
            return value.length === 3 || value.length === 4;
          }
          return false;
        });
        if (correctCvv) {
          let currentCardCvv: string = CONSTANTS.EMPTY_STRING;
          for (const key in cvvInputValues) {
            if (cvvInputValues[key].hasOwnProperty(keyToCheck)) {
              const value = cvvInputValues[key][keyToCheck];
              currentCardCvv = value;
            }
          }
          const isCardLastFourPresent = (
            cardLastFour: string,
            card: CardDetailsInterface
          ) => {
            return cardLastFour === card.cardLastFour;
          };

          const indexOfMatchingCard =
            agreementInfo.customerInfo.amountUsed.cardDetails.findIndex(
              (cardObj: CardDetailsInterface) => {
                return (
                  cardObj.cardTransactionType === CONSTANTS.PAYTOK &&
                  isCardLastFourPresent(item.lastFour, cardObj)
                );
              }
            );
          if (indexOfMatchingCard !== -1) {
            const customerContextClone = JSON.parse(
              JSON.stringify(agreementInfo.customerInfo)
            );
            const customerInfoClone = fromAmountCal ?? customerContextClone;
            customerInfoClone.amountUsed.cardDetails[
              indexOfMatchingCard
            ].amount = Number(cardAmountValues[item.lastFour]).toFixed(2);
            customerInfoClone.amountUsed.cardDetails[indexOfMatchingCard].cvv =
              currentCardCvv;
            agreementInfo.setCustomerInfo(customerInfoClone);
            props.setRerenderReaminingAmount(!props.rerenderReaminingAmount);
          } else if (indexOfMatchingCard == -1) {
            const sessionZip: string[] = splitMethod(
              item.billingAddress.postalCode,
              CONSTANTS.HYPHEN
            );
            const billInfo = {
              addressLine1: item.billingAddress.addressLine1,
              addressLine2: item.billingAddress.addressLine2,
              city: item.billingAddress.city,
              zip: sessionZip[0],
              state: item.billingAddress.state,
            };
            const cardDetailsObj: CardDetailsInterface = {
              amount: Number(cardAmountValues[item.lastFour]).toFixed(2),
              authNumber: CONSTANTS.EMPTY_STRING,
              cvv: currentCardCvv,
              cardLastFour: item.lastFour,
              cardType: item.cardTypeRef,
              cardTransactionType: CONSTANTS.PAYTOK,
              externalTransactionId: CONSTANTS.EMPTY_STRING,
              paymentId: item.paymentMethodId,
              expirationDate: item.tokenInfo.expirationDate,
              paymentToken: item.tokenInfo.paymentToken,
              billingAddress: billInfo,
            };
            const customerContextClone = JSON.parse(
              JSON.stringify(agreementInfo.customerInfo)
            );
            const customerInfoClone = fromAmountCal ?? customerContextClone;
            customerInfoClone.amountUsed.cardDetails.push(cardDetailsObj);
            agreementInfo.setCustomerInfo(customerInfoClone);
            props.setRerenderReaminingAmount(!props.rerenderReaminingAmount);
          }
        } else {
          if (fromCvv == CONSTANTS.FROM_CVV) {
            const customerContextClone = JSON.parse(
              JSON.stringify(agreementInfo.customerInfo)
            );
            customerContextClone.amountUsed.cardDetails =
              customerContextClone.amountUsed.cardDetails.filter(
                (card: CardDetailsInterface) => {
                  return card.cardLastFour !== item.lastFour;
                }
              );
            agreementInfo.setCustomerInfo(customerContextClone);
            props.setRerenderReaminingAmount(!props.rerenderReaminingAmount);
          }
        }
      }
    }
  }

  /**
   * PS_TCD_151
   * @param event
   * This function handles the UI for Diff card buttons
   */
  function onClickOfCardsType(event) {
    const currentCardType = event;
    if (currentCardType === CONSTANTS.MANAGE_CARD) {
      setTypeOfCard(CONSTANTS.MANAGE_CARD);
    }
    if (currentCardType === CONSTANTS.SWIPE_CARD) {
      setTypeOfCard(CONSTANTS.SWIPE_CARD);
      setCardPopup({ ...cardPopup, swipeCard: true });
    }
    if (currentCardType === CONSTANTS.CHARGE_CARD) {
      setTypeOfCard(CONSTANTS.CHARGE_CARD);
    }
    if (currentCardType === CONSTANTS.ADD_NEW_CARD) {
      setTypeOfCard(CONSTANTS.ADD_NEW_CARD);
    }
  }

  /**
   * PS_TCD_198
   * These function handles the swipe info
   * PS_TCD_198
   */
  const infoonclick = () => {
    if (nextBtn) {
      setSwipeInfoPopupShow(false);
      setNextBtn(false);
    } else {
      swipeInfoPopupShow === false
        ? setSwipeInfoPopupShow(true)
        : setSwipeInfoPopupShow(false);
    }
  };
  const step1Next = () => {
    setNextBtn(true);
    setSwipeInfoPopupShow(false);
  };
  const Previous = () => {
    setNextBtn(false);
    setSwipeInfoPopupShow(true);
  };
  const skip = () => {
    setSwipeInfoPopupShow(false);
    setNextBtn(false);
  };

  const fixedChange = (value: string) => {
    if (value == undefined || value == null || parseFloat(value) < 0.0) {
      return CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    } else {
      return String(parseFloat(value).toFixed(2));
    }
  };

  /**
   * PS_TCD_204
   * @param value
   * This function validates the amount entered on swipe device
   */
  function swipeTotalamount(value: string) {
    const agreementContextClone = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );
    const parseValue = value.replace(
      CONSTANTS.REPLACE_COMMA,
      CONSTANTS.EMPTY_STRING
    );
    if (
      Number(parseFloat(parseValue).toFixed(2)) <=
      Number(
        parseFloat(agreementContextClone.amountUsed.remainingAmountDue).toFixed(
          2
        )
      )
    ) {
      setSwipeDeviceAmount(parseValue);
      setSwipeErrorMessage(false);
    } else {
      setSwipeErrorMessage(true);
    }
  }

  /**
   * PS_TCD_182
   * @param value
   * This function validates the amount entered on swipe device
   * PS_TCD_196
   */
  const swipeCardpopup = () => {
    const agreementContextClone = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );
    return (
      <Grid
        item
        data-testid="swipecardmodal"
        id="Swipecard"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        <Grid container justifyContent={'center'}>
          <Grid item md={12} className={`${classes.mb3} ${classes.ps2}`}>
            <Typography
              variant="caption"
              className={`${classes.RAClabeltxtstyle} ${classes.semibold}`}
            >
              Remaining Amount Due:{' '}
            </Typography>
            <Typography
              variant="caption"
              className={`${classes.RACvaluetxtstyle} ${classes.bold}`}
            >
              $
              {fixedChange(
                String(
                  Number(agreementContextClone.amountUsed.remainingAmountDue) -
                    Number(swipeDeviceAmount)
                )
              )}
            </Typography>
          </Grid>
          <Grid item md={12} className={`${classes.mb3} ${classes.ps2}`}>
            <Grid
              item
              md={12}
              className={`${classes.formLabel} ${classes.w100} ${classes.floatLeft} ${classes.mt2}`}
            >
              Computer Name
            </Grid>
            <Grid
              item
              md={12}
              className={`${classes.mb3} ${classes.w100} ${classes.floatLeft}`}
            >
              <RACSelect
                options={swipeDevices}
                defaultValue={storeIdDetails.terminalId}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  deviceDropdown(e)
                }
              />
              <Grid
                className={`${classes.w100} ${classes.floatLeft} ${classes.mt2}`}
              >
                <Typography
                  className={`${classes.floatLeft}`}
                  variant="caption"
                >
                  Please read the instruction to know the device name.
                </Typography>
                <Grid
                  className={`${classes.floatLeft} ${classes.racDeviceInfo}`}
                >
                  <Info onClick={() => infoonclick()}></Info>
                  {swipeInfoPopupShow === true ? (
                    <Grid
                      id="swipepopup"
                      item
                      className={classes.racDeviceGuide}
                    >
                      <Card
                        className={`${classes.w100} ${classes.mb2} ${classes.card} ${classes.shadownone}`}
                      >
                        <CardContent>
                          <Typography
                            className={`${classes.semibold} ${classes.font14}`}
                          >
                            Step 1
                          </Typography>
                          <Typography className={classes.font14}>
                            Please go to &quot;My Details&quot; app in your
                            Desktop to know the system inforamtion.Refer the
                            screen shot given below.
                          </Typography>
                          <Grid className={classes.racDeviceImage}>
                            <Step1img></Step1img>
                          </Grid>
                          <Grid
                            className={`${classes.floatRight} ${classes.my3}`}
                          >
                            <RACButton
                              variant="outlined"
                              color="secondary"
                              className={classes.mx2}
                              onClick={() => skip()}
                            >
                              Skip
                            </RACButton>
                            <RACButton
                              variant="contained"
                              color="primary"
                              onClick={() => step1Next()}
                            >
                              Next
                            </RACButton>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ) : null}
                  {nextBtn === true ? (
                    <Grid
                      id="swipepopup"
                      item
                      className={classes.racDeviceGuide}
                    >
                      <Card
                        className={`${classes.w100} ${classes.mb2} ${classes.card} ${classes.shadownone} `}
                      >
                        <CardContent>
                          <Typography
                            className={`${classes.semibold} ${classes.font14}`}
                          >
                            Step 2
                          </Typography>
                          <Typography className={classes.font14}>
                            You can find the computer name as highlighted in red
                            color.Refer the screenshot given below.
                          </Typography>
                          <Grid className={classes.racDeviceImage}>
                            <Step2img></Step2img>
                          </Grid>
                          <Grid
                            className={`${classes.floatRight} ${classes.my3}`}
                          >
                            <RACButton
                              variant="outlined"
                              color="secondary"
                              className={classes.mx2}
                              onClick={() => Previous()}
                            >
                              Previous
                            </RACButton>
                            <RACButton
                              variant="contained"
                              color="primary"
                              onClick={() => skip()}
                            >
                              Ok
                            </RACButton>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12} className={`${classes.mb3} ${classes.ps2}`}>
            <Grid
              item
              md={12}
              className={`${classes.formLabel} ${classes.mt2}`}
            >
              Amount
            </Grid>
            <Grid item md={12} className={`${classes.mb3}`}>
              <RACTextbox
                isCurrency={true}
                className={`${classes.custDigit}`}
                value={swipeDeviceAmount}
                name="total_amt"
                id="dollor"
                data-testid="amtTxtBoxId"
                OnChange={(e: InputChangeEvent) =>
                  swipeTotalamount(e.target.value)
                }
                type={'number'}
                digitFormat={'currency'}
                maxlength={10}
                dollarTextClassName={classes.currencyDollarField}
              />
              {swipeErrorMessage ? (
                <label
                  className={classes.validationstyle}
                  style={{
                    visibility: swipeErrorMessage ? 'visible' : 'hidden',
                  }}
                >
                  Amount should not be entered more than the remaining amount
                </label>
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} className={`${classes.p3} ${classes.textright}`}>
          <RACButton
            className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
            variant="outlined"
            color="primary"
            style={{
              backgroundColor: '#fafcff',
              textTransform: 'none',
              fontWeight: 'bolder',
              paddingLeft: '14px',
              paddingRight: '14px',
              color: '#5a5a5a',
              borderColor: '#cee0ff  !important',
            }}
            onClick={() => sClose()}
            data-bs-toggle="modal"
            data-testid="SwipeCardClose"
          >
            Cancel
          </RACButton>

          <RACButton
            className={`${classes.paymentSmallBtn1}`}
            color="primary"
            variant="contained"
            style={{
              backgroundColor: '#2179fe',
              textTransform: 'none',
              fontWeight: 'bolder',
              paddingLeft: '14px',
              paddingRight: '14px',
              color: 'white',
            }}
            onClick={() => swipeNextClick()}
            data-bs-toggle="modal"
            disabled={
              Number(swipeDeviceAmount) > 0 &&
              String(storeIdDetails.terminalId) != 'null'
                ? false
                : true
            }
            data-testid="SwipeCardClose"
          >
            Next
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  /**
   *  PS_TCD_187
   * @param e
   * This function createa an array for the dropdown
   *  PS_TCD_196
   */
  const deviceDropdown = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    let selectedLabel;
    if (
      agreementInfo.featureFlags?.CloudStoreSwipeDevicelist ==
      CONSTANTS.ONE_STRING
    ) {
      selectedLabel = swipeDeviceResponse?.data?.devices?.filter((el) => {
        const terminalId = String(el.termNumber)?.slice(-3);
        return terminalId === selectedValue;
      });
    } else {
      selectedLabel =
        swipeDeviceResponse?.data?.StorePaymentDeviceResponse?.devices?.filter(
          (el) => {
            const terminalId = String(el.termNumber)?.slice(-3);
            return terminalId === selectedValue;
          }
        );
    }
    const label =
      selectedLabel !== undefined && selectedLabel.length > 0
        ? selectedLabel[0].name
        : undefined;

    const registerNumber =
      selectedLabel !== undefined && selectedLabel.length > 0
        ? selectedLabel[0].registerNumber
        : undefined;
    setStoreIdDetails({ ...storeIdDetails, registerNumber: registerNumber });
    setStoreIdDetails({ ...storeIdDetails, terminalId: selectedValue });
    window.sessionStorage.setItem(CONSTANTS.DEVICE_NAME, String(label));
  };

  /**
   * PS_TCD_205
   * This function handles the swipe api call and payload
   * PS_TCD_209
   */
  const swipeNextClick = async () => {
    const currentDate = new Date()
      .toJSON()
      .replace(CONSTANTS.SPLIT_WITH_T, CONSTANTS.HYPHEN)
      .replace(CONSTANTS.SPLIT_WITH_Z, CONSTANTS.EMPTY_STRING);
    const PaymentInfo: PaymentInfo = {
      TerminalID: String(storeIdDetails.terminalId),
      Amount: String(swipeDeviceAmount),
      PaymentDate:
        currentDate.split('.')[0] + ':000' + currentDate.split('.')[1],
      zip: String(
        agreementInfo.customerInfo.customerAddressDetails[0].postalCode
      ),
      PaymentType: CONSTANTS.TWO_STRING,
      TransactionType: CONSTANTS.TWO_STRING,
      RegisterNumber: storeIdDetails.registerNumber,
    };
    const SwipeCardRequestDTO = {
      PaymentInfo: PaymentInfo,
      storeNumber: window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER),
      userId: String(agreementInfo.coWorkerDetails.employeeId),
      clientId: CONSTANTS.RACPAD,
      customerId: Number(customerId),
      name: firstName + ' ' + lastName,
      CloudSwipeCustomerCard:
        agreementInfo.featureFlags?.CloudStoreSwipeDevicelist ==
        CONSTANTS.ONE_STRING
          ? CONSTANTS.STATUS_YES
          : CONSTANTS.STATUS_NO,
    };
    setSwipeSpinload(true);
    const swipeResponse = await Swipecustomercard(SwipeCardRequestDTO);
    setSwipeSpinload(false);
    const cardDetail = {
      cardAmount: Number(swipeDeviceAmount),
      cardAuthNumber: swipeResponse.data.cardAuthNumber,
      cardLastFour: swipeResponse.data.cardLastFour,
      cardToken: swipeResponse.data.cardToken,
      cardSequence: swipeResponse.data.cardRefNumber,
      cardTransactionType: CONSTANTS.SWP,
    };

    const agreementContextClone = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );

    agreementContextClone.amountUsed.cardDetails.push(cardDetail);
    const orderId = agreementContextClone.amountUsed.tenderDetails.orderId + 1;

    const sideBarCardObj = {
      type: CONSTANTS.CHARGE,
      amount: parseFloat(swipeDeviceAmount).toFixed(2),
      cardLastFour: swipeResponse.data.cardLastFour,
      orderId: orderId,
    };

    agreementContextClone.amountUsed.tenderDetails.card.push(sideBarCardObj);
    agreementInfo.setCustomerInfo(agreementContextClone);
  };
  function sClose() {
    setCardPopup({ ...cardPopup, swipeCard: false });
    setTypeOfCard(CONSTANTS.EMPTY_STRING);
  }

  /**
   * PS_TCD_146
   * This function deletes the card
   * PS_TCD_162
   */
  const DeletePopup = () => {
    return (
      <Grid
        item
        id="delete-auto"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        data-testid="DeleteCardPopupId"
        tabIndex={-1}
        aria-labelledby="delete-auto"
        aria-modal="true"
        role="dialog"
      >
        <Grid container className={classes.textcenter}>
          <Grid item md={12} className={classes.mb3}>
            <DeleteIcon></DeleteIcon>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="h6"
              className={`${classes.customsure} ${classes.semibold}`}
            >
              Delete Card
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            className={`${classes.bgpopdelete} ${classes.mt4} ${classes.pb3}`}
          >
            <Typography
              className={`${classes.font14} ${classes.semibold} ${classes.textcenter} ${classes.mt3} ${classes.mb1}`}
            >
              The card you selected ending with
              <Typography
                variant="caption"
                className={`${classes.blackcolortext} ${classes.ps2} ${classes.font14} ${classes.semibold}`}
              >
                {editCardDetails.cardLastFour}
              </Typography>{' '}
              will be{' '}
            </Typography>
            <Typography
              className={`${classes.font14} ${classes.semibold} ${classes.textcenter} ${classes.mb1}`}
            >
              removed from the customer&apos;s card on the file list.
            </Typography>
            <Typography
              className={`${classes.font14} ${classes.semibold} ${classes.graycolortext}`}
            >
              {' '}
              Are you sure you want to continue?
            </Typography>
            <Grid
              item
              md={12}
              className={`${classes.w100} ${classes.mt3} ${classes.textcenter}`}
            >
              <RACButton
                className={classes.mx1}
                variant="outlined"
                color="primary"
                onClick={() =>
                  setCardPopup({ ...cardPopup, deleteCardPopup: false })
                }
                data-bs-dismiss="modal"
                data-testid="NoKeepItBtnId"
                data-bs-toggle="modal"
                data-bs-target="#delivery-receipt"
              >
                {' '}
                No,Keep It
              </RACButton>
              <RACButton
                onClick={() => yesDeleteCard(editCardDetails.customerPaymentId)}
                className={`${classes.btnkeepdelete} ${classes.mx2} ${classes.px3}`}
                data-testid="yesDeleteCardId"
              >
                Yes,Delete Card
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  /**
   * PS_TCD_149
   * @param paymentMethodId
   * This function call the api to delete the card
   * PS_TCD_154
   */
  async function yesDeleteCard(paymentMethodId: string) {
    setCardPopup({ ...cardPopup, deleteCardPopup: false });
    setDeleteCardPopups({ ...deleteCardPopups, deleteCardSpinner: true });
    const response = await Deletecustomercard(paymentMethodId, customerId);
    if (
      response?.status === 200 &&
      response != undefined &&
      response?.data?.customerId === String(customerId)
    ) {
      setDeleteCardPopups({ ...deleteCardPopups, deleteCardSpinner: false });
      setDeleteCardPopups({ ...deleteCardPopups, deleteCardSuccess: true });
    } else {
      setDeleteCardPopups({ ...deleteCardPopups, deleteCardSpinner: false });
      setDeleteCardPopups({ ...deleteCardPopups, deleteCardFail: true });
    }
  }

  /**
   * PS_TCD_154
   * This function shows the popup for card delete
   * @returns
   */
  const cardSuccessfullyDeleted = () => {
    return (
      <Grid
        item
        data-testid="transcomppart"
        id="transatraction"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        {deleteCardPopups.deleteCardSuccess ? (
          <Grid item md={12} className={classes.textcenter}>
            <SuccessIcon></SuccessIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              Card Deleted Successfully
            </Typography>
          </Grid>
        ) : deleteCardPopups.deleteCardFail ? (
          <Grid item md={12} className={classes.textcenter}>
            <AlertIcon className={classes.racErrorIcon}></AlertIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              Something went wrong - Unable to delete card. Please retry
            </Typography>
          </Grid>
        ) : (
          CONSTANTS.EMPTY_STRING
        )}
        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => deleteCardOk()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const savedCardOk = () => {
    setSaveCardPopupShow((prevState) => ({
      ...prevState,
      saveCardFail: false,
      saveCardSuccess: false,
      saveCardPopUp: false,
    }));
    setTypeOfCard(CONSTANTS.EMPTY_STRING);
    setFailedToSaveCardError(CONSTANTS.EMPTY_STRING);
    getCardDetails();
    setCheckedItems({});
    setCardAmountValues({});
    setCvvInputValues({});
    setInputInitialRemainingAmounts({});
    setValueObj({});
  };

  /**
   * PS_TCD_163
   * This function closes the delete popups
   */
  const deleteCardOk = () => {
    setDeleteCardPopups({
      ...deleteCardPopups,
      deleteCardFail: false,
      deleteCardSuccess: false,
      deleteCardSpinner: false,
    });
    getCardDetails();
    setCheckedItems({});
    setCardAmountValues({});
    setCvvInputValues({});
    setInputInitialRemainingAmounts({});
    setValueObj({});
  };

  /**
   * PS_TCD_194
   * This function is the popup for successfull save card
   */
  const cardSuccessfullySaved = () => {
    return (
      <Grid
        item
        data-testid="transcomppart"
        id="transatraction"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        {saveCardPopupShow.saveCardSuccess ? (
          <Grid item md={12} className={classes.textcenter}>
            <SuccessIcon></SuccessIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              Card Saved Successfully
            </Typography>
          </Grid>
        ) : saveCardPopupShow.saveCardFail ? (
          <Grid item md={12} className={classes.textcenter}>
            <AlertIcon className={classes.racErrorIcon}></AlertIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              {failedToSaveCardError == CONSTANTS.EMPTY_STRING
                ? CONSTANTS.SOMETHING_WENT_WRONG_UNABLE_TO_SAVE_CARD_PLEASE_TRY_WITH_DIFFERENT_CARD_RETRY
                : failedToSaveCardError}
            </Typography>
          </Grid>
        ) : (
          CONSTANTS.EMPTY_STRING
        )}

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => savedCardOk()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  /**
   * PS_TCD_222
   * this disable the mangae card
   * @returns
   */
  function isManageCardDisable() {
    let isDisable = false;
    const agreements = agreementInfo.agreementDetails.filter((agr) => {
      return (
        agr.isAutopayEnroll !== null &&
        agr.storeNumber === window.sessionStorage.getItem('storeNumber')
      );
    });
    if (agreements.length == 0) {
      isDisable = true;
    }
    return isDisable;
  }

  function isSwipeChargeCardDisable() {
    const deliveryUnconfiremd = agreementInfo.agreementDetails.filter(
      (agreement) => {
        return (
          agreement.selected &&
          !agreement.deliveryFlag &&
          Number(agreement.numberOfPaymentsMade) >= CONSTANTS.THREE_NUMBER
        );
      }
    );
    const pendingAgreements: string[] = [];
    agreementInfo?.storeInfo?.storeDetails?.filter((item: StoreDetails) => {
      if (item.transferAgreementStatus === CONSTANTS.ONE_NUMBER) {
        pendingAgreements.push(item.storeNumber);
      }
    });
    const agreementsStoreNumber = agreementInfo.agreementDetails.map(
      (agreement: AgreementDetails) => {
        return pendingAgreements.includes(agreement.storeNumber);
      }
    );
    let isDisable = false;
    if (
      agreementInfo?.multiStoreSelected ||
      agreementInfo?.paymentOrigin === CONSTANTS.IN_PHONE_VALUE ||
      deliveryUnconfiremd.length !== CONSTANTS.ZERO_NUMBER ||
      agreementsStoreNumber.some(
        (store: boolean, index: number) => store[index] === true
      ) ||
      agreementInfo?.customerInfo?.customerPaymentInfo?.commitment?.[0]
        ?.commitmentStatusCode == CONSTANTS.OP
    ) {
      isDisable = true;
    }
    return isDisable;
  }

  /**
   * HTMl
   */
  return (
    <>
      <Grid
        item
        data-testid="carddivshow"
        id="Card"
        role="tabpanel"
        aria-labelledby="Card-tab"
      >
        {agreementInfo.multiStoreSelected ? (
          <Typography component={'label'} className={classes.mandatoryfield}>
            Please inform the customer that they will see multiple charges on
            their card for this payment.
          </Typography>
        ) : null}
        <Grid
          item
          className={`${classes.w100} ${classes.floatLeft} ${classes.my3} ${classes.mt4}`}
        >
          <Grid
            item
            className={`${classes.font16} ${classes.bold} ${classes.floatLeft}`}
          >
            Select Card
          </Grid>
          <Grid item className={classes.floatRight}>
            {agreementInfo.customerOrderPayment == CONSTANTS.EMPTY_STRING ? (
              <RACButton
                color="primary"
                variant="outlined"
                id="manageCard"
                onClick={() => onClickOfCardsType(CONSTANTS.MANAGE_CARD)}
                className={`${classes.paymentSmallBtn} ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
                data-bs-toggle="modal"
                disabled={isManageCardDisable()}
                data-testid="swipeId"
              >
                Manage Card
              </RACButton>
            ) : null}
            <RACButton
              color="primary"
              variant="outlined"
              id="swipeCard"
              onClick={() => onClickOfCardsType(CONSTANTS.SWIPE_CARD)}
              className={`${classes.paymentSmallBtn} ${classes.ms2} ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
              data-bs-toggle="modal"
              disabled={
                isSwipeChargeCardDisable() ||
                agreementInfo?.paymentOrigin === CONSTANTS.IN_PHONE_VALUE
              }
              data-testid="swipeId"
            >
              Swipe Card
            </RACButton>
            <RACButton
              color="primary"
              className={`${classes.paymentSmallBtn} ${classes.mx2} ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
              variant="outlined"
              id="chargeCard"
              onClick={() => onClickOfCardsType(CONSTANTS.CHARGE_CARD)}
              disabled={
                typeOfCard !== CONSTANTS.EMPTY_STRING &&
                isSwipeChargeCardDisable()
              }
              data-bs-toggle="modal"
              data-testid="swipeId"
            >
              Charge Card
            </RACButton>
            <RACButton
              color="primary"
              onClick={() => onClickOfCardsType(CONSTANTS.ADD_NEW_CARD)}
              className={`${classes.paymentSmallBtn}  ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
              variant="outlined"
              disabled={typeOfCard !== CONSTANTS.EMPTY_STRING ? true : false}
              data-bs-toggle="modal"
              data-testid="swipeId"
            >
              Add New Card
            </RACButton>
          </Grid>
        </Grid>
        <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
          {errorMessage.saveCard == CONSTANTS.YES ? (
            <Typography component={'label'} className={classes.mandatoryfield}>
              Amount should not be entered more than the remaining amount
            </Typography>
          ) : null}
          {GetcardMapping()}
        </Grid>
      </Grid>

      {errorMessage.multiStoreOneCardError == CONSTANTS.YES ? (
        <Typography component={'label'} className={classes.mandatoryfield}>
          Only one card payment is allowed while making payment for agreement
          created on other store.
        </Typography>
      ) : null}

      {typeOfCard == CONSTANTS.MANAGE_CARD && (
        <>
          <SecondFactor
            setTwoFactorCancelClick={() => {
              setAuthDetails(false);
              setTypeOfCard(CONSTANTS.EMPTY_STRING);
            }}
            setTwoFactorCompleted={() => {
              setAuthDetails(false);
              setManageCard(true);
              setTypeOfCard(CONSTANTS.EMPTY_STRING);
            }}
            moduleName="Accept Payment"
            CurrentRole={'DM'}
          ></SecondFactor>
        </>
      )}
      {(typeOfCard == CONSTANTS.ADD_NEW_CARD ||
        typeOfCard == CONSTANTS.CHARGE_CARD) && (
        <VantivCards
          failedToSaveCardError={failedToSaveCardError}
          setFailedToSaveCardError={(res) => setFailedToSaveCardError(res)}
          sessionStoreDetails={sessionStoreDetails}
          typeOfCard={typeOfCard}
          setTypeOfCard={(res) => setTypeOfCard(res)}
          states={states}
          storeIdDetails={storeIdDetails}
          saveCardPopupShow={saveCardPopupShow}
          setSaveCardPopupShow={(res) => setSaveCardPopupShow(res)}
        ></VantivCards>
      )}
      <RACModalCard
        isOpen={cardPopup.editCard}
        maxWidth="xs"
        title="Billing Address"
        onClose={() => {
          setEditAddressValidation(validationObj);
          setCardPopup({ ...cardPopup, editCard: false });
        }}
        closeIcon={true}
      >
        {editCardPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={cardPopup.swipeCard}
        maxWidth="xs"
        closeIcon={true}
        title="Swipe Card"
        onClose={() => sClose()}
      >
        {swipeCardpopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={manageCard}
        maxWidth="sm"
        onClose={() => setManageCard(false)}
        closeIcon={true}
      >
        <Autopay
          cardPopup={manageCard}
          setCardPopup={(res) => setManageCard(res)}
          unEnrollAutopay={false}
          manageCard={true}
          racExchange={false}
        ></Autopay>
      </RACModalCard>
      <RACModalCard
        isOpen={cardPopup.deleteCardPopup}
        maxWidth="xs"
        onClose={() => setCardPopup({ ...cardPopup, deleteCardPopup: false })}
      >
        {' '}
        {DeletePopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={
          deleteCardPopups.deleteCardSuccess || deleteCardPopups.deleteCardFail
        }
        maxWidth="xs"
        onClose={() => deleteCardOk()}
        closeIcon={false}
      >
        {cardSuccessfullyDeleted()}
      </RACModalCard>
      <RACModalCard
        isOpen={deleteCardPopups.deleteCardSpinner}
        maxWidth="xs"
        onClose={() =>
          setDeleteCardPopups({ ...deleteCardPopups, deleteCardSpinner: false })
        }
        closeIcon={false}
      >
        {spinnerPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={
          saveCardPopupShow.saveCardSuccess || saveCardPopupShow.saveCardFail
        }
        maxWidth="xs"
        onClose={() => savedCardOk()}
        closeIcon={false}
      >
        {cardSuccessfullySaved()}
      </RACModalCard>
      <RACModalCard
        isOpen={saveCardPopupShow.saveCardPopUp}
        maxWidth="xs"
        onClose={() =>
          setSaveCardPopupShow({
            ...saveCardPopupShow,
            saveCardPopUp: false,
          })
        }
        closeIcon={false}
      >
        {spinnerPopup()}
      </RACModalCard>
    </>
  );
}
