import CONSTANTS from '../constants/constant';
import {
  AddressComponent,
  AddressPayload,
  ApiValidateAddress,
} from '../interface/customerInformationInterface';
import { GoogleAddressResponse } from '../interface/utilsInterface/validateAddressInterface';

/*eslint-disable sonarjs/cognitive-complexity*/
export default function validateAddressFn(
  response: GoogleAddressResponse,
  request: AddressPayload,
  addressDr?: ApiValidateAddress,
  onlyZipStateCountry?: boolean
) {
  const verdict = response.result.verdict;
  const address = response?.result?.address;

  const validateAddressComponent = () => {
    // Filter address components that are confirmed
    const filteredComponents = address.addressComponents.filter(
      (item: AddressComponent) =>
        CONSTANTS.COMPLETE_LEVEL_CONFIRMATION.includes(item.componentType)
    );

    // Check if every filtered component meets the confirmation criteria
    return filteredComponents.every((item: AddressComponent) =>
      CONSTANTS.COMPLETE_LEVEL_CONFIRMATION.includes(item.confirmationLevel)
    );
  };

  const formatgoooleAddress = () => {
    const state = address.addressComponents.filter((item: AddressComponent) => {
      return item.componentType == 'administrative_area_level_1' ? true : false;
    });
    address.postalAddress.administrativeArea = state[0].componentName.text
      ? state[0].componentName.text
      : request.Addresses[0].StateProvince;
    return {
      message: '',
      status: true,
      suggestedAddress: address.formattedAddress,
      postalAddress: address.postalAddress,
    };
  };

  const formatAddressFromAddressDr = () => {
    const addressDoctorResponse = addressDr && addressDr.Addresses;
    const formattedAddress = [
      addressDoctorResponse?.StreetAddress,
      addressDoctorResponse?.Building,
      addressDoctorResponse?.Unit,
      `${addressDoctorResponse?.City}, ${addressDoctorResponse?.StateProvince}, ${address.postalAddress.postalCode}`,
    ]
      .filter(Boolean)
      .join(', ');

    address.formattedAddress = formattedAddress;
    if (addressDoctorResponse?.StreetAddress)
      address.postalAddress.addressLines = [
        addressDoctorResponse.Building,
        addressDoctorResponse.Unit,
        addressDoctorResponse.StreetAddress,
      ].filter(Boolean);
  };

  try {
    const unconfirmedComponentValidation =
      address.unconfirmedComponentTypes &&
      address.unconfirmedComponentTypes?.some((item: string) => {
        return CONSTANTS.CONFIRMED_COMPONENTS.includes(item);
      });

    if (onlyZipStateCountry) {
      if (unconfirmedComponentValidation && !validateAddressComponent()) {
        return {
          status: false,
          message:
            'Address could not be corrected. Please enter the valid address.',
        };
      } else if (
        !(unconfirmedComponentValidation && !validateAddressComponent()) &&
        addressDr?.Addresses &&
        (addressDr.Addresses.MatchCode == '1' ||
          addressDr.Addresses.MatchCode == '2')
      ) {
        formatAddressFromAddressDr();
        return {
          message: '',
          status: true,
          matchCode: addressDr.Addresses.MatchCode,
          suggestedAddress: address.formattedAddress,
          postalAddress: address.postalAddress,
          replaced:
            verdict.hasReplacedComponents &&
            address.addressComponents.some(
              (item: AddressComponent) => item.replaced
            ),
        };
      } else if (
        !addressDr &&
        !(unconfirmedComponentValidation && !validateAddressComponent())
      ) {
        return formatgoooleAddress();
      } else {
        formatAddressFromAddressDr();
        return {
          status: true,
          suggestedAddress: address.formattedAddress,
          postalAddress: address.postalAddress,
        };
      }
    } else {
      if (!(unconfirmedComponentValidation && !validateAddressComponent())) {
        return formatgoooleAddress();
      } else
        return {
          status: false,
          message:
            'Address could not be corrected. Please enter the valid address.',
        };
    }
  } catch (e) {
    return {
      status: false,
      message: 'Address validation currently Unavailable.',
    };
  }
}
