/**
 * SQ_ACS_2
 */
import React, { useContext } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
} from '@rentacenter/racstrap';
import CurrencyInput from 'react-currency-input-field';
import { availableCreditsStyle } from '../stylesJS/availableCreditsStyle';
import { agreementContext } from '../context/PaymentInformationContext';
import { CreditsValue } from '../interface/availableCreditsInterface';
import CONSTANTS from '../constants/constant';

/**
 * SQ_ACS_3
 * @returns
 */
export function AvailableCredits() {
  /**
   * SQ_ACS_4
   */
  const classes = availableCreditsStyle();
  const agreementInfo = useContext(agreementContext);

  /**
   * SQ_ACS_5, SQ_ACS_6, SQ_ACS_7, SQ_ACS_8, SQ_ACS_9, SQ_ACS_10, SQ_ACS_11, SQ_ACS_12, SQ_ACS_13
   * @returns
   */
  const bindAvailableCredits = () => {
    return agreementInfo?.customerInfo?.remainingCredits?.map(
      (creditsValue: CreditsValue, index: number) => {
        if (
          creditsValue?.storeNumber ===
            sessionStorage.getItem(CONSTANTS.STORE_NUMBER) &&
          creditsValue?.accountBalance > CONSTANTS.ZERO_TWO_DECIMAL_NUMBER &&
          (creditsValue?.bucket === CONSTANTS.CAPITAL_COA ||
            creditsValue?.bucket === CONSTANTS.IP_SUSPENSE ||
            creditsValue?.bucket === CONSTANTS.WEB_LEAD_DEPOSIT)
        ) {
          return (
            <>
              <Grid
                item
                key={index}
                className={`${classes.w100} ${classes.floatLeft}`}
              >
                <>
                  <Typography
                    component={'label'}
                    className={`${classes.formLabel} ${classes.w35} ${classes.mt3} ${classes.floatLeft}`}
                  >
                    {creditsValue.bucket}
                  </Typography>
                  <Box
                    component="span"
                    className={`${classes.labeltxtstyle} ${classes.mt3} ${classes.floatLeft} ${classes.accountBalanceCss}`}
                  >
                    $
                    <Box className={classes.displayInline}>
                      {creditsValue.accountBalance}
                    </Box>
                  </Box>
                </>
                <Grid
                  item
                  className={`${classes.w50} ${classes.floatRight} ${classes.mt2}`}
                >
                  <Grid item className={`${classes.inputgroup} ${classes.mb3}`}>
                    <span className={classes.inputgrouptext}>$</span>
                    <CurrencyInput
                      className={`${classes.formcontrol3} ${classes.textend}`}
                      type="text"
                      disabled={true}
                      id="WL-Deposit"
                      defaultValue={creditsValue.accountBalance}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        }
      }
    );
  };

  /**
   * SQ_ACS_14 - SQ_ACS_18
   */
  return (
    <>
      <Grid container data-testid="availcreditid">
        <Grid
          item
          className={`${classes.w100} ${classes.floatLeft} ${classes.mb2}`}
        >
          <Typography
            variant="h6"
            className={`${classes.floatLeft} ${classes.mt2} ${classes.raccollg8} ${classes.title}`}
          >
            Available Credit
          </Typography>
        </Grid>

        <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
          <Card
            className={`${classes.card} ${classes.w100} ${classes.floatLeft} ${classes.p3}`}
            style={{ marginBottom: '20px' }}
          >
            <CardContent className={classes.p0}>
              {bindAvailableCredits()}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
