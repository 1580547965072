/* eslint-disable */
/**
 * PS_CO_TC_NO_5
 * Importing the required racstrap component and the hooks required.
 * PS_CO_TC_NO_6
 * Importing the required API calls
 */
import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
  CircularProgress,
} from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { agreementContext } from '../context/PaymentInformationContext';
import { useParams } from 'react-router-dom';
import { ActiveClub, GetCustomerCoverage } from '../../api/user';
import CONSTANTS from '../constants/constant';
import {
  GetCustomerCoverageResponse,
  ActivateClubPayload,
  ClubStatusPopup,
} from '../interface/toggleClubInterface';
import { AgreementDetails } from '../interface/paymentInfoInterface';
import { ParamType } from '../interface/commonInterface';
import { makeStyles } from '@rentacenter/racstrap';


const useClasses = makeStyles((theme: any) => ({
  activateClubPopupStyle:{
    zIndex: '0 !important',
    // opacity: 0.6
},
}))

/**PS_CO_TC_NO_7
 * Declare the component ToggleClub() which helps to perform the Activate and Deactivate the club
 */
export function ToggleClub() {
  /**
   * PS_CO_TC_NO_8
   * Required state variables and styles declaration is done here.
   */
  const { customerId } = useParams<ParamType>();
  const classes = takePaymentStyles();
  const classesToggleClub = useClasses()
  const history = useHistory();
  const {
    renderContext,
    setRenderContext,
    agreementDetails,
    setAgreementDetails,
    agreementDetailsDeepClone,
    commitmentLoad,
    isSetToDefault
  } = useContext(agreementContext);
  const [disableFlag, setDisableFlag] = useState<boolean>(false);
  const [clubBtnStatus, setClubBtnStatus] = useState<string>(
    CONSTANTS.EMPTY_STRING
  );
  const [clubAmount, setClubAmount] = useState<string>(
    CONSTANTS.ZREO_TWO_DECIMAL_STRING
  );
  const [clubStatusPopup, setclubStatusPopup] = useState<ClubStatusPopup>({
    activateClub: false,
    deactivateClub: false,
    customerRedirection: false,
  });
  const [masterloader, setmasterloader] = useState<boolean>(false);
  const [sameStoreAgr, setSameStoreAgr] = useState<AgreementDetails | undefined>();
  const [clubAgreement, setClubAgreement] = useState<AgreementDetails | undefined>();

  /**PS_CO_TC_NO_10
   * The local variable declaration which helps to hold the value unique througout the functionality
   */
  let storeNumber: string;
  // let clubAgreement: AgreementDetails | undefined;
  // let sameStoreAgr: AgreementDetails | undefined;

  /**
   * PS_CO_TC_NO_9 - The useEffect is performed to initialy show the status of the club button.
   */
  useEffect(() => {
    findClubStatus();
  }, [agreementDetailsDeepClone, commitmentLoad, isSetToDefault]);
  /**
   * PS_CO_TC_NO_9 - PS_CO_TC_NO_15
   * findClubStatus() method is invoked on the pageload and handles the status of the button.
   */
  const findClubStatus = () => {

    storeNumber = String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER));
    let clubAgreementFetch = getClubAgreement(storeNumber);
    console.log("clubAgreementinit", clubAgreement);
    let sameStoreAgrFetch = getSameStoreAgreement(storeNumber);
    if (clubAgreementFetch && sameStoreAgrFetch) {
      setClubBtnStatus(
        Number(clubAgreementFetch?.numberOfPaymentsMade) > CONSTANTS.ZERO_NUMBER
          ? CONSTANTS.DEACTIVATE_CLUB
          : CONSTANTS.CLUB_PENDING
      );
      setDisableFlag(clubAgreementFetch.storeNumber !== storeNumber);
    } else if (!sameStoreAgrFetch && !clubAgreementFetch) {
      setClubBtnStatus(CONSTANTS.STATUS_ACTIVE);
      setDisableFlag(true);
    } else if ((!sameStoreAgrFetch && clubAgreementFetch)) {
      setClubBtnStatus(CONSTANTS.DEACTIVATE_CLUB);
      setDisableFlag(false);
    } else if (agreementDetails.some((x) => x.agreementType == CONSTANTS.CLUB && x.storeNumber != storeNumber)) {
      setClubBtnStatus(CONSTANTS.DEACTIVATE_CLUB);
      setDisableFlag(true);
    } else {
      setClubBtnStatus(CONSTANTS.STATUS_ACTIVE);
      setDisableFlag(false);
    }

    if (clubAgreementFetch?.deactivateClubFlag && commitmentLoad) {
      setClubBtnStatus(CONSTANTS.STATUS_ACTIVE);
      setDisableFlag(false);
    }

    setClubAgreement(clubAgreementFetch);
    setSameStoreAgr(sameStoreAgrFetch);
  };

  /**
   * PS_CO_TC_NO_17
   * The handleClubOnClick() method is invoked when the Activate/Deactivate club button is clicked.
   */
  const handleClubOnClick = async (status: string) => {

    console.log("clubAgreement", clubAgreement);
    if (status === CONSTANTS.STATUS_ACTIVE) {
      if (clubAgreement) {
        handleActivateClub(clubAgreement);
      } else {
        await handleActivateClubWithCoverage();
      }
    } else if (
      status === CONSTANTS.DEACTIVATE_CLUB ||
      status === CONSTANTS.CLUB_PENDING
    ) {
      handleDeactivateClub(clubAgreement, sameStoreAgr);
    }
  };
  /**PS_CO_TC_NO_10
   * The getClubAgreement() method is used to fetch the current store club agreements
   */
  const getClubAgreement = (storeNumber: string) => {
    return agreementDetails.find(
      (x) => x.agreementType == CONSTANTS.CLUB && x.storeNumber === storeNumber
    );
  };

  /**PS_CO_TC_NO_10
   * The getSameStoreAgreement() method is used to fetch the current store agreement.
   */
  const getSameStoreAgreement = (storeNumber: string) => {
    return agreementDetails.find(
      (x) => x.agreementType !== CONSTANTS.CLUB && x.storeNumber === storeNumber && x.otherIncomeType == CONSTANTS.EMPTY_STRING
    );
  };

  /**
   * PS_CO_TC_NO_23
   * The handleActivateClub() method is invoked, if there is any club agreement
   */
  const handleActivateClub = (clubAgreement: AgreementDetails) => {

    const btnStatus =
      clubAgreement.numberOfPaymentsMade == CONSTANTS.ZERO_NUMBER
        ? CONSTANTS.CLUB_PENDING
        : CONSTANTS.DEACTIVATE_CLUB;
    setClubBtnStatus(btnStatus);

    const updatedAgreements = agreementDetails.map((x) => {
      if (x.agreementType === CONSTANTS.CLUB) {
        return {
          ...x,
          selected: true,
          disabled: false,
          deactivateClubFlag: false,
        };
      }
      return x;
    });

    setAgreementDetails(updatedAgreements);
    setRenderContext(!renderContext);
  };

  /**
   * PS_CO_TC_NO_20 - PS_CO_TC_NO_34
   * The handleActivateClubWithCoverage() method is invoked, when the customer does not have any active club.
   * And then the club will be activated.
   */
  const handleActivateClubWithCoverage = async () => {
    setmasterloader(true);
    const response: GetCustomerCoverageResponse = await GetCustomerCoverage(
      customerId
    );
    const clubAmountWithTax =
      response?.data?.clubInfo?.clubAmountwithTax ??
      CONSTANTS.ZREO_TWO_DECIMAL_STRING;

    if (clubAmountWithTax) {
      const totalAmountAdded = calculateTotalAmount(
        response,
        clubAmountWithTax
      );
      setClubAmount(totalAmountAdded.toFixed(2));
      setclubStatusPopup({ ...clubStatusPopup, activateClub: true });
    }

    setmasterloader(false);
  };
  /**
   * PS_CO_TC_NO_31 - PS_CO_TC_NO_32
   * This function is invoked to calculate the ldwamountwithtax
   */
  const calculateTotalAmount = (
    response: GetCustomerCoverageResponse,
    clubAmountWithTax: string
  ): number => {
    let totalAmountAdded = Number(clubAmountWithTax);

    if (response?.data?.agreementsWithoutLDW) {
      for (const agreement of response?.data?.agreementsWithoutLDW) {
        if (agreement?.agreementId) {
          const clubInformation = agreementDetails.find(
            (x) =>
              x.nextDueDate !== CONSTANTS.SAC &&
              x.nextDueDate !== CONSTANTS.EPO &&
              x.nextDueDate !== CONSTANTS.PIF &&
              x.agreementId == String(agreement.agreementId)
          );
          if (clubInformation) {
            totalAmountAdded += Number(agreement.ldwAmountWithTax);
          }
        }
      }
    }

    return totalAmountAdded;
  };

  /**
   * PS_CO_TC_NO_35
   * @param clubAgreement
   * @param sameStoreAgr
   * The function handleDeactivateClub() is invoked during the deactivate club flow
   */
  const handleDeactivateClub = (
    clubAgreement: AgreementDetails | undefined,
    sameStoreAgr: AgreementDetails | undefined
  ) => {
    if (clubAgreement) {
      setClubAmount(
        Number(clubAgreement.totalDueAmount ?? CONSTANTS.ZERO_NUMBER).toFixed(2)
      );
    }
    if (
      (agreementDetails.length == CONSTANTS.ONE_NUMBER ||
        sameStoreAgr === undefined) &&
      clubAgreement
    ) {
      setclubStatusPopup({ ...clubStatusPopup, customerRedirection: true });
    } else {
      setclubStatusPopup({ ...clubStatusPopup, deactivateClub: true });
    }
  };
  /**
   * PS_CO_TC_NO_39 - PS_CO_TC_NO_46
   * The activateClubYesbtn() method is invoked when the yes button is clicked on Activate club popup
   */
  const activateClubYesbtn = async () => {
    const payload: ActivateClubPayload = {
      paymentSchedule: null,
      customerId: Number(customerId),
      isCreate: CONSTANTS.ONE_NUMBER,
      agreementId: null,
      clubStatusRefCode: CONSTANTS.CLUB_PENDING,
    };
    setmasterloader(true);
    const response = await ActiveClub(payload);

    if (response?.customerClubId) {
      setClubBtnStatus(CONSTANTS.CLUB_PENDING);
    }
    setmasterloader(false);
    window.location.reload();
  };

  /**
   * PS_CO_TC_NO_37
   * The activateClubPopup() is invoked to render the enire activate club popup UI
   */
  const activateClubPopup = () => {
    return (
      <>
        <Grid
          item
          id="activateClub"
          data-testid="ActivateClubPopupId"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="activateClub"
        // aria-hidden="true"
        >
          <Typography className={classes.ps2}>
            By activating Club, your regular payment with LDW will increase by
            <Typography
              variant="caption"
              className={`${classes.RAClabeltxtstyle} ${classes.font16} ${classes.bold} ${classes.ms1}`}
            >
              ${clubAmount}
            </Typography>
            . Do you want to activate Club?
          </Typography>

          <Grid
            item
            md={12}
            className={`${classes.textright} ${classes.modalfooter} ${classes.mt3}`}
          >
            <RACButton
              className={classes.mx1}
              variant="outlined"
              color="primary"
              data-testid="ActivateLDWCancelId"
              onClick={() =>
                setclubStatusPopup({ ...clubStatusPopup, activateClub: false })
              }
              data-bs-dismiss="modal"
            >
              No
            </RACButton>
            <RACButton
              type='button'
              className={classes.mx1}
              color="primary"
              variant="contained"
              data-testid="continueActivateLDWId"
              onClick={() => activateClubYesbtn()}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };

  /**
   *
   * @param action
   * PS_CO_TC_NO_53 -PS_CO_TC_NO_55
   * When the continue button is clicked on the deactivate club popup the deActivatePopupYesHandleChange() method
   * is invoked.
   */
  const deActivatePopupYesHandleChange = (action: ClubStatusPopup) => {

    if (action.customerRedirection) {
      history.push({
        pathname: `/customer/update/${customerId}/customer`,
      });
    } else if (action.deactivateClub) {
      const storeNumber = window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER);
      const clubAgreement = agreementDetails.find(
        (x) => x.agreementType == CONSTANTS.CLUB && x.storeNumber == storeNumber
      );
      const agreements = [...agreementDetails];

      if (clubAgreement) {
        setClubBtnStatus(CONSTANTS.STATUS_ACTIVE);

        const updatedAgreements = agreements.map((x) => {
          if (x.agreementType == CONSTANTS.CLUB) {
            return {
              ...x,
              selected: false,
              disabled: true,
              deactivateClubFlag: true
            };
          }
          return x;
        });
        setAgreementDetails(updatedAgreements);
      }
      else {
        setAgreementDetails(agreements);
      }

      setclubStatusPopup({ ...clubStatusPopup, deactivateClub: false });
      setRenderContext(!renderContext);
    }
  };

  /**
   *
   * @returns
   * PS_CO_TC_NO_51 - PS_CO_TC_NO_52
   * The deactivateClubPopup() method is used to render the deactivate club popup.
   */
  const deactivateClubPopup = () => {
    return (
      <>
        <Grid item md={12}>
          <Grid item md={12} className={classes.textcenter}>
            <Typography className={classes.RACPOPMsg}>
              {clubStatusPopup.deactivateClub
                ? 'By deactivating club, your regular payment will decrease by $' +
                clubAmount +
                '.   Press continue to confirm'
                : clubStatusPopup.customerRedirection
                  ? 'Customer has no active agreement Please deactivate Club in Customer Editor Screen Using Club button'
                  : CONSTANTS.EMPTY_STRING}
            </Typography>
          </Grid>
          <Grid
            container
            className={`${classes.spacerMT4} ${classes.justifycontentcenter}`}
          >
            <RACButton
              color="primary"
              variant="outlined"
              className={`${classes.me2} ${classes.foc}`}
              data-testid="cancelButton"
              onClick={() =>
                setclubStatusPopup({
                  ...clubStatusPopup,
                  deactivateClub: false,
                  customerRedirection: false,
                })
              }
            >
              Cancel
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              data-testid="continueButton"
              onClick={() => deActivatePopupYesHandleChange(clubStatusPopup)}
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const handleOnClose = (
    setclubStatusPopup: React.Dispatch<React.SetStateAction<ClubStatusPopup>>,
    keys: (keyof ClubStatusPopup)[]
  ) => {
    // debugger;
    const newState = { ...clubStatusPopup };
    keys.forEach((x) => {
      newState[x] = false;
    });
    setclubStatusPopup(newState);
    // setclubStatusPopup({...clubStatusPopup,[x]:false})
    // setclubStatusPopup((prevState) => {
    //   const newState = { ...prevState };

    //   // return newState;
    // });
  };

  return (
    <>
      {masterloader ? (
        <Grid className={classes.masterLoaderActivateClub}>
          <Grid className={classes.LoaderActivateClub}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      <RACButton
        className={classes.ms2}
        color="primary"
        variant="contained"
        data-testid='clubButton'
        onClick={() => handleClubOnClick(clubBtnStatus)}
        disabled={disableFlag}
      >
        {clubBtnStatus == CONSTANTS.CLUB_PENDING
          ? CONSTANTS.CLUB_IS_PENDING
          : clubBtnStatus == CONSTANTS.DEACTIVATE_CLUB
            ? CONSTANTS.DEACTIVATE_CLUB_DESCRIPTION
            : CONSTANTS.ACTIVATE_CLUB}
      </RACButton>

      <RACModalCard
        className={classesToggleClub.activateClubPopupStyle}
        isOpen={clubStatusPopup.activateClub}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleOnClose(setclubStatusPopup, ['activateClub'])}
      // children={activateClubPopup()}
      >
        {activateClubPopup()}
      </RACModalCard>

      <RACModalCard
        isOpen={
          clubStatusPopup.deactivateClub || clubStatusPopup.customerRedirection
        }
        closeIcon={true}
        borderRadius="20px"
        maxWidth="xs"
        onClose={() =>
          handleOnClose(setclubStatusPopup, [
            'deactivateClub',
            'customerRedirection',
          ])
        }
      // children={deactivateClubPopup()}
      >
        {deactivateClubPopup()}
      </RACModalCard>
    </>
  );
}