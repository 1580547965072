/*eslint-disable react-hooks/exhaustive-deps*/
/**
 * PS_APC_02
 * Import the required packages, components and interface
 */
import React, { useContext, useEffect, useState } from 'react';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import {
  RACTextbox,
  RACButton,
  RACCheckBox,
  Grid,
} from '@rentacenter/racstrap';
import { UpdateAlert } from '../../api/user';
import { useParams } from 'react-router-dom';
import { ParamType } from '../interface/commonInterface';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { alertPopupStyle } from '../stylesJS/alertPopupStyle';
import {
  AlertPopupProps,
  ArrayObj,
  Payload,
  ExistingAlert,
  Alerts,
} from '../interface/alertPopupInterface';
import CONSTANTS from '../constants/constant';
import { agreementContext } from '../context/PaymentInformationContext';

/**
 * PS_APC_03 - PS_APC_38
 * Initialize AlertPopupComponent with props
 */
export function AlertPopupComponent(props: AlertPopupProps) {
  /**
   * PS_APC_04 - PS_APC_09
   * Variable initialization
   * api call using useMutation
   */
  const classes = takePaymentStyles();
  const navLinkClass = alertPopupStyle();
  const existingAlert = props?.customerAlert;
  const activeExistingAlert = existingAlert?.filter(
    (el) => el.alertCleared == CONSTANTS.ZERO_NUMBER
  );
  const sortedAlerts = props?.alerts?.sort((a: Alerts, b: Alerts) => {
    if (
      a.alertTypeId === CONSTANTS.THREE_STRING &&
      b.alertTypeId !== CONSTANTS.THREE_STRING
    ) {
      return 1;
    } else if (
      a.alertTypeId !== CONSTANTS.THREE_STRING &&
      b.alertTypeId === CONSTANTS.THREE_STRING
    ) {
      return -1;
    } else {
      return a.alertTypeDescEn.localeCompare(b.alertTypeDescEn);
    }
  });
  const [alertsArr, setAlertsArr] = useState<ArrayObj[]>([]);
  const [selectedId, setSelectedId] = useState<number[]>([]);
  const [textBoxOpen, setTextBoxOpen] = useState<boolean>(false);
  const [customAlertText, setCustomAlertText] = useState<string>(
    CONSTANTS.EMPTY_STRING
  );
  const { customerId } = useParams<ParamType>();
  const queryClient = useQueryClient();
  const { coWorkerDetails } = useContext(agreementContext);
  /**
   * PS_APC_09
   * Making UpdateAlert api call using useMutation
   * onSuccess it call getCustomerAlert api and closes the popup
   */
  const mutation = useMutation({
    mutationFn: (payload: Payload) => UpdateAlert(payload, customerId),
    onSuccess: () => {
      queryClient.invalidateQueries([CONSTANTS.API_KEY_GET_CUSTOMER_ALERT]);
    },
  });
  /**
   * PS_APC_10 - PS_APC_13
   * Declare an useEffect
   * It sets the existing alert and displayes it as checked
   */
  useEffect(() => {
    if (activeExistingAlert) {
      const idArray: number[] = [];
      const arrayObj: ArrayObj[] = [];
      activeExistingAlert?.forEach((el: ExistingAlert) => {
        if (el?.alertTypeId != CONSTANTS.THREE_NUMBER) {
          idArray.push(Number(el?.alertTypeId));
          const obj = {
            alertTypeId: Number(el?.alertTypeId),
            customerAlertId: Number(el?.customerAlertId),
            alertText: CONSTANTS.EMPTY_STRING,
            alertClear: el?.alertCleared,
          };
          arrayObj.push(obj);
        }
      });
      setSelectedId(idArray);
      setAlertsArr(arrayObj);
    }
  }, []);

  const existingAlertUpdate = (id: number, value: number) => {
    const arr: ArrayObj[] = [];
    alertsArr.forEach((el: ArrayObj) => {
      if (el.alertTypeId == id) arr.push({ ...el, alertClear: value });
      else arr.push({ ...el });
    });
    return arr;
  };

  /**
   * PS_APC_16 - PS_APC_30
   * This function gets incoked when coWorker checks or unChecks the checkBox
   * @param e
   */
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const alertid = Number(e.target.id);
    const index = selectedId.indexOf(alertid);
    const sameAsExisting = activeExistingAlert.findIndex(
      (el) => el.alertTypeId == alertid
    );
    if (alertid == 3 && !textBoxOpen) setTextBoxOpen(true);
    if (index != -1) {
      alertObjRemove(alertid, sameAsExisting);
    } else {
      if (alertid != 3) {
        if (sameAsExisting != -1) setAlertsArr(existingAlertUpdate(alertid, 0));
        else {
          let customerAlertId: number | null = CONSTANTS.NULL;
          const inactiveAlertIndex = existingAlert.findIndex(
            (el) => el.alertTypeId == alertid
          );
          if (inactiveAlertIndex != -1)
            customerAlertId = Number(
              existingAlert[inactiveAlertIndex].customerAlertId
            );
          const arr = {
            alertTypeId: Number(alertid),
            customerAlertId: customerAlertId,
            alertText: CONSTANTS.EMPTY_STRING,
            alertClear: 0,
          };
          setAlertsArr([...alertsArr, arr]);
        }
      }
      setSelectedId([...selectedId, alertid]);
    }
  };

  const alertObjRemove = (alertid: number, sameAsExisting: number) => {
    const idArray = selectedId.filter((el: number) => el != alertid);
    setSelectedId(idArray);

    if (sameAsExisting != -1) setAlertsArr(existingAlertUpdate(alertid, 1));
    else setAlertsArr((prev) => prev.filter((el) => el.alertTypeId != alertid));

    if (alertid == 3) {
      setTextBoxOpen(false);
      setCustomAlertText(CONSTANTS.EMPTY_STRING);
    }
  };
  /**
   * PS_APC_31 - PS_APC_34
   * This function gets invoked when coWorker clicks on save button
   * This also invokes updateAlert api by using mutation
   */
  const handleSubmit = async () => {
    if (selectedId.includes(CONSTANTS.THREE_NUMBER)) {
      alertsArr.push({
        alertTypeId: 3,
        customerAlertId: null,
        alertText: customAlertText,
        alertClear: 0,
      });
    }
    const payload: Payload = {
      alertTypes: alertsArr,
      customerId: Number(customerId),
      userId: coWorkerDetails.employeeId,
    };
    mutation.mutateAsync(payload);
    props.setAlertFlag(false);
    props.setLoader(true);
  };
  /**
   * PS_APC_14 - PS_APC_15
   * This is used to bind the alerts in UI
   * @returns
   */
  const checkboxBind = () => {
    return (
      <>
        {sortedAlerts.map((item: Alerts, index: number) => (
          <Grid className={`${classes.p0} ${classes.mb3}`} key={index}>
            <RACCheckBox
              name="assignAlertChecked"
              id={item.alertTypeId}
              checked={selectedId?.includes(Number(item.alertTypeId))}
              onChange={(e) => handleCheck(e)}
            />
            {item.alertTypeDescEn}
          </Grid>
        ))}
      </>
    );
  };
  /**
   * PS_APC_35 - PS_APC_38
   * It binds textBox, alerts with save and cancel button
   */
  return (
    <Grid>
      {checkboxBind()}
      {textBoxOpen ? (
        <RACTextbox
          isCurrency={false}
          className={classes.mb4}
          id="CustomAlert"
          value={customAlertText}
          OnChange={(e) => setCustomAlertText(e.target.value.trim())}
        />
      ) : (
        <></>
      )}

      <Grid
        item
        md={12}
        className={`${classes.textright} ${classes.modalfooter}`}
      >
        <RACButton
          data-testid="cancel-button"
          size="small"
          data-bs-dismiss="modal"
          aria-label="Close"
          className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navLinkClass.paymentBtnHover}`}
          variant="outlined"
          color="primary"
          onClick={() => props.setAlertFlag(false)}
        >
          Cancel
        </RACButton>
        <RACButton
          data-testid="save-button"
          variant="contained"
          color="primary"
          isRounded={false}
          disabled={
            (selectedId.length &&
              !selectedId.includes(CONSTANTS.THREE_NUMBER)) ||
            (selectedId.includes(CONSTANTS.THREE_NUMBER) &&
              customAlertText != CONSTANTS.EMPTY_STRING)
              ? false
              : true
          }
          className={`${classes.paymentSmallBtn1}`}
          onClick={() => handleSubmit()}
        >
          Save
        </RACButton>
      </Grid>
    </Grid>
  );
}
