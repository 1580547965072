/* eslint-disable prettier/prettier */
export const ProductType = 'RPDPLACEHOLDER';

export enum ProductPermissions {
  PLACEHOLDER = 'PLACEHOLDER',
}
export const AMOrigin = "am-customer";
export const AccountManagementURLParam = `?origin=${AMOrigin}`;

export const API_ERROR_MESSAGE = 'Unable to fetch the data';

export enum CONTENT_TYPE {
  pdf = 'application/pdf',
  html = 'text/html',
  jpg = 'image/jpg',
  jpeg = 'image/jpeg',
  jpe = 'image/jpe',
  gif = 'image/gif',
  png = 'image/png',
  bmp = 'image/bmp',
}

export const ParentHeaderData = [
  "Accordian",
  "Receipt ID",
  "Receipt Date",
  "Action",
];

export const CardDetails = [{
  cardNumber: "123456",
  authCode: "741852",
  approvedAmount: "10.85"
}]

export const CardHeaderData = [
  "Card #",
  "Authorization Code",
  "Approved Amount",
  "Action"
]

export const ChildHeaderData = [
  "Check Box",
  "Revenue Source",
  "Revenue Type",
  "Description",
  "Next Due Date",
  "Amount To Return",
];

export const receiptDetailsExceptionsObject = {
  badRequest: false,
  serverError: false,
  notEligible: false
};

export const CCAmountObject = {
  lastPaymentReceived: "0.00",
  totalCashPayEligible: "0.00",
  totalRefundCOA: "0.00",
  totalCardPayEligible: "0.00",
  totalCardPayAmtReversed: "0.00"
} // PC-01 All the constant data are declared here and it's exported to other components.

export const tenderTypeUsedObject = {
  cash: false,
  check: false,
  card: false
}

export const exceptionModalObject = {
  badRequest: false,
  serverError: false,
};

export const loadingModalObject = {
  document: false,
  reversePay: false
}

export const ReverseReasonObject = {
  reverseReason: "",
  reasonInformation: "",
};

export const PostReversalObject: any = {
  storeNumber: "",
  customerId: "",
  reasonType: "",
  reasonText: "",
  receiptInfo: []
}

export const ModalRadius = "25px !important";

export enum ReversePayConfirmationMessage {
  RefundConfirmationMessage = "Reversing the payment will roll back the due date. Do you want to continue ?",
  CustomerAvailableConfirmationMessage = "Is the customer available? For Customer Service F.T./Ext. or Worry Free Guarantee the customer must be available. The payment reversal function will cancel if the customer is not available.",
}

export const GridLoader = [0, 0, 0, 0, 0, 0, 0];

export interface ParamType {
  customerId: string;
  agreementId: string;
}

export const StoreExceptionType = [
  { "abbreviation": "AGDC", "description": "Agreement declined" },
  { "abbreviation": "APSO", "description": "Agreement Not Digitally Signed By Customer" },
  { "abbreviation": "BNPRSNT", "description": "Agreements are being transferred without both parties being present." },
  { "abbreviation": "APP", "description": "Approval Performed By Same Person" },
  { "abbreviation": "EODOS", "description": "Auto EOD Final Drop Over or Short" },
  { "abbreviation": "BOS", "description": "Bank Over Short" },
  { "abbreviation": "CPO", "description": "Cash Price Override" },
  { "abbreviation": "COP", "description": "Cash Sale Outside BusParam" },
  { "abbreviation": "CPA", "description": "Changed Price On Agreement" },
  { "abbreviation": "CRT", "description": "Changed Rate or Term on Agreement" },
  { "abbreviation": "CB", "description": "Chargeback Activity" },
  { "abbreviation": "CLM", "description": "Claim Extensions" },
  { "abbreviation": "CPOA", "description": "Competitor Price Override On Agreement." },
  { "abbreviation": "CFWV", "description": "Convenience Fee Waived" },
  { "abbreviation": "DEB", "description": "Decision Engine Bypassed" },
  { "abbreviation": "DERLIM", "description": "DE Request Limit Reached" },
  { "abbreviation": "EPD", "description": "EPO Payout Discounted" },
  { "abbreviation": "FDOS", "description": "Final Drop Over Short" },
  { "abbreviation": "FDG", "description": "Free Days Given" },
  { "abbreviation": "POC", "description": "Home Office PO Receiving or Charge Off" },
  { "abbreviation": "IDC", "description": "Inventory Depreciation Cap" },
  { "abbreviation": "INS4A", "description": "Item Not Scanned In 4 Consecutive Audits" },
  { "abbreviation": "LPT", "description": "Large Payment" },
  { "abbreviation": "LGL7", "description": "Legal agreement due within 7 days" },
  { "abbreviation": "LGL90", "description": "Legal agreement was extended beyond 90 days" },
  { "abbreviation": "LNR30", "description": "Loaners – 30 days on rent" },
  { "abbreviation": "LNR", "description": "Loaners with no item in service" },
  { "abbreviation": "MIA", "description": "Missing Item On Inventory Audit" },
  { "abbreviation": "NER", "description": "Negative Receipt" },
  { "abbreviation": "NMRB", "description": "No manual receipt books are assigned to store" },
  { "abbreviation": "NSF", "description": "NSF Check Activity" },
  { "abbreviation": "AEET", "description": "Number of Audit Exceptions Exceeds Threshold" },
  { "abbreviation": "OSR", "description": "Over Short Drop Reconciliation" },
  { "abbreviation": "PDO", "description": "Paidout" },
  { "abbreviation": "PFEEW", "description": "Processing Fee Waived" },
  { "abbreviation": "PROMODIS", "description": "Promo Discount Used" },
  { "abbreviation": "RAO", "description": "Rate Of Agreement Override" },
  { "abbreviation": "RFN", "description": "Refund" },
  { "abbreviation": "RFO", "description": "Reinstatement Fee Override" },
  { "abbreviation": "REX", "description": "Rental Extension/Free Time" },
  { "abbreviation": "RAR", "description": "Returned and Redelivered" },
  { "abbreviation": "RVPO", "description": "Reverse PO Receipt" },
  { "abbreviation": "SAME4", "description": "Same person doing inventory in store 4 times in a row" },
  { "abbreviation": "SCC", "description": "Schedule Change" },
  { "abbreviation": "SCR", "description": "Scrap Item" },
  { "abbreviation": "SDO", "description": "Security Deposit Override" },
  { "abbreviation": "ANC", "description": "Store did not complete the required inventory audit for today" },
  { "abbreviation": "ANS", "description": "Store did not started the required inventory audit for yesterday" },
  { "abbreviation": "AUD", "description": "Store Managers must do an audit once a week" },
  { "abbreviation": "SWO", "description": "Switchout" },
  { "abbreviation": "TMM", "description": "Three Or More Messages" },
  { "abbreviation": "TRRAC", "description": "Transfer Reinstatement Rights Agreement Created" },
  { "abbreviation": "UIR", "description": "Unreserved Inventory Reason" },
  { "abbreviation": "UIISN", "description": "Update Inventory Item Serial Number" },
  { "abbreviation": "WLF", "description": "Waived Late Fee" },
  { "abbreviation": "WFO", "description": "Waiver Fee Override" },
  { "abbreviation": "CFT", "description": "Capped Free Time" }
]

/**
 * SLA module name Mapper used for two factor
 */

const SLA_MODULE_NAME: Map<string, string> = new Map();
SLA_MODULE_NAME.set('EMPLOYEE_PURCHASE_PLAN_SLA', 'RET EXT EPP');
SLA_MODULE_NAME.set('CAPPED_FREE_TIME_SLA', 'CappedFreeTime');
SLA_MODULE_NAME.set('APPLY_FREE_TIME_SLA', 'ApplyFreeTime');
SLA_MODULE_NAME.set('CUSTOMER_RETENTION_SLA', 'CustomerRetention');
SLA_MODULE_NAME.set('RAC_EXCHANGE_SLA', 'RACExchange');
SLA_MODULE_NAME.set('NSF_PAYMENT_SLA', 'NSFPayments');
SLA_MODULE_NAME.set('CCCB_PAYMENT_SLA', 'CCCBPayments');
SLA_MODULE_NAME.set('CARRY_WAIVE_LATE_FEE_SLA', 'Adjust Late Fee');
SLA_MODULE_NAME.set('ACCEPT_EPO_SLA', 'Accept Epo');
SLA_MODULE_NAME.set('ACCEPT_PAYMENT_SLA', 'Accept Payment');

/**
 * Payment origin mapper
 */

const PAYMENT_ORIGIN_TYPE: Map<string, string> = new Map();
PAYMENT_ORIGIN_TYPE.set('IN_STORE', 'S');
PAYMENT_ORIGIN_TYPE.set('IN_PHONE', 'H');
PAYMENT_ORIGIN_TYPE.set('TXT2PAY_STORE_ORIGIN', 'STORET2P');
PAYMENT_ORIGIN_TYPE.set('TXT2PAY_PHONE_ORIGIN', 'PHONET2P');

/**
 * default payment origin for text to pay card file
 */
const IN_STORE_T2P_ORIGIN = 'STORET2P';

/**
 * Agreement type that are not allowed to perform Carry Rent
 */
const NOT_ALLOWED_AGREEMENT_TYPE = ['EPO Rent', 'PIF Rent', 'PIF Note', 'Note Payment', 'Club', 'Promo'];

/**
 * Agreement type of this type then the common module type should be updated during Edit Schedule
 */
const EDIT_SCHEDULE_AGREEMENT_TYPE = ['EPO Rent', 'PIF Rent'];

/**
 * Final payment agreements having next due date not as Date
 */
const FINAL_PAYMENT_AGREEMENT = ['SAC', 'EPO', 'PIF'];

export { SLA_MODULE_NAME, PAYMENT_ORIGIN_TYPE, IN_STORE_T2P_ORIGIN, NOT_ALLOWED_AGREEMENT_TYPE, FINAL_PAYMENT_AGREEMENT, EDIT_SCHEDULE_AGREEMENT_TYPE }