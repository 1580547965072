/*eslint-disable react-hooks/exhaustive-deps*/
/**
 * PS_PT_02
 * Import the required packages, components, interfaces
 */
import React, { useContext, useEffect, useState } from 'react';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import {
  RACButton,
  RACModalCard,
  Grid,
  Typography,
  Card,
  Box,
  CircularProgress,
  RACAccordion,
} from '@rentacenter/racstrap';
import { agreementContext } from '../context/PaymentInformationContext';
import { GetAlert, GetCustomerAlert, UpdateAlert } from '../../api/user';
import { AlertPopupComponent } from './AlertPopupComponent';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as ExpandCancel } from '../../assets/images/alert-cancel-icon.svg';
import { ReactComponent as ExpandAlert } from '../../assets/images/expand-alert-icon.svg';
import { CustomerPaymentInformation } from './CustomerPaymentInformation';
import { CustomerInformation } from './CustomerInformation';
import { ParamType } from '../interface/commonInterface';
import {
  UpdateAlertPayload,
  CustomerAlert,
} from '../interface/paymentTriangleInterface';
import { paymentTriangleStyle } from '../stylesJS/paymentTriangleStyle';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import CONSTANTS from '../constants/constant';
import { Alerts } from '../interface/alertPopupInterface';
import { AgreementContextValue } from '../interface/contextInterface';
/**
 * PS_PT_03 - PS_PT_31
 * Declaring PaymentTriangleComponent()
 * That displays customer details and alerts in the page load.
 * @returns
 */
export default function PaymentTriangleComponent() {
  /**
   * PS_PT_04 - PS_PT_11
   * Declaring and initializing variables
   * Making api calls using useQueries and useMutation
   */
  const classes = takePaymentStyles();
  const useStyleClasses = paymentTriangleStyle();
  const history = useHistory();
  const { customerId } = useParams<ParamType>();
  const {
    customerInfo,
    coWorkerDetails,
    paymentHistoryResponse,
    featureFlags,
  } = useContext<AgreementContextValue>(agreementContext);
  const [customerAlert, setCustomerAlert] = useState<CustomerAlert[]>([]);
  const [loaderFlag, setLoaderFlag] = useState<boolean>(false);
  const [alertFlag, setAlertFlag] = useState<boolean>(false);
  const [alerts, setAlerts] = useState<Alerts[]>([]);
  const queryClient = useQueryClient();
  const totalRevenueAmount =
    paymentHistoryResponse?.payHistoryAgr?.value?.customerInfo
      ?.totalRentalRevenue;
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  /**
   * PS_PT_09
   * Making GetCustomerAlert and GetAlert api calls using useQueries
   */
  const [customerAlertResponse, alertResponse] = useQueries({
    queries: [
      {
        queryKey: [CONSTANTS.API_KEY_GET_CUSTOMER_ALERT],
        queryFn: () => GetCustomerAlert(customerId),
        onSuccess: () => setLoaderFlag(false),
      },
      {
        queryKey: [CONSTANTS.API_KEY_GET_ALERT],
        queryFn: () => GetAlert(customerId),
      },
    ],
  });
  /**
   * PS_PT_10 - PS_PT_11
   * Making UpdateAlert api call using useMutation
   * In the success state of UpdateAlert call GetCustomerAlert again using invalidateQueries
   */
  const mutation = useMutation({
    mutationFn: (payload: UpdateAlertPayload) =>
      UpdateAlert(payload, customerId),
    onSuccess: () => {
      queryClient.invalidateQueries([CONSTANTS.API_KEY_GET_CUSTOMER_ALERT]);
      setLoaderFlag(false);
    },
    onError: () => {
      setLoaderFlag(false);
    },
  });
  /**
   * PS_PT_12 - PS_PT_13
   * Declare UseEffect with dependency as customerAlertResponse.data, alertResponse.data
   * Call getCustomerAlertDetails on success of the dependency
   */
  useEffect(() => {
    if (customerAlertResponse.data && alertResponse.data)
      getCustomerAlertDetails();
  }, [customerAlertResponse.data, alertResponse.data]);
  /**
   * PS_PT_14
   * Declare a function as getCustomerAlertDetails
   * It sets the customer alert and alert to state variable
   */
  const getCustomerAlertDetails = async () => {
    setCustomerAlert(
      customerAlertResponse?.data.customerAlertByIdDTO?.value?.customeralert ??
        []
    );
    setAlerts(alertResponse.data.alert);
  };
  /**
   * PS_PT_17 - PS_PT_19
   * This bindCustomerAlert() bind the customer alert to UI
   * @returns
   */
  const bindCustomerAlert = () => {
    return customerAlert?.map(
      (alert: CustomerAlert, index: string | number) => {
        if (alert.alertCleared == CONSTANTS.ZERO_NUMBER)
          return (
            <span
              className={`${classes.alertWidgetStyle} ${classes.me2}`}
              key={index}
            >
              {alert.alertTypeId !== CONSTANTS.THREE_STRING &&
                alert.alertTypeId !== CONSTANTS.THREE_NUMBER && (
                  <a
                    onClick={() =>
                      history.push({
                        pathname: `/customer/update/${customerId}/customer?alertTypeId=${alert.alertTypeId}`,
                      })
                    }
                    className={classes.me2}
                  >
                    <ExpandAlert></ExpandAlert>
                  </a>
                )}
              {alert.alertTypeDescEn == CONSTANTS.OTHER
                ? alert.alertText
                : alert.alertTypeDescEn}
              <a className={classes.ms2}>
                <ExpandCancel
                  onClick={() => removeAlert(alert.customerAlertId)}
                ></ExpandCancel>
              </a>
            </span>
          );
      }
    );
  };
  /**
   * PS_PT_20 - PS_PT_25
   * This function removeAlert removes alert when coWorker clicks on cross icon
   * @param alertId
   */
  const removeAlert = async (alertId: string) => {
    setLoaderFlag(true);
    const removedAlert: CustomerAlert | undefined = customerAlert.find(
      (eachAlert: CustomerAlert) => eachAlert.customerAlertId === alertId
    );
    const payload: UpdateAlertPayload = {
      alertTypes: [
        {
          alertTypeId: Number(removedAlert?.alertTypeId),
          customerAlertId: Number(removedAlert?.customerAlertId),
          alertText: removedAlert?.alertText ?? CONSTANTS.EMPTY_STRING,
          alertClear: CONSTANTS.ONE_NUMBER,
        },
      ],
      customerId: Number(customerId),
      userId: coWorkerDetails.employeeId,
    };
    await mutation.mutateAsync(payload);
  };

  const alertCounter = () => {
    return customerAlert?.length > 0
      ? customerAlert.reduce(
          (count, alert) =>
            alert.alertCleared == CONSTANTS.ZERO_NUMBER ? count + 1 : count,
          0
        )
      : CONSTANTS.ZERO_NUMBER;
  };

  /**
   * PS_PT_26 - PS_PT_31
   * This return statement holds the HTML part for this component
   */
  return (
    <>
      <Card
        className={`${classes.card} ${classes.w100} ${classes.floatLeft}  ${classes.alertwidget}`}
        id="accordionExample"
      >
        <Grid item className={classes.px0}>
          <Grid
            item
            className={`${classes.w100} ${classes.floatLeft}`}
            id="headingOne"
          >
            <Grid container className={classes.px2}>
              <Grid
                className={`${classes.raccollg5} ${classes.paymentAlignCenter} ${classes.floatLeft} ${classes.borderrightstyle}`}
              >
                <Grid
                  item
                  className={`${classes.customerPaymentPadding}  ${classes.floatLeft}`}
                >
                  <Typography
                    component="span"
                    className={`${classes.titletxtstyle} ${classes.bold} ${classes.pe4} ${classes.floatLeft}`}
                  >
                    Customer Payment
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={`${classes.customerNamePadding} ${classes.floatLeft}`}
                >
                  <Box
                    component="span"
                    className={`${classes.labeltxtstyle} ${classes.w100} ${classes.floatLeft}`}
                  >
                    Customer Name
                  </Box>
                  {totalRevenueAmount &&
                  featureFlags.CustomerRewards == CONSTANTS.ONE_STRING &&
                  Number(totalRevenueAmount) >=
                    Number(featureFlags.RewardsEligibilityAmount) ? (
                    <>
                      <div
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                        }}
                      >
                        <span
                          onMouseOver={() => {
                            setShowTooltip(true);
                          }}
                          onMouseOut={() => {
                            setShowTooltip(false);
                          }}
                          style={{
                            backgroundColor: '#198754',
                            color: 'white',
                            padding: '1px 7px',
                            borderRadius: '11px',
                            fontSize: '12px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            history.push(
                              `/customer/update/${customerId}/customer`
                            );
                          }}
                        >
                          {customerInfo?.customerDetails[0]?.firstName +
                            ' ' +
                            customerInfo?.customerDetails[0]?.lastName}
                        </span>
                        {showTooltip && (
                          <div
                            style={{
                              position: 'absolute',
                              bottom: '100%',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              marginBottom: '5px',
                              backgroundColor: '#ECF0F3',
                              color: 'black',
                              padding: '5px',
                              borderRadius: '10px',
                              fontSize: '12px',
                              whiteSpace: 'nowrap',
                              zIndex: 2000,
                              fontWeight: 'bold',
                            }}
                          >
                            Eligible for RAC Select Rewards
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <a
                      className={`${classes.labelbluetxtstyle} ${classes.w100} ${classes.floatLeft}`}
                      onClick={() => {
                        history.push(`/customer/update/${customerId}/customer`);
                      }}
                    >
                      {customerInfo?.customerDetails[0]?.firstName +
                        ' ' +
                        customerInfo?.customerDetails[0]?.lastName}
                    </a>
                  )}
                </Grid>
                <Grid
                  className={`${classes.floatLeft} ${classes.schedulePadding} ${classes.px1}`}
                >
                  <Box
                    component="span"
                    className={`${classes.labeltxtstyle} ${classes.w100} ${classes.floatLeft} ${classes.p1}`}
                  >
                    Schedule :{' '}
                    <span className={`${classes.labelnotxtstyle}`}>
                      {customerInfo?.schedule || CONSTANTS.HYPHEN}
                    </span>
                  </Box>
                  <Box
                    component="span"
                    className={`${classes.labeltxtstyle} ${classes.w100} ${classes.floatLeft} ${classes.p1}`}
                  >
                    Day Paid :{' '}
                    <span className={`${classes.labelnotxtstyle}`}>
                      {customerInfo?.dayPaid || CONSTANTS.HYPHEN}
                    </span>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                item
                className={`${classes.raccollg7} ${classes.paymentAlignCenter} ${classes.floatLeft}`}
              >
                <Grid
                  item
                  className={`${classes.raccollg7} ${classes.floatLeft} ${classes.ps2}`}
                >
                  <Grid item className={`${classes.w100} ${classes.mt2}`}>
                    <React.Fragment>
                      <Typography
                        variant="h4"
                        className={`${classes.font14} ${classes.semibold} ${classes.floatLeft} ${classes.me1}`}
                      >
                        Customer Alerts
                      </Typography>
                      <Typography
                        variant="h4"
                        className={`${classes.font14} ${classes.semibold} ${classes.floatLeft}  ${classes.me4} ${classes.alertcolor}`}
                      >
                        {alertCounter() > 0 && `(${alertCounter()})`}
                      </Typography>
                    </React.Fragment>

                    <Grid item className={classes.alertwidgartcontainer}>
                      {bindCustomerAlert()}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  className={`${classes.raccollg5} ${classes.floatLeft} ${classes.ps2}`}
                >
                  <Grid container>
                    <Grid item className={classes.raccollg4}>
                      {loaderFlag && (
                        <Grid
                          item
                          className={`${classes.textright} ${classes.me2} ${classes.mt2}`}
                        >
                          <CircularProgress className={classes.loaderIcon} />
                        </Grid>
                      )}
                    </Grid>
                    <Grid item className={classes.raccollg6}>
                      <RACButton
                        variant="contained"
                        color="primary"
                        isRounded={false}
                        data-testid="assignAlert"
                        onClick={() => setAlertFlag(true)}
                      >
                        Assign Alert
                      </RACButton>
                    </Grid>
                  </Grid>
                </Grid>
                <RACModalCard
                  isOpen={alertFlag}
                  maxWidth="xs"
                  title="Customer Alerts"
                  onClose={() => {
                    setAlertFlag(false);
                  }}
                  closeIcon={true}
                >
                  <AlertPopupComponent
                    customerAlert={customerAlert}
                    alerts={alerts}
                    setAlertFlag={() => setAlertFlag(false)}
                    setLoader={() => setLoaderFlag(true)}
                  />
                </RACModalCard>
              </Grid>
            </Grid>

            <RACAccordion
              details={
                <>
                  <CustomerInformation /> <CustomerPaymentInformation />
                </>
              }
              accordionProps={{
                classes: {
                  root: useStyleClasses.customerInformationRoot,
                },
              }}
              summaryProps={{
                classes: {
                  root: useStyleClasses.accordionSummaryRoot,
                  content: useStyleClasses.accordionSummaryContent,
                },
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
