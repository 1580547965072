/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import {
    makeStyles,
    Card,
    Grid,
    Typography,
    CardContent,
    RACButton,
    RACModalCard,
    RACSelect,
    RACTextbox,
    ListItem,
    List,
    RACCheckBox,
    CircularProgress,
    RACRadio,
    RACInfoIcon,
    TextareaAutosize,
    RACTable,
    RACTableCell,
    RACTableRow,
    RACCOLOR,
} from '@rentacenter/racstrap';
import { fontSize } from '@mui/system';
import { ReactComponent as AlertIcon } from '../../../assets/images/no-records-found.svg';
import { ReactComponent as NoRecord } from '../../../assets/images/No-records.svg'
import { ReactComponent as LocationIcon } from '../../../assets/images/location-icon.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/images/phone-icon.svg';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import Criteriasearch from './SearchCriteria';
import { getInventorySearch ,GetRole,GetEmployeeID,getPriceQuote} from '../../../api/user'
import DynamicGridComponent from './SearchResult'
import { ReactComponent as DeleteIcon } from "../../../assets/images/layer28.svg";
import { ReactComponent as Deleteicon } from "../../../assets/images/delete.svg";
import { create } from 'lodash';
import { createContext } from 'react';
import { RentaldataContext } from '../../../context/Riscontext';
import CONSTANTS from '../../constants/constant';
import { risPaygridFormation } from '../../../utils/utils';
import RentalSecondFactor from './RentalTwoFactor';
import { CustomPropInterface } from '../../..';
import { ContainerContext } from '../../../app/App';
import { GlobalStyles } from '../../../stylesJS/global';
import { takePaymentStyles } from '../../../stylesJS/makeStyles';
import { agreementContext } from '../../context/PaymentInformationContext';
import { rentalItemStyles } from '../../stylesJS/rentalItemStyle';
import { CustomerInfoRentalItem, GetPriceQuoteReq, iniTxtValues, InventoryItem, InventoryRequest, InventorySearchResp } from '../../interface/rentalItemInterface';
import { DropDownArray, ParamType } from '../../interface/commonInterface';
import { AgreementContextValue } from '../../interface/contextInterface';
import { useParams } from 'react-router-dom';
// export const risResponse = createContext(CONSTANTS.EMPTY_STRING);

export interface SaleItem {
  itemFee: string;
  inventoryDescription: string;
  inventoryNumber: string;
  inventoryId: string;
  salesprice: string;
  type: string;
  Deliveryfee: string;
  salestax: string;
  totalsales: string;
  reason: string;
  DeliverfeeTax?: string;
  editNo?: string;
}

export default function Rentalitemsale() {
    // ;
    const classes2 = takePaymentStyles();
    const history = useHistory();
    const location = useLocation();
    const classes = rentalItemStyles();
    const containerData = useContext(
        ContainerContext
      ) as CustomPropInterface;

       /* RIS_PC_NO_03  creating the instance variable tax, customerID,customerName where it is
          received from Takepayment.tsx
          RIS_PC_NO_13  Declare state variable internalServerController which renders "something Went Wrong "
          in search result.
          Declare state variable loaderController which renders the loading page.
          Decalare state variable Gridloader which renders the loading page
       */
    const {updatedInventory} = useContext(RentaldataContext);
    const { customerId } = useParams<ParamType>();
    const {agreementDetails, setAgreementDetails,customerInfo} = useContext<AgreementContextValue>(agreementContext);
    const [controlRecord, setcontrolRecord] = React.useState<boolean>(false);
    const [internalServerController, setinternalServerController] = React.useState<boolean>(false);
    const [tabledata, settabledata] = useState<InventoryItem[]>([]);
    const [loaderController, setloaderController] = React.useState<boolean>(false);
    const [Gridloader, setGridloader] = React.useState<boolean>(true);
    const [request, setrequest] = useState<InventoryRequest>({} as InventoryRequest);
    const { tax, customerID, customerName,editNo ,editresponse, editIndex}: any = (location && location.state) || CONSTANTS.EMPTY_STRING;

    const [arrradio, setarrradio] = useState<boolean[]>([])
    const [itemselectdata, setitemselectdata] = useState<InventoryItem>({
        inventoryId: CONSTANTS.EMPTY_STRING,
        inventoryNumber: CONSTANTS.EMPTY_STRING,
        inventoryDescription: CONSTANTS.EMPTY_STRING,
        storeNumber: CONSTANTS.EMPTY_STRING,
        departmentsEn: CONSTANTS.EMPTY_STRING,
        departmentsEs: null,
        subDepartmentsEn: CONSTANTS.EMPTY_STRING,
        subDepartmentsEs: null,
        brandName: CONSTANTS.EMPTY_STRING,
        bracketEn: CONSTANTS.EMPTY_STRING,
        bracketEs: null,
        statusEn: CONSTANTS.EMPTY_STRING,
        statusEs: null,
        conditionEn: CONSTANTS.EMPTY_STRING,
        conditionEs: CONSTANTS.EMPTY_STRING,
        remainingValue: CONSTANTS.EMPTY_STRING,
        daysIdle: 0,
        serialNumber: CONSTANTS.EMPTY_STRING,
        modelNumber: CONSTANTS.EMPTY_STRING,
        conditionRefCode: CONSTANTS.EMPTY_STRING,
        rmsItemMasterId: CONSTANTS.EMPTY_STRING,
        inventoryPriceId: CONSTANTS.EMPTY_STRING,
        weeklyRate: CONSTANTS.EMPTY_STRING,
        weeklyTerm: 0,
        cashPrice: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        activeCashPrice: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        latestCashPrice: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        purchaseCost:CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        purchaseDate: CONSTANTS.EMPTY_STRING,
    })
    const [amountdata, setamountdata] = useState<SaleItem>({
            itemFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            inventoryDescription:CONSTANTS.EMPTY_STRING,
            inventoryNumber:CONSTANTS.EMPTY_STRING,
            inventoryId:CONSTANTS.EMPTY_STRING,
            salesprice: CONSTANTS.EMPTY_STRING,
            type: CONSTANTS.EMPTY_STRING,
            Deliveryfee: 'select',
            salestax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            totalsales: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            reason:CONSTANTS.EMPTY_STRING,
            DeliverfeeTax:CONSTANTS.ZREO_TWO_DECIMAL_STRING
    })
    const [customerInfoId,setCustomerInfoId]=useState<CustomerInfoRentalItem>({editNo:editNo});
    const [itemselectFlag, setitemselectFlag] = useState<boolean>(false)
    const [loadPriceCard,setLoadPriceCard] = useState(false)
    let [deliveryFeeApplicable,setDeliveryFeeApplicable]=useState<number>()
    const [alertpopupFlag, setalertpopupFlag] = useState<boolean>(false)
    const [sptxboxFlag, setsptxboxFlag] = useState<boolean>(false)
    const [alertinfopopupFlag, setalertinfopopupFlag] = useState<boolean>(false)
    const [overridepopupflag,setoverridepopupflag]=useState<boolean>(false)
    const [continuebtnPopupFlag,setcontinuebtnPopupFlag]=useState<boolean>(true)
    const [riscontinuebtnFlag,setriscontinuebtnFlag]=useState<boolean>(true)
    const [maximumpopupFlag,setmaximumpopupFlag]=useState<boolean>(false)
    const [minimumpopupFlag,setminimumpopupFlag]=useState<boolean>(false)
    const [TwoFactorCancelClick, setTwoFactorCancelClick] = useState<boolean>(false);
  const [TwoFactorCompleted, setTwoFactorCompleted] = useState<boolean>(false);
  const [SecondFactorOpen, setSecondFactorOpen] = useState<boolean>(false);
  const [CurrentRole, setCurrentRole] = useState<string>(CONSTANTS.EMPTY_STRING);
  const [dropdownArr,setdropdownArr]=useState<DropDownArray[]>([{label:'select',value:'select'}])
  let [price,setprice]=useState(CONSTANTS.EMPTY_STRING)
  const [firstlogin,setFirstlogin]=useState<boolean>(false);
  const [remoteFlag,setRemoteFlag]=useState<boolean>(false);
  let totalPrice=(Number(itemselectdata.cashPrice)).toFixed(2)
  const iniTxtValues={"storeNumbers": CONSTANTS.EMPTY_STRING,
  "itemNumber":CONSTANTS.EMPTY_STRING,
  "serialNumber": CONSTANTS.EMPTY_STRING,
  "bracket":CONSTANTS.EMPTY_STRING,
  "brand":CONSTANTS.EMPTY_STRING,
  "modelNumber":CONSTANTS.EMPTY_STRING,
  "poNumber": null,
  "statusId": 1,
  "subStatusId": [2],
  "conditionId":CONSTANTS.EMPTY_STRING,
  "department": CONSTANTS.EMPTY_STRING,
  "subDepartment": CONSTANTS.EMPTY_STRING,
  "setNumber": 0,
  "isActivePriceTag": true,
  "edit":CONSTANTS.EMPTY_STRING

   }

   const[cancelFlag,setcancelFlag] =useState<boolean>(false)
   const [TxtValues,setTxtValues]=useState<iniTxtValues>(iniTxtValues);
   const{slaRole,setSlaRole}=useContext(RentaldataContext);
   const [deleiveryfeeFlag,setDeliveryfeeFlag]=useState<boolean>(false)
   const [pin,setPin]=useState<number>(0);
//    const {Risresponse,setRisresponse}=useContext(RentaldataContext)
//    const {editresponse,setEditresponse}=useContext(RentaldataContext)

   /**RIS_PC_NO_22 The useEffect checks the condition of itemselected whether it is
    * equal to NEW if condition is true it disables the sales price txt box and
    * renders the alert popup.
    *
    * RIS_PC_NO_23 If the condition is not equal to NEW then the sales price text box
    * enabled and itemselected state variable is updated as true.
    */

    useEffect(() => {

        if(itemselectdata.inventoryId!=undefined && itemselectdata.inventoryId!=CONSTANTS.EMPTY_STRING&&
        itemselectdata.inventoryNumber!=undefined && itemselectdata.inventoryNumber!=CONSTANTS.EMPTY_STRING
     )
     {
         Pageload()
     }

    }, [itemselectdata])
    console.log("NNN", amountdata)

    const Pageload=async()=>{
        if (itemselectdata.conditionRefCode == "NEW") {
            setalertpopupFlag(true)
            setsptxboxFlag(true)
            setitemselectFlag(true)
        } else if (itemselectdata.conditionRefCode == "EXCP" || itemselectdata.conditionRefCode == "ATO") {
            setalertpopupFlag(false)
            setsptxboxFlag(false)
            setitemselectFlag(true)
        }
        setLoadPriceCard(true)
        await loaddata()
        setLoadPriceCard(false)
    }

 const loaddata = async()=>{
  let  inventoryInfo= [
        {
            "inventoryId": Number(itemselectdata.inventoryId),
            "inventoryPriceId": Number(itemselectdata.inventoryPriceId)
        }
    ]
    const GetPriceQuoteReq: GetPriceQuoteReq = {
        storeNumber:String(window.sessionStorage.getItem("storeNumber")) ,
        customerId: Number(customerId),
        agreementType:'RIS',
        fees:amountdata?.editNo=="1"&&Number(amountdata.Deliveryfee)>0?[
            {
             feeType:"DELI",
             feeAmount:String(amountdata.Deliveryfee)
            }
        ]:[],
        sacTotal:amountdata?.editNo=="1"?Number(amountdata.salesprice): Number(Number(itemselectdata.cashPrice)),
        inventoryInfo: inventoryInfo,
      };


      setLoadPriceCard(true)
    const response = await getPriceQuote( GetPriceQuoteReq)
    // setcontrolLoader(false)
    setLoadPriceCard(false)
    console.log("response",response)
    console.log("data",response.data?.deliveryFees?.feeAmounts)

     deliveryFeeApplicable=response?.data?.deliveryFees?.deliveryFeesApplicable
        setDeliveryFeeApplicable(deliveryFeeApplicable)
    if(response?.data?.deliveryFees?.deliveryFeesApplicable==1)
    {
        let delTax=response?.data?.fees?.filter((x)=>x.feeType=="DELI")
        setamountdata({...amountdata,DeliverfeeTax:delTax.length!=0?delTax[0]?.feeAmountTax:CONSTANTS.EMPTY_STRING})


        let delArr=[{label:CONSTANTS.SELECT,value:'select'}]
    for(let i =0;i<response.data?.deliveryFees?.feeAmounts.length;i++)
    {
        let data={label:response.data?.deliveryFees?.feeAmounts[i],value:response.data?.deliveryFees?.feeAmounts[i]}
        delArr.push(data)

    }
    setdropdownArr(delArr);

    }

    totalPrice=(Number(itemselectdata.cashPrice)).toFixed(2)
    let data = {
        itemFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        inventoryDescription: itemselectdata.inventoryDescription,
        inventoryNumber: itemselectdata.inventoryNumber,
        inventoryId:itemselectdata.inventoryId,
        type: CONSTANTS.EMPTY_STRING,
        salesprice:amountdata.editNo!="1"||amountdata.salesprice==CONSTANTS.ZREO_TWO_DECIMAL_STRING?(Number(itemselectdata.cashPrice)).toFixed(2):amountdata.salesprice,
        Deliveryfee: amountdata.Deliveryfee,
        salestax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        totalsales: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        reason:CONSTANTS.EMPTY_STRING,
        editNo:amountdata.editNo!=undefined?amountdata.editNo:editNo
    }
    let salesTax=response?.data?.agreementInitialPayment?.totalTax==undefined?0:response?.data?.agreementInitialPayment?.totalTax;
               let totalSale=Number(data.salesprice) + Number(data.Deliveryfee=='select'?0:data.Deliveryfee) + Number(salesTax)
               setamountdata({...data,salesprice:data.salesprice,salestax:salesTax,totalsales:Number(totalSale).toFixed(2),Deliveryfee:data.Deliveryfee})

 }

    /**RIS_PC_NO_41 This useEffect is triggered when the rentalitemsale page is loaded for the edit purpose */
    useEffect(()=>{

        if(editNo != 0 && Number(editIndex)>0 )
        {
            console.log("editresponse",editresponse)

            let object=[{
                inventoryId:agreementDetails[editIndex]?.inventoryInfo?.inventoryId,
                inventoryNumber:agreementDetails[editIndex]?.inventoryInfo?.inventoryNumber,
                inventoryDescription:agreementDetails[editIndex]?.inventoryInfo?.inventoryDescription,
                modelNumber:agreementDetails[editIndex]?.inventoryInfo?.modelNumber,
                serialNumber:agreementDetails[editIndex]?.inventoryInfo?.serialNumber,
                conditionEn:agreementDetails[editIndex]?.inventoryInfo?.conditionEn,
                conditionRefCode:agreementDetails[editIndex]?.inventoryInfo?.conditionRefCode,
                remainingValue:agreementDetails[editIndex]?.inventoryInfo?.remainingValue,
                conditionId:agreementDetails[editIndex]?.inventoryInfo?.conditionId,
                itemFee:agreementDetails[editIndex]?.inventoryInfo?.itemFee,
                inventoryPriceId: agreementDetails[editIndex]?.inventoryInfo?.inventoryPriceId,
                cashPrice:agreementDetails[editIndex]?.inventoryInfo?.cashPrice,
                purchaseDate:agreementDetails[editIndex]?.inventoryInfo?.purchaseDate,
                purchaseCost:agreementDetails[editIndex]?.inventoryInfo?.purchaseCost,
                Deliveryfee:Number(agreementDetails[editIndex]?.inventoryInfo?.Deliveryfee)==0?'select':agreementDetails[editIndex]?.inventoryInfo?.Deliveryfee,
                salesprice:agreementDetails[editIndex]?.inventoryInfo?.salesprice,
                totalsales: agreementDetails[editIndex]?.inventoryInfo?.totalsales,
                salestax:agreementDetails[editIndex]?.inventoryInfo?.salestax,
                weeklyRate: agreementDetails[editIndex]?.inventoryInfo?.weeklyRate,
                weeklyTerm: agreementDetails[editIndex]?.inventoryInfo?.weeklyTerm,
                editNo:editNo
            }]
            let conditionId=CONSTANTS.EMPTY_STRING

            if (object[0].conditionEn == 'Based on DOR-system push') {
                conditionId = '1';
              } else if (object[0].conditionEn == 'Term pushed up/down by Coworker') {
                conditionId = '5';
              } else if (object[0].conditionEn == 'New - Full Term') {
                conditionId = '6';
              } else {
                conditionId =CONSTANTS.EMPTY_STRING;
              }
            setTxtValues({...TxtValues,conditionId:object[0].conditionId!=CONSTANTS.EMPTY_STRING?object[0].conditionId:conditionId,
                itemNumber:object[0].inventoryNumber,
                modelNumber:object[0].modelNumber,
                serialNumber:object[0].serialNumber,

                })
            settabledata(object)

            setitemselectdata({...itemselectdata,inventoryId:object[0]?.inventoryId, inventoryNumber:object[0]?.inventoryNumber,
                inventoryDescription:object[0].inventoryDescription,modelNumber:object[0]?.modelNumber,serialNumber:object[0]?.serialNumber,
                conditionEn:object[0]?.conditionEn,conditionRefCode:object[0]?.conditionRefCode,remainingValue:object[0]?.remainingValue,weeklyRate:object[0]?.weeklyRate,
                weeklyTerm:object[0].weeklyTerm,inventoryPriceId:object[0]?.inventoryPriceId,cashPrice:object[0].cashPrice,purchaseDate:object[0].purchaseDate,purchaseCost:object[0].purchaseCost})
            setamountdata({...amountdata,itemFee:object[0].itemFee,
                             Deliveryfee:object[0].Deliveryfee,
                             salesprice:object[0].salesprice,
                             totalsales:object[0].totalsales,
                             salestax:object[0].salestax,
                             inventoryDescription:object[0]?.inventoryDescription,
                             editNo:object[0].editNo
                            })
                            setriscontinuebtnFlag(false)
                            // setEditresponse(undefined)

                            // history.replace({ pathname: '/payment1/rentalitemsale',
                            // state: {
                            //     editresponse:{},
                            //         customerID:customerInfo.customerID,
                            //         customerName:customerInfo.customerName,
                            //         editNo:"0"} })

                                    setcancelFlag(true)
        }
    },[])




   /** RIS_PC_NO_24 The useEffect checks whether the amountdata.reason is not equal to
    * null ,if condition true the continue button in override popup is enabled.
    *
   */

    useEffect(() => {
        ;
        if(amountdata.reason!=CONSTANTS.EMPTY_STRING&& amountdata.reason!=null)
        {
            setcontinuebtnPopupFlag(false)
        }else{
            setcontinuebtnPopupFlag(true)
        }
    }, [amountdata])

    /**RIS_PC_NO_30 Once the response is received from the second factor authentication
     * this useEffect is triggered and condition check is done.
     */
    useEffect(()=>{

        if(TwoFactorCompleted && !TwoFactorCancelClick&&remoteFlag==true)
        {   setTwoFactorCancelClick(false)
            setTwoFactorCompleted(false)
            setSecondFactorOpen(false)
            setRemoteFlag(false)
            towFactorCompleted()
        }

        if(TwoFactorCompleted && !TwoFactorCancelClick && firstlogin)
        {
            setTwoFactorCancelClick(false)
            setTwoFactorCompleted(false)
            setSecondFactorOpen(false)
            setFirstlogin(false)
            let response={
                inventoryId:itemselectdata.inventoryId,
                inventoryNumber:itemselectdata.inventoryNumber,
                inventoryDescription:itemselectdata.inventoryDescription,
                modelNumber:itemselectdata.modelNumber,
                serialNumber:itemselectdata.serialNumber,
                conditionEn:itemselectdata.conditionEn,
                conditionRefCode:itemselectdata.conditionRefCode,
                remainingValue:itemselectdata.remainingValue,
                weeklyRate: itemselectdata.weeklyRate,
                weeklyTerm: itemselectdata.weeklyTerm,
                inventoryPriceId: itemselectdata.inventoryPriceId,
                cashPrice:itemselectdata.cashPrice,
                purchaseDate:itemselectdata.purchaseDate,
                purchaseCost:itemselectdata.purchaseCost,
                conditionId:TxtValues.conditionId,
                itemFee:amountdata.itemFee,
                Deliveryfee:amountdata.Deliveryfee==CONSTANTS.SELECT?0:amountdata.Deliveryfee,
                salesprice:amountdata.salesprice,
                totalsales: amountdata.totalsales,
                salestax:amountdata.salestax,
                DeliverfeeTax:amountdata.DeliverfeeTax==CONSTANTS.EMPTY_STRING||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
                reason:amountdata.reason,
                editNo:amountdata.editNo
            }
            // setRisresponse(response)
            agreementDetailsUpdate(response);
        }
        else if(TwoFactorCompleted && !TwoFactorCancelClick && firstlogin==false && !remoteFlag)
        {   setTwoFactorCancelClick(false)
            setTwoFactorCompleted(false)
            setSecondFactorOpen(false)
            handleOnclick()
        }
        console.log("flagg",remoteFlag)

        if(!TwoFactorCompleted && TwoFactorCancelClick)
        {
            setTwoFactorCancelClick(false)
            setTwoFactorCompleted(false)
            setSecondFactorOpen(false)
            setRemoteFlag(false);
            setFirstlogin(false);

        }
    },[TwoFactorCompleted,TwoFactorCancelClick])

    console.log("...Employee",TwoFactorCompleted)
useEffect(()=>{
    const GettingRole = async () => {

        const GetcurrentUser = await GetEmployeeID();
        console.log("GetcurrentUser", GetcurrentUser);
        if (GetcurrentUser.status === 200) {
          const GetcurrentUserResponse = GetcurrentUser.data;
          const EmployeeId = GetcurrentUserResponse.employeeId;
          const GetMenuPayload = {
            coworkerId:EmployeeId,
          };
          const GetMenuDetails = await GetRole(GetMenuPayload);
          console.log("GetMenuDetails", GetMenuDetails);
          if (GetMenuDetails.status === 200) {
            const GetMenuDetailsResponse = GetMenuDetails.data;
            const currentRole = GetMenuDetailsResponse.coworkerProfile.role;
            console.log("currentRole", EmployeeId);
            console.log("currentRolerole", EmployeeId);
            setCurrentRole(currentRole);
          }
        }
      };
      if (containerData !== undefined) {
        console.log("containerData", containerData);
        console.log("containerDataRole", containerData.GetRole());
        setCurrentRole(containerData.GetRole());
      } else {
        GettingRole();
      }

},[containerData])

   /**
    * RIS_PC_NO_13 The searchclick() function is performed inside it req object is created
    * and getInventorysearch() service call is made
    * RIS_PC_NO_14 By making the service call the datas are fetched .
    * RIS_PC_NO_15 Based on the response.status received the condition check and functionality is
    * performed.
    */
    const searchclick = async (val: iniTxtValues) => {
        
        try {
            setloaderController(true);
            setGridloader(true);

        let req = {
            storeNumbers: [String(window.sessionStorage.getItem("storeNumber"))],
            itemNumber: val.itemNumber == CONSTANTS.EMPTY_STRING ? null : [val.itemNumber],
            serialNumber: val.serialNumber.trim(),
            bracket: val.bracket.trim(),
            brand: val.brand.trim(),
            modelNumber: val.modelNumber.trim(),
            poNumber: null,
            statusId: 1,
            subStatusId: [2],
            conditionId:val.conditionId==CONSTANTS.EMPTY_STRING?null:Number(val.conditionId),
            department: CONSTANTS.EMPTY_STRING,
            subDepartment: CONSTANTS.EMPTY_STRING,
            setNumber: 0,
            isActivePriceTag: true

        }
        console.log("IDD",req)
        setitemselectFlag(false)
        setCustomerInfoId({...customerInfoId,editNo:CONSTANTS.ZERO_NUMBER.toString()})
        setamountdata({...amountdata,Deliveryfee:CONSTANTS.ZREO_TWO_DECIMAL_STRING,salesprice:CONSTANTS.ZREO_TWO_DECIMAL_STRING,totalsales:CONSTANTS.ZREO_TWO_DECIMAL_STRING})

        setriscontinuebtnFlag(true)
        setrequest(req)
        const response: InventorySearchResp = await getInventorySearch(req)
        const excistingRentalAgreement=agreementDetails?.filter((x)=>x.agreementType=='Rental Item Sale');

            if (response.status == 400 || response.status == 500) {
                setinternalServerController(true);
                setloaderController(false);
            } else if (response.status == 200 && response.data.response.length > CONSTANTS.ZERO_NUMBER) {
                //setsearchResultContext(response.data.response);
                setloaderController(false);
                // eslint-disable-next-line no-console
                console.log('Response from api', response);

                const ApiResult: InventoryItem[] = response.data.response;
                // eslint-disable-next-line no-console
                console.log('response', ApiResult);
                // eslint-disable-next-line no-console
                console.log('response-array', ApiResult[0]);
                if(excistingRentalAgreement.length!=0)
                {
                    let tabledata1= ApiResult.filter((item) => !agreementDetails.some((agrItem) => agrItem.agreementId === item.inventoryId))
                    settabledata(tabledata1);
                    if(tabledata1.length<=0)
                    {
                        setcontrolRecord(true);
                        setloaderController(false);
                    }
                    console.log("tabledata",updatedInventory,tabledata1)
                }
                else{
                    settabledata(ApiResult);
                }
            } else if (response.data.response.length <= 0) {
                setcontrolRecord(true);
                setloaderController(false);
            }
            if (excistingRentalAgreement.length != 0) {
                let tabledata1 = response.data.response.filter((item) => !agreementDetails.some((agrItem) => agrItem.agreementId === item.inventoryId))
                settabledata(tabledata1);
                console.log("tabledataNew", updatedInventory, tabledata1)
            }
            else {
                settabledata(response.data.response);
            }
            console.log("111res", tabledata)
        }
        catch {
            setinternalServerController(true);
            setloaderController(false);
        }
    }

    /**CanclelBtn() is triggered when co-worker clicks the cancel button in Rental Item Sale page*/
    const cancelBtn = () => {
        // agreementDetailsUpdate();
        history.push({pathname:`/payment1/paymentinformation/${customerId}/0`,
            state:{
                   ristype:"Rental Item Sale"}})
    }
    /**RIS_PC_NO_22 The risRadiobtnonclick() function is triggered when radio
     * button is checked.
     */

    const risRadiobtnonclick = (value: number) => {
            setDeliveryfeeFlag(true)
            const radiobt: boolean[] = arrradio.map((val: boolean, indexNew: number) => {
                return value == indexNew ? !val : false
            })
            setarrradio([...radiobt]);
            tabledata.map((val: InventoryItem, indexNew: number) => {
                if (value == indexNew && radiobt[value] == true) {
                    setamountdata({...amountdata,Deliveryfee:CONSTANTS.SELECT,salesprice:CONSTANTS.ZREO_TWO_DECIMAL_STRING,totalsales:CONSTANTS.ZREO_TWO_DECIMAL_STRING,salestax:CONSTANTS.ZREO_TWO_DECIMAL_STRING})
                    setitemselectdata(val)

                }
                else if (value == indexNew && radiobt[value] == false) {
                    radiobt[value] = true
                    setarrradio([...radiobt])

                }
            })
            setriscontinuebtnFlag(false)



        // }
    }

    /**RIS_PC_NO_25 While clicking the delete icon in the selected item the deletClick() function
     * is triggered
     */
    const deleClick = () => {
        let radiobtn: boolean[] = arrradio.map((val: boolean) => {
            return val == true ? !val : false
        })
        setarrradio([...radiobtn])
        setitemselectdata({
            "inventoryId": CONSTANTS.EMPTY_STRING,
            "inventoryNumber": CONSTANTS.EMPTY_STRING,
            "inventoryDescription": CONSTANTS.EMPTY_STRING,
            "storeNumber": CONSTANTS.EMPTY_STRING,
            "departmentsEn": CONSTANTS.EMPTY_STRING,
            "departmentsEs": null,
            "subDepartmentsEn": CONSTANTS.EMPTY_STRING,
            "subDepartmentsEs": null,
            "brandName": CONSTANTS.EMPTY_STRING,
            "bracketEn": CONSTANTS.EMPTY_STRING,
            "bracketEs": null,
            "statusEn": CONSTANTS.EMPTY_STRING,
            "statusEs": null,
            "conditionEn": CONSTANTS.EMPTY_STRING,
            "conditionEs": CONSTANTS.EMPTY_STRING,
            "remainingValue": CONSTANTS.EMPTY_STRING,
            "daysIdle": 0,
            "serialNumber": CONSTANTS.EMPTY_STRING,
            "modelNumber": CONSTANTS.EMPTY_STRING,
            "conditionRefCode": CONSTANTS.EMPTY_STRING,
            "rmsItemMasterId": CONSTANTS.EMPTY_STRING,
            "inventoryPriceId": CONSTANTS.EMPTY_STRING,
            "weeklyRate": CONSTANTS.EMPTY_STRING,
            "weeklyTerm": 0
        })
        setitemselectFlag(false)
        setriscontinuebtnFlag(true)

    }
    console.log("selected", itemselectdata)
/**
 * RIS_PC_NO_16 The searchresultgrid() function is defined here where the
 * DynamicGridComponent is rendered the tabledata ,request are sended as props
 */
    const searchresultgrid = () => {
        ;
        console.log('Came inside Grid Method');
        if (tabledata !== undefined && tabledata.length > 0) {
            console.log('Inside table bind', tabledata);
            return (
                <div>
                    <DynamicGridComponent
                        tabledata={tabledata}
                        Request={request}
                        settabledata={settabledata}
                        itemselectFlag={itemselectFlag}
                        setitemselectFlag={setitemselectFlag}
                        customerInfo={customerInfoId}
                        itemselectdata={itemselectdata}
                        arrradio={arrradio}
                        setarrradio={setarrradio}
                        risRadiobtnonclick={(e: number) => risRadiobtnonclick(e)}
                    ></DynamicGridComponent>

                </div>
            );
        } else if (internalServerController) {
            return (
                <div>
                    <Typography className={`${classes.title2} ${classes.mt3}  ${classes.srmargin}`}>
                        Search Results
                    </Typography>
                    <div className={`${classes.textCenter} ${classes.mt5}`}>
                        <AlertIcon />
                        <Typography className={`${classes.mb3}`}>
                            Something Went Wrong
                        </Typography>
                    </div>
                </div>
            );
        } else if (controlRecord) {
            return (
                <div>
                    <Typography className={`${classes.title2} ${classes.mt3}  ${classes.srmargin}`}>
                        Search Results
                    </Typography>
                    <div className={`${classes.textCenter} ${classes.mt5}`}>
                        <NoRecord />
                        <Typography className={`${classes.mb3}`}>
                            Search criteria did not return any results
                        </Typography>
                    </div>
                </div>
            );
        }
    };

  /**RIS_PC_NO_22 This popup is rendered while selecting the New condition inventory item */

    const alertpopup = () => {
        return (
            <div>
                <Grid className={classes2.textcenter}>
                    <AlertIcon ></AlertIcon>
                    <Typography className={classes2.racErrorFunction}>
                        No price changes are allowed for New Items.
                    </Typography>
                </Grid>
                <Grid className={classes2.textcenter}>
                    <RACButton
                        autoFocus
                        variant="contained"
                        color="primary"
                        className={classes.me2N}
                        onClick={() => setalertpopupFlag(false)}
                    >
                        OK
                    </RACButton>
                </Grid>
            </div>
        );
    }
  /**RIS_PC_NO_33 once the handleOnclick() function is triggered this popup is rendered */
    const alertinfopopup = () => {
        return (
            <div>
                <Grid className={classes2.textcenter}>
                    {/* <AlertIcon ></AlertIcon> */}
                    <Typography className={classes2.racErrorFunction}>
                        Sale Price is outside business parameters and will create an exception. Do you wish to continue?
                    </Typography>
                </Grid>
                <Grid container  style={{marginLeft:"10px"}}>
                    <Grid item md={4} className={classes.floatLeft}>
                        <RACButton
                         style={{marginLeft:"100px"}}
                            variant="outlined"
                            color="primary"
                            className={`${classes.me1} ${classes.foc}`}
                            onClick={() => yesBtn()}
                        >
                            Yes
                        </RACButton>
                    </Grid>
                    <Grid  item md={4}className={classes.floatRight}>
                        <RACButton
                           style={{marginLeft:"90px", padding:"9% 20%"}}
                            variant="contained"
                            color="primary"
                            className={`${classes.me1} ${classes.foc}`}
                            onClick={()=>{noBtn("NO")}}
                        >
                            No
                        </RACButton>
                    </Grid>

                </Grid>

            </div>
        )

    }
    //RIS_PC_NO_34  In alertInfo popup when click the yes button the yesbtn() is triggered
    const  yesBtn=()=>{
        setalertinfopopupFlag(false)

        let response={
            inventoryId:itemselectdata.inventoryId,
            inventoryNumber:itemselectdata.inventoryNumber,
            inventoryDescription:itemselectdata.inventoryDescription,
            modelNumber:itemselectdata.modelNumber,
            serialNumber:itemselectdata.serialNumber,
            conditionEn:itemselectdata.conditionEn,
            remainingValue:itemselectdata.remainingValue,
            conditionRefCode:itemselectdata.conditionRefCode,
            weeklyRate: itemselectdata.weeklyRate,
            weeklyTerm: itemselectdata.weeklyTerm,
            inventoryPriceId: itemselectdata.inventoryPriceId,
            cashPrice:itemselectdata.cashPrice,
            purchaseDate:itemselectdata.purchaseDate,
            purchaseCost:itemselectdata.purchaseCost,
            conditionId:TxtValues.conditionId,
            itemFee:amountdata.itemFee,
            // ristype:"Rental Item Sale",
            // risNo:1,
            Deliveryfee:amountdata.Deliveryfee==CONSTANTS.SELECT?0:amountdata.Deliveryfee,
            salesprice:amountdata.salesprice,
            totalsales: amountdata.totalsales,
            salestax:amountdata.salestax,
            DeliverfeeTax:amountdata.DeliverfeeTax==CONSTANTS.EMPTY_STRING||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
            reason:amountdata.reason




        }
        agreementDetailsUpdate(response);
     }


 /**RIS_PC_NO_26 when co-worker click the ok button noBtn() is triggered with "maximum" as
  * argument.
   */
    const noBtn=async(val)=>{

        if(val=="default")
        {
            let data={
                itemFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                inventoryDescription: itemselectdata.inventoryDescription,
                inventoryNumber: itemselectdata.inventoryNumber,
                inventoryId:itemselectdata.inventoryId,
                type: CONSTANTS.EMPTY_STRING,
                description: CONSTANTS.EMPTY_STRING,
                salestax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                totalsales: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                reason:CONSTANTS.EMPTY_STRING,
                salesprice:Number(Number(itemselectdata.cashPrice)).toFixed(2),
                Deliveryfee:CONSTANTS.SELECT
            }
            let  inventoryInfo= [
                {
                    "inventoryId": Number(itemselectdata.inventoryId),
                    "inventoryPriceId": Number(itemselectdata.inventoryPriceId)
                }
            ]
            const GetPriceQuoteReq: GetPriceQuoteReq = {
                storeNumber:String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)) ,
                customerId: Number(customerId),
                agreementType:'RIS',
                fees:[],
                sacTotal: Number(data.salesprice),
                inventoryInfo: inventoryInfo,
              };
              setLoadPriceCard(true)
            const response = await getPriceQuote( GetPriceQuoteReq)
            setLoadPriceCard(false)
            console.log("response",response);
            if(response!=undefined)
            {
                let salesTax=response?.data?.agreementInitialPayment?.totalTax==undefined?CONSTANTS.ZERO_NUMBER:response?.data?.agreementInitialPayment?.totalTax;
               let totalSale=Number(amountdata.salesprice) + Number(data.Deliveryfee==CONSTANTS.SELECT?CONSTANTS.ZERO_NUMBER :data.Deliveryfee) + Number(salesTax)
               setamountdata({...amountdata,salesprice:data.salesprice,salestax:salesTax,totalsales:Number(totalSale).toFixed(2),Deliveryfee:data.Deliveryfee})
            }
        }
        else if(val=="minimum")
        {

            let data={
                itemFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                inventoryDescription: itemselectdata.inventoryDescription,
                inventoryNumber: itemselectdata.inventoryNumber,
                inventoryId:itemselectdata.inventoryId,
                // salesprice: itemselectdata.totalPrice,
                type: CONSTANTS.EMPTY_STRING,
                description: CONSTANTS.EMPTY_STRING,
                reason:amountdata.reason,
                salesprice:Number(Number(itemselectdata.cashPrice)).toFixed(2),
                Deliveryfee:amountdata.Deliveryfee
            }
            let  inventoryInfo= [
                {
                    "inventoryId": Number(itemselectdata.inventoryId),
                    "inventoryPriceId": Number(itemselectdata.inventoryPriceId)
                }
            ]
            const GetPriceQuoteReq: GetPriceQuoteReq = {
                storeNumber:String(window.sessionStorage.getItem("storeNumber")) ,
                customerId: Number(customerId),
                agreementType:'RIS',
                fees:deliveryFeeApplicable==1 && amountdata.Deliveryfee !=CONSTANTS.SELECT?[
                    {
                     feeType:"DELI",
                     feeAmount:String(amountdata.Deliveryfee)
                    }
                ]:[],
                sacTotal: Number(data.salesprice),
                // extensionDays: 0,
                inventoryInfo: inventoryInfo,
              };
              setminimumpopupFlag(false)
              setLoadPriceCard(true)
            const response = await getPriceQuote( GetPriceQuoteReq)
            setLoadPriceCard(false)
            console.log("response",response);
            if(response!=undefined)
            {
                let salesTax=response?.data?.agreementInitialPayment?.totalTax==undefined?0:response?.data?.agreementInitialPayment?.totalTax;
               let totalSale=Number(data.salesprice) + Number(amountdata.Deliveryfee=='select'?0:amountdata.Deliveryfee) + Number(salesTax)
               setamountdata({...amountdata,salesprice:data.salesprice,salestax:salesTax,totalsales:Number(totalSale).toFixed(2)})
            }
        }
        else if(val=="maximum")
        {
            let data={
                itemFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                inventoryDescription: itemselectdata.inventoryDescription,
                inventoryNumber: itemselectdata.inventoryNumber,
                inventoryId:itemselectdata.inventoryId,
                type: CONSTANTS.EMPTY_STRING,
                reason:amountdata.reason,
                salesprice:Number(Number(itemselectdata.cashPrice)).toFixed(2),
                Deliveryfee:amountdata.Deliveryfee
            }
            let  inventoryInfo= [
                {
                    "inventoryId": Number(itemselectdata.inventoryId),
                    "inventoryPriceId": Number(itemselectdata.inventoryPriceId)
                }
            ]
            const GetPriceQuoteReq: GetPriceQuoteReq = {
                storeNumber:String(window.sessionStorage.getItem("storeNumber")) ,
                customerId: Number(customerId),
                agreementType:'RIS',
                fees:deliveryFeeApplicable==1 && amountdata.Deliveryfee !=CONSTANTS.SELECT?[
                    {
                     feeType:"DELI",
                     feeAmount:String(amountdata.Deliveryfee)
                    }
                ]:[],
                sacTotal: Number(data.salesprice),
                inventoryInfo: inventoryInfo,
              };
              setmaximumpopupFlag(false)
              setLoadPriceCard(true)
            const response = await getPriceQuote( GetPriceQuoteReq)
            setLoadPriceCard(false)
            console.log("response",response);
            if(response!=undefined)
            {
                let salesTax=response?.data?.agreementInitialPayment?.totalTax==undefined?0:response?.data?.agreementInitialPayment?.totalTax;
               let totalSale=Number(data.salesprice) + Number(amountdata.Deliveryfee=='select'?0:amountdata.Deliveryfee) + Number(salesTax)
               setamountdata({...amountdata,salesprice:data.salesprice,salestax:salesTax,totalsales:Number(totalSale).toFixed(2)})
            }
        }
        else if(val=="NO")
        {
        let data={
            itemFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            inventoryDescription: itemselectdata.inventoryDescription,
            inventoryNumber: itemselectdata.inventoryNumber,
            inventoryId:itemselectdata.inventoryId,
            // salesprice: itemselectdata.totalPrice,
            type: CONSTANTS.EMPTY_STRING,
            salestax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            totalsales: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            reason:CONSTANTS.EMPTY_STRING,
            salesprice:Number(Number(itemselectdata.cashPrice)).toFixed(2),
            Deliveryfee:amountdata.Deliveryfee
        }
        let  inventoryInfo= [
            {
                "inventoryId": Number(itemselectdata.inventoryId),
                "inventoryPriceId": Number(itemselectdata.inventoryPriceId)
            }
        ]
        const GetPriceQuoteReq: GetPriceQuoteReq = {
            storeNumber:String(window.sessionStorage.getItem("storeNumber")) ,
            customerId: Number(customerId),
            agreementType:'RIS',
            fees:[],
            sacTotal: Number(data.salesprice),
            inventoryInfo: inventoryInfo,
          };
          setalertinfopopupFlag(false)
          setLoadPriceCard(true)
        const response = await getPriceQuote( GetPriceQuoteReq)
        setLoadPriceCard(false)
        console.log("response",response);
        if(response!=undefined)
        {
            let salesTax=response?.data?.agreementInitialPayment?.totalTax==undefined?0:response?.data?.agreementInitialPayment?.totalTax;
           let totalSale=Number(amountdata.salesprice) + Number(amountdata.Deliveryfee=='select'?0:amountdata.Deliveryfee) + Number(salesTax)
           setamountdata({...amountdata,salesprice:data.salesprice,salestax:salesTax,totalsales:Number(totalSale).toFixed(2)})
        }
    }

    }
    console.log("***",amountdata)

    /**RIS_PC_NO_34 This popup is rendered co-worker clicks yes button in alertInfo popup.
     * triggered
     */

    const overridepopup=()=>{
        return (
            <div>
                <Grid className={classes.textCenter}>
                    {/* <AlertIcon ></AlertIcon> */}
                    <Typography  variant='h5'
                    style={{marginLeft:"-29%",marginBottom:"1%"}}
                    className={`${classes2.racErrorFunction}${classes.description}`}>
                    Enter reason in Sale price override reason.
                    </Typography>
                    <TextareaAutosize
                                      name='reason'
                                      minRows={5}
                                      className={`${classes.textAreaN} `}
                                       value={amountdata.reason}
                                      onChange={(e:React.ChangeEvent<HTMLTextAreaElement>) => selecteditemHandlechange(e,'reason')}
                                      />
                </Grid>
                <Grid container  style={{marginLeft:"10px"}}>


                    <Grid  className={classes.floatRight}>
                        <RACButton
                           style={{marginLeft:"330px", padding:"6px 5%"}}
                            variant="contained"
                            color="primary"
                            disabled={continuebtnPopupFlag}
                            className={`${classes.me1} ${classes.foc}`}
                            onClick={()=>{overridepopupContinueBtn()}}
                        >
                         Continue
                        </RACButton>
                    </Grid>
                    <Grid item className={classes.floatRight}>
                    <RACButton
                        style={{marginLeft:"50px", padding:"6% 12%"}}
                        variant="outlined"
                        color="primary"
                        className={`${classes.me1} ${classes.foc}`}
                        onClick={() => setoverridepopupflag(false)}
                    >
                        Cancel
                    </RACButton>
                </Grid>

                </Grid>

            </div>
        )


    }

    /**RIS_PC_NO_34 If the sales price is less than the remaining value then the minimumpopup()
     * is rendered.
     */

    const minimumpopup=()=>{
        return (
            <div>
                <Grid className={classes2.textcenter}>
                    <Typography className={classes2.racErrorFunction}>
                    Invalid Sales Price detected! The minimum sales price for your access level is ${price}
                    </Typography>
                </Grid>
                <Grid className={classes2.textcenter}>
                    <RACButton
                        autoFocus
                        variant="contained"
                        color="primary"
                        className={classes.me2N}
                        onClick={() =>{noBtn("minimum")}}
                    >
                        OK
                    </RACButton>
                </Grid>
            </div>
        );

    }
   /**RIS_PC_NO_26 This popup is rendered when sales price entered greater than total price */
    const maximumpopup=()=>{
        return (
            <div>
                <Grid className={classes2.textcenter}>
                    <Typography className={classes2.racErrorFunction}>
                    Sale price is over the maximum value. The sale price must be less than or equal to ${totalPrice}.
                     The sale price will be updated with this maximum value
                    </Typography>
                </Grid>
                <Grid className={classes2.textcenter}>
                    <RACButton
                        autoFocus
                        variant="contained"
                        color="primary"
                        className={classes.me2N}
                        onClick={() =>noBtn("maximum")}
                    >
                        OK
                    </RACButton>
                </Grid>
            </div>
        );

    }

   /**
    * RIS_PC_NO_33 The handleOnclick function is triggedred  once the twofactor authentication completed
    * RIS_PC_NO_35 This function is triggered inside the twoFactorCompleted() based on condition
   */

    const handleOnclick = () => {
        if (itemselectdata.conditionRefCode == "EXCP" || itemselectdata.conditionRefCode == "ATO") {
            if(Number(amountdata.salesprice)!=Number(totalPrice))
            {
                let response={
                    inventoryId:itemselectdata.inventoryId,
                    inventoryNumber:itemselectdata.inventoryNumber,
                    inventoryDescription:itemselectdata.inventoryDescription,
                    modelNumber:itemselectdata.modelNumber,
                    serialNumber:itemselectdata.serialNumber,
                    conditionEn:itemselectdata.conditionEn,
                    remainingValue:itemselectdata.remainingValue,
                    conditionRefCode:itemselectdata.conditionRefCode,
                    weeklyRate: itemselectdata.weeklyRate,
                    weeklyTerm: itemselectdata.weeklyTerm,
                    inventoryPriceId: itemselectdata.inventoryPriceId,
                    cashPrice:itemselectdata.cashPrice,
                    purchaseDate:itemselectdata.purchaseDate,
                    purchaseCost:itemselectdata.purchaseCost,
                    conditionId:TxtValues.conditionId,
                    itemFee:amountdata.itemFee,
                    Deliveryfee:amountdata.Deliveryfee==CONSTANTS.SELECT?0:amountdata.Deliveryfee,
                    salesprice:amountdata.salesprice,
                    totalsales: amountdata.totalsales,
                    salestax:amountdata.salestax,
                    DeliverfeeTax:amountdata.DeliverfeeTax==CONSTANTS.EMPTY_STRING||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
                    reason:amountdata.reason

                }
                agreementDetailsUpdate(response);
            }
        }
        else{
            setalertinfopopupFlag(false);
        }
    }

    /**
     *
     * RIS_PC_NO_25 when co-worker clicks the continue button this function is triggered
     *
     * RIS_PC_NO_26 Inside the condition check is done
     *
     * RIS_PC_NO_27 Condition check is done whether salesprice not equal to total price, and seconFactoropen state variable updated as true.
     *
     */

    const riscontinuebtnOnclick=()=>{

        const rvAmount=Number(itemselectdata.remainingValue)/2 + Number(itemselectdata.remainingValue)

        if(Number(amountdata.salesprice) !==Number(totalPrice) && Number(amountdata.salesprice) > Number(totalPrice) )
        {
            setmaximumpopupFlag(true);
        }
        else
        if(Number(amountdata.salesprice) !==Number(totalPrice)&& Number(amountdata.salesprice) < Number(totalPrice))
        {

            if(CurrentRole=="DMT"||CurrentRole=="DM"||CurrentRole=="RD")
            {
                if(Number(amountdata.salesprice)== 0.00 )
                {
                    price="0.01"
                    setprice(price)
                    setminimumpopupFlag(true)

                }else
                 if(Number(amountdata.salesprice) > rvAmount&& Number(amountdata.salesprice)<=Number(totalPrice)-0.01)
                {   setPin(0);
                    setFirstlogin(true)
                    setSecondFactorOpen(true)


                }else if(Number(amountdata.salesprice) >= 0.01 && Number(amountdata.salesprice)<=rvAmount)
                {
                    setPin(0);
                    setFirstlogin(false)
                    setSecondFactorOpen(true)

                }
            }else if(CurrentRole=="CAR"||CurrentRole=="AM"||CurrentRole=="LAM"){

                if(Number(amountdata.salesprice)>=Number(itemselectdata.remainingValue) &&
                   Number(amountdata.salesprice)<=Number(totalPrice))
                   {
                    setPin(0);
                    setFirstlogin(false)
                    setSecondFactorOpen(true)
                   }
                   else if(Number(amountdata.salesprice)<Number(itemselectdata.remainingValue)){
                    setPin(1);
                    setRemoteFlag(true)
                    setSecondFactorOpen(true)
                   }
            }else if(CurrentRole=="SM"||CurrentRole=="SSM"){
                if(Number(amountdata.salesprice)>=(Number(itemselectdata.remainingValue)/2 )&&
                Number(amountdata.salesprice)<= rvAmount)
                {
                    setPin(0);
                    setFirstlogin(false)
                    setSecondFactorOpen(true)
                }else if(Number(amountdata.salesprice)> rvAmount&& Number(amountdata.salesprice)<=Number(totalPrice))
                {
                    setPin(0);
                    setFirstlogin(true)
                    setSecondFactorOpen(true)

                }
                else  if(Number(amountdata.salesprice)<(Number(itemselectdata.remainingValue)/2 )){
                    setPin(1);
                    setRemoteFlag(true)
                    setSecondFactorOpen(true)
                }
            }

        }
        else{

            let response={
                inventoryId:itemselectdata.inventoryId,
                inventoryNumber:itemselectdata.inventoryNumber,
                inventoryDescription:itemselectdata.inventoryDescription,
                modelNumber:itemselectdata.modelNumber,
                serialNumber:itemselectdata.serialNumber,
                conditionEn:itemselectdata.conditionEn,
                conditionRefCode:itemselectdata.conditionRefCode,
                remainingValue:itemselectdata.remainingValue,
                weeklyRate: itemselectdata.weeklyRate,
                weeklyTerm: itemselectdata.weeklyTerm,
                inventoryPriceId: itemselectdata.inventoryPriceId,
                cashPrice:itemselectdata.cashPrice,
                purchaseDate:itemselectdata.purchaseDate,
                purchaseCost:itemselectdata.purchaseCost,
                conditionId:TxtValues.conditionId,
                itemFee:amountdata.itemFee,
                Deliveryfee:amountdata.Deliveryfee==CONSTANTS.SELECT?0:amountdata.Deliveryfee,
                salesprice:amountdata.salesprice,
                totalsales: amountdata.totalsales,
                salestax:amountdata.salestax,
                DeliverfeeTax:amountdata.DeliverfeeTax==CONSTANTS.EMPTY_STRING||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
                reason:amountdata.reason,
                editNo:amountdata.editNo
            }
            // setRisresponse(response)
            agreementDetailsUpdate(response);
        }
        setcancelFlag(false)

       }

       /**RIS_PC_NO_31 This function is triggered when two factor authentication over.
        * RIS_PC_NO_32 The condition check is done whether salesprice is equal to 0.00 and else if condition is performed
        * RIS_PC_NO_33
        * RIS_PC_NO_35 The condition check is done based on role CAR,LAM,AM
        * RIS_PC_NO_36 The condition check is done based on role SM,SSM
        * RIS_PC_NO_37 else part is performed and the response is send to the payment page.
        */

       const towFactorCompleted=()=>{
        const rvAmount=Number(itemselectdata.remainingValue)/2 + Number(itemselectdata.remainingValue)

        if(Number(amountdata.salesprice) !==Number(totalPrice) && Number(amountdata.salesprice) > Number(totalPrice) )
        {
            setmaximumpopupFlag(true);
        }
        else if(Number(amountdata.salesprice) !==Number(totalPrice)&& Number(amountdata.salesprice) < Number(totalPrice))
        {
            if(slaRole=="DMT"||slaRole=="DM"||slaRole=="RD")
            {
                if(Number(amountdata.salesprice)== 0.00 )
                {
                    price="0.01"
                    setprice(price)
                    setminimumpopupFlag(true)

                }else
                 if(Number(amountdata.salesprice) > rvAmount&& Number(amountdata.salesprice)<=Number(totalPrice)-0.01)
                {
                    // setSecondFactorOpen(true)
                    let response={
                        inventoryId:itemselectdata.inventoryId,
                        inventoryNumber:itemselectdata.inventoryNumber,
                        inventoryDescription:itemselectdata.inventoryDescription,
                        modelNumber:itemselectdata.modelNumber,
                        serialNumber:itemselectdata.serialNumber,
                        conditionEn:itemselectdata.conditionEn,
                        conditionRefCode:itemselectdata.conditionRefCode,
                        remainingValue:itemselectdata.remainingValue,
                        weeklyRate: itemselectdata.weeklyRate,
                        weeklyTerm: itemselectdata.weeklyTerm,
                        inventoryPriceId: itemselectdata.inventoryPriceId,
                        cashPrice:itemselectdata.cashPrice,
                        purchaseDate:itemselectdata.purchaseDate,
                        purchaseCost:itemselectdata.purchaseCost,
                        conditionId:TxtValues.conditionId,
                        itemFee:amountdata.itemFee,
                        Deliveryfee:amountdata.Deliveryfee==CONSTANTS.SELECT?0:amountdata.Deliveryfee,
                        salesprice:amountdata.salesprice,
                        totalsales: amountdata.totalsales,
                        salestax:amountdata.salestax,
                        DeliverfeeTax:amountdata.DeliverfeeTax==CONSTANTS.EMPTY_STRING||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
                        reason:amountdata.reason,
                        editNo:amountdata.editNo
                    }
                    // setRisresponse(response)
                    agreementDetailsUpdate(response);


                }else if(Number(amountdata.salesprice) >= 0.01 && Number(amountdata.salesprice)<=rvAmount)
                {
                    handleOnclick()
                }
            }else if(slaRole=="CAR"||slaRole=="AM"||slaRole=="LAM"){

                if(Number(amountdata.salesprice)>=Number(itemselectdata.remainingValue) &&
                   Number(amountdata.salesprice)<=Number(totalPrice))
                   {
                    handleOnclick()
                   }
                   else if(Number(amountdata.salesprice)<Number(itemselectdata.remainingValue)){
                    price=itemselectdata.remainingValue
                    setprice(price)
                    setminimumpopupFlag(true)
                   }
            }else if(slaRole=="SM"||slaRole=="SSM"){
                if(Number(amountdata.salesprice)>=(Number(itemselectdata.remainingValue)/2 )&&
                Number(amountdata.salesprice)<= rvAmount)
                {
                 handleOnclick()
                }else if(Number(amountdata.salesprice)> rvAmount&& Number(amountdata.salesprice)<=Number(totalPrice))
                {
                    let response={
                        inventoryId:itemselectdata.inventoryId,
                        inventoryNumber:itemselectdata.inventoryNumber,
                        inventoryDescription:itemselectdata.inventoryDescription,
                        modelNumber:itemselectdata.modelNumber,
                        serialNumber:itemselectdata.serialNumber,
                        conditionEn:itemselectdata.conditionEn,
                        conditionRefCode:itemselectdata.conditionRefCode,
                        remainingValue:itemselectdata.remainingValue,
                        weeklyRate: itemselectdata.weeklyRate,
                        weeklyTerm: itemselectdata.weeklyTerm,
                        inventoryPriceId: itemselectdata.inventoryPriceId,
                        cashPrice:itemselectdata.cashPrice,
                        purchaseDate:itemselectdata.purchaseDate,
                        purchaseCost:itemselectdata.purchaseCost,
                        conditionId:TxtValues.conditionId,
                        itemFee:amountdata.itemFee,
                        Deliveryfee:amountdata.Deliveryfee==CONSTANTS.SELECT?0:amountdata.Deliveryfee,
                        salesprice:amountdata.salesprice,
                        totalsales: amountdata.totalsales,
                        salestax:amountdata.salestax,
                        DeliverfeeTax:amountdata.DeliverfeeTax==CONSTANTS.EMPTY_STRING||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
                        reason:amountdata.reason,
                        editNo:amountdata.editNo
                    }
                    agreementDetailsUpdate(response);
                }
                else  if(Number(amountdata.salesprice)<(Number(itemselectdata.remainingValue)/2 )){
                    price=String(Number(itemselectdata.remainingValue)/2)
                    setprice(price)
                    setminimumpopupFlag(true)
                    // setSecondFactorOpen(true)
                }


            }

        }else{

            let response={
                inventoryId:itemselectdata.inventoryId,
                inventoryNumber:itemselectdata.inventoryNumber,
                inventoryDescription:itemselectdata.inventoryDescription,
                modelNumber:itemselectdata.modelNumber,
                serialNumber:itemselectdata.serialNumber,
                conditionEn:itemselectdata.conditionEn,
                conditionRefCode:itemselectdata.conditionRefCode,
                remainingValue:itemselectdata.remainingValue,
                weeklyRate: itemselectdata.weeklyRate,
                weeklyTerm: itemselectdata.weeklyTerm,
                inventoryPriceId: itemselectdata.inventoryPriceId,
                cashPrice:itemselectdata.cashPrice,
                purchaseDate:itemselectdata.purchaseDate,
                purchaseCost:itemselectdata.purchaseCost,
                conditionId:TxtValues.conditionId,
                itemFee:amountdata.itemFee,
                Deliveryfee:amountdata.Deliveryfee==CONSTANTS.SELECT?0:amountdata.Deliveryfee,
                salesprice:amountdata.salesprice,
                totalsales: amountdata.totalsales,
                salestax:amountdata.salestax,
                DeliverfeeTax:amountdata.DeliverfeeTax==CONSTANTS.EMPTY_STRING||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
                reason:amountdata.reason,
                editNo:amountdata.editNo




            }
            agreementDetailsUpdate(response);

        }
        setcancelFlag(false)

       }

   /**RIS_PC_NO_24  selecteditemHandlechange() function is triggered when the value is entered in the
    * sales price text box
   */
    const selecteditemHandlechange = async(val: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, name: string) => {
        ;
        switch (name) {
            case "salesprice":
                let value = String(val.target.value).replace(CONSTANTS.REPLACE_COMMA, CONSTANTS.EMPTY_STRING)
                setamountdata({ ...amountdata, [val.target.name]: value
                })
                break;
            case "Deliveryfee":
                let valueDel = String(val.target.value).replace(CONSTANTS.REPLACE_COMMA, CONSTANTS.EMPTY_STRING)
                    let amount=Number(amountdata.salesprice)

                    let  inventoryInfo= [
                        {
                            "inventoryId": Number(itemselectdata.inventoryId),
                            "inventoryPriceId": Number(itemselectdata.inventoryPriceId)
                        }
                    ]
                    const GetPriceQuoteReq: GetPriceQuoteReq = {
                         storeNumber:String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)) ,
                            customerId: Number(customerId),
                            agreementType:'RIS',
                            fees:deliveryFeeApplicable==1?[
                                {
                                 feeType:"DELI",
                                 feeAmount:valueDel
                                }
                            ]:[],
                            sacTotal:amount,
                            inventoryInfo: inventoryInfo,
                      };
                    setLoadPriceCard(true)
                    const response = await getPriceQuote( GetPriceQuoteReq)
                    setLoadPriceCard(false)
                    console.log("response",response);

                    if(response!=undefined)
                    {
                        let salesTax=response?.data?.agreementInitialPayment?.totalTax==undefined?0:response?.data?.agreementInitialPayment?.totalTax;
                       let totalSale=Number(amountdata.salesprice) + Number(valueDel) + Number(salesTax)
                       let delTax=response?.data?.fees?.filter((x)=>x.feeType=="DELI")
                       setamountdata({...amountdata,salestax:salesTax,DeliverfeeTax:delTax?.length!=0?delTax[0]?.feeAmountTax:CONSTANTS.ZREO_TWO_DECIMAL_STRING,[name]: valueDel,totalsales:Number(totalSale).toFixed(2)
                    })
                    }
                break;
            // let delivTax=val.target.value * props.tax;
            case "reason":
                setamountdata({...amountdata,"reason":val.target.value})


        }

    }

    const handleOnBlur=async(val: React.ChangeEvent<HTMLInputElement>)=>{
        let amount=Number(val.target.value)

        let  inventoryInfo= [
            {
                "inventoryId": Number(itemselectdata.inventoryId),
                "inventoryPriceId": Number(itemselectdata.inventoryPriceId)
            }
        ]
        const GetPriceQuoteReq: GetPriceQuoteReq = {
             storeNumber:String(window.sessionStorage.getItem("storeNumber")) ,
                customerId: Number(customerId),
                agreementType:'RIS',
                fees:deliveryFeeApplicable==1 && amountdata.Deliveryfee !=CONSTANTS.SELECT?[
                    {
                     feeType:"DELI",
                     feeAmount:String(amountdata.Deliveryfee)
                    }
                ]:[],
                sacTotal:amount,
                inventoryInfo: inventoryInfo,
          };
        setLoadPriceCard(true)
        const response = await getPriceQuote( GetPriceQuoteReq)
        setLoadPriceCard(false)
        console.log("response",response);
        if(response!=undefined)
        {
            let salesTax=response?.data?.agreementInitialPayment?.totalTax==undefined?0:response?.data?.agreementInitialPayment?.totalTax;
           let totalSale=Number(amountdata.salesprice) + Number(amountdata.Deliveryfee=='select'?0:amountdata.Deliveryfee) + Number(salesTax)
           setamountdata({...amountdata,salestax:salesTax,totalsales:Number(totalSale).toFixed(2)})
        }

    }

    /**RIS_PC_NO_34 Once the reason is entered and click the continue button overridepopupContinueBtn()
     * function is triggered.
     */

    const overridepopupContinueBtn=()=>{
        let response={
            inventoryId:itemselectdata.inventoryId,
            inventoryNumber:itemselectdata.inventoryNumber,
            inventoryDescription:itemselectdata.inventoryDescription,
            modelNumber:itemselectdata.modelNumber,
            serialNumber:itemselectdata.serialNumber,
            conditionEn:itemselectdata.conditionEn,
            remainingValue:itemselectdata.remainingValue,
            conditionRefCode:itemselectdata.conditionRefCode,
            weeklyRate: itemselectdata.weeklyRate,
            weeklyTerm: itemselectdata.weeklyTerm,
            inventoryPriceId: itemselectdata.inventoryPriceId,
            cashPrice:itemselectdata.cashPrice,
            purchaseDate:itemselectdata.purchaseDate,
            purchaseCost:itemselectdata.purchaseCost,
            conditionId:TxtValues.conditionId,
            itemFee:amountdata.itemFee,
            Deliveryfee:amountdata.Deliveryfee==CONSTANTS.SELECT?0:amountdata.Deliveryfee,
            salesprice:amountdata.salesprice,
            totalsales: amountdata.totalsales,
            salestax:amountdata.salestax,
            DeliverfeeTax:amountdata.DeliverfeeTax==CONSTANTS.EMPTY_STRING||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
            reason:amountdata.reason




        }
        agreementDetailsUpdate(response);
    }

    const agreementDetailsUpdate =(response)=>{
        const risObject= risPaygridFormation(response);
        // const index= agreementDetails.findIndex((x)=>x.agreementId == risObject.agreementId )
        if(editIndex!=CONSTANTS.EMPTY_STRING && editIndex>=CONSTANTS.ZERO_NUMBER)
            {
                const newAgreementDetails=[...agreementDetails];
                newAgreementDetails[editIndex]=risObject[0];
                setAgreementDetails(newAgreementDetails);
            } 
            else{
                setAgreementDetails([...agreementDetails,...risObject]);
            }  

        history.push({pathname:`/payment1/paymentinformation/${customerId}/0`,
                      state:{
                             ristype:"Rental Item Sale"}})

    }
 
   /**RIS_PC_NO_22 alertpopupFlag updated as true and alert popup is rendered
    *
    */
    return (

        <Grid>
            <RACModalCard
                isOpen={alertpopupFlag}
                maxWidth="xs"
                // onClose={() => setalertpopupFlag(false)}
                // closeIcon={false}
                borderRadius={"20px !important"}
                // eslint-disable-next-line react/no-children-prop
                children={alertpopup()}
            />
            <RACModalCard
                isOpen={alertinfopopupFlag}
                maxWidth="xs"
                onClose={() => setalertinfopopupFlag(false)}
                closeIcon={false}
                borderRadius={"20px !important"}
                // eslint-disable-next-line react/no-children-prop
                children={alertinfopopup()}
            />
             <RACModalCard
                isOpen={overridepopupflag}
                maxWidth="sm"
                onClose={() => setoverridepopupflag(false)}
                closeIcon={false}
                borderRadius={"20px !important"}
                // eslint-disable-next-line react/no-children-prop
                children={overridepopup()}
            />
            <RACModalCard
                isOpen={maximumpopupFlag}
                maxWidth="xs"
                onClose={() => setmaximumpopupFlag(false)}
                closeIcon={false}
                borderRadius={"20px !important"}
                // eslint-disable-next-line react/no-children-prop
                children={maximumpopup()}
            />
            <RACModalCard
                isOpen={minimumpopupFlag}
                maxWidth="xs"
                onClose={() => setmaximumpopupFlag(false)}
                closeIcon={false}
                borderRadius={"20px !important"}
                // eslint-disable-next-line react/no-children-prop
                children={minimumpopup()}
            />
            <Grid container className={classes.tittles}>

                <Grid>
                    <List className={`${classes.dflex} ${classes.p0} `}>
                        <ListItem className={`${classes.widthAuto} ${classes.p2N} ${classes.fontSemiBold} ${classes.floatStart} ${classes.BreadCrumbInactive}`}>
                            <Typography className={`${classes.pr2} ${classes.textGrey} `} >
                            <a
                                    style={{ cursor: "pointer", textDecoration: "none" }}
                                    onClick={() => history.push(`/dashboard`)}
                                >
                                    Dashboard
                                </a>

                            </Typography>
                        </ListItem>
                        <ListItem className={`${classes.widthAuto} ${classes.p2N} ${classes.floatStart} ${classes.fontSemiBold} ${classes.BreadCrumbInactive}`}>
                            <Typography className={`${classes.pr2} ${classes.textGrey} `} >
                            <a
                                style={{ cursor:"pointer",textDecoration:"none"}}
                                onClick={()=> history.push(`/payment1/paymentinformation/${customerInfo.customerId}/0`)}>Payment</a>

                            </Typography>
                        </ListItem>
                        <ListItem className={`${classes.widthAuto} ${classes.p2N} ${classes.floatStart} ${classes.fontSemiBold}`}>
                            <Typography className={`${classes.pr2}   ${classes.textBlue} `} >
                                Rental Item Sale
                            </Typography>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Card
                className={`${classes.w100}  ${classes.card}  ${classes.cardHeight} ${classes.marginTop} ${classes.cardborder}`}
            >
                <CardContent>
                    <Grid container>
                        <Grid item md={3} style={{ marginLeft: "-18px", marginTop: "1px" }}>
                            <Typography
                                variant="h1"
                                className={`${classes.title1} ${classes.titleColor}`}>
                                Rental Item Sale
                            </Typography>
                        </Grid>
                        <Grid style={{ marginLeft: "-2%" }} className={classes.custDetailSection}>
                            <Grid style={{ marginLeft: "-75%" }} item md={4} className={classes.paddingCustname}>
                                <Typography>
                                    Customer Name
                                </Typography>
                                <Typography className={classes.marginCustName} style={{ cursor: 'pointer' }} onClick={() => history.push({
                                    pathname: `/customer/update/${customerName}/customer`
                                })}>
                                    {`${customerInfo?.customerDetails[0]?.firstName} ${customerInfo?.customerDetails[0]?.lastName}`}
                                </Typography>
                            </Grid><Grid item md={4} style={{ marginLeft: "-125%" }} className={classes.paddingCustname}>
                                <Typography >
                                    Customer ID
                                </Typography>
                                <Typography className={classes.marginCustID}>
                                    {customerInfo.customerId}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>

            <Grid style={{ width: "100%",display:"flex" }}>

              {/*  RIS_PC_NO_04  Rendering the criteria search component */}
              {/* /**RIS_PC_NO_16 The searchresultgrid() function is trigered for rendering the search result grid */ }

                <Grid container className={itemselectFlag == true ? `${classes.afterselect}` : `${classes.beforeselect}`} >
                    {/* <Grid > */}
                    <Criteriasearch
                        searchclick={searchclick}
                        TxtValues={TxtValues}
                        setTxtValues={setTxtValues}
                    />
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.mbFooterSpacing}>
                        {loaderController === true && Gridloader === true ? (
                            <>
                                <Typography className={`${classes.title2} ${classes.mt3} ${classes.srmargin}`}>
                                    Search Results
                                </Typography>
                                <Grid className={`${classes.GridLoader} ${classes.textCenter} ${classes.mt5}`}>
                                    <CircularProgress />
                                </Grid>
                            </>
                        ) : (
                            <Grid >
                                {searchresultgrid()}

                            </Grid>

                        )}

                    </Grid>


                    {/* </Grid> */}
                </Grid>
                {/**RIS_PC_NO_23 The selected item is rendered when itemselectFlag is updated
                 * as true.
                 */}

                <>
                    <Grid className={itemselectFlag == true ? `${classes.floatRight} ${classes.selecteditem}` : `${classes.selecteditemNon}`} >
                        <Typography className={`${classes.title3} ${classes.mt3} ${classes.srmargin}`}>
                            Selected Items
                        </Typography>

                        <Card className={`${classes.card}  ${classes.mb2}`} style={{ borderRadius: "20px" }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                {loadPriceCard ? (
                                    <Grid className={classes.masterLoader}>
                                    <Grid className={classes.Loader}>
                                        <CircularProgress />
                                    </Grid>
                                    </Grid>
                                ) : null}
                                    <Grid
                                        item
                                        className={`${classes.w100} ${classes.floatLeft}  ${classes2.mb2}`}
                                    >
                                        <Grid item
                                            className={`${classes.RACpaymenttotalcontainer1} ${classes2.p3}`}>
                                            <Grid
                                                item
                                                className={`${classes.w100} ${classes.floatLeft} ${classes2.mb2}`}
                                            >
                                                <Typography
                                                    className={`${classes2.floatLeft} ${classes2.totalsublabelstyle} ${classes2.bold} ${classes2.mt2} ${classes2.colorwhite}`}
                                                >
                                                    {amountdata.inventoryDescription}
                                                </Typography>

                                            </Grid>

                                            <Grid item className={`${classes.w100} ${classes.floatLeft} `}>
                                                <Typography className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes2.colorwhite}`}>
                                                    Item #
                                                </Typography>
                                                {/* <Grid item className={`${classes.floatLeft} ${classes2.totalsublabelstyle} ${classes2.bold} ${classes2.totallabelstyle} ${classes2.colorwhite}`} */}
                                                {/* style={{marginLeft:"2px"}}> */}
                                                <Typography className={`  ${classes2.floatLeft} ${classes.totalsublabelstyle}`} style={{ marginLeft: "2px" }} >
                                                    : {amountdata.inventoryNumber}
                                                </Typography>
                                                <Typography className={`  ${classes2.floatLeft} `} style={{ float: "right", marginRight: "10px",cursor:"pointer" }} >
                                                    <Deleteicon onClick={() => { deleClick() }}></Deleteicon>
                                                </Typography>
                                            </Grid>
                                            {/* </Grid> */}

                                        </Grid>

                                    </Grid>
                                    <Grid item className={`${classes.w100} ${classes.floatLeft} ${classes2.mb2}`}>
                                        <Typography style={{marginTop:"7px"}} className={`${classes.floatLeft} ${classes.totalsublabelstyle1} ${classes2.bold}`}>
                                            Sale Price
                                        </Typography>
                                        <Grid
                                            item
                                            className={`${classes2.floatRight} ${classes2.totalsublabelstyle} ${classes2.bold} `}
                                            style={{ marginRight: "-96px" }}
                                        >
                                            <RACTextbox
                                                className={classes.custDigitN}
                                                type="number"
                                                name="salesprice"
                                                value={amountdata.salesprice}
                                                OnChange={(e: React.ChangeEvent<HTMLInputElement>) => { selecteditemHandlechange(e, "salesprice") }}
                                                Onblur={(val:React.ChangeEvent<HTMLInputElement>) => {handleOnBlur(val)}}
                                                isCurrency={true}
                                                maxlength={10}
                                                required={false}
                                                disabled={sptxboxFlag}
                                            // errorMessage={errorMessage==false?"Enter amount less thanEpoAmount IncludingTax":CONSTANTS.EMPTY_STRING}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item className={`${classes.w100} ${classes.floatLeft} ${classes2.mb2}`}>
                                        <Typography className={`${classes.floatLeft} ${classes.totalsublabelstyle1} ${classes2.bold}`}>
                                            Item Fees
                                        </Typography>
                                        <Grid
                                            item
                                            className={`${classes2.floatRight} ${classes2.totalsublabelstyle} `}
                                        >
                                            <Typography style={{ marginRight: "4px"  }} className={`${classes.floatLeft} ${classes.totalsublabelstyle1} `}>
                                                {amountdata.itemFee}
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                    <Grid item className={`${classes.w100} ${classes.floatLeft} ${classes2.mb2}`}>
                                        <Typography
                                        style={{marginTop:"7px"}}
                                         className={`${classes.floatLeft} ${classes.totalsublabelstyle1} ${classes2.bold}`}>
                                            Delivery Charge($)
                                        </Typography>
                                        <Grid
                                            item
                                            className={` ${classes2.totalsublabelstyle} ${classes2.bold} `}
                                            style={{float:"right", marginRight:"-2%" }}
                                        >
                                            <RACSelect
                                                // className={classes.custDigitN}
                                                classes={{nativeSelect:classes.custDigitN1}}
                                                // type="number"
                                                name="Deliveryfee"
                                                defaultValue={amountdata.Deliveryfee}
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { selecteditemHandlechange(e, "Deliveryfee") }}
                                                // defaultValue={ TxtValues.conditionId }
                                                options={dropdownArr}
                                                isDisabled={deliveryFeeApplicable==0?true:false}
                                                loading={loadPriceCard}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item className={`${classes.w100} ${classes.floatLeft} ${classes2.mb2}`}>
                                        <Typography className={`${classes.floatLeft} ${classes.totalsublabelstyle1} ${classes2.bold}`}>
                                            Sales Tax
                                        </Typography>
                                        <Grid
                                            item
                                            className={`${classes2.floatRight} ${classes2.totalsublabelstyle} `}
                                        >
                                            <Typography style={{ marginRight: "4px" }} className={`${classes.floatLeft} ${classes.totalsublabelstyle1} `}>
                                                {amountdata.salestax}
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        className={`${classes.w100} ${classes.floatLeft} ${classes2.mb4}`}
                                        style={{marginRight:"4px"}}
                                    >

                                        <Grid
                                            item
                                            className={`${classes2.floatRight} ${classes.totalsublabelstyle3}  `}
                                        >
                                            <Typography className={`${classes.floatLeft}${classes.totalsublabelstyle3} ${classes2.bold}  `} style={{cursor:'pointer'}}>
                                                <a style={{ textDecoration: "underline" }} onClick={()=>{noBtn("default")}}>Set to Default</a>
                                            </Typography>

                                        </Grid>


                                    </Grid>

                                    <Grid item
                                        className={`${classes.RACpaymenttotalcontainer2} ${classes2.p1}`}
                                        style={{marginRight:"4px"}}>
                                        <Grid
                                            item
                                            className={`${classes.w100} ${classes.floatLeft} ${classes2.mb1}`}
                                        >
                                            <Typography
                                                className={`${classes2.floatLeft} ${classes.totalsublabelstyle2} ${classes2.bold} ${classes2.mt2}`}
                                            >
                                                Total Sales
                                            </Typography>
                                            <Grid
                                                item
                                                className={`${classes2.floatRight} ${classes.totalsublabelstyle3}  `}
                                                style={{marginTop:"2%"}}
                                            >
                                                <Typography variant='h4' className={`${classes.floatLeft}${classes.totalsublabelstyle3} ${classes2.bold}  `}>
                                                    {amountdata.totalsales}
                                                </Typography>

                                            </Grid>


                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        {/* )} */}
                    </Grid> </>
                {/* : null} */}
            </Grid>
            {/* </Card> */}

            {/**RIS_PC_NO_25 when continue button is clicked riscontinuebtnOnclick() function triggered */}
            <Grid
                item
                classes={{
                    root: clsx(
                        classes.customerToolbar,
                        classes.fixedBottom
                    ),
                }}
                style={{ zIndex: 0 }}
            >
                <Grid item className={classes.floatLeft}>
                    <RACButton
                        variant="outlined"
                        color="primary"
                        className={`${classes.me1} ${classes.foc}`}
                        onClick={() => cancelBtn()}
                    >
                        Cancel
                    </RACButton>
                </Grid>
                <Grid className={classes.floatRight}>
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={classes.me1}
                    disabled={riscontinuebtnFlag}
                    onClick={() =>riscontinuebtnOnclick()}
                    >
                        Continue
                    </RACButton>
                </Grid>
            </Grid>
            {SecondFactorOpen === true ? <RentalSecondFactor
             setTwoFactorCancelClick={setTwoFactorCancelClick}
             setTwoFactorCompleted={setTwoFactorCompleted}
            //  moduleName="ApplyFreeTime"
             pin={pin}
             setPin={setPin}
             CurrentRole={CurrentRole}
             /> : null}
            </Grid>
    )

}