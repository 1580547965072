/**
 * PS_CPAP_01
 * Import the required packages, interface and context
 */
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ParamType } from '../interface/commonInterface';
import { UpdateAddressInfo } from '../../api/user';
import {
  RACButton,
  RACRadio,
  RACModalCard,
  RACTable,
  RACTableRow,
  RACTableCell,
  Grid,
} from '@rentacenter/racstrap';
import { storeNumberFormat } from '../utils/scheduleAndDateFormat';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import {
  CustomerMultiplePrimaryAddressProps,
  UpdatePayload,
  PopupObj,
  PrimaryAddressPopup,
} from '../interface/customerPrimaryAddrsPopupInterface';
import CONSTANTS from '../constants/constant';
import { ApiErrorModal } from './existingFunctions/ApiErrorModal';

/**
 * PS_CPAP_02 - PS_CPAP_34
 * Invoke a functinal based component as CustomerPrimaryAddressPopup
 * @param props (primaryAddress and close function)
 * @returns
 */

export default function CustomerPrimaryAddressPopup(
  props: CustomerMultiplePrimaryAddressProps
) {
  /**
   * PS_CPAP_03 - PS_CPAP_08
   * Declare variables and form an array based on address id as key
   * to display in the popup grid
   */
  const classes = takePaymentStyles();
  const history = useHistory();

  const addrsDetails = props.primaryAddr;
  const { customerId } = useParams<ParamType>();
  const [addressId, setAddressId] = useState<string>();
  const popupObj: PopupObj = {
    validationPopup: false,
    successPopup: false,
    errorPopup: false,
  };

  const [popup, setPopup] = useState<PopupObj>(popupObj);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [yesSaveLoader, setYesSaveLoader] = useState<boolean>(false);
  const addressGridValues: PrimaryAddressPopup[] = Object.values(
    addrsDetails.reduce((acc, obj) => {
      const key = obj.addressId;
      if (!acc[key] && !obj.storeNumber && !obj.agreementNumber) {
        acc[key] = {
          ...obj,
          storeNumber: [],
          agreementNumber: [],
          agreementIdArray: [],
        };
      } else if (!acc[key]) {
        acc[key] = {
          ...obj,
          storeNumber: [obj.storeNumber],
          agreementNumber: [obj.agreementNumber],
          agreementIdArray: [obj.agreementId],
        };
      } else if (obj.storeNumber && obj.agreementNumber) {
        !acc[key].storeNumber.includes(obj.storeNumber)
          ? acc[key].storeNumber.push(obj.storeNumber)
          : null;
        !acc[key].agreementNumber.includes(obj.agreementNumber)
          ? acc[key].agreementNumber.push(obj.agreementNumber)
          : null;
        !acc[key].agreementIdArray.includes(obj.agreementId)
          ? acc[key].agreementIdArray.push(obj.agreementId)
          : null;
      }
      return acc;
    }, {})
  );

  const primaryAddressHeader: string[] =
    CONSTANTS.CUSTOMER_PRIMARY_ADDRESS_POPUP_HEADER;
  /**
   * PS_CPAP_09
   * This function binds the header to the UI
   * @returns
   */
  const renderHeader = () => {
    return (
      <>
        {primaryAddressHeader.map((val: string, index: number) => (
          <RACTableCell key={index}>
            {val !== 'Radio' ? val : null}
          </RACTableCell>
        ))}
      </>
    );
  };
  /**
   * PS_CPAP_10 - PS_CPAP_14
   * This function binds the popup grid data from addressGridValues
   * @returns
   */
  const renderBody = () => {
    return (
      <React.Fragment>
        {addressGridValues.map((item: PrimaryAddressPopup, index: number) => {
          return (
            <React.Fragment key={index}>
              <RACTableRow
                key={index}
                style={{
                  backgroundColor: 'white',
                  paddingLeft: '5%',
                  paddingRight: '5%',
                }}
              >
                <RACTableCell>
                  <RACRadio
                    value=""
                    checked={addressId === item.addressId}
                    onClick={() => setAddressId(item.addressId)}
                  />
                </RACTableCell>
                <RACTableCell style={{ paddingRight: '1%' }}>
                  {item.addressLine1}
                </RACTableCell>
                <RACTableCell>
                  {item.addressLine2 == CONSTANTS.EMPTY_STRING ||
                  item.addressLine2 == null
                    ? CONSTANTS.HYPHEN
                    : item.addressLine2}
                </RACTableCell>
                <RACTableCell>{item.postalCode}</RACTableCell>
                <RACTableCell>{item.city}</RACTableCell>
                <RACTableCell>{item.state}</RACTableCell>
                <RACTableCell title={`${item.storeNumber}`}>
                  {item.storeNumber.length > CONSTANTS.ZERO_NUMBER
                    ? storeNumberFormat(item.storeNumber)
                    : CONSTANTS.HYPHEN}
                </RACTableCell>
                <RACTableCell title={`${item.agreementNumber}`}>
                  {item.agreementNumber.length}
                </RACTableCell>
              </RACTableRow>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };
  /**
   * PS_CPAP_15 - PS_CPAP_17
   * This function triggers in the save click
   * It checks for the agreements associated with not selected address
   */
  const savePopupValidation = () => {
    setSaveLoader(true);
    let count = CONSTANTS.ZERO_NUMBER;
    addressGridValues.forEach((el) =>
      el.agreementNumber.length > CONSTANTS.ZERO_NUMBER &&
      el.addressId != addressId
        ? count++
        : null
    );
    count > CONSTANTS.ZERO_NUMBER
      ? setPopup({ ...popup, validationPopup: true })
      : handleSave();
  };

  /**
   * PS_CPAP_18 - PS_CPAP_26
   * This function makes api call to save the primary address in the DB
   * @param type
   */
  const handleSave = async (type?: string) => {
    setYesSaveLoader(true);
    try {
      const payload: UpdatePayload[] = [];
      const selectedindex = addressGridValues.findIndex(
        (add: PrimaryAddressPopup) => add.addressId == addressId
      );

      addressGridValues.forEach((el: PrimaryAddressPopup) => {
        let addressType: string = CONSTANTS.PRIM;
        let active: string = CONSTANTS.STATUS_YES;
        if (el.addressId != addressId) {
          if (el.agreementIdArray.length > 0) {
            if (type == CONSTANTS.YES) {
              addressType = CONSTANTS.PRIM;
              active = CONSTANTS.STATUS_NO;
              addressGridValues[selectedindex].agreementIdArray.push(
                ...el.agreementIdArray
              );
              el.agreementIdArray = [];
            } else {
              addressType = CONSTANTS.DELIV;
              active = CONSTANTS.STATUS_YES;
            }
          } else {
            addressType = CONSTANTS.PRIM;
            active = CONSTANTS.STATUS_NO;
          }
        }

        payload.push({
          city: el.city,
          addressType: addressType,
          postalCode: el.postalCode,
          active: active,
          addressLine1: el.addressLine1,
          addressLine2: el.addressLine2,
          state: el.state,
          agreementIds: el.agreementIdArray,
          addressId: el.addressId,
        });
      });

      const updateCustomerAddress = await UpdateAddressInfo({
        customerId: customerId,
        addresses: payload,
      });

      if (updateCustomerAddress.status == 200) {
        setPopup({ ...popup, validationPopup: false, successPopup: true });
      } else {
        setPopup({
          ...popup,
          validationPopup: false,
          successPopup: false,
          errorPopup: true,
        });
      }
      setSaveLoader(false);
    } catch (err) {
      setSaveLoader(false);
      setYesSaveLoader(false);
      setPopup({
        ...popup,
        validationPopup: false,
        successPopup: false,
        errorPopup: true,
      });
    }
  };
  /**
   * PS_CPAP_27 - PS_CPAP_29
   * This function holds the HTML for the validation message to change aggrements to new primaryaddress
   * @returns
   */
  const renderPrimarySaveAddress = () => {
    return (
      <Grid container>
        <Grid
          className={`${classes.fontFamilyOpenSansBold}`}
          style={{
            marginLeft: '20px',
            marginRight: '20px',
            fontSize: '16px',
          }}
        >
          Other Primary Address are associated with agreements. Do you want to
          change it to new primary address
        </Grid>
        <Grid
          container
          className={`${classes.justifycontentcenter} ${classes.spacerMT4}`}
        >
          <RACButton
            data-testid="noBtn"
            variant="outlined"
            color="inherit"
            className={`${classes.me2} ${classes.foc}`}
            onClick={() => handleSave(CONSTANTS.NO)}
          >
            No
          </RACButton>
          <RACButton
            data-testid="yesBtn"
            variant="contained"
            color="primary"
            onClick={() => {
              handleSave(CONSTANTS.YES);
            }}
            loading={yesSaveLoader}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  /**
   * PS_CPAP_30 - PS_CPAP_31
   * This function holds the primary address updated successfully popup HTML
   * @returns
   */
  const primaryAddressUpdatedSuccessfully = () => {
    return (
      <Grid container>
        <Grid
          data-testid="addressSuccessPopup"
          className={`${classes.fontFamilyOpenSansBold}`}
          style={{
            marginLeft: '18px',
            marginRight: '15px',
            fontSize: '16px',
          }}
        >
          Primary Address is updated successfully
        </Grid>
        <RACButton
          data-testid="successOkBtn"
          style={{ marginTop: '20px', marginLeft: '180px' }}
          variant="contained"
          color="primary"
          id="assignAlertBtn"
          className={classes.mt1}
          onClick={() => {
            setPopup({ ...popup, successPopup: false, validationPopup: false });
            props.setPopupValue(props.secondPriority);
            props.onClose();
          }}
        >
          Ok
        </RACButton>
      </Grid>
    );
  };
  /**
   * PS_CPAP_32 - PS_CPAP_34
   * Within return, it calls renderHeader and renderBody to bind data to UI
   */
  return (
    <>
      <Grid>
        <Grid
          className={`${classes.fontFamilyOpenSansBold}`}
          style={{
            marginLeft: '32%',
            fontSize: 'medium',
            marginTop: '-14px',
          }}
        >
          Customer is having multiple primary address.
        </Grid>
        <Grid
          className={`${classes.fontFamilyOpenSansBold}`}
          style={{
            marginLeft: '31%',
            fontSize: 'medium',
          }}
        >
          Please select any one of the address as primary
        </Grid>
        <Grid>
          <RACTable
            renderTableHead={renderHeader}
            renderTableContent={() => renderBody()}
          />
        </Grid>
        <Grid style={{ marginTop: '3%' }}>
          <RACButton
            data-testid="cancelBtn"
            variant="outlined"
            color="inherit"
            style={{ marginLeft: '75%', padding: '8px 26px' }}
            onClick={() =>
              history.push({ pathname: `/payment1/paymentsearch` })
            }
          >
            Cancel
          </RACButton>
          <RACButton
            data-testid="saveBtn"
            variant="contained"
            color="primary"
            className={classes.me1}
            style={{ float: 'right', marginRight: '2%', padding: '8px 20px' }}
            disabled={addressId ? false : true}
            onClick={() => savePopupValidation()}
            loading={saveLoader}
          >
            Save
          </RACButton>
        </Grid>
      </Grid>

      {popup.validationPopup ? (
        <RACModalCard
          borderRadius="20px"
          isOpen={popup.validationPopup}
          maxWidth="xs"
          title=""
          onClose={() => {
            setPopup({ ...popup, validationPopup: false });
          }}
          closeIcon={true}
        >
          {renderPrimarySaveAddress()}
        </RACModalCard>
      ) : null}

      {popup.successPopup ? (
        <RACModalCard
          borderRadius="20px"
          isOpen={popup.successPopup}
          maxWidth="xs"
          title=""
          onClose={() => {
            setPopup({ ...popup, successPopup: false });
            props.setPopupValue(props.secondPriority);
          }}
          closeIcon={true}
        >
          {primaryAddressUpdatedSuccessfully()}
        </RACModalCard>
      ) : null}

      {popup.errorPopup ? (
        <ApiErrorModal
          open
          message={CONSTANTS.FAILED_TO_UPDATE_ADDRESS}
          onClose={() => {
            setPopup({ ...popup, errorPopup: false });
            props.setPopupValue(props.secondPriority);
          }}
        />
      ) : null}
    </>
  );
}
