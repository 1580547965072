/*eslint-disable react-hooks/exhaustive-deps*/
/**
 * Import the required packanges, interface and context
 */
import {
  RACSelect,
  RACTextbox,
  RACButton,
  RACRadio,
  RACModalCard,
  Grid,
  Typography,
  TextareaAutosize,
} from '@rentacenter/racstrap';
import React, { useContext, useEffect, useState } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import { useParams } from 'react-router-dom';
import { DropDownArray, ParamType } from '../interface/commonInterface';
import {
  GetCustomerInfoOptions,
  UpdateAddressInfo,
  UpdateNoDeReason,
  addressValidator,
} from '../../api/user';
import { orderBy } from 'lodash';
import {
  bestTimeFormat,
  primaryPhoneFormat,
} from '../utils/scheduleAndDateFormat';
import { format } from 'date-fns';
import validateAddressFn from '../utils/validateAddressFn';
import clsx from 'clsx';
import { ReactComponent as WarningIcon } from '../../assets/images/no-records-found.svg';
import {
  AddressObj,
  ContactObj,
  PopupObj,
  ContactPayload,
  AddressPayload,
  UpdateApiPayload,
  UpdateAddressPayload,
  AddressResponse,
  BestTimeResponse,
  AddressvalidatorApiResponse,
  GoogleValidationPostalAddress,
  StateProvince,
  ApiValidateAddress,
} from '../interface/customerInformationInterface';
import CONSTANTS from '../constants/constant';
import { customerInfoStyle } from '../stylesJS/customerInfoStyle';
import {
  CustomerAddressDetail,
  CustomerContactDetail,
} from '../interface/paymentInfoInterface';
import { AgreementContextValue } from '../interface/contextInterface';
import { ApiErrorModal } from './existingFunctions/ApiErrorModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import { GoogleAddressResponse } from '../interface/utilsInterface/validateAddressInterface';
/**
 * PS_CI_02 - PS_CI_112
 * Invoke a functional based compoennt as CustomerInformation()
 * @returns
 */
export function CustomerInformation() {
  /**
   * PS_CI_03 - PS_CI_14
   * Declaring variables and destructing from context
   */
  const classes = customerInfoStyle();
  const { customerId } = useParams<ParamType>();
  const { customerInfo, featureFlags, stateData, setStateData, renderContext } =
    useContext<AgreementContextValue>(agreementContext);
  const storeNumber: string | null = sessionStorage.getItem(
    CONSTANTS.STORE_NUMBER
  );
  const primaryAddress: CustomerAddressDetail[] =
    customerInfo?.customerAddressDetails?.filter(
      (el: CustomerAddressDetail) => el.addressType == CONSTANTS.PRIM
    ) ?? [];
  const primaryPhone: CustomerContactDetail[] =
    customerInfo?.customerContactDetails?.filter(
      (el: CustomerContactDetail) => el.primary === CONSTANTS.STATUS_YES
    ) ?? [];
  /**
   * PS_CI_06 - PS_CI_07
   * Declaring addressDetail state variable with initial object
   */
  const [addressDetail, setAddressDetail] = useState<AddressObj>({
    addressId: CONSTANTS.EMPTY_STRING,
    address1: CONSTANTS.EMPTY_STRING,
    address2: CONSTANTS.EMPTY_STRING,
    zip: CONSTANTS.EMPTY_STRING,
    city: CONSTANTS.EMPTY_STRING,
    state: CONSTANTS.EMPTY_STRING,
  });
  /**
   * PS_CI_08 - PS_CI_09
   * Declaring contactDetail state variable with initial object
   */
  const [contactDetail, setContactDetail] = useState<ContactObj>({
    phoneNum: CONSTANTS.EMPTY_STRING,
    bestTime: CONSTANTS.EMPTY_STRING,
    instructions: CONSTANTS.EMPTY_STRING,
  });
  const [popupText, setPopupText] = useState<string>(CONSTANTS.EMPTY_STRING);
  const [suggestedAddress, setSuggestedAddress] =
    useState<GoogleValidationPostalAddress | null>();
  const [addressValidationRadio, setAddressValidationRadio] = useState<string>(
    CONSTANTS.SUGGESTED
  );
  /**
   * PS_CI_11
   * To handle different popup in this component
   */
  const handleBooleanVariables: PopupObj = {
    addressLoaderAndField: false,
    openAddressNotValidateModal: false,
    openAddressValidateModal: false,
    openApiErrorModal: false,
    contactLoaderAndField: false,
    addressSaveLoader: false,
  };
  const [componentFlags, setComponentFlags] = useState<PopupObj>(
    handleBooleanVariables
  );
  const [contactDisable, setContactDisable] = useState<boolean>(false);
  const [addressDisable, setAddressDisable] = useState<boolean>(false);
  const [matchCode, setMatchCode] = useState<string>(CONSTANTS.EMPTY_STRING);
  const [bestTimeList, setBestTimeList] = useState<DropDownArray[]>();

  /**
   * PS_CI_16 - PS_CI_22
   * This makes api call to get the state and best time options
   */
  useQuery({
    queryKey: [CONSTANTS.API_KEY_GET_CUSTOMER_INFO_OPTIONS, customerId],
    queryFn: () => GetCustomerInfoOptions(storeNumber),
    onSuccess(res) {
      const stateOption = res.data.stateList.getStateResponse.map(
        (el: StateProvince) => ({
          label: el.abbreviation,
          value: el.abbreviation,
        })
      );
      setStateData(orderBy(stateOption, [CONSTANTS.LABEL], ['asc']));

      const bestTimeToCallList = res.data.callTimeList.map(
        (state: BestTimeResponse) => {
          return { label: state.description, value: state.description };
        }
      );
      setBestTimeList(bestTimeToCallList);
    },
  });

  const mutation = useMutation({
    mutationFn: (payload: UpdateApiPayload) => UpdateNoDeReason(payload),
    onSuccess: () => {
      setComponentFlags({ ...componentFlags, contactLoaderAndField: false });
      window.location.reload();
    },
    onError: () => {
      setContactDetail({
        phoneNum: primaryPhone[0]?.phoneNumber,
        bestTime: primaryPhone[0]?.callTimeTypeDesc,
        instructions: primaryPhone[0]?.note,
      });

      setComponentFlags({
        ...componentFlags,
        openApiErrorModal: true,
        contactLoaderAndField: false,
      });
    },
  });

  /**
   * Set the initial values for addressDetails and contactDetails
   * from the primary address and phone
   */
  useEffect(() => {
    const addressDetailObj: AddressObj = {
      addressId: primaryAddress[0]?.addressId,
      address1: primaryAddress[0]?.addressLine1,
      address2: primaryAddress[0]?.addressLine2,
      zip: primaryAddress[0]?.postalCode,
      city: primaryAddress[0]?.city,
      state: primaryAddress[0]?.state,
    };
    setAddressDetail(addressDetailObj);

    const contactDetailObj: ContactObj = {
      phoneNum: primaryPhone[0]?.phoneNumber,
      bestTime: primaryPhone[0]?.callTimeTypeDesc,
      instructions: primaryPhone[0]?.note,
    };
    setContactDetail(contactDetailObj);
  }, [renderContext]);

  /**
   * PS_CI_23 - PS_CI_25
   * This triggers in the onChange of address input field
   * @param e
   */
  const handleAddressChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = e.target.id || CONSTANTS.STATE;
    let value: string;
    if (id == CONSTANTS.ZIP) value = zipValidation(e.target.value);
    else value = e.target.value;
    setAddressDetail({ ...addressDetail, [id]: value });
  };

  const zipValidation = (value: string): string => {
    let zip = value.replace(/\D/g, CONSTANTS.EMPTY_STRING);
    if (zip.length > 5) {
      zip = zip.slice(0, 5) + CONSTANTS.HYPHEN + zip.slice(5);
    }
    return zip;
  };

  const handleAddressBlur = () => {
    if (
      addressDetail.address1 == CONSTANTS.EMPTY_STRING ||
      addressDetail.city == CONSTANTS.EMPTY_STRING ||
      addressDetail.state == CONSTANTS.EMPTY_STRING ||
      addressDetail.zip == CONSTANTS.EMPTY_STRING ||
      (addressDetail?.zip?.length != CONSTANTS.FIVE_NUMBER &&
        addressDetail?.zip?.length != CONSTANTS.TEN_NUMBER)
    )
      setAddressDisable(true);
    else setAddressDisable(false);
  };
  /**
   * PS_CI_26 - PS_CI_28
   * This function triggers in the onChange of contact input field
   * @param e
   */
  const handleContactChange = (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const id = e.target.id || CONSTANTS.BEST_TIME;
    const value = e.target.value;

    setContactDetail({ ...contactDetail, [id]: value });
  };

  const handleContactBlur = () => {
    if (
      contactDetail.bestTime == CONSTANTS.EMPTY_STRING ||
      contactDetail.phoneNum == CONSTANTS.EMPTY_STRING
    )
      setContactDisable(true);
    else setContactDisable(false);
  };
  /**
   * PS_CI_29 - PS_CI_37
   * This function makes api call to update contact details
   */
  const handleContactSave = async () => {
    setComponentFlags({ ...componentFlags, contactLoaderAndField: true });

    const contactPayload: ContactPayload[] = [
      {
        phoneId: primaryPhone[0].phoneId,
        phoneNumber: contactDetail.phoneNum,
        phoneType: primaryPhone[0].phoneType,
        phoneTypeDesc: primaryPhone[0].phoneTypeDesc,
        extension: primaryPhone[0].extension,
        primary: primaryPhone[0].primary,
        callTimeType: bestTimeFormat(contactDetail.bestTime),
        callTimeTypeDesc: contactDetail.bestTime,
        active: primaryPhone[0].active,
        note: contactDetail.instructions,
      },
    ];

    const payload: UpdateApiPayload = {
      customerId: customerId,
      phones: contactPayload,
    };
    await mutation.mutateAsync(payload);
  };
  /**
   * PS_CI_38 - PS_CI_79
   * This function makes api call to validate address
   */
  const handleAddressSave = async () => {
    setComponentFlags({ ...componentFlags, addressLoaderAndField: true });
    const currentTimeZoneOffset = format(new Date(), CONSTANTS.XXX);
    const currentTimeStamp = format(new Date(), CONSTANTS.YYYY_MM_DD_HH_MM_SS);

    const payload: AddressPayload = {
      MessageID: `${CONSTANTS.CMS006}-${
        Math.floor(Math.random() * 100000000) + 1
      }`,
      MessageType: CONSTANTS.CMS006,
      MessageDTS: currentTimeStamp,
      MessageDTSTZOffset: currentTimeZoneOffset,
      RegionID:
        addressDetail.state === CONSTANTS.MX_STATE
          ? CONSTANTS.TWO_STRING
          : CONSTANTS.ONE_STRING,
      ClientID: CONSTANTS.EIGHT_STRING,
      ClientLocationNumber: storeNumber,
      ClientSource: CONSTANTS.ONE_STRING,
      ClientOriginator: CONSTANTS.ONE_STRING,
      LocationNumber: storeNumber,
      EncryptionMethod: CONSTANTS.ZERO_STRING,
      Addresses: [
        {
          AddressID: addressDetail.addressId,
          StreetAddress:
            addressDetail.address2?.trim().length > 0
              ? `${addressDetail.address1}, ${addressDetail.address2}`
              : addressDetail.address1,
          City: addressDetail.city,
          StateProvince: addressDetail.state,
          PostalCode: addressDetail.zip.replace(
            CONSTANTS.HYPHEN,
            CONSTANTS.EMPTY_STRING
          ),
        },
      ],
      StoreConfig: {
        storeNumbers: [storeNumber],
        paramKeyNames: [CONSTANTS.ADDRESS_DOCTOR_ENABLED],
      },
    };

    try {
      if (featureFlags?.EnableGoogleAddressValidation) {
        googleAddressValidationEnabled(payload);
      } else {
        let validateAddress = await addressValidator(payload);
        validateAddress = validateAddress?.data.validateAddress?.Addresses;
        if (
          validateAddress.MatchCode === CONSTANTS.ONE_STRING ||
          validateAddress.MatchCode === CONSTANTS.TWO_STRING
        ) {
          setMatchCode(validateAddress.MatchCode);
          let errorMessage: string;
          if (validateAddress.MatchCode == 1)
            errorMessage =
              CONSTANTS.ADDRESS_COULD_NOT_BE_CORRECTED_UNLIKELY_TO_MATCH_OVERRIDE;
          else {
            errorMessage = `Zip code found: ${validateAddress?.PostalCode}. Do you want to override`;
          }
          setPopupText(errorMessage);
          setComponentFlags({
            ...componentFlags,
            openAddressNotValidateModal: true,
          });
          setSuggestedAddress(null);
        } else {
          const suggestedObject = {
            regionCode: validateAddress.Country,
            postalCode: validateAddress.PostalCode,
            administrativeArea: validateAddress.StateProvince,
            locality: validateAddress.City,
            addressLines: [validateAddress.StreetAddress],
          };
          setSuggestedAddress(suggestedObject);
          setComponentFlags({
            ...componentFlags,
            openAddressValidateModal: true,
          });
        }
      }
    } catch (err) {
      setPopupText(CONSTANTS.ADDRESS_VALIDATION_CURRENTLY_UNAVAILABLE);
      setComponentFlags({
        ...componentFlags,
        openAddressNotValidateModal: true,
        addressLoaderAndField: false,
      });
    }
  };

  const googleAddressValidationEnabled = async (payload: AddressPayload) => {
    const { address1, address2, zip, city, state } = addressDetail;
    const googleAddressValidationPayload: AddressPayload =
      prepareGoogleAddressValidationPayload(
        payload,
        address1,
        address2,
        zip,
        city,
        state
      );
    const response: AddressvalidatorApiResponse[] = await Promise.all([
      addressValidator(payload),
      addressValidator(googleAddressValidationPayload),
    ]);
    //Need to confirm address doctor validation - code review
    if (!isValidResponse(response)) {
      throw new Error(CONSTANTS.SOMETHING_WENT_WRONG);
    }

    const result: AddressResponse = validateAddressFn(
      response[1].data.validateAddress as GoogleAddressResponse,
      googleAddressValidationPayload,
      response[0].data.validateAddress as ApiValidateAddress | undefined,
      isStoreConfigEnabled(response[0])
    );

    handleAddressValidationResult(result, payload);
  };

  const prepareGoogleAddressValidationPayload = (
    payload: AddressPayload,
    address1: string,
    address2: string,
    zip: string,
    city: string,
    state: string
  ) => {
    const googleAddressValidationPayload: AddressPayload = JSON.parse(
      JSON.stringify(payload)
    );
    googleAddressValidationPayload.enableGoogleAddressValidation = true;
    const arr = [address1, city, state, zip];
    if (address2) arr.unshift(address2);
    googleAddressValidationPayload.validateAddress = {
      postalCode: zip,
      addressLines: arr,
    };
    return googleAddressValidationPayload;
  };

  const isValidResponse = (response: AddressvalidatorApiResponse[]) => {
    return (
      response[0].status === 200 &&
      response[1].status === 200 &&
      response[0].data &&
      response[1].data.validateAddress
    );
  };

  const isStoreConfigEnabled = (response: AddressvalidatorApiResponse) => {
    return (
      response.data &&
      response.data.storeConfig &&
      response.data.storeConfig.storeProfileResponse.configDetails[0]
        .configDetails[0].paramValue === CONSTANTS.ONE_STRING
    );
  };

  const handleAddressValidationResult = (
    result: AddressResponse,
    payload: AddressPayload
  ) => {
    if (result.status) {
      if (result.matchCode) {
        handleMatchCode(result.matchCode, payload);
      } else {
        const postalAddress =
          result.postalAddress ?? ({} as GoogleValidationPostalAddress);
        setSuggestedAddress(postalAddress);
        setComponentFlags({
          ...componentFlags,
          openAddressValidateModal: true,
        });
      }
    } else {
      setPopupText(result.message ?? CONSTANTS.EMPTY_STRING);
      setComponentFlags({
        ...componentFlags,
        openAddressNotValidateModal: true,
      });
    }
  };

  const handleMatchCode = (matchCode: string, payload: AddressPayload) => {
    setMatchCode(matchCode);
    const errorMessage = getErrorMessageForMatchCode(matchCode, payload);
    setPopupText(errorMessage);
    setComponentFlags({ ...componentFlags, openAddressNotValidateModal: true });
    setSuggestedAddress(CONSTANTS.NULL);
  };

  const getErrorMessageForMatchCode = (
    matchCode: string,
    payload: AddressPayload
  ) => {
    if (matchCode === CONSTANTS.ONE_STRING) {
      return CONSTANTS.ADDRESS_COULD_NOT_BE_CORRECTED_UNLIKELY_TO_MATCH_OVERRIDE;
    } else if (matchCode === CONSTANTS.TWO_STRING) {
      return `Zip code found: ${payload.Addresses[0].PostalCode}. Do you Want to overrride?`;
    }
    return CONSTANTS.EMPTY_STRING;
  };

  /**
   * PS_CI_80 - PS_CI_91
   * This function makes api call to save address
   * @param value
   */
  const handleValidationSave = async (value: string) => {
    setComponentFlags({ ...componentFlags, addressLoaderAndField: true });
    const addresses: UpdateAddressPayload[] = [];
    if (value == CONSTANTS.SUGGESTED) {
      const addressObj: UpdateAddressPayload = {
        active: CONSTANTS.STATUS_YES,
        addressId: addressDetail.addressId,
        addressLine1:
          suggestedAddress?.addressLines[0] ?? CONSTANTS.EMPTY_STRING,
        addressLine2:
          suggestedAddress?.addressLines[1] ?? CONSTANTS.EMPTY_STRING,
        addressType: CONSTANTS.PRIM,
        agreementIds: [],
        city: suggestedAddress?.locality ?? CONSTANTS.EMPTY_STRING,
        postalCode: suggestedAddress?.postalCode ?? CONSTANTS.EMPTY_STRING,
        state: suggestedAddress?.administrativeArea ?? CONSTANTS.EMPTY_STRING,
      };
      addresses.push(addressObj);
    } else {
      const addressObj: UpdateAddressPayload = {
        active: CONSTANTS.STATUS_YES,
        addressId: addressDetail.addressId,
        addressLine1: addressDetail.address1,
        addressLine2: addressDetail.address2,
        addressType: CONSTANTS.PRIM,
        agreementIds: [],
        city: addressDetail.city,
        postalCode: addressDetail.zip,
        state: addressDetail.state,
      };
      addresses.push(addressObj);
    }
    const payload: UpdateApiPayload = {
      customerId: customerId,
      addresses: addresses,
    };

    try {
      const updateAddrs = await UpdateAddressInfo(payload);
      if (updateAddrs.status != 200)
        setComponentFlags({ ...componentFlags, openApiErrorModal: true });
      //Need to reload here - code reeview
    } catch (error) {
      setComponentFlags({ ...componentFlags, openApiErrorModal: true });
    } finally {
      setComponentFlags((prev: PopupObj) => ({
        ...prev,
        addressSaveLoader: false,
        addressLoaderAndField: false,
        openAddressNotValidateModal: false,
        openAddressValidateModal: false,
      }));
      window.location.reload();
    }
  };
  /**
   * PS_CI_92 - PS_CI_96
   * This holds Html for address not  validation modal
   * @returns
   */
  const addressNotValidationModal = () => {
    return (
      <>
        <Grid item md={12} className={classes.spacerPX2}>
          <Grid
            style={{
              justifyContent: 'center',
              display: 'flex',
              marginBottom: '2%',
            }}
          >
            <WarningIcon width={'10%'} height={'10%'} />
          </Grid>
          <Grid item md={12} className={classes.customerTextcenter}>
            <Typography className={classes.customerRacpopup}>
              {popupText}
            </Typography>
          </Grid>
          <Grid
            container
            classes={{
              root: clsx(
                classes.customerJustifycontentcenter,
                classes.spacerMT4
              ),
            }}
          >
            {matchCode == CONSTANTS.ONE_STRING ||
            matchCode == CONSTANTS.TWO_STRING ? (
              <Grid item md={12} className={classes.customerTextcenter}>
                <RACButton
                  className={`${classes.spacerMR2}`}
                  variant="outlined"
                  color="primary"
                  isRounded={false}
                  onClick={() => {
                    setComponentFlags({
                      ...componentFlags,
                      openAddressNotValidateModal: false,
                    });
                    handleValidationSave(CONSTANTS.ENTERED);
                  }}
                >
                  No
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  isRounded={false}
                  onClick={() => {
                    setComponentFlags({
                      ...componentFlags,
                      openAddressNotValidateModal: false,
                    });
                    handleValidationSave(CONSTANTS.SUGGESTED);
                  }}
                >
                  Yes
                </RACButton>
              </Grid>
            ) : (
              <Grid item md={12} className={classes.customerTextcenter}>
                <RACButton
                  variant="contained"
                  color="primary"
                  isRounded={false}
                  onClick={() => {
                    setComponentFlags({
                      ...componentFlags,
                      openAddressNotValidateModal: false,
                    });
                  }}
                >
                  Ok
                </RACButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    );
  };
  /**
   * PS_CI_97 - PS_CI_104
   * This holds html for address validation modal
   * @returns
   */
  const addressValidationModal = () => {
    const { address1, address2, zip, city, state } = addressDetail;
    const suggestedAddr = `${suggestedAddress?.addressLines?.join(
      CONSTANTS.COMMA_WITH_SPACE
    )}, ${suggestedAddress?.locality}, ${
      suggestedAddress?.administrativeArea
    } - ${suggestedAddress?.postalCode}`;
    const addressLine = `${address1} ${
      address2 ? CONSTANTS.COMMA + address2 : CONSTANTS.EMPTY_STRING
    }`;
    const enteredAddress = `${addressLine}, ${city}, ${state} - ${zip}`;
    return (
      <>
        <>
          {!featureFlags?.EnableGoogleAddressValidation && (
            <Typography display="inline" variant="body2">
              Entered address is not located
            </Typography>
          )}
          <Grid item md={12} className={classes.spacerPX2}>
            <Typography className={`${classes.RACPOPMsg} ${classes.bold}`}>
              Entered Address
            </Typography>
            <Grid className={classes.formCheck}>
              <RACRadio
                id="inlineRadio1"
                name="inlineRadioOptions"
                checked={addressValidationRadio === CONSTANTS.ENTERED}
                onClick={() => setAddressValidationRadio(CONSTANTS.ENTERED)}
                label={enteredAddress}
                value={enteredAddress}
              />
            </Grid>
            <Grid
              item
              md={12}
              className={`${classes.spacerMB2} ${classes.spacerMT2}`}
            >
              <Typography className={`${classes.RACPOPMsg} ${classes.bold}`}>
                Suggested Addresses
              </Typography>
              <Grid className={classes.formCheck}>
                <RACRadio
                  id="inlineRadio1"
                  name="inlineRadioOptions"
                  checked={addressValidationRadio === CONSTANTS.SUGGESTED}
                  onClick={() => setAddressValidationRadio(CONSTANTS.SUGGESTED)}
                  label={suggestedAddr}
                  value={suggestedAddr}
                />
              </Grid>
            </Grid>
          </Grid>
        </>

        <Grid
          className={`${classes.spacerMT4} ${classes.w100} ${classes.floatLeft}`}
        >
          <Grid className={classes.floatLeft}>
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.foc}
              onClick={() =>
                setComponentFlags({
                  ...componentFlags,
                  openAddressValidateModal: false,
                })
              }
            >
              Cancel
            </RACButton>
          </Grid>
          <Grid className={`${classes.floatRight}`}>
            {!featureFlags?.EnableGoogleAddressValidation && (
              <RACButton
                variant="contained"
                color="primary"
                className={`${classes.marginRight2}`}
                onClick={() => handleValidationSave(addressValidationRadio)}
                disabled={addressValidationRadio === CONSTANTS.SUGGESTED}
                loading={
                  addressValidationRadio === CONSTANTS.ENTERED &&
                  componentFlags.addressLoaderAndField
                }
              >
                Override
              </RACButton>
            )}
            <Grid className={`${classes.floatRight}`}>
              <RACButton
                variant="contained"
                color="primary"
                onClick={() => handleValidationSave(addressValidationRadio)}
                disabled={
                  !addressValidationRadio ||
                  (addressValidationRadio === CONSTANTS.ENTERED &&
                    !featureFlags?.EnableGoogleAddressValidation)
                }
                loading={
                  (addressValidationRadio === CONSTANTS.SUGGESTED ||
                    (featureFlags?.EnableGoogleAddressValidation
                      ? true
                      : false)) &&
                  componentFlags.addressLoaderAndField
                }
              >
                Continue
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  /**
   * PS_CI_105 - PS_CI_112
   * Within return, holds html for input fields
   * and modal card for popups
   */
  return (
    <>
      <Grid
        className={classes.customerAddress}
        data-testid={'customerInformation'}
      >
        <Grid container>
          <Grid item sm={12} md={6} lg={6} classes={{ root: classes.row }}>
            <Typography variant="h4">Address</Typography>
            <Grid container spacing={2} classes={{ root: classes.row }}>
              <Grid item sm={12} md={12} lg={10}>
                <RACTextbox
                  disabled={componentFlags.addressLoaderAndField}
                  id="address1"
                  name={addressDetail.address1}
                  inputlabel="Address Line 1"
                  value={addressDetail.address1}
                  maxlength={30}
                  type="text"
                  required
                  OnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleAddressChange(e)
                  }
                  Onblur={() => handleAddressBlur()}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} classes={{ root: classes.row }}>
              <Grid item sm={12} md={12} lg={10}>
                <RACTextbox
                  disabled={componentFlags.addressLoaderAndField}
                  id="address2"
                  name={addressDetail.address2}
                  inputlabel="Address Line 2"
                  value={addressDetail.address2}
                  maxlength={30}
                  type="text"
                  OnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleAddressChange(e)
                  }
                  Onblur={() => handleAddressBlur()}
                />
              </Grid>
            </Grid>

            <Grid item sm={12} md={12} lg={12}>
              <Grid container spacing={2} classes={{ root: classes.row }}>
                <Grid item sm={10} md={10} lg={10}>
                  <Grid container spacing={2}>
                    <Grid item sm={4} md={4} lg={4}>
                      <RACTextbox
                        disabled={componentFlags.addressLoaderAndField}
                        id="zip"
                        name={addressDetail?.zip}
                        inputlabel="Zip"
                        maxlength={10}
                        value={addressDetail?.zip}
                        type="text"
                        required
                        OnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleAddressChange(e)
                        }
                        Onblur={() => handleAddressBlur()}
                        errorMessage={
                          addressDetail?.zip?.length != CONSTANTS.FIVE_NUMBER &&
                          addressDetail?.zip?.length != CONSTANTS.TEN_NUMBER
                            ? CONSTANTS.INVALID_ZIP
                            : CONSTANTS.EMPTY_STRING
                        }
                      />
                    </Grid>
                    <Grid item sm={4} md={4} lg={4}>
                      <RACTextbox
                        disabled={componentFlags.addressLoaderAndField}
                        id="city"
                        name={addressDetail?.city}
                        inputlabel="City"
                        value={addressDetail?.city}
                        maxlength={25}
                        type="text"
                        required
                        OnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleAddressChange(e)
                        }
                        Onblur={() => handleAddressBlur()}
                      />
                    </Grid>
                    <Grid item sm={4} md={4} lg={4}>
                      <RACSelect
                        classes={{ paper: classes.selectOptionsPaper }}
                        isDisabled={componentFlags.addressLoaderAndField}
                        inputLabel="State"
                        name={'state'}
                        defaultValue={addressDetail?.state}
                        options={stateData ?? []}
                        required
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          handleAddressChange(e)
                        }
                        data-testid="stateDropDown"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={2}
                md={2}
                lg={3}
                container
                justifyContent="flex-end"
                style={{ float: 'right' }}
              >
                <RACButton
                  data-testid="addressSaveBtn"
                  style={{ right: '70px' }}
                  variant="contained"
                  size="large"
                  color="primary"
                  loading={componentFlags.addressLoaderAndField}
                  className={classes.saveButton}
                  disabled={addressDisable}
                  onClick={() => handleAddressSave()}
                >
                  Save
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={6} lg={6} classes={{ root: classes.row }}>
            <Typography variant="h4">Contact</Typography>

            <Grid item sm={12} md={12} lg={12}>
              <Grid container spacing={2} classes={{ root: classes.row }}>
                <Grid item sm={10} md={10} lg={10}>
                  <Grid container spacing={2}>
                    <Grid item sm={4} md={4} lg={6}>
                      <RACTextbox
                        disabled={componentFlags.contactLoaderAndField}
                        id="phoneNum"
                        inputlabel="Primary phone"
                        value={primaryPhoneFormat(contactDetail.phoneNum)}
                        type="text"
                        maxlength={14}
                        required
                        OnChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          handleContactChange(e)
                        }
                        Onblur={() => handleContactBlur()}
                      />
                    </Grid>
                    <Grid item sm={4} md={4} lg={6}>
                      <RACSelect
                        classes={{ paper: classes.selectOptionsPaper }}
                        isDisabled={componentFlags.contactLoaderAndField}
                        inputLabel="Best Time to Call"
                        defaultValue={contactDetail.bestTime}
                        options={bestTimeList ?? []}
                        required
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          handleContactChange(e)
                        }
                        data-testid="callTimeList"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} classes={{ root: classes.row }}>
                <Grid item sm={12} md={12} lg={8}>
                  <Typography variant="h6">Instructions</Typography>

                  <TextareaAutosize
                    id="instructions"
                    disabled={componentFlags.contactLoaderAndField}
                    style={{
                      padding: '10px 190px 90px 10px',
                      borderRadius: '8px',
                      resize: 'none',
                    }}
                    name={contactDetail.instructions ?? CONSTANTS.EMPTY_STRING}
                    value={contactDetail.instructions ?? CONSTANTS.EMPTY_STRING}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      handleContactChange(e)
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={2}
                md={2}
                lg={3}
                container
                justifyContent="flex-end"
                style={{ float: 'right' }}
              >
                <RACButton
                  data-testid="contactSaveBtn"
                  style={{ right: '70px' }}
                  variant="contained"
                  size="large"
                  color="primary"
                  loading={componentFlags.contactLoaderAndField}
                  className={classes.saveButton}
                  onClick={handleContactSave}
                  disabled={contactDisable}
                >
                  Save
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {componentFlags.openApiErrorModal && (
          <ApiErrorModal
            open={componentFlags.openApiErrorModal}
            message={CONSTANTS.FAILED_TO_UPDATE_ADDRESS}
            onClose={() =>
              setComponentFlags({ ...componentFlags, openApiErrorModal: false })
            }
          />
        )}
      </Grid>

      {componentFlags.openAddressNotValidateModal && (
        <RACModalCard
          isOpen={componentFlags.openAddressNotValidateModal}
          classes={{
            dialogContent: classes.dialogContent2,
            dialog: classes.dialogRoot,
          }}
          maxWidth="sm"
        >
          {addressNotValidationModal()}
        </RACModalCard>
      )}

      {componentFlags.openAddressValidateModal && (
        <RACModalCard
          isOpen={componentFlags.openAddressValidateModal}
          classes={{
            dialogContent: classes.dialogContent,
            dialog: classes.dialogRoot,
          }}
          maxWidth="xs"
          title="Validation Message"
        >
          {addressValidationModal()}
        </RACModalCard>
      )}
    </>
  );
}
