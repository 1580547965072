/**
 * PS_PO_01
 * import necassary files
 * PS_PO_06
 */
import React, { useState, useContext } from 'react';
import {
  Grid,
  RACModalCard,
  RACButton,
  makeStyles,
} from '@rentacenter/racstrap';
import { Typography } from '@material-ui/core';
import { agreementContext } from '../context/PaymentInformationContext';
import CONSTANTS from '../constants/constant';
import { Customer } from '../interface/paymentInfoInterface';

export interface PaymentOriginModalProps {
  onClose: (onclose: boolean) => void;
}

/**
 * PS_PO_07 - providing styles
 */
export const useStyles = makeStyles((theme: any) => ({
  py1: {
    padding: '4px',
  },
  textCenter: {
    textAlign: 'center',
  },
  py4: {
    paddingTop: theme.typography.pxToRem(24),
    paddingBottom: theme.typography.pxToRem(24),
  },
  mx1: {
    marginRight: theme.typography.pxToRem(4),
    marginLeft: theme.typography.pxToRem(4),
    borderRadius: theme.typography.pxToRem(8),
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
  mx2: {
    width: '40%',
  },
  floatRight: {
    float: 'right',
  },
  ms2: {
    marginLeft: theme.typography.pxToRem(8),
  },
  notesLabel: {
    color: '#2179FE',
    padding: '10px 10px 10px 10px',
    backgroundColor: '#459efd17',
    display: 'inline-block',
    textAlign: 'left',
    marginLeft: '10px',
    marginRight: '10px',
  },
}));

type ButtonColor = 'inherit' | 'default' | 'secondary' | 'primary';
type ButtonVariant = 'outlined' | 'contained';

export const PaymentOriginModal: React.FC<PaymentOriginModalProps> = ({
  onClose,
}) => {
  const classes = useStyles();

  /**
   * PS_PO_10 - Context
   */
  const agreementInfo = useContext(agreementContext);

  /**
   * PS_PO_09 - State variables
   */
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [showConvenienceFee, setShowConvenienceFee] = useState(false);
  const [loadingSaveYes, setLoadingSaveYes] = useState(false);
  const [loadingStore, setLoadingStore] = useState(false);
  const convienevnceFeeWithoutTax =
    agreementInfo?.customerInfo?.convinenceFee?.[0]?.amount;

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const handleCloseClick = async () => {
    await delay(2000);
    onClose(false);
  };

  /**
   * PS_PO_15
   * @param buttonType
   * This function hansles the payment origin
   * PS_PO_25
   */
  const handleButtonClick = (buttonType: string) => {
    setActiveButton(buttonType);
    if (buttonType === CONSTANTS.IN_PHONE_VALUE) {
      setShowConvenienceFee(true);
    } else {
      setLoadingStore(true);
      agreementInfo.setPaymentOrigin(CONSTANTS.IN_STORE_VALUE);
      handleCloseClick();
    }
  };

  /**
   * PS_PO_27
   * This function set the payment origin to phone
   * when triggered
   * PS_PO_33
   */
  const handleYesClick = () => {
    setLoadingSaveYes(true);
    agreementInfo.setPaymentOrigin(CONSTANTS.IN_PHONE_VALUE);
    const agreementContextClone: Customer = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );

    const convinenceFee = agreementInfo.customerInfo.convinenceFee;
    const convinenceFeeWithTax: string = convinenceFee[0].tax;
    agreementContextClone.amountUsed.tenderDetails.cash.amount =
      CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    agreementContextClone.amountUsed.convenienceFee = (
      parseFloat(convinenceFee[0].amount) + parseFloat(convinenceFeeWithTax)
    ).toFixed(2);
    agreementInfo.setCustomerInfo(agreementContextClone);
    handleCloseClick();
  };

  /**
   * PS_PO_11 - this function will close the yes/no popup
   * @param buttonType
   */
  const handleNoClick = () => {
    setActiveButton(null);
    setShowConvenienceFee(false);
  };

  /**
   * this dynamically assign attribute for the element
   * @param buttonType
   * @returns attributes
   */
  const getButtonProps = (
    buttonType: string
  ): {
    variant: ButtonVariant;
    color: ButtonColor;
    style: React.CSSProperties;
    disabled: boolean;
  } => {
    const isActive = activeButton === buttonType;
    return {
      variant: isActive ? 'contained' : 'outlined',
      color: isActive ? 'primary' : 'secondary',
      style: {
        fontWeight: 'bold',
        color: isActive ? '#fff' : '#4A5174',
        backgroundColor: isActive ? '#2179FE' : 'transparent',
        opacity: isActive ? 0.5 : 1,
      },
      disabled: activeButton !== null ? true : false,
    };
  };

  /**
   * PS_PO_13
   * @returns HTML for the popup
   * PS_PO_34
   */
  const paymentOriginPopup = () => (
    <Grid>
      <Grid className={classes.py1}>
        <Typography
          style={{
            fontFamily: 'OpenSans-semiBold',
            fontSize: '14px',
            paddingLeft: '26px',
            color: '#4A5174',
          }}
        >
          Please select the payment origin for this transaction.
        </Typography>
      </Grid>
      <Grid className={`${classes.py4} ${classes.textCenter}`}>
        <RACButton
          key={Math.random()}
          className={classes.mx1}
          {...getButtonProps(CONSTANTS.IN_PHONE_VALUE)}
          data-testid="inPhoneButton"
          onClick={() => handleButtonClick(CONSTANTS.IN_PHONE_VALUE)}
        >
          Over the Phone
        </RACButton>
        <RACButton
          key={Math.random()}
          className={`${classes.mx1} ${classes.mx2}`}
          {...getButtonProps(CONSTANTS.IN_STORE_VALUE)}
          data-testid="inStoreButton"
          loading={loadingStore}
          onClick={() => handleButtonClick(CONSTANTS.IN_STORE_VALUE)}
        >
          In Store
        </RACButton>
      </Grid>
      {showConvenienceFee && (
        <>
          <Typography variant="body2" className={classes.textCenter}>
            <span className={classes.notesLabel}>
              A convenience fee of $
              {Number(convienevnceFeeWithoutTax).toFixed(2)} (plus tax when
              applicable) will be added. Would you like to continue?
            </span>
          </Typography>
          <Grid style={{ display: 'flex', float: 'right', padding: '10px' }}>
            <RACButton
              className={`${classes.floatRight} ${classes.ms2}`}
              color="primary"
              variant="contained"
              loading={loadingSaveYes}
              style={{ marginRight: '2px' }}
              data-testid="phoneYesButton"
              onClick={handleYesClick}
            >
              Yes
            </RACButton>
            <RACButton
              className={`${classes.floatRight} ${classes.ms2}`}
              color="default"
              variant="outlined"
              data-testid="phoneNoButton"
              onClick={handleNoClick}
            >
              No
            </RACButton>
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <RACModalCard
      isOpen
      maxWidth="xs"
      title="Payment Origin"
      onClose={() => onClose(false)}
      closeIcon={true}
    >
      {paymentOriginPopup()}
    </RACModalCard>
  );
};
