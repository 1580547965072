/*eslint-disable*/

import React,{ useState, useEffect ,useContext} from 'react';
import { takePaymentStyles } from '../../../stylesJS/makeStyles';
import {
  RACTextbox,
  RACButton,
  RACModalCard,
  Grid,
  makeStyles,
} from '@rentacenter/racstrap';
// import { carryWaiveLateFeeOnblurCalci } from '../../../utils/utils';

import { agreementContext } from '../../context/PaymentInformationContext';
import { AgreementDetails } from '../../interface/paymentInfoInterface';
import CONSTANTS from '../../constants/constant';
import { AgreementPaymentDetails, AmountDetails } from '../../interface/calculatePaymentResponse';
import {calculateModule} from '../../../utils/utils';
interface lateFeeObject {
  totalFeesOwed: string | number;
  amountToCollect: string | number;
  carryLateFee: string | number;
  waivedLateFee: string | number
}
interface CarryWaiveLateFeePopUpProps {
  isOpen: boolean;
  close: (res: boolean) => void;
  index: number | undefined;
  customerId: string;
  selectedAgreements: AgreementDetails[];
}
export function CarryWaiveLateFeePopUp(props:CarryWaiveLateFeePopUpProps){
  /**
   * PS_NO_CWL_7
   * The state variable declaration is done here
   */
    const classes = takePaymentStyles();
    const [savebtnDisable,setSavebtnDisable] = useState<boolean>(false);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [lateFeeObject, setLateFeeObject] = useState<lateFeeObject>({
      totalFeesOwed: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      amountToCollect:  CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      carryLateFee:  CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      waivedLateFee:  CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    });
    const{setAgreementDetails,renderContext,setRenderContext}=useContext(agreementContext);

    useEffect(()=>{
        if(props.selectedAgreements[0].lateFee> CONSTANTS.ZREO_TWO_DECIMAL_STRING)
            {
                const obj:lateFeeObject={
                    totalFeesOwed: props.selectedAgreements[0]?.originalLateFee??CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                    amountToCollect:props.selectedAgreements[0].lateFee,
                    carryLateFee: props.selectedAgreements[0]?.carriedLateFee??CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                    waivedLateFee: props.selectedAgreements[0]?.waivedLateFee??CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                  };

                  setLateFeeObject(obj);

            }
    },[])

    /**
     * PS_NO_CWL_12
     * The useEffect is triggered once the edited values are updated and it helps tjo
     * enable and disable the continue button.
     */
    useEffect(()=>{

        const sumofAmount=Number(Number(lateFeeObject.amountToCollect) +Number(lateFeeObject.carryLateFee)+Number(lateFeeObject.waivedLateFee)).toFixed(2);
        if(sumofAmount>Number(lateFeeObject.totalFeesOwed).toFixed(2) ||sumofAmount<= CONSTANTS.ZREO_TWO_DECIMAL_STRING || Number(lateFeeObject.carryLateFee).toFixed(2)>'4.00')
            {
                setSavebtnDisable(true);
            }
            else{
                setSavebtnDisable(false);
            }
    },[lateFeeObject])

    /**
     * PS_NO_CWL_10 - PS_NO_CWL_11
     * The handleOnchangeLateFee() method is invoked when the user enters the amount in
     * the text fields.
     * @param e
     */
   const handleOnchangeLateFee=(e:React.ChangeEvent<HTMLInputElement>)=>{
    switch(e.target.name){
        case CONSTANTS.AMOUNT_TO_COLLECT:
            setLateFeeObject({...lateFeeObject,'amountToCollect':e.target.value});
            break;
        case CONSTANTS.CARRY_LATE_FEE:
            setLateFeeObject({...lateFeeObject,'carryLateFee':e.target.value});
            break;
        case CONSTANTS.WAIVED_LATE_FEE:
            setLateFeeObject({...lateFeeObject,'waivedLateFee':e.target.value});
            break;
    }

   }
   /**
    *PS_NO_CWL_14 - PS_NO_CWL_31
    The  handleOnBlurLateFee() method is triggered when we click outside the text fields.
    * @param e
    */

    const handleOnBlurLateFee = (e: React.ChangeEvent<HTMLInputElement>) => {

      const { name, value } = e.target;
      const updatedLateFeeObject = { ...lateFeeObject };

      switch (name) {
        case CONSTANTS.AMOUNT_TO_COLLECT:
          handleAmountToCollect(updatedLateFeeObject, Number(value));
          break;
        case CONSTANTS.CARRY_LATE_FEE:
          handleCarryLateFee(updatedLateFeeObject, Number(value));
          break;
        case CONSTANTS.WAIVED_LATE_FEE:
          handleWaivedLateFee(updatedLateFeeObject, Number(value));
          break;
      }

      setLateFeeObject(updatedLateFeeObject);
    };

    const handleAmountToCollect = (lateFeeObj: lateFeeObject, collectAmount: number) => {

      const { totalFeesOwed, carryLateFee, waivedLateFee } = lateFeeObj;
      const remainingAmount = Number(Number(totalFeesOwed) - collectAmount).toFixed(2);

      if (collectAmount < Number(totalFeesOwed) && carryLateFee == CONSTANTS.ZERO_TWO_DECIMAL_NUMBER) {
        lateFeeObj.amountToCollect = Number(collectAmount).toFixed(2);
        lateFeeObj.waivedLateFee = Number(Number(totalFeesOwed) - collectAmount).toFixed(2);
      } else if (collectAmount == totalFeesOwed || collectAmount > Number(totalFeesOwed)) {
        lateFeeObj.amountToCollect = Number(collectAmount).toFixed(2);
        lateFeeObj.carryLateFee = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
        lateFeeObj.waivedLateFee = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      } else if (collectAmount < Number(totalFeesOwed) && (carryLateFee != CONSTANTS.ZERO_TWO_DECIMAL_NUMBER || waivedLateFee != CONSTANTS.ZERO_TWO_DECIMAL_NUMBER)) {
        handleComplexAmountToCollect(lateFeeObj, collectAmount, Number(remainingAmount));
      }
    };

    const handleComplexAmountToCollect = (lateFeeObj: lateFeeObject, collectAmount: number, remainingAmount: number) => {

      const { carryLateFee } = lateFeeObj;

      if (Number(carryLateFee) <= CONSTANTS.FOUR_NUMBER) {
        if (remainingAmount >= Number(carryLateFee)) {
          lateFeeObj.amountToCollect = Number(collectAmount).toFixed(2);
          lateFeeObj.waivedLateFee = Number(remainingAmount - Number(carryLateFee)).toFixed(2);
        } else {
          lateFeeObj.amountToCollect = Number(collectAmount).toFixed(2);
          lateFeeObj.carryLateFee = Number(remainingAmount).toFixed(2);
          lateFeeObj.waivedLateFee = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
        }
      } else {
        lateFeeObj.amountToCollect = Number(collectAmount).toFixed(2);
        lateFeeObj.carryLateFee = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
        lateFeeObj.waivedLateFee = Number(remainingAmount).toFixed(2);
      }
    };

    const handleCarryLateFee = (lateFeeObj: lateFeeObject, carryAmount: number) => {

      const { totalFeesOwed, waivedLateFee, amountToCollect } = lateFeeObj;
      const remainingAmount = Number(Number(totalFeesOwed) - carryAmount).toFixed(2);

      if (carryAmount <= CONSTANTS.FOUR_NUMBER) {
        handleCarryLateFeeUnderFour(lateFeeObj, carryAmount, Number(remainingAmount), Number(waivedLateFee));
      } else {
        lateFeeObj.carryLateFee = Number(carryAmount).toFixed(2);
        lateFeeObj.amountToCollect =CONSTANTS.ZREO_TWO_DECIMAL_STRING;
        lateFeeObj.waivedLateFee = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      }
    };

    const handleCarryLateFeeUnderFour = (lateFeeObj: lateFeeObject, carryAmount: number, remainingAmount: number, waiveAmount: number) => {
      if (remainingAmount < waiveAmount) {
        lateFeeObj.amountToCollect = remainingAmount < CONSTANTS.ZERO_NUMBER ? CONSTANTS.ZREO_TWO_DECIMAL_STRING : Number(remainingAmount).toFixed(2);
        lateFeeObj.carryLateFee = Number(carryAmount).toFixed(2);
        lateFeeObj.waivedLateFee = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      } else if (remainingAmount == waiveAmount) {
        lateFeeObj.amountToCollect = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
        lateFeeObj.carryLateFee = Number(carryAmount).toFixed(2);
      } else {
        const newRemaining = remainingAmount - waiveAmount;
        lateFeeObj.amountToCollect = newRemaining < CONSTANTS.ZERO_NUMBER ? CONSTANTS.ZREO_TWO_DECIMAL_STRING : Number(newRemaining).toFixed(2);
        lateFeeObj.carryLateFee = Number(carryAmount).toFixed(2);
      }
    };

    const handleWaivedLateFee = (lateFeeObj: lateFeeObject, waivedAmount: number) => {
      const { totalFeesOwed, carryLateFee } = lateFeeObj;
      const remainingLateFee = Number(totalFeesOwed) - waivedAmount;

      if (waivedAmount == totalFeesOwed || waivedAmount > Number(totalFeesOwed)) {
        lateFeeObj.waivedLateFee = Number(waivedAmount).toFixed(2);
        lateFeeObj.carryLateFee = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
        lateFeeObj.amountToCollect = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      } else if (waivedAmount < Number(totalFeesOwed)) {
        handlePartialWaivedLateFee(lateFeeObj, waivedAmount, remainingLateFee);
      }
    };

    const handlePartialWaivedLateFee = (lateFeeObj: lateFeeObject, waivedAmount: number, remainingLateFee: number) => {
      const { carryLateFee } = lateFeeObj;

      if (Number(carryLateFee) <= CONSTANTS.FOUR_NUMBER) {
        if (remainingLateFee >= Number(carryLateFee)) {
          lateFeeObj.waivedLateFee = Number(waivedAmount).toFixed(2);
          lateFeeObj.amountToCollect = Number(remainingLateFee - Number(carryLateFee)).toFixed(2);
        } else {
          lateFeeObj.waivedLateFee = Number(waivedAmount).toFixed(2);
          lateFeeObj.carryLateFee = Number(remainingLateFee).toFixed(2);
          lateFeeObj.amountToCollect = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
        }
      } else {
        lateFeeObj.waivedLateFee = Number(waivedAmount).toFixed(2);
        lateFeeObj.amountToCollect = Number(remainingLateFee).toFixed(2);
        lateFeeObj.carryLateFee=  CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      }
    };

   /**
    * PS_NO_CWL_39
    * The cancelonClick() method is invoked when the user clicks the cancel button.
    */
   const cancelonClick =()=>{
     setLateFeeObject({
        totalFeesOwed: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        amountToCollect: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        carryLateFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        waivedLateFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      });
      props.close(false);

   }

   /**
    * PS_NO_CWL_33 - PS_NO_CWL_38
    * The carryWaiveContinueOnclick() method is triggered when the user click the
    * continue button.
    */
   const carryWaiveContinueOnclick=async()=>{
    let commonModuleAgrRes:AgreementPaymentDetails[]=[]
     setLoadingSave(true);
    const lateFeeAmountWithTax=Number(props.selectedAgreements[0].lateFee) + Number(props.selectedAgreements[0].lateFeetax);
     const EPOAmount=Number(props.selectedAgreements[0].epoAmount)-lateFeeAmountWithTax;
     props.selectedAgreements[0].carriedLateFee=String(lateFeeObject.carryLateFee);
     props.selectedAgreements[0].waivedLateFee=String(lateFeeObject.waivedLateFee);

       const commonModuleServiceRes = await calculateModule(
        props.customerId,
        String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)),
        props.selectedAgreements
      );
       setLoadingSave(false);
       commonModuleAgrRes = commonModuleServiceRes?.paymentDetails[0]?.agreementPaymentDetails.filter(
         (x: AgreementPaymentDetails) => String(x.agreementId) === String(props.selectedAgreements[0].agreementId) );
       const lateFee:AmountDetails[] = commonModuleAgrRes[0]?.amountDetails?.filter((x:AmountDetails)=>x.itemCategory== CONSTANTS.LTRPAYFEE)??[];
       const carryLateFee:string =commonModuleAgrRes[0]?.amountDetails?.filter((x:AmountDetails)=>x.itemCategory== CONSTANTS.CLFEE)[0]?.amount??CONSTANTS.ZREO_TWO_DECIMAL_STRING;
       const waiveLateFee:string= commonModuleAgrRes[0]?.amountDetails?.filter((x:AmountDetails)=>x.itemCategory== CONSTANTS.CLFEEWAV)[0]?.amount??CONSTANTS.ZREO_TWO_DECIMAL_STRING;

         if (props?.index !=undefined && props?.index >=0 ) {
           setAgreementDetails((prevDetails:AgreementDetails[]) =>
             prevDetails.map((detail:AgreementDetails, i:number) =>
               i === props.index
                 ? updateAgreementDetail(detail,lateFee,carryLateFee,waiveLateFee,commonModuleAgrRes,EPOAmount)
                 : detail
             )
           );
           setRenderContext(!renderContext);
         }
         props.close(false);

    }

    const updateAgreementDetail=(detail:AgreementDetails,lateFee:AmountDetails[],carryLateFee:string,waiveLateFee:string,commonModuleAgrRes:AgreementPaymentDetails[],EPOAmount:number )=>{

      return {
        ...detail,
        totalTax: Number(commonModuleAgrRes[0].totalTax).toFixed(2),
        lateFee: lateFee?.length > CONSTANTS.ZERO_NUMBER ? Number(lateFee[0].amount).toFixed(2) : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        lateFeeTax: lateFee?.length > CONSTANTS.ZERO_NUMBER ? Number(lateFee[0].tax).toFixed(2) : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        totalDueAmount:(Number(commonModuleAgrRes[0].totalAmount)+Number(commonModuleAgrRes[0].totalTax)).toFixed(2),
        waivedLateFee: waiveLateFee ? waiveLateFee : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        carriedLateFee: carryLateFee?carryLateFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        epoAmount:Number(EPOAmount+Number(lateFee[0].amount)+ Number(lateFee[0].tax)).toFixed(2)

      }
    }
    /**
     * PS_NO_CWL_9
     * The carryWaivePopUp() is used to render the entire UI of the carry/waive late fee
     * @returns
     */
    const carryWaivePopUp = () => {
        //(Pseudo no : PC_CW_08)  This func will be called in the return part
        return (
          <>
            <Grid container spacing={3} className={classes.px3}>
              <Grid item md={6} className={classes.mb2}>
                <RACTextbox
                  inputlabel='Total Fees Owed'
                  inputLabelClassname={classes.waiveLabel}
                  className={classes.waiveText}
                  value={String(lateFeeObject.totalFeesOwed)}
                  isCurrency={true}
                  disabled={true}
                  required={true}
                />
              </Grid>
              <Grid
                item
                md={6}
                className={classes.mb2}
                style={{ marginRight: '-25px' }}
              >
                <RACTextbox
                  inputlabel='Amount To Collect'
                  inputLabelClassname={classes.waiveLabel}
                  className={classes.waiveText}
                  value={String(lateFeeObject.amountToCollect)}
                  isCurrency={true}
                  OnChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnchangeLateFee(e)}
                  Onblur={(e: React.ChangeEvent<HTMLInputElement>) => handleOnBlurLateFee(e)}
                  name='amountToCollect'
                  type={'number'}
                  maxlength={5}
                />
                {Number(lateFeeObject.amountToCollect) >
                Number(lateFeeObject.totalFeesOwed) ? (
                  <label className={classes.mandatoryfield}>
                    Amount shouldn&apos;t greater than total fees owed.
                  </label>
                ) : null}
              </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.px3}>
              <Grid item md={6} className={classes.mb2}>
                {/* <Grid style={{ fontSize: 'small', fontWeight: 'bold', marginTop: '2%', marginBottom: '2%', marginLeft: '5%' }} ><span><label className='classes.formLabel'>Carry Late Fee Amount (max $4.00)</label></span></Grid> */}
                <RACTextbox
                  inputlabel='Carry Late Fee Amount (max $4.00)'
                  inputLabelClassname={classes.waiveLabel}
                  className={classes.waiveText}
                  value={String(lateFeeObject.carryLateFee)}
                  isCurrency={true}
                  OnChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnchangeLateFee(e)}
                  Onblur={(e: React.ChangeEvent<HTMLInputElement>) => handleOnBlurLateFee(e)}
                  name='carryLateFee'
                  type='number'
                  maxlength={5}
                />
                {Number(lateFeeObject.carryLateFee) > Number(4.0) ? (
                  <label className={classes.mandatoryfield}>
                    Amount shouldn&apos;t greater than $4.00
                  </label>
                ):Number(lateFeeObject.carryLateFee) > Number(lateFeeObject.totalFeesOwed) ? (
                    <label className={classes.mandatoryfield}>
                      Amount shouldn&apos;t greater than total fees owed.
                    </label>
                  ) : null}
              </Grid>
              <Grid
                item
                md={6}
                className={classes.mb2}
                style={{ marginRight: '-25px' }}
              >
                <RACTextbox
                  inputlabel='Waived Late Fee Amount'
                  inputLabelClassname={classes.waiveLabel1}
                  className={classes.waiveText}
                  value={String(lateFeeObject.waivedLateFee)}
                  isCurrency={true}
                  OnChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnchangeLateFee(e)}
                  Onblur={(e: React.ChangeEvent<HTMLInputElement>) => handleOnBlurLateFee(e)}
                  maxlength={5}
                  name='waivedLateFee'
                  type={'number'}
                />
                {Number(lateFeeObject.waivedLateFee) > Number(lateFeeObject.totalFeesOwed) ? (
                  <label className={classes.mandatoryfield}>
                    Amount shouldn&apos;t greater than total fees owed.
                  </label>
                ) : null}
              </Grid>

            </Grid>
            <Grid style={{ float: 'right' }}>
              <RACButton
                style={{
                  marginRight: '20px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
                color='inherit'
                variant='outlined'
                data-testid='lateFee-cancel-btn'
                onClick={() =>props.close(false)
                //  onClickCancel()
                }
              >
                Cancel
              </RACButton>
              <RACButton
                style={{ marginRight: '15px' }}
                color='primary'
                variant='contained'
                disabled={savebtnDisable}
                loading={loadingSave}
                data-testid='lateFee-continue-btn'
                onClick={() => carryWaiveContinueOnclick()}
              >
                Continue
              </RACButton>
            </Grid>
          </>
        );
      };
    return(
        <>
        <RACModalCard
        isOpen={props.isOpen}
        maxWidth='xs'
        title='Carry Waive/Late Fees'
        onClose={() => cancelonClick()}
        closeIcon={true}
        borderRadius='20px'
        // children={carryWaivePopUp()}
      >
        {carryWaivePopUp()}
        </RACModalCard>
        </>
    )

}
