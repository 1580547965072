/*eslint-disable */
import { makeStyles } from '@rentacenter/racstrap';

export const customerInfoStyle = () =>{
    const useClasses = makeStyles((theme) => ({
        customerAddress: {
          display: 'flex',
          flexDirection: 'column',
          padding: `${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(12)}`,
        },
        addressLineWrapper: { display: 'flex', flexDirection: 'row' },
        saveButton: {
          height: theme.typography.pxToRem(43),
          marginTop: theme.typography.pxToRem(16),
        },
        row: { marginBottom: theme.typography.pxToRem(24) },
        selectOptionsPaper: {
          maxHeight: theme.typography.pxToRem(200),
        },
        spacerPX2: {
      
          paddingLeft: theme.typography.pxToRem(8),
          paddingRight: theme.typography.pxToRem(8),
      
        },
        RACPOPMsg: {
          fontFamily: 'OpenSans-bold',
          fontSize: theme.typography.pxToRem(16),
          letterSpacing: 0,
        },
        bold: {
          fontFamily: 'OpenSans-bold',
        },
        semiBold: {
          fontFamily: 'OpenSans-semibold',
        },
        dispInlineFlex: {
          display: 'inline-flex',
        },
        spacerMB2: {
          marginBottom: theme.typography.pxToRem(8),
        },
        spacerMT2: {
          marginTop: theme.typography.pxToRem(16),
        },
        formCheck: {
          minHeight: '1.5rem',
          marginBottom: '0.125rem',
          display: 'inline-block',
          marginRight: '1rem',
          paddingLeft: 0,
        },
        spacerMT4: {
          marginTop: theme.typography.pxToRem(24),
        },
        w100: {
          width: '100%',
        },
        floatLeft: {
          float: 'left',
        },
        foc: {
          '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '1px 1px 6px 3px #80bdff',
          },
        },
        floatRight: {
          float: 'right',
        },
        textCenter: {
          textAlign: 'center',
        },
        justifyContentCenter: {
          justifyContent: 'center',
        },
        dialogContent: {
            marginBottom: '2rem',
          },
          dialogContent2: {
            textAlign: 'center',
            fontFamily: 'OpenSans-Bold',
          },
          spacerPX2: {
        
            paddingLeft: theme.typography.pxToRem(8),
        
            paddingRight: theme.typography.pxToRem(8),
        
          },
          customerTextcenter: {
        
            textAlign: "center",
        
          },
          customerRacpopup: {
        
            fontFamily: "OpenSans-bold",
        
            fontSize: theme.typography.pxToRem(16),
        
            letterSpacing: "0px",
        
          },
        
          customerJustifycontentcenter: {
        
            justifyContent: "center",
        
          },
        
          spacerMT4: {
        
            marginTop: theme.typography.pxToRem(24),
        
          },
        
          spacerMR2: {
        
            marginRight: theme.typography.pxToRem(8),
        
          },
          dialogRoot: {
            '& .MuiPaper-rounded': {
              borderRadius: theme.typography.pxToRem(12),
            },
            '& .MuiDialog-paperWidthXs': {
              maxWidth: theme.typography.pxToRem(500),
            },
            '& .MuiTypography-h5': {
              fontFamily: 'OpenSans-semiBold',
              fontSize: theme.typography.pxToRem(20),
              fontWeight: 500,
              lineHeight: theme.typography.pxToRem(30),
            },
            '& .MuiTypography-h6': {
              fontFamily: 'OpenSans-semiBold',
              lineHeight: theme.typography.pxToRem(20),
            },
          },
          suggestedAddressesHeadline: {
            fontFamily: 'OpenSans-Bold !important',
            margin: '10px 0 !important',
          },
          suggestedAddressesInput: {
            fontSize: `${theme.typography.pxToRem(14)} !important`,
          },
          buttonRoot: {
            margin: 'auto',
          },
          cancelButton: {
            margin: 'auto',
            marginLeft: theme.typography.pxToRem(16),
          },
          marginRight2:{
            marginRight: '10px'
          }
      }));

      const classes = useClasses();
      return classes;
}