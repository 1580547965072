/*eslint-disable react-hooks/exhaustive-deps*/
/**
 * PS_RE_2
 * Import the required packages, interface and context
 */
import React from 'react';
import {
  RACButton,
  RACModalCard,
  Grid,
  Typography,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACRadio,
} from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import { useContext, useEffect, useState } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import { useHistory, useParams } from 'react-router-dom';
import { ParamType } from '../interface/commonInterface';
import { ellipsisFormat } from '../utils/scheduleAndDateFormat';
import CONSTANTS from '../constants/constant';
import { AgreementDetails } from '../interface/paymentInfoInterface';
import { SuspenseProps } from '../interface/suspenseInterface';
/**
 * PS_RE_3 - PS_RE_30
 * Declare a functional based component as RACExchangeAlertPopUp()
 * @returns
 */
export const RACExchangeAlertPopUp = (props: SuspenseProps) => {
  /**
   * PS_RE_4 - PS_RE_8
   * Declaring and initializing variables
   * Destructing agreementDetails from the context
   */
  const classes = takePaymentStyles();
  const { agreementDetails, isSetToDefault } = useContext(agreementContext);
  const storeNumber = sessionStorage.getItem(CONSTANTS.STORE_NUMBER);
  const agreementsPendingForReturn = agreementDetails?.filter(
    (agreement: AgreementDetails) =>
      agreement.isExchanged && agreement.storeNumber == storeNumber
  );
  const { customerId, agreementId } = useParams<ParamType>();
  const history = useHistory();
  const [showReturnPopup, setShowReturnPopup] = useState<boolean>(false);
  const [agreementIdSelectionPopUp, setAgreementIdSelectionPopUp] =
    useState<boolean>(false);
  const [returnAgreementId, setReturnAgreementId] = useState<string>(
    CONSTANTS.EMPTY_STRING
  );
  const header = CONSTANTS.RAC_EXCHANGE_RETURN_ALERT_POPUP_HEADER;
  /**
   * PS_RE_9 - PS_RE_10
   * within this useEffect, it check for no of pending agreements
   * If atleast one pending agreement and not initialPayment flow, it displays popup
   */
  useEffect(() => {
    if (
      agreementsPendingForReturn.length >= CONSTANTS.ONE_NUMBER &&
      agreementId == CONSTANTS.ZERO_STRING
    ) {
      setShowReturnPopup(true);
      setReturnAgreementId(agreementsPendingForReturn[0]?.agreementId);
    }
  }, [isSetToDefault]);

  useEffect(() => {
    props.popupValue == CONSTANTS.RACEXCHANGE_POPUPS &&
      agreementsPendingForReturn.length == CONSTANTS.ZERO_NUMBER &&
      props.setPopupValue(CONSTANTS.PAYMENT_ORIGIN_POPUP);
  }, [props.popupValue]);

  /**
   * PS_RE_11 - PS_RE_16
   * This function gets all the pending agreements number and displays it in the popup
   * @returns
   */
  const agreementsReturnPendingAlertPopup = () => {
    const agreementNumbers: string[] = [];
    agreementsPendingForReturn.map((ele: AgreementDetails) =>
      agreementNumbers.push(ele.agreementNumber)
    );
    let agreementNumberString = agreementNumbers.join(
      CONSTANTS.COMMA_WITH_SPACE
    );
    agreementNumberString = ellipsisFormat(agreementNumberString, 20);

    return (
      <Grid>
        <Grid
          item
          id="returnPendingAlertPopUp"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="ReturnPendingAlertPopUp"
          aria-hidden="true"
          data-testid="popupGrid"
        >
          <Grid item md={12} className={classes.textCenter}>
            <AlertIcon></AlertIcon>
            <Typography
              variant={'body2'}
              className={classes.RACPOPMsg}
              title={agreementNumbers.join(CONSTANTS.COMMA_WITH_SPACE)}
            >
              {`Exchange Agreement ${agreementNumberString} return not complete, Do you want to process?`}
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            className={`${classes.textcenter} ${classes.mt3} ${classes.modalfooter}`}
          >
            <RACButton
              data-testid="noBtn"
              className={` ${classes.mr15px}`}
              color="primary"
              variant="outlined"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              onClick={() => {
                close();
              }}
            >
              No
            </RACButton>
            <RACButton
              data-testid="yesBtn"
              className={`${classes.mr15px}`}
              color="primary"
              variant="contained"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              onClick={() => {
                agreementsPendingForReturn.length > CONSTANTS.ONE_NUMBER
                  ? setAgreementIdSelectionPopUp(true)
                  : history.push(
                      `/agreement/info/details/${customerId}/${returnAgreementId}?return=payment`
                    );
              }}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  /**
   * PS_RE_17 - PS_RE_21
   * This popup displays multiple pending agreements and alows to select one
   * with cancel and next button.
   * on click of the next button, it navigates the user
   * @returns
   */
  const agreementIdSelectionPopup = () => {
    return (
      <Grid className={`${classes.textright}`}>
        {
          <RACTable
            className={classes.racGrid}
            renderTableHead={bindHead}
            renderTableContent={bindBody}
          />
        }
        <Grid
          className={`${classes.buttonsPadding} ${classes.floatRight} ${classes.pr0}`}
        >
          <RACButton
            data-testid="cancelBtn"
            variant="outlined"
            color="primary"
            className={`${classes.customerFloatleft} ${classes.mr15px}`}
            onClick={() => {
              close();
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            data-testid="nextBtn"
            variant="contained"
            color="primary"
            className={`${classes.customerFloatleft} ${classes.mr15px}`}
            onClick={() => {
              history.push(
                `/agreement/info/details/${customerId}/${returnAgreementId}?return=payment`
              );
            }}
          >
            Next
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  /**
   * PS_RE_22 - PS_RE_23
   * This function binds header for the agreement id selection popup
   * @returns
   */
  function bindHead() {
    return (
      <>
        {header.map((element: string, index: number) => (
          <RACTableCell
            key={index}
            className={
              element == 'Agreement #'
                ? `${classes.width40}`
                : element == 'Description'
                ? `${classes.width50}`
                : element == ' '
                ? `${classes.width10}`
                : ''
            }
          >
            {element}
          </RACTableCell>
        ))}
      </>
    );
  }

  /**
   * PS_RE_24 - PS_RE_25
   * This function binds body for the agreement id selection popup
   * @returns
   */
  function bindBody() {
    return (
      <>
        {agreementsPendingForReturn?.map(
          (element: AgreementDetails, index: number) => (
            <RACTableRow
              className={`${classes.bgWhilte} ${classes.w100}`}
              key={index}
            >
              <RACTableCell className={`${classes.width10}`}>
                <RACRadio
                  role={`${index}` + 'id'}
                  onChange={() => {
                    setReturnAgreementId(element.agreementId);
                  }}
                  value={element}
                  name="agreementId"
                  checked={element?.agreementId == returnAgreementId}
                />
              </RACTableCell>
              <RACTableCell className={`${classes.width40}`}>
                {element?.agreementNumber}
              </RACTableCell>
              <RACTableCell className={`${classes.width50}`}>
                {element?.agreementDesc &&
                element?.agreementDesc.length > CONSTANTS.TWENTY_FIVE
                  ? ellipsisFormat(
                      element?.agreementDesc,
                      CONSTANTS.TWENTY_FIVE
                    )
                  : element?.agreementDesc}
              </RACTableCell>
            </RACTableRow>
          )
        )}
      </>
    );
  }

  /**
   * PS_RE_26 - PS_RE_27
   * this closes both popups
   */
  const close = () => {
    props.setPopupValue(CONSTANTS.PAYMENT_ORIGIN_POPUP);
    setShowReturnPopup(false);
    setAgreementIdSelectionPopUp(false);
  };

  /**
   * PS_RE_28 - PS_RE_30
   * Within return, it holds modal card for agreement pending and
   * agreement id selection popup
   */
  return (
    <>
      {props.popupValue == CONSTANTS.RACEXCHANGE_POPUPS ? (
        <>
          <RACModalCard
            isOpen={
              showReturnPopup &&
              props.popupValue == CONSTANTS.RACEXCHANGE_POPUPS
            }
            maxWidth="xs"
            closeIcon={true}
            borderRadius="16px"
            onClose={() => close()}
            className={`${classes.customModal4}`}
          >
            {agreementsReturnPendingAlertPopup()}
          </RACModalCard>

          <RACModalCard
            isOpen={agreementIdSelectionPopUp}
            maxWidth="xs"
            closeIcon={true}
            borderRadius="16px"
            title="Return Pending Agreement"
            onClose={() => close()}
            className={`${classes.customModal4}`}
          >
            {agreementIdSelectionPopup()}
          </RACModalCard>
        </>
      ) : null}
    </>
  );
};

/**
 * Need to handle the useEffect triggering scenario to display the popup on load
 */
