/*eslint-disable react-hooks/exhaustive-deps*/
/**
 * PS_SC_01
 * Import the required packages, interface and context
 */
import React, { useContext, useEffect, useState } from 'react';
import {
  RACTextbox,
  RACButton,
  RACCheckBox,
  RACModalCard,
  RACTable,
  RACTableRow,
  RACTableCell,
  Grid,
  Typography,
} from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { PayHistory } from '../../stylesJS/PayHistory';
import { agreementContext } from '../context/PaymentInformationContext';
import CONSTANTS from '../constants/constant';
import { schedule } from '../utils/scheduleAndDateFormat';
import { AgreementDetails } from '../interface/paymentInfoInterface';
import { AgreementContextValue } from '../interface/contextInterface';

/**
 * PS_SC_02 - PS_SC_21
 * When add suspense is triggered
 * @returns
 */
export function AddSuspenseComponent() {
  /**
   * PS_SC_04 - PS_SC_05
   * Declaring and initializing variables
   */
  const classes = takePaymentStyles();
  const {
    agreementDetails,
    setAgreementDetails,
    modalCardEnable,
    setModalCardEnable,
    renderContext,
    setRenderContext,
    customerInfo,
    setCustomerInfo,
  } = useContext<AgreementContextValue>(agreementContext);
  const payclasses = PayHistory();
  const [renderer, setRenderer] = useState<boolean>(false);
  const [agreementDetailsClone] = useState<AgreementDetails[]>(
    JSON.parse(JSON.stringify(agreementDetails))
  );
  const [addButtonDisable, setAddButtonDisable] = useState<boolean>(false);

  /**
   * within useEffect, set the disable condition for button
   */
  useEffect(() => {
    setAddButtonDisable(
      agreementDetailsClone.some((el: AgreementDetails) => el.error)
    );
  }, [renderer]);

  /**
   * PS_SC_12 - PS_SC_20
   * This function triggers in onChange of input field is clicked
   * @param e
   * @param param1
   */
  const addSusupense = (
    e: React.ChangeEvent<HTMLInputElement>,
    { epoAmount, suspenseAmount }
  ) => {
    const value = e.target.value.replace(
      CONSTANTS.REPLACE_COMMA,
      CONSTANTS.EMPTY_STRING
    );
    const index = agreementDetailsClone.findIndex(
      (el) => el.agreementId == e.target.id
    );

    agreementDetailsClone[index].suspenseDetails.suspAdd = value;

    if (Number(value) + Number(suspenseAmount) > epoAmount)
      agreementDetailsClone[index].error =
        CONSTANTS.AMOUNT_ENTERED_CANNOT_BE_GREATER_THAN_EPO;
    else if (
      epoAmount > 0 &&
      Number(value) + Number(suspenseAmount) == epoAmount
    )
      agreementDetailsClone[index].error =
        CONSTANTS.AMOUNT_EQUAL_TO_EPO_USE_EPO_FUNCTIONALITY;
    else delete agreementDetailsClone[index]?.error;
    setRenderer((prev) => !prev);
  };

  /**
   * PS_SC_09 - PS_SC_12
   * This function binds the suspense data to the grid
   * @returns
   */
  const bindSuspenseBody = () => {
    return (
      <>
        {agreementDetailsClone.map(
          (eachAgreement: AgreementDetails, index: number) => {
            const {
              agreementNumber,
              agreementSchedule,
              totalDueAmount,
              epoAmount,
              suspenseAmount,
              agreementId,
            } = eachAgreement;
            return (
              eachAgreement.selected && (
                <RACTableRow
                  key={index}
                  className={payclasses.payHistbgTransparent}
                >
                  <RACTableCell>
                    <Typography variant="caption" color="primary">
                      {agreementNumber}
                    </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption">
                      {' '}
                      {schedule(agreementSchedule)}{' '}
                    </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption">{totalDueAmount}</Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption"> {epoAmount} </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption">
                      {suspenseAmount ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING}
                    </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <RACTextbox
                      id={agreementId}
                      isCurrency={true}
                      className={`${classes.custDigit} ${classes.cashtxtbox}`}
                      dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                      value={eachAgreement.suspenseDetails.suspAdd}
                      name={'suspenseTxtbox' + index}
                      digitFormat={'currency'}
                      type="number"
                      maxlength={10}
                      errorMessage={
                        eachAgreement.error ?? CONSTANTS.EMPTY_STRING
                      }
                      OnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        addSusupense(e, { epoAmount, suspenseAmount })
                      }
                      data-testid="addSuspenseTextBox"
                    />
                  </RACTableCell>
                </RACTableRow>
              )
            );
          }
        )}
      </>
    );
  };

  /**
   * This function triggers when add button is clicked
   */
  const onAddHandle = () => {
    setAgreementDetails(agreementDetailsClone);
    setRenderer((prev) => !prev);
    const customerDetail = JSON.parse(JSON.stringify(customerInfo));
    customerDetail.carryRentEnable = false;
    setCustomerInfo(customerDetail);
    setModalCardEnable({ ...modalCardEnable, addSuspensePopup: false });
    setRenderContext(!renderContext);
  };

  /**
   * PS_SC_06 - PS_SC_08
   * Within return, it binds grid with add and cancel button
   */
  return (
    <Grid
      item
      data-testid="addcheckbtnmode"
      id="check-info"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="carrywaive"
      aria-hidden="true"
    >
      <Grid container spacing={2} className={classes.px3}>
        <RACTable
          className={classes.racGrid}
          renderTableHead={bindSuspenseHeader}
          renderTableContent={bindSuspenseBody}
        />
      </Grid>

      <Grid
        className={`${classes.floatRight} ${classes.modalfooter}`}
        style={{ marginTop: '20px' }}
      >
        <RACButton
          className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
          variant="outlined"
          color="primary"
          data-testid="addSusp-cancelBtn"
          style={{
            backgroundColor: 'white',
            textTransform: 'none',
            fontWeight: 'bolder',
            paddingLeft: '14px',
            paddingRight: '14px',
            color: '#2179fe',
          }}
          onClick={() =>
            setModalCardEnable({ ...modalCardEnable, addSuspensePopup: false })
          }
          data-bs-toggle="modal"
        >
          Cancel
        </RACButton>

        <RACButton
          className={`${classes.paymentSmallBtn1}`}
          color="primary"
          variant="contained"
          onClick={() => onAddHandle()}
          disabled={addButtonDisable}
          data-testid="ADD-addsuspense"
        >
          Add
        </RACButton>
      </Grid>
    </Grid>
  );
}

/**
 * PS_SC_22 - PS_SC_39
 * When use suspense is triggered
 * @returns
 */
export function UseSuspenseComponent() {
  /**
   * PS_SC_24 - PS_SC_25
   * Declaring and initializing variables
   */
  const classes = takePaymentStyles();
  const payclasses = PayHistory();
  const {
    agreementDetails,
    setAgreementDetails,
    modalCardEnable,
    setModalCardEnable,
    renderContext,
    setRenderContext,
  } = useContext<AgreementContextValue>(agreementContext);

  const [agreementDetailsClone] = useState<AgreementDetails[]>(
    JSON.parse(JSON.stringify(agreementDetails))
  );

  const [renderer, setRenderer] = useState<boolean>(false);
  const [useButtonDisable, setUseButtonDisable] = useState<boolean>(false);

  /**
   * Within useEffect, set the disable condition for button
   */
  useEffect(() => {
    setUseButtonDisable(
      agreementDetailsClone.some((el: AgreementDetails) => el.error)
    );
  }, [renderer]);

  /**
   * PS_SC_33 - PS_SC_38
   * This function triggers in onChange of input field is clicked
   * @param e
   * @param param1
   */
  const suspenseUseChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    { suspenseAmount }
  ) => {
    const value = e.target.value.replace(
      CONSTANTS.REPLACE_COMMA,
      CONSTANTS.EMPTY_STRING
    );
    const index = agreementDetailsClone.findIndex(
      (el: AgreementDetails) => el.agreementId == e.target.id
    );

    agreementDetailsClone[index].suspenseDetails.suspUse = value;

    if (Number(value) > Number(suspenseAmount))
      agreementDetailsClone[index].error =
        CONSTANTS.AMOUNT_GREATER_THAN_AVAILABLE_SUSPENSE;
    else delete agreementDetailsClone[index]?.error;

    setRenderer((prev) => !prev);
  };

  /**
   * PS_SC_29 - PS_SC_32
   * This function binds the suspense data to the grid
   * @returns
   */
  const bindSuspenseGrid = () => {
    return (
      <>
        {agreementDetailsClone.map(
          (eachAgreement: AgreementDetails, index: number) => {
            const {
              agreementNumber,
              agreementSchedule,
              totalDueAmount,
              epoAmount,
              suspenseAmount,
              agreementId,
            } = eachAgreement;
            return (
              eachAgreement.selected && (
                <RACTableRow
                  key={index}
                  className={payclasses.payHistbgTransparent}
                >
                  <RACTableCell>
                    <Typography variant="caption" color="primary">
                      {agreementNumber}
                    </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption">
                      {' '}
                      {schedule(agreementSchedule)}{' '}
                    </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption">{totalDueAmount}</Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption"> {epoAmount} </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption">{suspenseAmount}</Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <RACTextbox
                      id={agreementId}
                      isCurrency={true}
                      className={`${classes.custDigit} ${classes.cashtxtbox}`}
                      dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                      value={eachAgreement.suspenseDetails.suspUse}
                      data-testid="suspensetextbox"
                      name={'suspenseTxtbox' + index}
                      digitFormat={'currency'}
                      type="number"
                      maxlength={10}
                      errorMessage={
                        eachAgreement.error ?? CONSTANTS.EMPTY_STRING
                      }
                      OnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        suspenseUseChange(e, { suspenseAmount })
                      }
                    />
                  </RACTableCell>
                </RACTableRow>
              )
            );
          }
        )}
      </>
    );
  };

  /**
   * This function triggers when use button is clicked
   */
  const onUseHandle = () => {
    setAgreementDetails(agreementDetailsClone);
    setRenderer((prev) => !prev);
    setModalCardEnable({ ...modalCardEnable, useSuspensePopup: false });
    setRenderContext(!renderContext);
  };

  /**
   * PS_SC_26 - PS_SC_28
   * Within return, it binds grid with use and cancel button
   */
  return (
    <Grid
      item
      data-testid="addcheckbtnmode"
      id="check-info"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="carrywaive"
      aria-hidden="true"
    >
      <Grid container spacing={2} className={classes.px3}>
        <RACTable
          className={classes.racGrid}
          renderTableHead={bindSuspenseHeader}
          renderTableContent={bindSuspenseGrid}
        />
      </Grid>

      <Grid
        className={`${classes.floatRight} ${classes.modalfooter}`}
        style={{ marginTop: '20px' }}
      >
        <RACButton
          data-testid="useSusp-cancelBtn"
          className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
          variant="outlined"
          color="primary"
          style={{
            backgroundColor: 'white',
            textTransform: 'none',
            fontWeight: 'bolder',
            paddingLeft: '14px',
            paddingRight: '14px',
            color: '#2179fe',
          }}
          onClick={() =>
            setModalCardEnable({ ...modalCardEnable, useSuspensePopup: false })
          }
          data-bs-toggle="modal"
        >
          Cancel
        </RACButton>
        <RACButton
          className={`${classes.paymentSmallBtn1}`}
          color="primary"
          variant="contained"
          onClick={() => onUseHandle()}
          data-testid="USE-useSuspense"
          disabled={useButtonDisable}
        >
          Use
        </RACButton>
      </Grid>
    </Grid>
  );
}

/**
 * PS_SC_40 - PS_SC_70
 * When transfer is triggered
 * @returns
 */
export function TransferSuspenseComponent() {
  /**
   * PS_SC_42 - PS_SC_43
   * Declaring and initializing variables
   */
  const classes = takePaymentStyles();
  const payclasses = PayHistory();
  const {
    agreementDetails,
    setAgreementDetails,
    renderContext,
    setRenderContext,
    modalCardEnable,
    setModalCardEnable,
  } = useContext<AgreementContextValue>(agreementContext);
  const [agreementDetailsClone, setAgreementDetailsClone] = useState<
    AgreementDetails[]
  >(JSON.parse(JSON.stringify(agreementDetails)));
  const storeNumber = sessionStorage.getItem(CONSTANTS.STORE_NUMBER);
  const [render, setRenderer] = useState<boolean>(false);
  const [newCOA, setNewCOA] = useState<boolean>(false);
  const transferAgreements = agreementDetailsClone.filter(
    (eachAgreement: AgreementDetails) =>
      eachAgreement.storeNumber === storeNumber &&
      eachAgreement.agreementType == CONSTANTS.RENTAL_AGREEMENT_RTO &&
      eachAgreement.agreementStatus == CONSTANTS.ACTIVE &&
      !CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(eachAgreement.nextDueDate)
  );
  const [coaTransferPopup, setCoaTransferPopup] = useState<boolean>(false);
  const [checkboxState, setCheckboxState] = useState<boolean>(false);

  useEffect(() => {
    transferAgreements.length > 1 ||
    transferAgreements[0]?.suspenseDetails?.transferSusUse !=
      CONSTANTS.ZREO_TWO_DECIMAL_STRING
      ? setNewCOA(false)
      : setNewCOA(true);
  }, [render]);

  /**
   * PS_SC_57 - PS_SC_62
   * This function triggers in onChange of input field is clicked
   * @param e
   * @param param1
   * @param onBlur
   */
  function transferSusupense(
    e: React.ChangeEvent<HTMLInputElement>,
    { epoAmount, suspenseAmount },
    onBlur = false
  ) {
    const value = e.target.value.replace(
      CONSTANTS.REPLACE_COMMA,
      CONSTANTS.EMPTY_STRING
    );
    const index = agreementDetailsClone.findIndex(
      (el) => el.agreementId == e.target.id
    );

    if (!onBlur)
      agreementDetailsClone[index].suspenseAmountNew = e.target.value;

    if (Number(value) < Number(epoAmount)) {
      delete agreementDetailsClone[index]?.error;

      if (onBlur && Number(value) > suspenseAmount)
        agreementDetailsClone[index].suspenseDetails.transferSusAdd = (
          Number(value) - Number(suspenseAmount)
        ).toFixed(2);
      else if (onBlur && Number(value) < suspenseAmount)
        agreementDetailsClone[index].suspenseDetails.transferSusUse = (
          Number(suspenseAmount) - Number(value)
        ).toFixed(2);
      else {
        agreementDetailsClone[index].suspenseDetails.transferSusUse =
          CONSTANTS.ZREO_TWO_DECIMAL_STRING;
        agreementDetailsClone[index].suspenseDetails.transferSusAdd =
          CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      }
    } else
      agreementDetailsClone[index].error = CONSTANTS.AMOUNT_GREATER_THAN_EPO;

    setRenderer((prev) => !prev);
  }

  /**
   * This function triggers when transfer button is clicked
   */
  function onTransferClick() {
    setAgreementDetails(
      agreementDetailsClone.map((el) => {
        delete el?.suspenseAmountNew;
        return el;
      })
    );

    setModalCardEnable({ ...modalCardEnable, transferSuspensePopup: false });
    setRenderContext(!renderContext);
  }

  /**
   * PS_HFS_80 - PS_HFS_87
   * This function returns amount based on the argument received
   * @param value
   * @returns
   */
  const filterAgreements = (data: AgreementDetails[]) => {
    return data.filter(
      (eachAgreement: AgreementDetails) =>
        eachAgreement.agreementType != CONSTANTS.CLUB &&
        !eachAgreement.isExchanged &&
        eachAgreement.storeNumber === storeNumber &&
        eachAgreement.agreementType == CONSTANTS.RENTAL_AGREEMENT_RTO &&
        eachAgreement.agreementStatus == CONSTANTS.ACTIVE &&
        !CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(eachAgreement.nextDueDate)
    );
  };
  function totalAmount(value?: string): string | number | boolean {
    const currentSusp = filterAgreements(agreementDetails).reduce(
      (accum, item) => accum + Number(item.suspenseAmount),
      0
    );

    const newSusp = filterAgreements(agreementDetailsClone).reduce(
      (accum, item) =>
        accum +
        (item?.suspenseAmountNew != undefined
          ? Number(item?.suspenseAmountNew)
          : Number(item?.suspenseAmount) +
            Number(item.suspenseDetails.transferSusAdd) -
            Number(item.suspenseDetails.transferSusUse)),
      0
    );

    return amountValue(currentSusp, newSusp, value);
  }

  function amountValue(currentSusp: number, newSusp: number, value?: string) {
    switch (value) {
      case CONSTANTS.CURRENT_SUSP:
        return currentSusp;
      case CONSTANTS.NEW_SUSP:
        return newCOA ? CONSTANTS.ZREO_TWO_DECIMAL_STRING : newSusp;
      case CONSTANTS.DIFF_SUSP:
        return newCOA
          ? CONSTANTS.ZREO_TWO_DECIMAL_STRING
          : currentSusp - newSusp;
      case CONSTANTS.NEW_COA:
        return newCOA &&
          transferAgreements[0]?.suspenseDetails?.transferSusUse !=
            CONSTANTS.ZREO_TWO_DECIMAL_STRING
          ? currentSusp
          : CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      default:
        return handleDefaultCase(currentSusp, newSusp);
    }
  }

  function handleDefaultCase(currentSusp: number, newSusp: number) {
    if (!newCOA && transferAgreements.length == 1) {
      return true;
    } else if (newCOA && checkboxState) {
      return false;
    } else if (!newCOA && currentSusp - newSusp === 0) {
      return false;
    }
    return true;
  }

  /**
   * PS_SC_52 - PS_SC_56
   * This function binds the suspense data to the grid
   * @returns
   */
  const bindSuspenseGrid = () => {
    return (
      <>
        {agreementDetailsClone.map(
          (eachAgreement: AgreementDetails, index: number) => {
            const {
              agreementNumber,
              agreementSchedule,
              totalDueAmount,
              epoAmount,
              suspenseAmount,
              agreementId,
            } = eachAgreement;

            const agreementOld = agreementDetails.find(
              (el) => el?.agreementId == agreementId
            );

            const suspAmount: string = (
              Number(agreementOld?.suspenseAmount) +
              Number(agreementOld?.suspenseDetails?.transferSusAdd) -
              Number(agreementOld?.suspenseDetails?.transferSusUse)
            ).toFixed(2);

            const textBoxValue = eachAgreement?.suspenseAmountNew ?? suspAmount;

            return (
              eachAgreement.agreementType != CONSTANTS.CLUB &&
              !eachAgreement.isExchanged &&
              eachAgreement.storeNumber === storeNumber &&
              eachAgreement.agreementType == CONSTANTS.RENTAL_AGREEMENT_RTO &&
              eachAgreement.agreementStatus == CONSTANTS.ACTIVE &&
              !CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(
                eachAgreement.nextDueDate
              ) && (
                <RACTableRow
                  key={index}
                  className={payclasses.payHistbgTransparent}
                >
                  <RACTableCell>
                    <Typography variant="caption" color="primary">
                      {agreementNumber}
                    </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption">
                      {' '}
                      {schedule(agreementSchedule)}{' '}
                    </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption">{totalDueAmount}</Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption"> {epoAmount} </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption">{suspAmount}</Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <RACTextbox
                      id={agreementId}
                      isCurrency={true}
                      className={`${classes.custDigit} ${classes.cashtxtbox}`}
                      dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                      value={textBoxValue}
                      disabled={newCOA}
                      data-testid="suspensetextbox"
                      name={'suspenseTxtbox' + index}
                      digitFormat="currency"
                      type="number"
                      maxlength={10}
                      errorMessage={
                        eachAgreement.error ?? CONSTANTS.EMPTY_STRING
                      }
                      OnChange={(e) =>
                        transferSusupense(e, {
                          epoAmount,
                          suspenseAmount: Number(suspenseAmount).toFixed(2),
                        })
                      }
                      Onblur={(e) =>
                        transferSusupense(
                          e,
                          {
                            epoAmount,
                            suspenseAmount: Number(suspenseAmount).toFixed(2),
                          },
                          true
                        )
                      }
                    />
                  </RACTableCell>
                </RACTableRow>
              )
            );
          }
        )}
      </>
    );
  };

  /**
   * This function is triggers while checking or unchecking the check box
   * @param checked
   */
  const handleCOAsuspense = (checked: boolean) => {
    if (checked) {
      setAgreementDetailsClone(
        agreementDetailsClone.map((eachAgreement: AgreementDetails) => {
          if (
            eachAgreement.selected &&
            eachAgreement.agreementId == transferAgreements[0].agreementId
          )
            return {
              ...eachAgreement,
              suspenseDetails: {
                ...eachAgreement.suspenseDetails,
                suspUse: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                transferSusUse: eachAgreement.suspenseAmount,
                transferedToCOA: true,
              },
            };
          else return { ...eachAgreement };
        })
      );
    } else {
      setAgreementDetailsClone(
        agreementDetailsClone.map((eachAgreement: AgreementDetails) => {
          if (
            eachAgreement.selected &&
            eachAgreement.agreementId == transferAgreements[0].agreementId
          )
            return {
              ...eachAgreement,
              suspenseDetails: {
                ...eachAgreement.suspenseDetails,
                transferSusUse: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                transferedToCOA: false,
              },
            };
          else return { ...eachAgreement };
        })
      );
    }
  };

  /**
   * PS_SC_44 - PS_SC_51
   * Within return, it binds grid with transfer and cancel button
   */
  return (
    <Grid
      item
      data-testid="transferSuspense"
      id="check-info"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="carrywaive"
      aria-hidden="true"
    >
      <Grid container spacing={2} className={classes.px3}>
        <RACTable
          className={classes.racGrid}
          renderTableHead={bindSuspenseHeader}
          renderTableContent={bindSuspenseGrid}
        />
      </Grid>
      <Grid>
        <RACCheckBox
          disabled={!newCOA}
          onChange={(e) => {
            setCheckboxState(e.target.checked);
            handleCOAsuspense(e.target.checked);
          }}
          label={'Transfer Return Agreement Suspense to COA'}
          data-testid="coaCheckBox"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={7}>
        <Grid
          className={` ${payclasses.payHistbgLightBlue} ${payclasses.payHistp3} ${payclasses.payHistmt2} ${payclasses.payHistborderRadius5}`}
        >
          <Grid container className={payclasses.payHistjustifySpaceBetween}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={7}
              className={payclasses.payHistmb2}
            >
              <Grid container>
                <Grid item xs="auto">
                  <Typography
                    className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7}`}
                  >
                    {' '}
                    Current Suspense Total:{' '}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography
                    className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7} ${payclasses.payHistfontBold}`}
                    data-testid="currentSuspense"
                  >
                    {'  ' +
                      CONSTANTS.DOLLAR +
                      totalAmount(CONSTANTS.CURRENT_SUSP)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={6}
              lg={5}
              className={payclasses.payHistmb2}
            >
              <Grid container>
                <Grid item xs="auto">
                  <Typography
                    className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7}`}
                  >
                    {' '}
                    New Suspense Total :{' '}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography
                    className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7} ${payclasses.payHistfontBold}`}
                    data-testid="newSuspense"
                  >
                    {CONSTANTS.DOLLAR + totalAmount(CONSTANTS.NEW_SUSP)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {transferAgreements.length == 1 &&
            Number(transferAgreements[0].suspenseAmount) > 0 ? (
              <Grid
                xs={12}
                sm={12}
                md={6}
                lg={5}
                className={payclasses.payHistmb2}
              >
                <Grid container>
                  <Grid item xs="auto">
                    <Typography
                      className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7}`}
                    >
                      {' '}
                      New COA Total :{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography
                      className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7} ${payclasses.payHistfontBold}`}
                      data-testid="newCOA"
                    >
                      {CONSTANTS.DOLLAR + totalAmount(CONSTANTS.NEW_COA)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Grid
              xs={12}
              sm={12}
              md={6}
              lg={5}
              className={payclasses.payHistmb2}
            >
              <Grid container>
                <Grid item xs="auto">
                  <Typography
                    className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7}`}
                  >
                    {' '}
                    Difference :{' '}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography
                    className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7} ${payclasses.payHistfontBold}`}
                    data-testid="difference"
                  >
                    ${Number(totalAmount(CONSTANTS.DIFF_SUSP)).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={`${classes.floatRight} ${classes.modalfooter}`}>
        <RACButton
          className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
          variant="outlined"
          color="primary"
          style={{
            backgroundColor: 'white',
            textTransform: 'none',
            fontWeight: 'bolder',
            paddingLeft: '14px',
            paddingRight: '14px',
            color: '#2179fe',
          }}
          onClick={() =>
            setModalCardEnable({
              ...modalCardEnable,
              transferSuspensePopup: false,
            })
          }
          data-testid="cancelButton"
          data-bs-toggle="modal"
        >
          Cancel
        </RACButton>
        <RACButton
          className={`${classes.paymentSmallBtn1}`}
          color="primary"
          variant="contained"
          disabled={
            (totalAmount() as boolean) ||
            agreementDetailsClone.some((el) => el.error)
          }
          onClick={() => {
            if (newCOA) {
              setCoaTransferPopup(true);
            } else onTransferClick();
          }}
          data-testid="transferButton"
        >
          Transfer
        </RACButton>
      </Grid>

      {coaTransferPopup ? (
        <ConfirmTransferSuspenseBind
          amount={totalAmount(CONSTANTS.NEW_COA)}
          coaPopup={coaTransferPopup}
          setCoaPopup={setCoaTransferPopup}
          onOk={onTransferClick}
        />
      ) : null}
    </Grid>
  );
}

/**
 * This function holds the header for suspense grid popup
 * @returns
 */
export function bindSuspenseHeader() {
  const classes = takePaymentStyles();
  const headerArray = CONSTANTS.SUSPENSE_POPUP_HEADER;
  return (
    <>
      {headerArray.map((val, index) => {
        return val === null ? (
          <RACTableCell key={index} className={`${classes.font12}`}>
            <span style={{ float: 'right' }}>{val}</span>
          </RACTableCell>
        ) : (
          <RACTableCell key={index} className={`${classes.font12}`}>
            {val}
          </RACTableCell>
        );
      })}
    </>
  );
}

/**
 * This function holds popup for coa
 * @param param0
 * @returns
 */
export const ConfirmTransferSuspenseBind = ({
  amount,
  coaPopup,
  setCoaPopup,
  onOk,
}) => {
  const classes = takePaymentStyles();

  const popoupBind = () => {
    return (
      <Grid item id="transatraction" aria-hidden="true">
        <Grid item md={12} className={classes.textcenter}>
          <Typography
            className={`${classes.bold} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            data-testid="coaPopupText"
          >
            You are attempting to move ${amount} from suspense to
            customer&apos;s credit on account. Would you like to continue ?
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="secondary"
            variant="outlined"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => setCoaPopup(false)}
            data-bs-target="#delivery-receipt"
            data-testid="coaPopupNobtn"
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => onOk()}
            data-bs-target="#delivery-receipt"
            data-testid="coaPopupYesBtn"
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <RACModalCard
        isOpen={coaPopup}
        maxWidth="xs"
        onClose={() => setCoaPopup(false)}
        closeIcon={false}
        borderRadius={'25px !important'}
      >
        {popoupBind()}
      </RACModalCard>
    </>
  );
};
