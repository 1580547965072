/* eslint-disable react-hooks/exhaustive-deps */
/**
 * PS_CHKP_1
 */
import React, { useEffect, useState, useContext } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import {
  RACSelect,
  RACTextbox,
  RACButton,
  RACModalCard,
  Grid,
  Typography,
} from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import CurrencyInput from 'react-currency-input-field';
import {
  CheckTypesInterface,
  CheckDataInterface,
  PropInterFace,
  CheckErrorInterface,
  CheckRegexInterface,
} from '../interface/addCheckInterface';
import CONSTANTS from '../constants/constant';
import { tenderDetails } from '../utils/amountCalculations';

/**
 * PS_CHKP_2
 * @param props
 * @returns
 */
export const AddCheck = (props: PropInterFace) => {
  /**
   * PS_CHKP_3
   */
  const classes = takePaymentStyles();
  const checkDetails: CheckDataInterface = {
    typeOfCheck: CONSTANTS.ZERO_STRING,
    checkName: CONSTANTS.EMPTY_STRING,
    checkNumber: CONSTANTS.EMPTY_STRING,
    writtenTo: CONSTANTS.EMPTY_STRING,
    writtenBy: CONSTANTS.EMPTY_STRING,
    amount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    orderId: 0,
  };

  const CheckDataError: CheckErrorInterface = {
    typeOfCheck: CONSTANTS.EMPTY_STRING,
    checkNumber: CONSTANTS.EMPTY_STRING,
    writtenTo: CONSTANTS.EMPTY_STRING,
    writtenBy: CONSTANTS.EMPTY_STRING,
    amount: CONSTANTS.EMPTY_STRING,
  };
  const checkRegex: CheckRegexInterface = {
    checkNumber: CONSTANTS.ONLY_NUMERIC_ALLOWED,
    writtenTo: CONSTANTS.ONLY_CHARS_ALLOWED,
    writtenBy: CONSTANTS.ONLY_CHARS_ALLOWED,
  };
  const [checkData, setCheckData] = useState<CheckDataInterface>(checkDetails);
  const [checkError, setCheckError] =
    useState<CheckErrorInterface>(CheckDataError);
  const [checkAlert, setCheckAlert] = useState<boolean>(false);

  const [checkAlertMessage, setCheckAlertMessage] = useState<string>(
    CONSTANTS.EMPTY_STRING
  );

  const checkTypes: CheckTypesInterface[] = CONSTANTS.CHECK_TYPE_ARRAY;
  const agreementInfo = useContext(agreementContext);

  /**
   * PS_CHKP_4
   */
  useEffect(() => {
    if (
      props.openCheckForm &&
      props.editMode &&
      props.id != CONSTANTS.MINUS_ONE_NUMBER
    ) {
      setCheckData(
        agreementInfo.customerInfo.amountUsed.tenderDetails.checkDetails[
          props.id
        ]
      );
    }
    setCheckError(CheckDataError);
  }, [props.editMode, props.id]);

  /**
   * PS_CHKP_5
   * @param name
   * @param value
   */
  const onChange = (name: string, value: string | undefined): void => {
    if (value == undefined) return;
    if (name == CONSTANTS.TYPE_OF_CHECK_STRING) {
      const checkName = checkTypes.filter((e) => e.value == value);
      setCheckData((prevState) => ({
        ...prevState,
        [name]: value,
        checkName: checkName[0]?.label,
      }));
    } else if (name == CONSTANTS.AMOUNT) {
      (parseFloat(value) < CONSTANTS.HUNDRED_THOUSAND_DECIMAL ||
        value === undefined) &&
        setCheckData((prevState) => ({ ...prevState, [name]: value }));
    } else {
      setCheckData((prevState) => ({
        ...prevState,
        [name]: value.replace(checkRegex[name], CONSTANTS.EMPTY_STRING),
      }));
    }
  };

  /**
   * PS_CHKP_14
   */
  const amountOnBlur = () => {
    checkData.amount
      ? setCheckData((prevState) => ({
          ...prevState,
          amount: Number(prevState.amount).toFixed(2),
        }))
      : setCheckData((prevState) => ({
          ...prevState,
          amount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        }));
  };

  /**
   * PS_CHKP_8, PS_CHKP_9
   * @returns
   */
  const validate = (): boolean => {
    let isError = false;

    const validationRules = [
      {
        field: 'typeOfCheck',
        condition: checkData.typeOfCheck === CONSTANTS.ZERO_STRING,
        errorMessage: CONSTANTS.PLEASE_SELECT_THE_CHECK_TYPE,
      },
      {
        field: 'checkNumber',
        condition: checkData.checkNumber === CONSTANTS.EMPTY_STRING,
        errorMessage: CONSTANTS.PLEASE_ENTER_CHECK_NUMBER,
      },
      {
        field: 'writtenTo',
        condition: checkData.writtenTo === CONSTANTS.EMPTY_STRING,
        errorMessage: CONSTANTS.PLEASE_ENTER_WRITTEN_TO,
      },
      {
        field: 'writtenBy',
        condition: checkData.writtenBy === CONSTANTS.EMPTY_STRING,
        errorMessage: CONSTANTS.PLEASE_ENTER_WRITTEN_TO,
      },
      {
        field: 'amount',
        condition:
          checkData.amount == undefined || Number(checkData.amount) <= 0,
        errorMessage: CONSTANTS.AMOUNT_MUST_BE_GREATER_THAN_ZERO,
      },
    ];

    validationRules.forEach(({ field, condition, errorMessage }) => {
      if (condition) {
        setCheckError((prevState) => ({
          ...prevState,
          [field]: errorMessage,
        }));
        isError = true;
      } else {
        setCheckError((prevState) => ({
          ...prevState,
          [field]: CONSTANTS.EMPTY_STRING,
        }));
      }
    });

    if (!isError) {
      isError = validateOverpayment();
    }

    return !isError;
  };

  const validateOverpayment = (): boolean => {
    const checkMoDetails = tenderDetails(agreementInfo, CONSTANTS.CHECK);
    const totAmountforTender = checkMoDetails.filter(
      (check) => check.orderId === checkData.orderId
    );

    const amountEnteredBefore =
      totAmountforTender.length === CONSTANTS.ZERO_NUMBER ||
      Number(totAmountforTender[0].amount) <= 0
        ? CONSTANTS.ZERO_NUMBER
        : Number(totAmountforTender[0].amount);

    const isOverpayment =
      Number(checkData.amount) - amountEnteredBefore >
      Number(agreementInfo.customerInfo.amountUsed.remainingAmountDue);

    if (isOverpayment) {
      if (checkData.typeOfCheck == CONSTANTS.ONE_STRING) {
        setCheckAlertMessage(
          CONSTANTS.PERSONAL_CHECK_IS_NOT_APPLICABLE_FOR_OVER_PAYMENT
        );
        setCheckAlert(true);
        return true;
      } else if (checkData.typeOfCheck == CONSTANTS.TWO_STRING) {
        setCheckAlertMessage(
          CONSTANTS.TRAVELLER_CHECK_IS_NOT_APPLICABLE_FOR_OVER_PAYMENT
        );
        setCheckAlert(true);
        return true;
      }
    }

    return false;
  };

  /**
   * PS_CHKP_7
   */
  const save = () => {
    if (validate()) {
      if (props.editMode) {
        const currentCheckData = JSON.parse(
          JSON.stringify(agreementInfo.customerInfo)
        );
        currentCheckData.amountUsed.tenderDetails.checkDetails[props.id] =
          checkData;
        agreementInfo.setCustomerInfo(currentCheckData);
        props.render.setRerenderReaminingAmount(
          !props.render.rerenderReaminingAmount
        );
      } else {
        const customerInfoClone = JSON.parse(
          JSON.stringify(agreementInfo.customerInfo)
        );

        customerInfoClone.amountUsed.tenderDetails.orderId =
          customerInfoClone.amountUsed.tenderDetails.orderId +
          CONSTANTS.ONE_NUMBER;
        checkData.orderId = customerInfoClone.amountUsed.tenderDetails.orderId;

        customerInfoClone.amountUsed.tenderDetails.checkDetails.push(checkData);

        agreementInfo.setCustomerInfo(customerInfoClone);
        props.render.setRerenderReaminingAmount(
          !props.render.rerenderReaminingAmount
        );
      }
      close();
    }
  };

  /**
   * PS_CHKP_10
   */
  const close = () => {
    props.closeAddCheckPopup();
    setCheckData(checkDetails);
    setCheckError(CheckDataError);
  };

  /**
   * PS_CHKP_11, PS_CHKP_12
   * @returns
   */
  const addCheckPopup = () => {
    return (
      <Grid
        item
        data-testid="addcheckbtnmode"
        id="check-info"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="carrywaive"
        aria-hidden="true"
      >
        <Grid container spacing={2} className={classes.px3}>
          <Grid item md={6} className={classes.mb2}>
            <RACSelect
              data-testid="dropmainId"
              options={checkTypes}
              defaultValue={checkData?.typeOfCheck}
              inputLabel="Type of Check"
              required={true}
              onChange={(e) =>
                onChange(CONSTANTS.TYPE_OF_CHECK_STRING, e.target.value)
              }
            ></RACSelect>
            {checkError.typeOfCheck ? (
              <label className={classes.mandatoryfield}>
                {checkError?.typeOfCheck}
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              type="text"
              maxlength={20}
              id="Check#"
              name="checkNumber"
              required={true}
              inputlabel=" Check #"
              value={checkData?.checkNumber}
              OnChange={(e) => onChange(e.target.name, e.target.value)}
              data-testid="checkno"
              isCurrency={false}
            />
            {checkError.checkNumber ? (
              <label className={classes.mandatoryfield}>
                {checkError?.checkNumber}
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              type="text"
              maxlength={30}
              required={true}
              inputlabel="Written To"
              id="WrittenTo"
              name="writtenTo"
              value={checkData?.writtenTo}
              OnChange={(e) => onChange(e.target.name, e.target.value)}
              data-testid="writtento"
              isCurrency={false}
            />
            {checkError.writtenTo ? (
              <label className={classes.mandatoryfield}>
                {checkError?.writtenTo}
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              isCurrency={false}
              maxlength={30}
              id="Writtenby"
              name="writtenBy"
              inputlabel="Written By"
              required={true}
              value={checkData?.writtenBy}
              OnChange={(e) => onChange(e.target.name, e.target.value)}
              data-testid="writtenby"
            />
            {checkError.writtenBy ? (
              <label className={classes.mandatoryfield}>
                {checkError?.writtenBy}
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <label className={classes.labeltxtstyle}>Amount</label>
            <span className={classes.mandatoryfield}></span>
            <div className={classes.inputgroup}>
              <span className={classes.inputgrouptext}>$</span>
              <CurrencyInput
                type="text"
                name="amount"
                className={classes.formcontrol3}
                value={checkData?.amount}
                onBlur={() => {
                  amountOnBlur();
                }}
                onValueChange={(e: string | undefined) =>
                  onChange(CONSTANTS.AMOUNT, e)
                }
                decimalsLimit={2}
                data-testid="amountbox"
              />
            </div>
            {checkError.amount ? (
              <label className={classes.mandatoryfield}>
                {checkError?.amount}
              </label>
            ) : null}
          </Grid>
        </Grid>

        <Grid className={`${classes.textright} ${classes.modalfooter}`}>
          <RACButton
            data-testid="AC-cancel-btn"
            className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
            variant="outlined"
            color="primary"
            style={{
              backgroundColor: 'white',
              textTransform: 'none',
              fontWeight: 'bolder',
              paddingLeft: '14px',
              paddingRight: '14px',
              color: '#2179fe',
            }}
            onClick={() => {
              close();
            }}
            data-bs-toggle="modal"
          >
            Cancel
          </RACButton>

          {props.editMode ? (
            <RACButton
              className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
              color="primary"
              variant="contained"
              onClick={() => save()}
              data-testId="checkupdate"
            >
              Update
            </RACButton>
          ) : (
            <RACButton
              className={`${classes.paymentSmallBtn1}`}
              color="primary"
              variant="contained"
              onClick={() => save()}
              data-testid="addchecksave"
            >
              Save
            </RACButton>
          )}
        </Grid>
      </Grid>
    );
  };

  /**
   * PS_CHKP_9
   * @returns
   */
  const checkAlertPopup = () => {
    return (
      <Grid item data-testid="personal">
        <Grid
          item
          id="transaction"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="transaction"
          aria-hidden="true"
        >
          <Typography className={`${classes.textcenter} ${classes.mt3}`}>
            {checkAlertMessage}
          </Typography>

          <Grid
            item
            md={12}
            className={`${classes.textcenter} ${classes.mt3} ${classes.modalfooter}`}
          >
            <RACButton
              className={classes.mx1}
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              color="primary"
              variant="contained"
              onClick={() => {
                setCheckAlert(false);
              }}
              data-bs-target="#delivery-receipt"
              data-testid="PersonalOkId"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  /**
   * PS_CHKP_13
   */
  return (
    <>
      <RACModalCard
        isOpen={props.openCheckForm}
        maxWidth="xs"
        title="Check Information"
        onClose={() => close()}
        closeIcon={true}
      >
        {addCheckPopup()}
      </RACModalCard>

      <RACModalCard
        isOpen={checkAlert}
        maxWidth="xs"
        onClose={() => setCheckAlert(false)}
      >
        {checkAlertPopup()}
      </RACModalCard>
    </>
  );
};
